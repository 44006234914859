import React from 'react';
import styles from './AddMember.module.scss';
import {PlusL} from '../../assets/icons';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

const AddMember = ({onClick}) => {
  const {t} = useTranslation();
  const {update_loading} = useSelector(({credit_form}) => credit_form);
  return (
    <button disabled={update_loading} type='button' onClick={onClick} className={styles.root}>
      <span>
        <PlusL />
      </span>
      <p>{t('ADD_MEMBER.title')}</p>
    </button>
  );
};

export default AddMember;
