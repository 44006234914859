import React, {useRef, useState} from 'react';
import styles from './DropdownSelectCredit.module.scss';
import {useOnClickOutside} from '../../helpers/hooks';
import {Triangle} from '../../assets/icons';
import {v4 as uuidv4} from 'uuid';

const mockArr = [
  {id: 1, title: 'Poltava'},
  {id: 2, title: 'Kharkiv'},
  {id: 3, title: 'Lviv'},
  {id: 4, title: 'Oslo'},
  {id: 5, title: 'Sumy'}
];

const DropdownSelectCredit = ({
  label = 'Number',
  values,
  isTouched,
  border = null,
  error,
  onChange,
  subtitle = 'Select number or number range',
  className = '',
  placeholder = 'Choose some data',
  inputProps
}) => {
  const ref2 = useRef();
  const [openSelect, setOpenSelect] = useState(false);
  const [choose, setChoosen] = useState(null);
  const {onBlur, ...otherProps} = inputProps;

  useOnClickOutside(ref2, () => {
    setOpenSelect(false);
  });
  const options = !values ? mockArr : values;
  const colorClass = isTouched
    ? !error
      ? otherProps.value !== null && 'green'
      : 'red'
    : error
    ? 'red'
    : placeholder && !error
    ? 'green'
    : '';

  let blurTimeout;
  const handleBlur = () => {
    blurTimeout = setTimeout(() => {
      onBlur();
    }, 200);
  };
  return (
    <div
      onClick={() => {
        setOpenSelect(!openSelect);
      }}
      className={`${styles.root} ${className} ${colorClass} ${border ? border : 'column--dir-none'}`}
    >
      <p className={styles['select__label']}>{label}</p>
      <div ref={ref2} className={`${styles['select__dropdown']} ${colorClass}`}>
        <button
          type={'button'}
          onClick={() => {
            setOpenSelect(!openSelect);
          }}
          className={styles['select__dropdown--select']}
          onBlur={handleBlur}
          {...otherProps}
        >
          <p className={styles['select__dropdown--placeholder']}> {choose ? choose.title : placeholder}</p>
          <span className={`${styles['triangle__container']} ${colorClass}`}>
            <Triangle className={`${styles['select__dropdown--arrow']} ${openSelect && 'down'} `} />
          </span>
        </button>

        {openSelect && (
          <div className={styles['select__items']}>
            {options &&
              options.length > 0 &&
              options.map((item, idx) => {
                return (
                  <button
                    type={'button'}
                    key={idx}
                    onClick={() => {
                      onChange(item.id);
                      setChoosen(item);
                      setOpenSelect(false);
                      onBlur();
                    }}
                    className={styles['select__item']}
                  >
                    {item.title}
                  </button>
                );
              })}
          </div>
        )}
      </div>
      <span className={styles['select__subtitle']}>{subtitle}</span>
    </div>
  );
};

export default DropdownSelectCredit;
