import React, {useState, useRef} from 'react';
import {PrevMonth, NextMonth} from '../../assets/icons';
import styles from './MonthSelector.module.scss';
import {useTranslation} from 'react-i18next';
import {useOnClickOutside} from '../../helpers/hooks';

const MonthSelector = ({currentMonth, setCurrentMonth, touched, setTouched, setStartDate, setEndDate}) => {
  const ref = useRef();
  const {t} = useTranslation();
  const [popup, setPopup] = useState(false);
  useOnClickOutside(ref, () => setPopup(false));

  const isPrevMonthDisabled = () => {
    // Check if the previous month is less than January 2010
    const prevMonth = new Date(currentMonth);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    return prevMonth < new Date('January 2010');
  };

  const isNextMonthDisabled = () => {
    // Check if the next month is in the future
    const nextMonth = new Date(currentMonth);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    return nextMonth > new Date();
  };

  const handlePrevMonth = () => {
    if (!isPrevMonthDisabled()) {
      const newMonth = new Date(currentMonth);
      newMonth.setMonth(newMonth.getMonth() - 1);

      // Set to the first day of the month
      const firstDayOfMonth = new Date(newMonth.getFullYear(), newMonth.getMonth(), 1);

      // Set to the last day of the previous month
      const lastDayOfPrevMonth = new Date(newMonth.getFullYear(), newMonth.getMonth() + 1, 1);
      lastDayOfPrevMonth.setDate(lastDayOfPrevMonth.getDate() - 1);

      setCurrentMonth(firstDayOfMonth);
      setStartDate(firstDayOfMonth, 'month');
      setEndDate(lastDayOfPrevMonth, 'month');
    }
  };

  const handleNextMonth = () => {
    if (!isNextMonthDisabled()) {
      const newMonth = new Date(currentMonth);
      newMonth.setMonth(newMonth.getMonth() + 1);
      setCurrentMonth(newMonth);

      // Set to the first day of the month
      const firstDayOfMonth = new Date(newMonth.getFullYear(), newMonth.getMonth(), 1);

      // Set to the last day of the previous month
      const lastDayOfPrevMonth = new Date(newMonth.getFullYear(), newMonth.getMonth() + 1, 1);
      lastDayOfPrevMonth.setDate(lastDayOfPrevMonth.getDate() - 1);

      setCurrentMonth(firstDayOfMonth);
      setStartDate(firstDayOfMonth, 'month');
      setEndDate(lastDayOfPrevMonth, 'month');
    }
  };

  const handleShowPopup = () => {
    touched === 'datepicker' && setPopup(true);
  };

  const handleReset = () => {
    setTouched(false);
    setPopup(false);
    const newMonth = new Date(currentMonth);
    newMonth.setMonth(newMonth.getMonth() + 1);
    setCurrentMonth(newMonth);
    // Set to the first day of the month
    const firstDayOfMonth = new Date(newMonth.getFullYear(), newMonth.getMonth(), 1);
    // Set to the last day of the previous month
    const lastDayOfPrevMonth = new Date(newMonth.getFullYear(), newMonth.getMonth() + 1, 1);
    lastDayOfPrevMonth.setDate(lastDayOfPrevMonth.getDate() - 1);
    setCurrentMonth(firstDayOfMonth);
    setStartDate(firstDayOfMonth, 'month');
    setEndDate(lastDayOfPrevMonth, 'month');
  };

  return (
    <>
      <div className={`${styles['monthselector']} ${touched === 'datepicker' && styles['monthselector--disable']}`}>
        <div onClick={handleShowPopup} className={styles.wrapper}>
          <button
            type='button'
            aria-label='prev month'
            className={styles['monthselector__btn']}
            onClick={handlePrevMonth}
            disabled={isPrevMonthDisabled() || touched === 'datepicker'}
          >
            <PrevMonth />
          </button>
          <p className={styles['monthselector__value']}>
            {new Intl.DateTimeFormat('en-US', {month: 'long', year: 'numeric'}).format(currentMonth)}
          </p>
          <button
            type='button'
            aria-label='next month'
            className={styles['monthselector__btn']}
            onClick={handleNextMonth}
            disabled={isNextMonthDisabled() || touched === 'datepicker'}
          >
            <NextMonth />
          </button>
        </div>

        {
          <div ref={ref} className={`${styles['popup']} ${styles[`popup__${popup ? 'open' : 'hide'}`]}`}>
            <p>{t('RECENTTRANSACTIONS.reset')}</p>
            <div className={`${styles['popup__buttons']}`}>
              <button onClick={handleReset} type='button'>
                {t('RECENTTRANSACTIONS.yes')}
              </button>
              <button onClick={() => setPopup(false)} type='button'>
                {t('RECENTTRANSACTIONS.no')}
              </button>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default MonthSelector;
