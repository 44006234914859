import React, {useState, useEffect} from 'react';
import styles from '../Auth.module.scss';
import {BankId, ArrowRightBlack} from '../../../assets/icons';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {postAuthAuthostart, getCollectRequest, resetStore, getCancelRequest} from '../authActions';
import {errorOccurred, iOS, onSuccessRedirect} from '../../../helpers/functions';
import {authPath, mainPath} from '../../../routes/paths';
import {useTranslation} from 'react-i18next';

const BankIdOnTheDevice = ({actionFunc}) => {
  const [collectInterval, setCollectInterval] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const {IP, type, autoStartToken, collectErrorCode, collectStatus, loading} = useSelector(({auth}) => auth);

  const fetchAutostartToken = () => {
    dispatch(postAuthAuthostart({IP}));
  };
  const {t} = useTranslation();

  useEffect(() => {
    fetchAutostartToken();
    return () => {
      dispatch(resetStore());
      if (collectInterval) clearInterval(collectInterval);
    };
  }, []);

  const handleCollectInterval = () => {
    setCollectInterval(
      setInterval(() => {
        dispatch(getCollectRequest(autoStartToken));
      }, 2000)
    );
  };

  //Comentet because issue with safari
  const onStartManuallyClick = () => {
    const iOSUrl = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=null`;
    const url = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
    const startManuallyUrl = iOS() ? iOSUrl : url;
    window.location.href = url;
    parent.postMessage({startManuallyUrl}, '*');
  };

  useEffect(() => {
    if (autoStartToken) {
      // onStartManuallyClick();
      if (collectInterval) clearInterval(collectInterval);
      handleCollectInterval();
    }
  }, [autoStartToken]);

  useEffect(() => {
    if (errorOccurred(collectErrorCode) || collectStatus?.toLowerCase() === 'complete') {
      if (collectInterval) clearInterval(collectInterval);
      if (collectStatus?.toLowerCase() === 'complete') onSuccessRedirect(autoStartToken, type);
    }
  }, [collectErrorCode, collectStatus]);

  //WHEN AUTH WILL BE READY USE THE FUNCTION
  const onCancelClick = () => {
    dispatch(getCancelRequest(autoStartToken));
    dispatch(resetStore());
    if (collectInterval) clearInterval(collectInterval);
    history.push(authPath.choogeAuth.path);
  };

  //test FUNC FOR IPHONE

  const setSessionStorageParams = () => {
    sessionStorage.setItem('session_id', 'test-session-bankid');
    sessionStorage.setItem('IP', '195.60.70.219');
    sessionStorage.setItem('expired_date', '2023-09-15T11:25:20.000Z');
    sessionStorage.setItem('expires', '2023-08-15T08:00:00');
    history.push(mainPath.invoices.path);
  };

  return (
    <>
      <p className={`${styles.text} mb16`}>{t('BANKIDONTHEDEVICE.launched')}</p>
      <p className={`${styles.text} mb40`}>{t('BANKIDONTHEDEVICE.nothinghappen')}</p>

      <button
        onClick={() => {
          actionFunc();
          //setSessionStorageParams();
        }}
        className={styles.auth__type}
      >
        <BankId /> <p>{t('BANKIDONTHEDEVICE.onthedevice')}</p> <ArrowRightBlack />
      </button>
    </>
  );
};

export default BankIdOnTheDevice;
