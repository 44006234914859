import React from 'react';
import {useTranslation} from 'react-i18next';
import {Alarm} from '../../../../assets/icons';
import {mainPath, creditAppPath} from '../../../../routes/paths';
import {Link, useHistory, useLocation} from 'react-router-dom';
import styles from '../ApplicationStatus.module.scss';
import {useDispatch} from 'react-redux';
import {cancelApplication} from '../../creditFormActions';
import {setToastParams} from '../../../Auth/authActions';

const BadLoan = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {search} = useLocation();
  const application_id = search.split('=')[2] || null;

  const handleCancelApplication = () => {
    dispatch(cancelApplication({application_id: application_id}))
      .then((res) => {
        if (res.error) {
          dispatch(
            setToastParams({
              toast_open: true,
              toast_type: false,
              toast_text: t('APPLICATION_STATUS.went_wrong'),
              toast_description: t('APPLICATION_STATUS.went_wrong_try')
            })
          );
          history.push(mainPath.overview.path);
        } else {
          history.push(mainPath.overview.path);
          dispatch(
            setToastParams({
              toast_open: true,
              toast_type: true,
              toast_text: '',
              toast_description: t('APPLICATION_STATUS.toast__text_success')
            })
          );
        }
      })
      .catch((error) => {
        console.error('Error cancelling application:', error);
        dispatch(
          setToastParams({
            toast_open: true,
            toast_type: false,
            toast_text: t('APPLICATION_STATUS.went_wrong'),
            toast_description: t('APPLICATION_STATUS.went_wrong_try')
          })
        );
        history.push(mainPath.overview.path);
      });
  };

  return (
    <>
      <Alarm />
      <h2 className={styles.title}>{t('APPLICATION_STATUS.warning')}</h2>
      <p className={styles.subtitle}>
        <b> {t('APPLICATION_STATUS.according_to')}</b>

        {t('APPLICATION_STATUS.this')}
      </p>

      <Link
        to={creditAppPath.applicationStatusPath.path + `?type=default`}
        className={`${styles.button} ${styles.button__xl} main__button`}
      >
        {t('APPLICATION_STATUS.continue')}
      </Link>

      <button
        disabled={!application_id}
        onClick={handleCancelApplication}
        className={`${styles.link2} ${styles.button__xl}  main__button`}
      >
        {t('APPLICATION_STATUS.want')}
      </button>
    </>
  );
};

export default BadLoan;
