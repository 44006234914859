import {CONTRACTS} from './contractsActionTypes';

export function getContracts(include_rejected = true) {
  return {
    type: CONTRACTS.GET_CONTRACTS,
    payload: {
      client: 'second',
      request: {
        url: `applications/GetCreditContracts?include_rejected=${include_rejected}`,
        method: 'get'
      }
    }
  };
}

export function getPDF(application_id, document_type, file_name) {
  return {
    type: CONTRACTS.GET_PDF,
    payload: {
      client: 'second',
      request: {
        url: `applications/GetPDF?application_id=${application_id}&document_type=${document_type}`,
        method: 'get'
      }
    }
  };
}
