import React, {useEffect} from 'react';
import Header from '../../layout/Header';
import AuthRoutes from '../../routes/AuthRoutes';
import styles from './AuthContainer.module.scss';
import {useDispatch} from 'react-redux';
import {setUserIP} from '../../features/Auth/authActions';
import {ProgressBar} from '../../shared';
import {checkIsApp} from '../../helpers/functions';
import {Helmet} from 'react-helmet';
import {useLocation} from 'react-router-dom';
import {useWindowWidth} from '../../helpers/hooks';

const AuthContainer = () => {
  const dispatch = useDispatch();
  const ip = sessionStorage.getItem('IP');
  const isApp = checkIsApp();
  const {pathname} = useLocation();
  const width = useWindowWidth();

  const isEpiredPage = pathname === '/auth/expired-session';
  const isConfirmEmailPage = pathname.includes('confirmation-email-code') && width < 768;

  useEffect(() => {
    if (!ip) {
      fetch('https://api.ipify.org?format=json')
        .then((response) => response.json())
        .then((data) => {
          dispatch(setUserIP(data.ip));
        })
        .catch((error) => console.error(error));
    } else {
      dispatch(setUserIP(ip));
    }
  }, [ip]);

  return (
    <>
      {isApp ? <ProgressBar /> : !isEpiredPage && !isConfirmEmailPage && <Header isAuth />}
      <Helmet>
        <meta name='description' content='Here can be description for every page' />
        <meta name='keywords' content='Flex limit SEO keywords' />
        <meta property='og:title' content='Your Page Title' />
        <meta property='og:description' content='This is the description of the page.' />
      </Helmet>
      <main className={`${styles.root} ${isEpiredPage && styles.expired__page}`}>
        <div className={styles.content}>
          <AuthRoutes />
        </div>
      </main>
    </>
  );
};

export default AuthContainer;
