import React, {useState} from 'react';
import styles from './PhoneField.module.scss';

const PhoneField = ({label, inputProps, error, className, disabled, placeholder, subtitle}) => {
  const [isInputFocused, setInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleFocus = () => {
    setInputFocused(true);
  };

  const handleBlur = () => {
    setInputFocused(false);
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (inputProps.onChange) {
      inputProps.onChange(e);
    }
  };

  const combinedInputProps = {
    ...inputProps,
    onChange: handleChange,
    onFocus: (e) => {
      handleFocus(e);
      if (inputProps.onFocus) {
        inputProps.onFocus(e);
      }
    },
    onBlur: (e) => {
      handleBlur(e);
      if (inputProps.onBlur) {
        inputProps.onBlur(e);
      }
    }
  };

  return (
    <div className={`${styles.root} ${className} `}>
      {label && (
        <label className={styles.label} htmlFor={label}>
          {label}
        </label>
      )}
      <label
        htmlFor={label}
        className={`${styles.input__area} ${error && styles.error} ${
          isInputFocused && !error ? styles.active_field : ''
        }`}
      >
        <span className={styles.reserved}>+46</span>
        <input
          type='number'
          className={styles.input}
          placeholder={placeholder}
          disabled={disabled}
          id={label}
          {...combinedInputProps}
        />
      </label>

      {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
    </div>
  );
};

export default PhoneField;
