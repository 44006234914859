export const INVOICES = {
  GET_INVOICES: 'GET_INVOICES',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
  GET_INVOICES_FAIL: 'GET_INVOICES_FAIL',

  GET_INVOICE: 'GET_INVOICE',
  GET_INVOICE_SUCCESS: 'GET_INVOICE_SUCCESS',
  GET_INVOICE_FAIL: 'GET_INVOICE_FAIL',

  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAIL: 'GET_PRODUCTS_FAIL',

  GET_PDF: 'GET_PDF',
  GET_PDF_SUCCESS: 'GET_PDF_SUCCESS',
  GET_PDF_FAIL: 'GET_PDF_FAIL',

  CONFIRM_PFM: 'CONFIRM_PFM',
  CONFIRM_PFM_SUCCESS: 'CONFIRM_PFM_SUCCESS',
  CONFIRM_PFM_FAIL: 'CONFIRM_PFM_FAIL',

  GET_PFM_INFO: 'GET_PFM_INFO',
  GET_PFM_INFO_SUCCESS: 'GET_PFM_INFO_SUCCESS',
  GET_PFM_INFO_FAIL: 'GET_PFM_INFO_FAIL'
};
