import {useCallback, useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const useQueryParams = () => new URLSearchParams(useLocation().search);

export const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState((state) => !state), []);
  return [state, toggle];
};

export const useCurrentPageName = (pathsObj) => {
  const currentPath = useLocation()?.pathname;
  let name = '';

  if (typeof pathsObj === 'object') {
    Object?.values(pathsObj)?.find((obj) => {
      if (obj?.path === currentPath) name = obj?.name || '';
    });
  }
  return name;
};

export function useOnClickOutside(ref, handler, refAvoid = null) {
  useEffect(() => {
    //THIS MOMENT NEED TO SKIP EXTRA CLICK ON THE GUIDE 2nd step where we show the dropdown
    const activeStep = JSON.parse(localStorage.getItem('react_tour_data'))?.active_step || 0;
    const need_tour = JSON.parse(localStorage.getItem('react_tour_data'))?.need_tour || false;

    const listener = (event) => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        (refAvoid && (!refAvoid.current || refAvoid.current.contains(event.target)))
      ) {
        return;
      }

      activeStep === 2 && need_tour ? console.log('Second tour') : handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(document.documentElement.clientWidth);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return windowWidth;
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};
