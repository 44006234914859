import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {authPath, mainPath, rootMainPath} from '../../routes/paths';
import {postSignIn} from './authActions';
import {signInSchema} from './authSchema';
import styles from './Auth.module.scss';
import {Link} from 'react-router-dom';
import {CheckboxMUI} from '../../shared';
import {useTranslation} from 'react-i18next';
import bank from './images/bank.png';
import {checkIsApp} from '../../helpers/functions';
import {useLocation} from 'react-router-dom';

const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);
  const {t} = useTranslation();
  const {search} = useLocation();

  useEffect(() => {
    sessionStorage.getItem('session_id') && history.push(rootMainPath);
  }, []);

  const {
    control,
    handleSubmit,
    setError,
    formState: {errors, isValid}
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(signInSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const isApp = checkIsApp();

  const onSubmit = async (data) => {
    const res = await dispatch(postSignIn(data));
    const errors = res?.error?.response?.data;
  };

  return (
    <form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
      <img className={styles.bank_id} src={bank} alt='bank_id' />
      <h2 className={styles.title}> {t('SIGNIN.title')}</h2>
      <p className={styles.description}>
        {t('SIGNIN.description')}
        <br />
        <Link to={mainPath.overview.path}>{t('SIGNIN.link')}</Link>.
      </p>
      <b className={styles.bold}>{t('SIGNIN.privacy')}</b>
      <div className={styles.checkbox__container}>
        <CheckboxMUI className={styles.checkbox} defaultChecked={checked} onChange={() => setChecked(!checked)} />
      </div>
      <button
        onClick={() => history.push(isApp ? `${authPath.choogeAuthApp.path}${search}` : authPath.choogeAuth.path)}
        className={`${styles['signin__button']} main__button`}
        disabled={!checked}
      >
        {t('SIGNIN.continue')}
      </button>
    </form>
  );
};

export default SignIn;
