import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {authPath, rootAuthPath} from './paths';
import {SignIn, ChooseAuth, ExpiredSession, ConfirmEmail, ConfirmationEmailStatus} from '../features/Auth';
import NotFound from '../shared/NotFound';

const AuthRoutes = () => {
  // if (localStorage.token) return <Redirect to={rootMainPath} />;

  return (
    <Switch>
      <Redirect from={rootAuthPath} exact to={authPath.signIn.path} />
      <Route path={authPath.signIn.path} exact component={SignIn} />
      <Route path={authPath.signInApp.path} exact component={SignIn} />
      <Route path={authPath.choogeAuth.path} exact component={ChooseAuth} />
      <Route path={authPath.choogeAuthApp.path} exact component={ChooseAuth} />
      <Route path={authPath.expiredSession.path} exact component={ExpiredSession} />
      <Route path={authPath.confirmationEmailCode.path} exact component={ConfirmEmail} />
      <Route path={authPath.confirmationEmailStatus.path} exact component={ConfirmationEmailStatus} />
      <Route path='*' component={NotFound} />
    </Switch>
  );
};

export default AuthRoutes;
