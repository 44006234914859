import React, {useState, useEffect} from 'react';
import styles from './Overview.module.scss';
import SideBar from '../SideBar';
import RecentTransactions from '../Transactions/RecentTransactions';
import WithdrawAccount from './WithdrawAccount';
import ApplyAccount from './ApplyAccount';
import PayInvoice from './PayInvoice';
import {useTranslation} from 'react-i18next';
import {useWindowWidth} from '../../helpers/hooks';
import {CustomerServiceCard, CreditAccountCard, UrgentCard, ToastComponent} from '../../shared';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import EmptyInvoice from './EmptyInvoice';
import {markInfoShowRequest} from './withdrawalActions';
import {useQuery} from 'react-query';
import {axiosInstance} from '../../app/store';
import TransPageToast from '../../shared/TransPageToast/TransPageToast';

const Overview = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const width = useWindowWidth();
  const {search} = useLocation();
  const {is_menu_shown, customer_flags, notifications, guide_step} = useSelector((state) => state.settings);
  const need_tour = JSON.parse(localStorage.getItem('react_tour_data'))?.need_tour || false;
  const [toastConfigs, setToastConfigs] = useState({isSuccess: true, text: ''});

  const showBlur = need_tour && guide_step === 1;
  const showBlur2 = need_tour && guide_step === 7;
  const [showToast, setShowToast] = useState(false);
  const status = search.split('=').length > 0 ? search.split('=')[1] : null;

  const {data} = useQuery('customer', async () => {
    const response = await axiosInstance.get('customers/GetCustomer');
    return response.data;
  });

  const hasSupervisionOfInterest =
    (customer_flags &&
      Array.isArray(customer_flags?.flags) &&
      customer_flags?.flags.includes('SupervisionOfInterest')) ||
    false;

  const acceptedId =
    (notifications && notifications.filter((item) => item.type === 'ApplicationAccepted')[0]?.identifier) || null;

  const rejectedId =
    (notifications && notifications.filter((item) => item.type === 'ApplicationDenied')[0]?.identifier) || null;

  const hasCanApply =
    (customer_flags && Array.isArray(customer_flags?.flags) && customer_flags?.flags.includes('CanApply')) || false;

  const hasBanned =
    (customer_flags && Array.isArray(customer_flags?.flags) && customer_flags?.flags.includes('Banned')) || false;

  const hasNoContract =
    (customer_flags && Array.isArray(customer_flags?.flags) && customer_flags?.flags.includes('NoContract')) || false;

  const hasPastDueInvoices =
    (customer_flags && Array.isArray(customer_flags?.flags) && customer_flags?.flags.includes('PastDueInvoices')) ||
    false;

  const hasCanWithdraw =
    (customer_flags && Array.isArray(customer_flags?.flags) && customer_flags?.flags.includes('CanWithdraw')) || false;

  const hasInArrears =
    (customer_flags && Array.isArray(customer_flags?.flags) && customer_flags?.flags.includes('InArrears')) || false;

  const hasFirstVisitAfterRejectedCA =
    (customer_flags &&
      Array.isArray(customer_flags?.flags) &&
      customer_flags?.flags.includes('FirstVisitAfterRejectedCA')) ||
    false;

  const hasFirstVisitAfterAcceptedCA =
    (customer_flags &&
      Array.isArray(customer_flags?.flags) &&
      customer_flags?.flags.includes('FirstVisitAfterAcceptedCA')) ||
    false;

  const hasApplicationInProcess =
    (customer_flags &&
      Array.isArray(customer_flags?.flags) &&
      customer_flags?.flags.includes('ApplicationInProcess')) ||
    false;

  const hasWithdrawalInProcess =
    (customer_flags && Array.isArray(customer_flags?.flags) && customer_flags?.flags.includes('WithdrawalInProcess')) ||
    false;

  useEffect(() => {
    if (status === 'success') {
      setToastConfigs({isSuccess: false, text: 'The email was successfully confirmed'});
      setShowToast(true);
      history.replace({
        search: ''
      });
    }

    if (hasFirstVisitAfterRejectedCA) {
      rejectedId &&
        dispatch(
          markInfoShowRequest({
            application_id: rejectedId
          })
        ).then((res) => {
          if (res?.payload?.status === 200) {
            setToastConfigs({
              isSuccess: false,
              text: 'Your credit application was denied.',
              description: 'Please try again later or contact customer service.'
            });
            setShowToast(true);
          } else {
            setToastConfigs({isSuccess: false, text: 'Something went wrong'});
            setShowToast(true);
          }
        });
    }
    if (hasFirstVisitAfterAcceptedCA) {
      acceptedId &&
        dispatch(
          markInfoShowRequest({
            application_id: acceptedId
          })
        ).then((res) => {
          if (res?.payload?.status === 200) {
            setToastConfigs({isSuccess: true, text: 'Your credit application was accepted!'});
            setShowToast(true);
          } else {
            setToastConfigs({isSuccess: false, text: 'Something went wrong'});
            setShowToast(true);
          }
        });
    }
  }, [customer_flags, data]);

  return (
    <>
      <div className={`main__headers `}>
        <div className='container'>
          <h1 className={`${styles['overview__title']} main__title `}>{t('OVERVIEW.overview')}</h1>
          <p className={`${styles['overview__description']} main__description`}>
            {t('OVERVIEW.here')}
            <br />
            {t('OVERVIEW.find')}
          </p>
        </div>{' '}
      </div>

      <main className={`${styles.root} ${is_menu_shown && 'main__blured'} main__container container ${is_menu_shown}`}>
        <div className={`${styles['overview__main']} main__grid`}>
          <div className={`${styles['overview__column']} main__column ${showBlur && 'main__tourblur'}`}>
            <TransPageToast />
            {/* FOR MOBILE APP */}
            {width <= 1000 && (
              <>
                <ToastComponent
                  isFixed
                  isSuccess={toastConfigs.isSuccess}
                  visible={showToast}
                  setter={setShowToast}
                  title={toastConfigs.text}
                  description={toastConfigs?.description}
                />
                {hasCanWithdraw && <WithdrawAccount />}

                {(hasPastDueInvoices || hasInArrears) && (
                  <PayInvoice type={hasPastDueInvoices ? 'past_due' : 'in_arrears'} />
                )}
                {(hasBanned || hasSupervisionOfInterest || hasWithdrawalInProcess) && (
                  <PayInvoice
                    isUnavailable
                    type={hasBanned ? 'banned' : hasWithdrawalInProcess ? 'withdrawal_in_progress' : 'supervision'}
                  />
                )}
                {((hasCanApply && hasNoContract) || hasCanApply) && <ApplyAccount />}

                {((hasApplicationInProcess && hasNoContract) || (hasNoContract && !hasCanApply)) && <EmptyInvoice />}

                <RecentTransactions />
                <CreditAccountCard />
                <CustomerServiceCard type='sidebar' />
              </>
            )}

            {/* FOR DESKTOP APP */}
            {width > 1000 && (
              <>
                <ToastComponent
                  isFixed
                  isSuccess={toastConfigs.isSuccess}
                  visible={showToast}
                  setter={setShowToast}
                  title={toastConfigs.text}
                  description={toastConfigs?.description}
                />
                {(hasPastDueInvoices || hasInArrears) && (
                  <PayInvoice type={hasPastDueInvoices ? 'past_due' : 'in_arrears'} />
                )}

                {(hasBanned || hasSupervisionOfInterest || hasWithdrawalInProcess) && (
                  <PayInvoice
                    isUnavailable
                    type={hasBanned ? 'banned' : hasWithdrawalInProcess ? 'withdrawal_in_progress' : 'supervision'}
                  />
                )}
                {hasCanWithdraw && <WithdrawAccount />}
                {((hasCanApply && hasNoContract) || hasCanApply) && <ApplyAccount />}
                {((hasApplicationInProcess && hasNoContract) || (hasNoContract && !hasCanApply)) && <EmptyInvoice />}
                <RecentTransactions />
              </>
            )}
          </div>
          <SideBar className={showBlur2 && 'main__tourblur'} />
        </div>
      </main>
    </>
  );
};

export default Overview;
