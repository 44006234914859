import React, {useEffect, useRef, useState} from 'react';
import styles from './DropdownSelectAge.module.scss';
import {useOnClickOutside} from '../../helpers/hooks';
import {Triangle} from '../../assets/icons';
import {Trash} from '../../assets/icons';
import {useDebounce} from '../../helpers/hooks';
import {useSelector} from 'react-redux';
const mockArr = [
  {id: 'Year', title: 'years'},
  {id: 'Month', title: 'months'}
];

const DropdownSelectAge = ({
  label = 'Number',
  values,
  isFirst,
  order,
  error,
  onChange,
  customOnBlur,
  canDelete = true,
  subtitle = 'Select number or number range',
  clearErrors,
  className = '',
  setError,
  deleteElem,
  inputProps
}) => {
  const ref2 = useRef();
  const [openSelect, setOpenSelect] = useState(false);
  const {onBlur, ...ontherProps} = inputProps;
  const {update_loading} = useSelector(({credit_form}) => credit_form);
  useOnClickOutside(ref2, () => {
    setOpenSelect(false);
  });
  const options = !values ? mockArr : values;
  const colorClass = !error ? 'green' : 'red';

  const [age, setAge] = useState(inputProps?.value?.age?.value || 0);
  const debouncedSliderAdapter = useDebounce(age, 2000);
  useEffect(() => {
    age > 0 &&
      age < 100 &&
      (ageValidator(inputProps?.value?.age.value, inputProps?.value?.age.unit),
      customOnBlur(order, {
        ...inputProps?.value?.age,
        value: parseInt(age)
      }),
      setAge(0));
  }, [debouncedSliderAdapter]);

  if (inputProps?.value?.age?.value === undefined) {
    return null;
  }

  const ageValidator = (age, unit) => {
    let minValue, maxValue;

    if (unit === 'Month') {
      minValue = 1;
      maxValue = 12;
    } else if (unit === 'Year') {
      minValue = 1;
      maxValue = 100;
    } else {
      return;
    }

    if (age < minValue || age > maxValue) {
      const errorMessage = `The  value should be from ${minValue} to ${maxValue}`;
      setError(inputProps.name, {type: 'custom', message: errorMessage});
    } else {
      clearErrors(inputProps.name);
      return true;
    }
  };

  return (
    <div className={`${styles.root} ${className} ${colorClass}`}>
      <p className={styles['select__label']}>{label}</p>
      <div className={styles['select__row']}>
        <div ref={ref2} className={`${styles['select__dropdown']} ${colorClass}`}>
          <div className={`${styles.main} ${!canDelete && styles.main__delete}`}>
            <input
              disabled={!canDelete}
              onBlur={() =>
                age > 0 &&
                (ageValidator(inputProps?.value?.age.value, inputProps?.value?.age.unit),
                customOnBlur(order, {
                  ...inputProps?.value?.age,
                  value: parseInt(age)
                }),
                setAge(0))
              }
              onChange={(e) => {
                onChange({
                  ...inputProps?.value,
                  age: {
                    ...inputProps?.value?.age,
                    value: parseInt(e.target.value)
                  }
                });
                setAge(e.target.value);
              }}
              value={inputProps?.value?.age?.value}
              className={`${styles.input} ${!canDelete && styles.input__delete}`}
              type='number'
            />
            <button
              type={'button'}
              disabled={!canDelete}
              onClick={() => {
                setOpenSelect(!openSelect);
              }}
              className={`${styles['select__dropdown--select']} ${!canDelete && styles['select__dropdown--delete']}`}
              {...ontherProps}
            >
              <p className={styles['select__dropdown--placeholder']}>
                {' '}
                {inputProps.value?.age.unit === 'Year' ? 'years' : 'months'}
              </p>
              <span className={`${styles['triangle__container']} ${colorClass}`}>
                <Triangle className={`${styles['select__dropdown--arrow']} ${openSelect && 'down'} `} />
              </span>
            </button>
          </div>

          {openSelect && (
            <div className={styles['select__items']}>
              {options &&
                options.length > 0 &&
                options.map((item, idx) => {
                  return (
                    <button
                      type='button'
                      key={idx}
                      disabled={!canDelete || update_loading}
                      onClick={() => {
                        if (ageValidator(inputProps?.value?.age.value, item.id)) {
                          setOpenSelect(false);
                          customOnBlur(order, {
                            ...inputProps?.value?.age,
                            unit: item.id
                          });
                        } else {
                          setOpenSelect(false);
                          onChange({
                            ...inputProps?.value,
                            age: {
                              ...inputProps?.value?.age,
                              unit: item.id
                            }
                          });
                        }
                      }}
                      className={`${styles.select__item} `}
                    >
                      {item.title}
                    </button>
                  );
                })}
            </div>
          )}
        </div>
        {!isFirst && (
          <button
            type='button'
            onClick={() => deleteElem(order, 'adult')}
            disabled={!canDelete || update_loading}
            className={styles.remove}
            aria-label='remove'
          >
            <Trash />
          </button>
        )}
      </div>
      <span className={styles['select__subtitle']}>{subtitle}</span>
    </div>
  );
};

export default DropdownSelectAge;
