import React, {useEffect} from 'react';
import styles from './CreditAccountCard.module.scss';
import {useTranslation} from 'react-i18next';
import SkeletonMUI from '../SkeletonMUI';
import {useQuery} from 'react-query';
import {axiosInstance} from '../../app/store';
import AnimationWrapper from '../AnimationWrapper';

const CreditAccountCard = () => {
  const {t} = useTranslation();

  const {data, isLoading} = useQuery('ledgers', async () => {
    const response = await axiosInstance.get('ledgers/GetLedger'); // Replace with your API endpoint
    return response.data;
  });

  const gridData = [
    {
      title: t('CREDITACCOUNTCARD.capital'),
      price: data?.principal_used.toLocaleString('sv-SE', {
        style: 'currency',
        currency: 'SEK',
        maximumFractionDigits: 0
      })
    },
    {
      title: t('CREDITACCOUNTCARD.interest'),
      price: data?.interest.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK', maximumFractionDigits: 0})
    },
    {
      title: t('CREDITACCOUNTCARD.fees'),
      price: data?.fees.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK', maximumFractionDigits: 0})
    },
    {
      title: t('CREDITACCOUNTCARD.left'),
      price: data?.available_balance.toLocaleString('sv-SE', {
        style: 'currency',
        currency: 'SEK',
        maximumFractionDigits: 0
      })
    }
  ];

  if (isLoading) {
    return (
      <div className={`${styles.root} card__root`}>
        <SkeletonMUI variant='text' width={150} height={40} loading />
        <SkeletonMUI variant='text' width={100} height={30} loading />
        <SkeletonMUI variant='text' width={120} height={36} loading />
        <hr className={styles['card__line']} />
        <div className={styles['card__grid']}>
          <SkeletonMUI variant='text' width={100} height={36} loading />
          <SkeletonMUI variant='text' width={100} height={36} loading />
          <SkeletonMUI variant='text' width={100} height={36} loading />
          <SkeletonMUI variant='text' width={100} height={36} loading />
        </div>
      </div>
    );
  }

  const total_debt_burden = data?.principal + data?.interest + data?.fees;

  return (
    <AnimationWrapper>
      <div className={`${styles.root} card__root`}>
        <h3 className={styles['card__title']}>{t('CREDITACCOUNTCARD.credit')}</h3>
        <div className={styles['card__main']}>
          <p className={styles['card__subtitle']}>{t('CREDITACCOUNTCARD.total')}</p>
          <p className={styles['card__quantity']}>
            {total_debt_burden
              ? total_debt_burden.toLocaleString('sv-SE', {
                  style: 'currency',
                  currency: 'SEK',
                  maximumFractionDigits: 0
                })
              : (0).toLocaleString('sv-SE', {style: 'currency', currency: 'SEK', maximumFractionDigits: 2})}
          </p>

          <hr className={styles['card__line']} />
          <div className={styles['card__grid']}>
            {gridData.map((data, index) => {
              return (
                <div className={styles['card__grid--elem']} key={index}>
                  <h5 className={styles['card__grid--title']}>{data.title}</h5>
                  <p className={styles['card__grid--price']}>
                    {data.price
                      ? data?.price.toLocaleString('sv-SE', {
                          style: 'currency',
                          currency: 'SEK',
                          maximumFractionDigits: 0
                        })
                      : (0).toLocaleString('sv-SE', {
                          style: 'currency',
                          currency: 'SEK',
                          maximumFractionDigits: 2
                        })}
                  </p>
                </div>
              );
            })}
          </div>
          {/* <button className={`${styles['card__button']} main__button`}>{t('CREDITACCOUNTCARD.next')}</button>
      <button className={`${styles['card__free']} main__button`}>{t('CREDITACCOUNTCARD.free')}</button> */}
        </div>
      </div>
    </AnimationWrapper>
  );
};

export default CreditAccountCard;
