import React, {useState} from 'react';
import styles from './ConfirmEmail.module.scss';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useQuery} from 'react-query';
import {axiosInstance} from '../../../app/store';
import {confirmEmailCode} from '../../Settings/settingsActions';
import {notifyError} from '../../../helpers/notifySnack';
import {postEmailConfirmation} from '../../Settings/settingsActions';
import {MarkSuccess} from '../../../assets/icons';

import Letter from '../images/letter.png';
import {authPath, mainPath} from '../../../routes/paths';

const ConfirmEmail = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {search} = useLocation();
  const from = search.split('=').length > 0 ? search.split('=')[1] : null;
  const [success, setSuccess] = useState(false);

  const {data: customer_data} = useQuery('customer', async () => {
    const response = await axiosInstance.get('customers/GetCustomer');
    return response.data;
  });

  const [code, setCode] = useState('');

  const handleSetCode = (e) => {
    if (e.target.value.length <= 5) {
      setCode(e.target.value);
    }
  };

  const handleSubmitEmailCode = (e) => {
    e.preventDefault();
    dispatch(confirmEmailCode({code, email: customer_data?.email})).then((res) => {
      if (res.payload.data.success) {
        handleReturnWithoutConfirming('success');
      } else {
        if (res.payload.data.message === 'email_code_not_confirmed') {
          notifyError("An email code wasn't confirmed");
        }
      }
    });
  };

  const handleReturnWithoutConfirming = (status = null) => {
    if (from === 'settings') {
      history.push(`${mainPath.settings.path}`);
    } else if (status === 'success') {
      history.push(`${authPath.confirmationEmailStatus.path}`);
    } else {
      history.push('/');
    }
  };

  const handleResendEmail = (email) => {
    dispatch(postEmailConfirmation(JSON.stringify({email_address: email}))).then((res) => {
      if (res.payload.data.success) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      } else {
        notifyError(res.payload.data.message);
      }
    });
  };

  return (
    <form className={`${styles.root} ${success && styles.root__xl}`}>
      <img className={styles.root__letter} src={Letter} alt='Letter' />
      <h2 className={styles.root__title}>{t('CONFIRM_EMAIL_POPUP.title')}</h2>
      <p className={styles.root__subtitle}>
        {t('CONFIRM_EMAIL_POPUP.subtitle')} <b>{customer_data?.email}</b>
      </p>

      <input
        value={code}
        onChange={(e) => handleSetCode(e)}
        placeholder={'-----'}
        className={styles.root__input}
        type='text'
      />
      <button
        type='button'
        disabled={code.length !== 5}
        onClick={(e) => handleSubmitEmailCode(e)}
        className={`${styles['root__button']}`}
      >
        {t('CONFIRM_EMAIL_POPUP.confirm')}
      </button>

      <button
        type='button'
        onClick={() => handleResendEmail(customer_data?.email)}
        className={`${styles['root__resend__button']}`}
      >
        {t('CONFIRM_EMAIL_POPUP.resend')}
      </button>

      <div
        className={`${styles['root__success']} ${
          success ? styles['root__success--open'] : styles['root__success--closed']
        }`}
      >
        <MarkSuccess />
        <p>
          Confirmation email sent to <b>{customer_data?.email}</b>
        </p>
      </div>

      <button type='button' onClick={() => handleReturnWithoutConfirming()} className={`${styles['root__return']}`}>
        {t('CONFIRM_EMAIL_POPUP.return')}
      </button>
    </form>
  );
};

export default ConfirmEmail;
