import React, {useRef, useState, useEffect} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {mainPath} from '../../routes/paths';
import styles from './Header.module.scss';
import {Logo, User, Overview, Invoices, Transactions, Contracts, Close, Settings, Logotype} from '../../assets/icons';
import {HeaderDropdown, PersonalDropdown, PulsationDot, PaymentFreeMonth, NotificationPopup} from '../../shared';
import {useOnClickOutside, useWindowWidth} from '../../helpers/hooks';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {showMenu, getCustomerNotifications} from '../../features/Settings/settingsActions';
import {useLocation} from 'react-router-dom';
import {useToggle} from '../../helpers/hooks';

const navElems = [
  {title: 'NAVBAR.overview', icon: <Overview />, path: mainPath.overview.path},
  {title: 'NAVBAR.invoices', icon: <Invoices />, path: mainPath.invoices.path},
  {title: 'NAVBAR.transactions', icon: <Transactions />, path: mainPath.transactions.path},
  {title: 'NAVBAR.contracts', icon: <Contracts />, path: mainPath.contracts.path},
  {title: 'NAVBAR.settings', icon: <Settings />, path: mainPath.settings.path}
];

const Header = ({isAuth, isPersonalModal, setPersonalOpen}) => {
  const ref = useRef();
  const ref2 = useRef();
  const refBTN = useRef();
  const {pathname} = useLocation();
  const isMainPathPage = pathname.includes('main');
  const isAuthPage = pathname.includes('auth');

  const {t} = useTranslation();
  const [isNavigationModal, setNavigationOpen] = useState(false);

  const width = useWindowWidth();
  const dispatch = useDispatch();

  useOnClickOutside(ref, () => setNavigationOpen(false));
  useOnClickOutside(ref2, () => setPersonalOpen(false), refBTN);

  const [bubleStatus, setBubleStatus] = useState(false);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const [isScrollToTop, setScrollToTop] = useState(true);
  const scrollTimer = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [dialog, toggleDialog] = useToggle(false);
  const [dialogNotification, toggleDialogNotification] = useToggle(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.pageYOffset;
      if (prevScrollPosition > currentPosition) {
        setScrollToTop(true);
      } else {
        setScrollToTop(false);
      }
      setPrevScrollPosition(currentPosition);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPosition]);

  useEffect(() => {
    //refetch();
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollTimer.current) {
        clearTimeout(scrollTimer.current);
      }
      setIsScrolling(true);
      scrollTimer.current = setTimeout(() => {
        setIsScrolling(false);
        scrollTimer.current = null;
      }, 250);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  //SHOW NOTIFICATION POPUP FOR 5s AND THEN HIDE
  const hideNotification = () => {
    toggleDialogNotification(false);
  };
  useEffect(() => {
    toggleDialogNotification(true);
    const timeoutId = setTimeout(hideNotification, 4000);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    dispatch(getCustomerNotifications());
  }, []);

  useEffect(() => {
    dispatch(showMenu(isPersonalModal));
    const rootElement = document.getElementById('root');
    if (rootElement) {
      if (isPersonalModal) {
        rootElement.classList.add('root__blur');
      } else {
        rootElement.classList.remove('root__blur');
      }
    }
    return () => {
      if (rootElement) {
        rootElement.classList.remove('root__blur');
      }
    };
  }, [dispatch, isPersonalModal]);

  const need_tour = JSON.parse(localStorage.getItem('react_tour_data'))?.need_tour || false;
  const {guide_step, notifications, notification_quantity} = useSelector((state) => state.settings);

  const showBlur = need_tour && guide_step === 2;
  const greenStatuses = [
    'InvoiceOpen',
    'ApplicationAccepted',
    'WithdrawalTransferring',
    'EligibleForPaymentFreeMonth',
    'ApplicationWaitingForConfirmation',
    'EligibleForUpselling'
  ];
  const currentStatuses = notifications && notifications.map((notification) => notification.type);

  function checkGreenStatuses(currentStatuses, greenStatuses) {
    for (let i = 0; i < currentStatuses.length; i++) {
      if (!greenStatuses.includes(currentStatuses[i])) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (currentStatuses && currentStatuses.length > 0) {
      setBubleStatus(checkGreenStatuses(currentStatuses, greenStatuses));
    }
  }, [currentStatuses, greenStatuses]);

  return (
    <>
      <Helmet>
        <meta name='description' content='Here can be description for every page' />
        <meta name='keywords' content='Flex limit SEO keywords' />
        <meta property='og:title' content='Your Page Title' />
        <meta property='og:description' content='This is the description of the page.' />
      </Helmet>
      <header
        className={`${styles.root} ${prevScrollPosition === 0 && styles.top_padding} ${`show ${
          isMainPathPage && 'show__blue'
        } ${isAuthPage && styles.auth__blue}`}`}
      >
        <div className={`${styles.container}  container `}>
          <Link
            aria-label='Go to the main overview page'
            className={styles['header__logo']}
            to={mainPath.overview.path}
          >
            {width < 769 ? (
              isAuthPage ? (
                <Logo className={styles['header__logo--icon']} />
              ) : (
                <Logotype height={50} className={styles['header__logo--icon']} />
              )
            ) : (
              <Logo className={styles['header__logo--icon']} />
            )}
          </Link>

          {!isAuth && (
            <div className={styles['header__buttons']}>
              <div className={styles.links}>
                {navElems.map((elem, idx) => {
                  return (
                    <NavLink className={styles.link} to={elem.path} key={idx}>
                      {elem.icon}
                      <p>{t(elem.title)}</p>
                    </NavLink>
                  );
                })}
              </div>

              {
                <div className={` ${styles[`header__button--container`]} ${showBlur && 'main__tourblur'}`}>
                  {isPersonalModal ? (
                    <button
                      onClick={() => setPersonalOpen(false)}
                      className={`${styles['header__button']} ${styles[`header__button--menu`]}  ${
                        styles[`header__button--close`]
                      }`}
                      aria-label='cancel'
                      type='button'
                      ref={refBTN}
                    >
                      <div>
                        <Close />
                      </div>

                      <span className={styles['header__button--txt']}>{t('HEADER.close')}</span>
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setPersonalOpen(true);
                      }}
                      className={`${styles['header__button']}  ${styles['header__button--menu']}`}
                      aria-label='Open User Profile'
                    >
                      <User className='mb4' />
                      <span className={styles['header__button--txt']}>{t('HEADER.profile')}</span>

                      {notification_quantity?.length > 0 && (
                        <PulsationDot
                          pulse={true}
                          type={bubleStatus ? 'blue' : 'default'}
                          className={styles['header__dot']}
                        />
                      )}
                    </button>
                  )}

                  <PersonalDropdown
                    isPersonalModal={isPersonalModal}
                    dialog={dialog}
                    toggleDialog={toggleDialog}
                    setter={setPersonalOpen}
                    windowRef={ref2}
                  />

                  {!isPersonalModal && notification_quantity?.length > 0 && (
                    <NotificationPopup isPersonalModal={dialogNotification} />
                  )}
                </div>
              }
            </div>
          )}
        </div>
        {isNavigationModal && <HeaderDropdown setter={setNavigationOpen} windowRef={ref} />}
        <PaymentFreeMonth dialog={dialog} toggleDialog={toggleDialog} />
      </header>
    </>
  );
};

export default Header;
