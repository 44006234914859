import React from 'react';
import styles from './PulsationDot.module.scss';

const PulsationDot = ({className = '', pulse = true, type = 'default'}) => {
  return (
    <div className={`${styles.root}${className && ` ${className}`}`}>
      <div className={pulse ? styles.dot : styles.dot_static}>
        <div className={`${styles.inner} ${type}`}></div>
      </div>
    </div>
  );
};

export default PulsationDot;
