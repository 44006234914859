import React, {useEffect, useRef} from 'react';
import styles from './CustomerServiceCard.module.scss';
import phone from './image/image.png';
import {Phone, Email, Facebook} from '../../assets/icons';
import {useTranslation} from 'react-i18next';
import AnimationWrapper from '../AnimationWrapper';

const CustomerServiceCard = ({type = 'header'}) => {
  // element types: "header" | "sidebar"
  const {t} = useTranslation();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const scrollParam = queryParams.get('scroll');

    if (scrollParam === 'customer') {
      setTimeout(() => {
        const customerElement = document.getElementById('customer');

        if (customerElement) {
          const topOffset = customerElement.offsetTop - 50;
          window.scrollTo({top: topOffset, behavior: 'smooth'});
        }
      }, 1000);
    }
  }, []);

  return (
    <AnimationWrapper>
      <div id='customer' className={`${styles.root} ${type === 'sidebar' && styles.sidebar}`}>
        <div className={styles['service__title']}>
          <h3>{t('CUSTOMERSERVICECARD.customer')}</h3>
          {/* <span className={styles.shadow}></span> */}
        </div>
        <div className={styles['service__main']}>
          <p className={styles['service__text']}>
            <b>{t('CUSTOMERSERVICECARD.need')}</b> {t('CUSTOMERSERVICECARD.assist')}
          </p>
          <div className={styles['service__links']}>
            <div className={styles['service__link']}>
              <Phone className='mr31' /> <a href='tel:+46 8 44 681 156'>+46 8 44 681 156</a>
            </div>
            <div className={styles['service__link']}>
              <Email className='mr31' /> <a href='mailto:kundtjanst@flexlimit.se'>kundtjanst@flexlimit.se</a>
            </div>
            <div className={styles['service__link']}>
              <Facebook className='mr31' />{' '}
              <a
                href={new URL('https://www.facebook.com/flexlimit/')}
                target={'_blank'}
                rel='noopener noreferrer external'
              >
                facebook.com/FlexLimit
              </a>
            </div>
          </div>
          <div className={styles['service__pages']}>
            <div className={styles['service__page']}>
              <a target='_blank' href='https://www.flexlimit.se/kundtjanst/vanliga-fragor'>
                {t('CUSTOMERSERVICECARD.faq')}
              </a>
              <span className={styles['shadow__small']}></span>
            </div>
            <div className={styles['service__page']}>
              <a target='_blank' href='https://www.flexlimit.se/skuldradgivning'>
                {t('CUSTOMERSERVICECARD.debt')}
              </a>
              <span className={styles['shadow__small']}></span>
            </div>
          </div>
          <img className={styles['service__image']} src={phone} alt='phone' width='347' height='210' />
        </div>
      </div>
    </AnimationWrapper>
  );
};

export default CustomerServiceCard;
