import React, {useState} from 'react';
import styles from './EmptyInvoice.module.scss';
import {RangeInput} from '../../../shared';
import {mainPath, creditAppPath} from '../../../routes/paths';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useWindowWidth} from '../../../helpers/hooks';
import {AnimationWrapper} from '../../../shared';
import {WarningIcon} from '../../../assets/icons';
import {useSelector} from 'react-redux';

const EmptyInvoice = () => {
  const {t} = useTranslation();
  const {notifications} = useSelector((state) => state.settings);

  const width = useWindowWidth();

  const invoiceData = [
    {
      title: t('PAYINVOICE.available'),
      price: `${(0).toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}`
    },
    {
      title: t('PAYINVOICE.credit'),
      price: `${(0).toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}`
    },
    {
      title: t('PAYINVOICE.total'),
      price: `${(0).toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}`
    }
  ];

  const invoiceDataMobile = [
    {
      title: t('WITHDRAWACCOUNT.available_s'),
      price: `${(0).toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}`
    },
    {
      title: t('WITHDRAWACCOUNT.used_s'),
      price: `${(0).toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}`
    },
    {
      title: t('WITHDRAWACCOUNT.total_s'),
      price: `${(0).toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})} `
    }
  ];

  const hasWaitingForPSD = notifications.filter((item) => item.type === 'ApplicationWaitingForPSD2Data').length > 0;
  const hasUnfinished = notifications.filter((item) => item.type === 'ApplicationUnfinished').length > 0;
  const hasCustomerPSD = notifications.filter((item) => item.type === 'ApplicationWaitingForCustomerPSD2').length > 0;
  const hasApplicationWaitingForConfirmation =
    notifications.filter((item) => item.type === 'ApplicationWaitingForConfirmation').length > 0;

  return (
    <AnimationWrapper>
      <div className={styles.root}>
        <>
          <div className={styles.header}>
            {hasWaitingForPSD && (
              <div className={styles.header__message}>
                <WarningIcon />
                <div className={styles.header__text}>
                  <p>{t('PROFILECARD.waiting_psd_tink')}</p>
                </div>
              </div>
            )}

            {hasApplicationWaitingForConfirmation && (
              <div className={styles.header__message}>
                <WarningIcon />
                <div className={styles.header__text}>
                  <p> {t('PROFILECARD.approved')}</p>

                  <Link to={`${creditAppPath.applicationStatusPath.path}`}>{t('PROFILECARD.finalize')}</Link>
                </div>
              </div>
            )}

            {hasUnfinished && (
              <div className={styles.header__message}>
                <WarningIcon />
                <div className={styles.header__text}>
                  <p>{t('PROFILECARD.unfinished_app')}</p>
                  <Link to={creditAppPath.creditPath.path}>{t('PROFILECARD.unfinished_app_link')}</Link>
                </div>
              </div>
            )}

            {hasCustomerPSD && (
              <div className={styles.header__message}>
                <WarningIcon />
                <div className={styles.header__text}>
                  <p>{t('PROFILECARD.waiting_psd_customer')}</p>
                  <Link to={creditAppPath.creditPath.path}>{t('PROFILECARD.waiting_psd_customer_link')}</Link>
                </div>
              </div>
            )}
          </div>
          <div className={styles.container}>
            <div className={styles['invoice__columns']}>
              <div className={styles['invoice__column']}>
                <div className={styles['invoice__input']}>
                  <div className={styles['invoice__input--field']}>0,00</div>

                  <span className={styles['invoice__input--currency']}>kr</span>
                </div>
                <p className={styles['invoice__text']}>{t('PAYINVOICE.enter')}</p>
              </div>
              <div className={styles['invoice__column']}>
                {invoiceData.map((data, index) => (
                  <div key={index} className={styles['invoice__column--container']}>
                    <p className={styles['invoice__column--title']}>{data.title}</p>
                    <span className={styles['invoice__column--price']}>{data.price}</span>
                  </div>
                ))}
              </div>
            </div>

            <RangeInput
              totalLimit={30000}
              availableLimit={30000}
              usedLimit={0}
              sliderWidth={width > 1400 ? 700 : width > 900 ? 600 : width * 0.78}
              disabled
              showMarks={false}
              value={50}
            />

            <div className={styles['invoice__elems']}>
              {invoiceDataMobile.map((data, index) => (
                <div key={index} className={styles['invoice__elems--container']}>
                  <p className={styles['invoice__elems--title']}>{data.title}</p>
                  <span className={styles['invoice__elems--price']}>{data.price}</span>
                </div>
              ))}
            </div>
            <Link disabled to={mainPath.invoices.path} className={`${styles['invoice__button']} main__button`}>
              {t('PAYINVOICE.make')}
            </Link>
          </div>
        </>
      </div>
    </AnimationWrapper>
  );
};

export default EmptyInvoice;
