export const WITHDRAWAL = {
  NEW_WITHDRAWAL: 'NEW_WITHDRAWAL',
  NEW_WITHDRAWAL_FAIL: 'NEW_WITHDRAWAL_FAIL',
  NEW_WITHDRAWAL_SUCCESS: 'NEW_WITHDRAWAL_SUCCESS',

  GET_CONFIRMATION_DETAILS: 'GET_CONFIRMATION_DETAILS',
  GET_CONFIRMATION_DETAILS_FAIL: 'GET_CONFIRMATION_DETAILS_FAIL',
  GET_CONFIRMATION_DETAILS_SUCCESS: 'GET_CONFIRMATION_DETAILS_SUCCESS',

  CONFIRM_WITHDRAWAL: 'CONFIRM_WITHDRAWAL',
  CONFIRM_WITHDRAWAL_FAIL: 'CONFIRM_WITHDRAWAL_FAIL',
  CONFIRM_WITHDRAWAL_SUCCESS: 'CONFIRM_WITHDRAWAL_SUCCESS',

  CANCEL_WITHDRAWAL: 'CANCEL_WITHDRAWAL',
  CANCEL_WITHDRAWAL_FAIL: 'CANCEL_WITHDRAWAL_FAIL',
  CANCEL_WITHDRAWAL_SUCCESS: 'CANCEL_WITHDRAWAL_SUCCESS',

  MARK_INFO_SHOW: 'MARK_INFO_SHOW',
  MARK_INFO_SHOW_FAIL: 'MARK_INFO_SHOW_FAIL',
  MARK_INFO_SHOW_SUCCESS: 'MARK_INFO_SHOW_SUCCESS'
};
