import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import authReducer from '../features/Auth/authReducer';
import transactionsReducer from '../features/Transactions/transactionsReducer';
import invoicesReducer from '../features/Invoices/invoicesReducer';
import sidebarReducer from '../features/SideBar/sidebarReducer';
import {APP} from './appActionTypes';
import settingsReducer from '../features/Settings/settingsReducer';
import contractsReducer from '../features/Contracts/contractsReducer';
import creditFormReducer from '../features/CreditForm/creditFormReducer';
import withdrawalReducer from '../features/Overview/withdrawalReducer';

const INITIAL_STATE = {
  loading: false,
  buttonLoading: false,
  errorSnack: false,
  errorSnackText: '',
  successSnack: '',
  successSnackText: ''
};

const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APP.LOADING:
      return {...state, loading: action.payload};
    case APP.BUTTON_LOADING:
      return {...state, buttonLoading: action.payload};
    case APP.ERROR_SNACK_OPEN:
      return {...state, errorSnack: true, errorSnackText: action.payload};
    case APP.ERROR_SNACK_CLOSE:
      return {...state, errorSnack: false};
    case APP.SUCCESS_SNACK_OPEN:
      return {...state, successSnack: true, successSnackText: action.payload};
    case APP.SUCCESS_SNACK_CLOSE:
      return {...state, successSnack: false};
    default:
      return state;
  }
};

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    transaction: transactionsReducer,
    settings: settingsReducer,
    invoices: invoicesReducer,
    sidebar: sidebarReducer,
    contracts: contractsReducer,
    credit_form: creditFormReducer,
    withdrawal: withdrawalReducer,
    app: appReducer
  });

export default rootReducer;
