import React from 'react';
import styles from './Footer.module.scss';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

const Footer = () => {
  const {t} = useTranslation();
  const quickLinks = [
    {id: 1, title: t('FOOTER.website'), link: 'https://www.figma.com/exit?url=https%3A%2F%2Fwww.flexlimit.se%2F'},
    {id: 2, title: t('FOOTER.borrow'), link: 'https://www.flexlimit.se/lana-pengar'},
    {id: 3, title: t('FOOTER.prices'), link: 'https://www.flexlimit.se/priser'},
    {id: 4, title: t('FOOTER.customer'), link: 'https://www.flexlimit.se/lana-pengar'},
    {id: 5, title: t('FOOTER.privacy'), link: 'https://www.flexlimit.se/lana-pengar'},
    {id: 6, title: t('FOOTER.find'), link: 'https://www.flexlimit.se/lana-pengar'},
    {id: 7, title: t('FOOTER.compare'), link: 'https://www.flexlimit.se/lana-pengar'},
    {id: 8, title: t('FOOTER.signin'), link: 'https://www.flexlimit.se/lana-pengar'}
  ];

  const {is_menu_shown} = useSelector((state) => state.settings);

  const loansLinks = [
    {id: 1, title: t('FOOTER.private'), link: 'http://flexlimit.local/#'},
    {id: 2, title: t('FOOTER.account'), link: 'https://www.flexlimit.se/lana-pengar'},
    {id: 3, title: t('FOOTER.consumer'), link: 'https://www.flexlimit.se/priser'},
    {id: 4, title: t('FOOTER.bank'), link: 'https://www.flexlimit.se/lana-pengar'},
    {id: 5, title: t('FOOTER.quick'), link: 'https://www.flexlimit.se/lana-pengar'},
    {id: 6, title: t('FOOTER.internet'), link: 'https://www.flexlimit.se/lana-pengar'},
    {id: 7, title: t('FOOTER.unsecured'), link: 'https://www.flexlimit.se/lana-pengar'},
    {id: 8, title: t('FOOTER.mobile'), link: 'https://www.flexlimit.se/lana-pengar'},
    {id: 9, title: t('FOOTER.credit'), link: 'https://www.flexlimit.se/lana-pengar'}
  ];

  return (
    <footer className={`${styles.root} ${is_menu_shown && 'main__blured'}`}>
      <div className={`${styles['footer__container']} container`}>
        <div className={styles['footer__column']}>
          <h3 className={styles['footer__title']}>
            C Finance AB / <br /> FlexLimit
          </h3>
          <a className={styles['footer__contacts']} href='mailto:kundtjanst@flexlimit.se'>
            kundtjanst@flexlimit.se
          </a>
          <a className={styles['footer__contacts']} href='tel:08 44 681 156'>
            08 44 681 156
          </a>
          <address className={styles['footer__contacts']}>
            Johannesfredvägen 11 <br />
            168 69 Brake
          </address>
        </div>
        {/* <div className={styles['footer__column']}>
          <h3 className={styles['footer__title']}>{t('FOOTER.about')}</h3>
          <p className={styles['footer__about']}>{t('FOOTER.account_credit')}</p>
        </div> */}
        {/* <div className={styles['footer__column']}>
          <h3 className={styles['footer__title']}>{t('FOOTER.quick_links')}</h3>
          {quickLinks.map(({title, link}, idx) => {
            return (
              <a key={idx} className={styles['footer__links']} target={'_blank'} href={new URL(link)}>
                {title}
              </a>
            );
          })}
        </div> */}
        {/* <div className={styles['footer__column']}>
          <h3 className={styles['footer__title']}>{t('FOOTER.about_loans')}</h3>
          {loansLinks.map(({title, link}, idx) => {
            return (
              <a key={idx} className={styles['footer__links']} target={'_blank'} href={new URL(link)}>
                {title}
              </a>
            );
          })}
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
