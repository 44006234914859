import React from 'react';
import styles from './Tink.module.scss';
import tink from './images/tink.png';
import {User2, Lock} from '../../assets/icons';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {creditAppPath} from '../../routes/paths';
import {useDispatch} from 'react-redux';
import {getTinkUrl} from '../Settings/settingsActions';
import {useHistory} from 'react-router-dom';
import {setToastParams} from '../Auth/authActions';
import {mainPath} from '../../routes/paths';

const Tink = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleTinkContinue = async () => {
    const res = await dispatch(getTinkUrl('/credit/tink-processing'));

    /*FOR THE HandleTinkReturn we need to have customer_reference_id and tink_url*/

    const {url} = res.payload.data;

    function parseQueryParams(url) {
      const queryParamsObject = {};
      const queryString = url.split('https://flexlimit.4-com.pro/credit/tink-processing')[1];

      if (queryString) {
        const queryParams = queryString.split('&');

        queryParams.forEach((param) => {
          const [key, value] = param.split('=');
          queryParamsObject[key] = decodeURIComponent(value);
        });
      }

      return queryParamsObject;
    }

    const queryParams = parseQueryParams(url);
    localStorage.setItem('customer_reference_id', queryParams?.customer_reference_id);
    localStorage.setItem('tink_url', url);

    /**/

    if (res?.payload?.status === 200) {
      window.location.href = res.payload.data.url;
    } else {
      history.push(mainPath.overview.path);
      dispatch(
        setToastParams({
          toast_open: true,
          toast_type: false,
          toast_text: t('APPLICATION_STATUS.went_wrong'),
          toast_description: t('APPLICATION_STATUS.went_wrong_try')
        })
      );
    }
  };

  return (
    <div className={`${styles.root} container`}>
      <div className={styles.container}>
        <img src={tink} alt='tink' />
        <h2 className={styles.title}>{t('TINK.title')}</h2>

        <div className={styles['textblock']}>
          <span className={styles['textblock__image']}>
            <User2 />
          </span>
          <div className={styles['textblock__texts']}>
            <h3 className={styles['textblock__title']}>{t('TINK.subtitle_1')}</h3>
            <p className={styles['textblock__text']}>
              {t('TINK.text_1')}{' '}
              <Link className={styles['textblock__link']} to={creditAppPath.dataCollectionPath.path}>
                {t('TINK.link_1')}
              </Link>
            </p>
            <p className={styles['textblock__text']}>
              <b> {t('TINK.bold_text')}</b> {t('TINK.text_2')}
            </p>
          </div>
        </div>

        <div className={styles['textblock']}>
          <span className={styles['textblock__image']}>
            <Lock />
          </span>

          <div className={styles['textblock__texts']}>
            <h3 className={styles['textblock__title']}>{t('TINK.subtitle_2')}</h3>
            <p className={styles['textblock__text']}>
              {t('TINK.text_3')}{' '}
              <a
                className={styles['textblock__link']}
                target='_blank'
                href='https://link.tink.com/terms-and-conditions/sv'
              >
                {t('TINK.link_2')}
              </a>{' '}
              {t('TINK.and')}{' '}
              <a className={styles['textblock__link']} target='_blank' href='https://oauth.tink.se/privacy-policy/sv'>
                {t('TINK.link_3')}
              </a>
            </p>
          </div>
        </div>

        <button onClick={handleTinkContinue} className={`${styles.continue} main__button`} type='button'>
          {t('TINK.continue')}
        </button>
        {/* <Link className={styles.avbryt} to={creditAppPath.creditPath.path}>
          {t('TINK.back')}
        </Link> */}
      </div>
    </div>
  );
};

export default Tink;
