import {WITHDRAWAL} from './withdrawalActionTypes';

const INITIAL_STATE = {
  loading: false,
  popup_loading: false,
  error: null,
  new_withdrawal: null,
  details: null,
  confirmation: null,
  canceling: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case WITHDRAWAL.GET_CONFIRMATION_DETAILS:
    case WITHDRAWAL.NEW_WITHDRAWAL:
    case WITHDRAWAL.MARK_INFO_SHOW:
      return {...state, loading: true};

    case WITHDRAWAL.CONFIRM_WITHDRAWAL:
    case WITHDRAWAL.CANCEL_WITHDRAWAL:
      return {...state, popup_loading: true};

    case WITHDRAWAL.GET_CONFIRMATION_DETAILS_FAIL:
    case WITHDRAWAL.NEW_WITHDRAWAL_FAIL:
    case WITHDRAWAL.MARK_INFO_SHOW_FAIL:
    case WITHDRAWAL.MARK_INFO_SHOW_SUCCESS:
      return {...state, loading: false};

    case WITHDRAWAL.CONFIRM_WITHDRAWAL_FAIL:
    case WITHDRAWAL.CANCEL_WITHDRAWAL_FAIL:
      return {...state, popup_loading: false};

    case WITHDRAWAL.NEW_WITHDRAWAL_SUCCESS:
      return {...state, loading: false, new_withdrawal: action.payload.data};

    case WITHDRAWAL.GET_CONFIRMATION_DETAILS_SUCCESS:
      return {...state, loading: false, details: action.payload.data};

    case WITHDRAWAL.CONFIRM_WITHDRAWAL_SUCCESS:
      return {...state, popup_loading: false, confirmation: action.payload.data};

    case WITHDRAWAL.CANCEL_WITHDRAWAL_SUCCESS:
      return {...state, popup_loading: false, canceling: action.payload.data};

    default:
      return state;
  }
}
