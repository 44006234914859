import React, {useEffect} from 'react';
import styles from './CreditForm.module.scss';
import {Controller} from 'react-hook-form';
import {AddMember, DropdownSelectAge} from '../../shared';
import BlockSeparator from './BlockSeparator';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {setAppSecondCircle, updateCustomerDetails} from './creditFormActions';
import {arraysOfObjectsAreEqual} from '../../helpers/functions';

const StepTwo = ({
  control,
  resetField,
  dirtyFields,
  errors,
  clearErrors,
  setValue,
  touchedFields,
  trigger,
  getValues,
  setError
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {costumer_details, second_circle} = useSelector(({credit_form}) => credit_form);

  const followedField = Array.from({length: 15}, (_, index) => `person_${index + 1}`);
  const fieldOfErrors = followedField.map((elem, index) => (errors[elem] ? true : false));
  const hasError = fieldOfErrors.includes(true);

  let color = 'gray';

  useEffect(() => {
    color = costumer_details?.household?.people.length > 0 ? (hasError ? 'red' : 'green') : 'grey';
    dispatch(setAppSecondCircle(color));
  }, [hasError, costumer_details]);

  useEffect(() => {
    setValue('people', costumer_details?.household?.people);
    setValue('number_of_adults', costumer_details?.household?.number_of_adults);
    setValue('number_of_minors', costumer_details?.household?.number_of_minors);
    costumer_details?.household?.people.map((elem, idx) => {
      return setValue(`person_${idx + 1}`, elem);
    });
  }, [costumer_details]);

  const handleReturnNewPersonId = () => {
    const people = costumer_details?.household?.people || [];

    if (people.length === 0) {
      return 'person_1';
    }

    let nextId = 1;
    const identifiers = people.map((person) => person.identifier);

    while (identifiers.includes(`person_${nextId}`)) {
      nextId++;
    }

    return `person_${nextId}`;
  };

  const handleAddMember = () => {
    dispatch(
      updateCustomerDetails({
        id: costumer_details?.id,
        household: {
          number_of_adults: costumer_details?.household?.number_of_adults + 1,
          people: [
            ...costumer_details?.household?.people,
            {
              identifier: handleReturnNewPersonId(),
              age: {
                value: 18,
                unit: 'Year'
              }
            }
          ]
        }
      })
    );
  };

  const handleDeleteMember = (id, type = 'adult') => {
    const people = [...costumer_details?.household?.people];
    const index = id;

    if (index >= 0 && index < people.length) {
      people.splice(index, 1);
    }

    dispatch(
      updateCustomerDetails({
        id: costumer_details?.id,
        household: {
          number_of_adults: costumer_details?.household?.number_of_adults - (type === 'adult' ? 1 : 0),
          number_of_minors: costumer_details?.household?.number_of_minors - (type === 'minor' ? 1 : 0),
          people: people
        }
      })
    ).then((res) => {
      if (res?.payload?.status === 200) {
        //IF THERE IS ONE ADULT CLEAR DATA FOR HOUSEHOLD
        const adultsNumber = res.payload.data.household.number_of_adults;
        adultsNumber < 2 &&
          dispatch(
            updateCustomerDetails({
              id: costumer_details?.id,
              income_and_expenses: {
                household_income: {
                  monthly_net_income: getValues('personal_income_monthly_net_income'),
                  monthly_gross_income: getValues('personal_income_monthly_gross_income')
                },
                household_expenses: {
                  monthly_expenses: getValues('personal_expenses_monthly_expenses')
                }
              }
            })
          );
      }
    });
  };

  const handleEditMember = (id, updatedAge) => {
    const people = [...costumer_details?.household?.people];
    const index = id;

    if (index >= 0 && index < people.length) {
      const person = {...people[index]};
      person.age = updatedAge;

      people.splice(index, 1, person);
    }

    const number_of_adults = people.filter((person) => person.age.value >= 18).length;
    const number_of_minors = people.length - number_of_adults;

    // Check if there is any change in the people array before dispatching
    if (!arraysOfObjectsAreEqual(people, costumer_details?.household?.people)) {
      dispatch(
        updateCustomerDetails({
          id: costumer_details?.id,
          household: {
            number_of_adults: number_of_adults,
            number_of_minors: number_of_minors,
            people: people
          }
        })
      );
    }
  };

  return (
    <>
      <BlockSeparator anchor={'spep_2'} step={2} color={second_circle} />
      <h3 className={styles['block__title']}>{t('CREDIT_FORM.household')}</h3>
      <p className={styles['block__subtitle']}>{t('CREDIT_FORM.household_descr1')}</p>
      <p className={`${styles['block__subtitle']} mb20`}>{t('CREDIT_FORM.household_descr2')}</p>

      <div className={styles.grid}>
        <Controller
          name='person_1'
          control={control}
          render={({field}) => (
            <DropdownSelectAge
              isFirst
              label={`${t('CREDIT_FORM.person')} 1 ${t('CREDIT_FORM.age')}`}
              className={'credit__select'}
              canDelete={false}
              resetField={resetField}
              dirtyFields={dirtyFields}
              onChange={(value) => {}}
              subtitle={t('CREDIT_FORM.prefiled_age')}
              isTouched={touchedFields?.number_of_adults}
              error={errors && errors[`person_1`]?.message}
              placeholder={costumer_details && getValues('number_of_adults')}
              inputProps={field}
            />
          )}
        />
        {costumer_details &&
          costumer_details.household &&
          costumer_details.household.people &&
          costumer_details.household.people.length > 1 &&
          costumer_details.household.people.map((elem, idx) => {
            return (
              idx !== 0 && (
                <Controller
                  key={idx}
                  name={`person_${idx + 1}`}
                  control={control}
                  render={({field}) => (
                    <DropdownSelectAge
                      label={`${t('CREDIT_FORM.person')} ${idx + 1} ${t('CREDIT_FORM.age')}`}
                      className={'credit__select'}
                      order={idx}
                      onChange={(body) => setValue(`person_${idx + 1}`, body)}
                      customOnBlur={handleEditMember}
                      deleteElem={handleDeleteMember}
                      clearErrors={clearErrors}
                      setError={setError}
                      trigger={trigger}
                      subtitle={t('CREDIT_FORM.enter_age')}
                      isTouched={touchedFields && touchedFields[`person_${idx + 1}`]}
                      error={errors && errors[`person_${idx + 1}`]?.message}
                      placeholder={costumer_details && getValues(`person_${idx + 1}`)}
                      inputProps={field}
                    />
                  )}
                />
              )
            );
          })}
      </div>
      {costumer_details &&
        costumer_details.household &&
        costumer_details.household.people &&
        costumer_details.household.people.length < 15 && <AddMember onClick={handleAddMember} />}
    </>
  );
};

export default StepTwo;
