import React, {useEffect, useState} from 'react';
import styles from '../Auth.module.scss';
import {SkeletonMUI} from '../../../shared';

import {useSelector, useDispatch} from 'react-redux';
import {postAuthAuthostart, getCollectRequest, resetStore, getAnimatedQRRequest} from '../authActions';
import {errorOccurred, onSuccessRedirect} from '../../../helpers/functions';
import {useTranslation} from 'react-i18next';

const BankIdQrCode = () => {
  const [collectInterval, setCollectInterval] = useState(null);
  // const [QRFetchingInterval, setQRFetchingInterval] = useState(null);

  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {IP, type, autoStartToken, qrCode, collectErrorCode, collectStatus} = useSelector(({auth}) => auth);

  const onUnmount = () => {
    dispatch(resetStore());
    if (collectInterval) clearInterval(collectInterval);
    // clearInterval(QRFetchingInterval);
  };

  const fetchAutostartToken = () => {
    dispatch(postAuthAuthostart({IP}));
  };

  useEffect(() => {
    fetchAutostartToken();
    return () => onUnmount();
  }, []);

  const handleQRFetchingInterval = () => {
    dispatch(getAnimatedQRRequest(autoStartToken)).then(() => handleCollectInterval());
    return null;
  };

  const handleCollectInterval = () => {
    setCollectInterval(
      setInterval(() => {
        dispatch(getCollectRequest(autoStartToken));
      }, 2000)
    );
  };

  useEffect(() => {
    if (autoStartToken) {
      handleQRFetchingInterval();
    }
    if (collectInterval) clearInterval(collectInterval);
  }, [autoStartToken]);

  useEffect(() => {
    if (errorOccurred(collectErrorCode) || collectStatus?.toLowerCase() === 'complete') {
      if (collectInterval) clearInterval(collectInterval);
      if (collectStatus?.toLowerCase() === 'complete') onSuccessRedirect(autoStartToken, type);
    }
  }, [collectErrorCode, collectStatus]);

  return (
    <>
      <p className={`${styles.text} mb40`}>{t('BANKIDQRCODE.start')}</p>

      {qrCode ? (
        <img className={`${styles.qr} `} src={`data:image/png;base64,${qrCode}`} alt='qr code' />
      ) : (
        <SkeletonMUI variant='rectangular' height={158} width={158} loading />
      )}
    </>
  );
};

export default BankIdQrCode;
