import React, {useState, useEffect} from 'react';
import styles from './ContractMobileCard.module.scss';
import {getPDF} from '../contractsActions';
import {useDispatch, useSelector} from 'react-redux';
import {handleDownload} from '../../../helpers/functions';
import dayjs from 'dayjs';
import {SkeletonMUI} from '../../../shared';
import {useTranslation} from 'react-i18next';
import {ArrowRightBlack, File} from '../../../assets/icons';

const ContractMobileCard = ({data, isLoading = false}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const {pdf, loading} = useSelector(({contracts}) => contracts);
  const arrowHandler = () => {
    setShowMenu(!showMenu);
  };
  const [pdfDownloaded, setPdfDownloaded] = useState(false);

  const type = data?.status === 'Active' ? 'active' : data?.status === 'Rejected' ? 'rejected' : 'terminated';

  //DOWNLOAD PDF FUNCTION
  const handleDownloadPDF = async (id, type, name) => {
    await dispatch(getPDF(id, type, name));
    setPdfDownloaded(true);
  };

  useEffect(() => {
    pdfDownloaded && handleDownload(pdf);
    setPdfDownloaded(false);
  }, [pdfDownloaded]);

  const rowsArr = [
    {
      label: 'CONTRACTMOBILE.rate',
      value: `${data?.product_details?.nominal_interest_rate}%`
    },
    {
      label: 'CONTRACTMOBILE.payment',
      value: `${data?.product_details?.minimum_payment_percent}%`
    },
    {
      label: 'CONTRACTMOBILE.minimum_payment',
      value: `${data?.product_details?.minimum_payment_amount.toLocaleString('sv-SE', {
        style: 'currency',
        currency: 'SEK'
      })}`
    },
    {
      label: 'CONTRACTMOBILE.fee',
      value: `${data?.product_details?.origination_fee.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}`
    },
    {
      label: 'CONTRACTMOBILE.fees',
      value: `${data?.product_details?.monthly_fees.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}`
    },
    {
      label: 'CONTRACTMOBILE.apr',
      value: `${data?.product_details?.apr.toFixed(2)}%`
    }
  ];

  return isLoading ? (
    <SkeletonMUI className='mb16' variant='rectangular' height={70} loading />
  ) : (
    <div className={styles.root}>
      <div onClick={arrowHandler} className={styles.header}>
        <div className={`${styles['header__status']} ${type}`}>
          <div className={type === 'rejected' ? styles.row : styles.column}>
            {type !== 'rejected' ? (
              <b>
                {t('CONTRACTMOBILE.loan')} {data?.contract_id}.
              </b>
            ) : (
              <b>{t('CONTRACTMOBILE.rejected')}</b>
            )}

            {type === 'active' ? (
              showMenu ? (
                <p>{t('CONTRACTMOBILE.status_s')}</p>
              ) : (
                <p>{t('CONTRACTMOBILE.status')}</p>
              )
            ) : type === 'rejected' ? (
              <p>
                {t('CONTRACTMOBILE.status_rejected_on')} {dayjs(data?.applied_date).format('DD.MM.YYYY')}
              </p>
            ) : (
              <p>
                {t('CONTRACTMOBILE.status_closed')} {dayjs(data?.applied_date).format('DD.MM.YYYY')}
              </p>
            )}
          </div>
        </div>
        <div className={styles['header__main']}>
          <div className={styles['header__char']}>
            <p className={styles['header__char--title']}>{t('CONTRACTMOBILE.date')}</p>
            <span className={styles['header__char--text']}>{dayjs(data?.applied_date).format('DD.MM.YYYY')}</span>
          </div>

          <div className={styles['header__char']}>
            <p className={styles['header__char--title']}>{t('CONTRACTMOBILE.loan_amount')}</p>
            <span className={styles['header__char--text']}>
              {data?.product_details?.limit.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
            </span>
          </div>

          <button aria-label='show menu' onClick={arrowHandler} className={styles['header__button']}>
            <ArrowRightBlack className={!showMenu ? styles.icon : styles.icon__rotate} />
          </button>
        </div>
      </div>

      <div className={`${styles.main} ${showMenu ? styles.show : styles.hide}`}>
        <div className={styles['main__table']}>
          {rowsArr.map((row, index) => (
            <div className={styles['main__table--row']} key={index}>
              <div className={styles['main__table--char']}>{t(row.label)}</div>
              <div className={styles['main__table--char']}>{row.value}</div>
            </div>
          ))}

          <p className={styles['main__below']}>{t('CONTRACTMOBILE.below')}</p>

          {data &&
            data?.documents &&
            data?.documents.length > 0 &&
            data?.documents.map((item, idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => handleDownloadPDF(data?.contract_id, item.type, item.file_name)}
                  className={styles['main__download']}
                >
                  <File className='mr10' />
                  <p>
                    {' '}
                    {t('CONTRACT.download')} {item?.file_name} PDF
                  </p>
                </button>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ContractMobileCard;
