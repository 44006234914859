import {SETTINGS} from './settingsActionTypes';

const INITIAL_STATE = {
  loading: false,
  settings_loading: false,
  confirmation_loading: false,
  customer_loading: false,
  email_loading: false,
  tink_loading: false,
  application_loading: false,
  handle_tink: null,
  customer_data: null,
  settings: null,
  tink: null,
  is_menu_shown: false,
  customer_flags: null,
  customer: null,
  options: [],
  guide_step: 0,
  notifications: [],
  email_confirmed: null,
  notification_quantity: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SETTINGS.GET_HANDLE_TINK:
    case SETTINGS.PSD2_FOR_APP:
      return {...state, application_loading: true};
    case SETTINGS.GET_HANDLE_TINK_SUCCESS:
      return {...state, application_loading: false, handle_tink: action.payload.data};

    case SETTINGS.GET_CUSTOMER_FLAGS:
      return {...state, settings_loading: true};
    case SETTINGS.GET_CUSTOMER_FLAGS_SUCCESS:
      return {...state, settings_loading: false, customer_flags: action.payload.data};
    case SETTINGS.GET_CUSTOMER_FLAGS_FAIL:
      return {...state, settings_loading: false};

    case SETTINGS.SET_SHOW_MENU:
      return {...state, is_menu_shown: action.value};

    case SETTINGS.SET_GUIDE_STEP:
      return {...state, guide_step: action.value};

    case SETTINGS.SET_NOTIFICATION_QUANTITY:
      return {...state, notification_quantity: action.value};

    case SETTINGS.PSD2_FOR_APP_SUCCESS:
      return {...state, application_loading: false, psd2: action.payload.data};

    case SETTINGS.PSD2_FOR_APP_FAIL:
    case SETTINGS.GET_HANDLE_TINK_FAIL:
      return {...state, application_loading: false};

    case SETTINGS.EMAIL_CONFIRMED_SUCCESS:
      return {...state, email_confirmed: action.payload.data.confirmed};
    case SETTINGS.EMAIL_CONFIRMED_FAIL:
      return {...state, email_confirmed: false};

    case SETTINGS.GET_SETTINGS:
      return {...state, customer_loading: true};

    case SETTINGS.GET_CUSTOMER:
    case SETTINGS.GET_CUSTOMER_NOTIFICATIONS:
      return {...state, loading: true};

    case SETTINGS.GET_CUSTOMER_NOTIFICATIONS_FAIL:
      return {...state, loading: false, notifications: []};

    case SETTINGS.GET_CUSTOMER_NOTIFICATIONS_SUCCESS:
      return {...state, loading: false, notifications: action.payload.data};

    case SETTINGS.GET_CUSTOMER_SUCCESS:
      return {...state, loading: false, customer: action?.payload?.data};

    case SETTINGS.GET_TINK:
      return {...state, tink_loading: true};
    case SETTINGS.GET_TINK_FAIL:
      return {...state, tink_loading: false};
    case SETTINGS.GET_TINK_SUCCESS:
      return {...state, tink_loading: false, tink: action?.payload?.data};

    case SETTINGS.POST_EMAIL_CONFIRMATION:
      return {...state, email_loading: true};

    case SETTINGS.POST_EMAIL_CONFIRMATION_SUCCESS:
      return {...state, email_loading: false};

    case SETTINGS.GET_CUSTOMER_SUCCESS:
      return {...state, loading: false, customer_data: action?.payload?.data};

    case SETTINGS.UPDATE_SETTINGS:
      return {...state, settings_loading: true};

    case SETTINGS.UPDATE_SETTINGS_SUCCESS:
      return {...state, settings_loading: false, settings: action?.payload?.data};

    case SETTINGS.UPDATE_SETTINGS_FAIL:
      return {...state, settings_loading: false};

    case SETTINGS.CONFIRM_EMAIL_CODE:
      return {...state, confirmation_loading: true};

    case SETTINGS.CONFIRM_EMAIL_CODE_SUCCESS:
    case SETTINGS.CONFIRM_EMAIL_CODE_FAIL:
      return {...state, confirmation_loading: false};

    case SETTINGS.GET_SETTINGS_SUCCESS:
      let optionArr = [];
      action?.payload?.data.accounts.map((item, idx) => {
        optionArr.push({
          id: idx,
          title: item.iban
        });
      });
      return {...state, customer_loading: false, settings: action?.payload?.data, options: optionArr};

    default:
      return state;
  }
}
