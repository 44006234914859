import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {rootMainPath, rootAuthPath, rootCreditAppPath} from './paths';
import AuthContainer from '../containers/AuthContainer';
import MainContainer from '../containers/MainContainer';
import CreditContainer from '../containers/CreditContainer';
import {Page404} from '../shared';

const AppRoutes = () => {
  return (
    <Switch>
      <Route
        path='/'
        exact
        render={() => (sessionStorage.session_id ? <Redirect to={rootMainPath} /> : <Redirect to={rootAuthPath} />)}
      />
      <Route path={rootAuthPath} component={AuthContainer} />
      <Route path={rootMainPath} component={MainContainer} />
      <Route path={rootCreditAppPath} component={CreditContainer} />
      <Route path={'/404'} component={Page404} />
    </Switch>
  );
};

export default AppRoutes;
