import React, {useEffect, useState} from 'react';
import styles from './RangeInputPure.module.scss';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {iOS} from '../../helpers/functions';

const RangeInputPure = ({
  sliderWidth = 350,
  step = 3.33333,
  setter,
  value,
  totalLimit = 20000,
  availableLimit = 20000,
  disabled = false,
  marksArray,
  lowestValue = 0
}) => {
  const availableLimitWidth = sliderWidth;

  const marks = [
    {
      value: 0,
      label: '0'
    },
    {
      value: 25,
      label: Math.ceil(totalLimit * 0.25)
    },
    {
      value: 50,
      label: Math.ceil(totalLimit * 0.5)
    },
    {
      value: 75,
      label: Math.ceil(totalLimit * 0.75)
    },
    {
      value: 100,
      label: totalLimit
    }
  ];

  marksArray = marks;

  const percentValueAdapter = (clickedValue) => {
    const percentValue = Math.round((clickedValue * availableLimit) / 100 / 10) * 10;

    if (percentValue >= 500) {
      setter(percentValue);
    } else {
      setter(500);
    }
  };

  const isiOS = iOS();

  return (
    <div className={`${styles.root} ${sliderWidth > 350 && styles.root__xl} ${disabled && 'disabled'}`}>
      <Box sx={{width: availableLimitWidth}}>
        {
          <Slider
            aria-label='Custom marks'
            step={step}
            value={value ? value : 0}
            onChange={(e) => {
              !isiOS && navigator.vibrate([15, 30, 15, 30]);

              !disabled && percentValueAdapter(e.target.value);
            }}
            valueLabelDisplay='off'
            //marks={marksArray}
            color='secondary'
            sx={{
              width: availableLimitWidth,
              color: `${disabled ? '#D9D9D9' : '#235971'}`,
              '& .MuiSlider-mark': {
                background: 'transparent'
              },
              '& .MuiSlider-markLabel': {
                top: '65px',
                fontSize: '12px'
              },
              '&.MuiSlider-root': {
                height: '59px',
                padding: '0'
              },

              '& .MuiSlider-track': {
                borderRadius: value === 100 ? '20px' : '20px 0 0 20px',
                borderRight: disabled ? '1px solid #CBCBCB' : '1px solid black'
              },

              '& .MuiSlider-thumb': {
                borderRadius: '50%',
                width: '38px',
                height: '38px',
                background: '#22232A',
                zIndex: 100
              },
              '& .MuiSlider-rail': {
                backgroundColor: '#D9D9D9',
                opacity: 1,
                borderRadius: '20px 20px 20px 20px',
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)'
              }
            }}
          />
        }
        <div className={`${styles.marks} `}>
          {marksArray.length > 0 &&
            marksArray.map((item, idx) => {
              const isLastChild = idx === marksArray.length - 1;
              const leftPosition = isLastChild
                ? `calc(${item.value}% - 40px)`
                : `calc(${item.value}% - ${totalLimit / 10000 >= 1 ? 22 : 22}px)`;
              return (
                <span
                  className={`${styles['marks__elem']} ${disabled && styles.marks__elem__disabled}`}
                  key={idx}
                  style={{left: idx === 0 ? 0 : leftPosition}}
                >
                  {item.label}
                </span>
              );
            })}
        </div>
      </Box>
    </div>
  );
};

export default RangeInputPure;
