import React from 'react';
import styles from './LoadingBlock.module.scss';
import {Logotype} from '../../assets/icons';
import {useSelector} from 'react-redux';
import testAnimatedLogo from '../../assets/icons/test_logo.gif';
import {useTranslation} from 'react-i18next';

const LoadingBlock = () => {
  const {loading} = useSelector(({auth}) => auth);
  const {pdf_loading} = useSelector(({invoices}) => invoices);
  const {email_loading, tink_loading} = useSelector(({settings}) => settings);
  const {popup_loading} = useSelector(({withdrawal}) => withdrawal);
  const {update_loading} = useSelector(({credit_form}) => credit_form);

  const isLoading = loading || pdf_loading || email_loading || tink_loading || popup_loading || update_loading;
  const {t} = useTranslation();

  return (
    <div style={{display: isLoading ? 'flex' : ' none'}} className={`${styles.root}`}>
      {/* <Logotype className={styles.logo} /> */}
      <img className={styles['logo']} src={testAnimatedLogo} alt='testAnimatedLogo' />
      <p>{t('REACT__TOUR.loading')}</p>
    </div>
  );
};

export default LoadingBlock;
