import React, {Suspense, useEffect} from 'react';
import styles from './PersonalDropdown.module.scss';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {UserFill, LogoutIcon} from '../../assets/icons';
import {useQuery} from 'react-query';
import {axiosInstance} from '../../app/store';
import SkeletonMUI from '../SkeletonMUI';
import {authPath} from '../../routes/paths';
import NotificationsBlock from '../NotificationsBlock';
import {useWindowWidth} from '../../helpers/hooks';

const PersonalDropdownContent = ({customer_data, ledgers_data, setter, dialog, toggleDialog, t}) => {
  const creditData = [
    {
      label: t('CREDITACCOUNTCARD.capital'),
      value: (ledgers_data?.principal || 0).toLocaleString('sv-SE', {
        style: 'currency',
        currency: 'SEK',
        maximumFractionDigits: ledgers_data?.principal ? 0 : 2
      })
    },
    {
      label: t('CREDITACCOUNTCARD.interest'),
      value: (ledgers_data?.interest || 0).toLocaleString('sv-SE', {
        style: 'currency',
        currency: 'SEK',
        maximumFractionDigits: ledgers_data?.interest ? 0 : 2
      })
    },
    {
      label: t('CREDITACCOUNTCARD.fees'),
      value: (ledgers_data?.fees || 0).toLocaleString('sv-SE', {
        style: 'currency',
        currency: 'SEK',
        maximumFractionDigits: ledgers_data?.fees ? 0 : 2
      })
    },
    {
      label: t('CREDITACCOUNTCARD.usable'),
      value: (ledgers_data?.available_balance || 0).toLocaleString('sv-SE', {
        style: 'currency',
        currency: 'SEK',
        maximumFractionDigits: ledgers_data?.available_balance ? 0 : 2
      })
    }
  ];

  const width = useWindowWidth();

  useEffect(() => {
    const disableScroll = () => {
      document.body.style.overflow = 'hidden';
    };
    width <= 768 && !dialog && disableScroll();
    return () => {
      if (width <= 768) {
        document.body.style.overflow = 'initial';
      }
    };
  }, [dialog]);

  const total_debt_burden = ledgers_data?.principal + ledgers_data?.interest + ledgers_data?.fees;

  return (
    <>
      <div className={styles['card__header']}>
        <UserFill className={styles['card__header--icon']} />

        <p className={styles['card__header--name']}>
          {customer_data?.first_name} {customer_data?.given_name} {customer_data?.last_name}
        </p>
        {/* <p className={styles['card__header--mail']}>{customer_data?.email}</p> */}

        {/* <Link className={styles['card__header--edit']} onClick={() => setter(false)} to={mainPath.settings.path}>
          {t('PROFILECARD.edit')}
        </Link> */}
      </div>

      <div className={styles['credit']}>
        <div className={styles['credit__column']}>
          <b className={styles['credit__title']}>{t('CREDITACCOUNTCARD.credit')}</b>
          <p className={styles['credit__subtitle']}>{t('CREDITACCOUNTCARD.total')}</p>
          <span className={styles['credit__price']}>
            {(total_debt_burden || 0).toLocaleString('sv-SE', {
              style: 'currency',
              currency: 'SEK',
              maximumFractionDigits: total_debt_burden ? 0 : 2
            })}
          </span>
        </div>
        <div className={styles['credit__column']}>
          {creditData.map((item, index) => (
            <div key={index} className={styles['credit__column--char']}>
              <p>{item.label}</p>
              <span>
                {item.value &&
                  item.value.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK', maximumFractionDigits: 0})}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className={styles['card__footer']}>
        <NotificationsBlock
          isPopup
          customer_data={customer_data}
          closeDropdown={setter}
          dialog={dialog}
          toggleDialog={toggleDialog}
        />
      </div>
    </>
  );
};

const PersonalDropdown = ({windowRef, setter, dialog, toggleDialog, isPersonalModal}) => {
  const {t} = useTranslation();
  const token = sessionStorage.getItem('session_id');
  const {data: ledgers_data} = useQuery('ledgers', async () => {
    const response = await axiosInstance.get('ledgers/GetLedger');
    return response.data;
  });

  const {data: customer_data, ...rest} = useQuery('customer', async () => {
    const response = await axiosInstance.get('customers/GetCustomer');
    return response.data;
  });

  const history = useHistory();
  const logout = () => {
    sessionStorage.clear();
    history.push(authPath.signIn.path);
  };

  return (
    <>
      <div
        className={`${styles['triangle']}  ${isPersonalModal ? styles.triangle__open : styles.triangle__closed}`}
      ></div>
      <div
        ref={windowRef}
        className={`${styles.root} ${isPersonalModal === null && styles.root__hide}   ${
          isPersonalModal === false && styles.root__closed
        } ${isPersonalModal === true && styles.root__open}`}
      >
        <Suspense
          fallback={
            <div ref={windowRef} className={`${styles.root} ${styles.loading}`}>
              <SkeletonMUI variant='text' width={'100%'} height={156} loading />
              <SkeletonMUI variant='text' width={'100%'} height={156} loading />
              <SkeletonMUI variant='text' width={'100%'} height={30} loading />
              <SkeletonMUI variant='text' width={'100%'} height={30} loading />
            </div>
          }
        >
          <PersonalDropdownContent
            dialog={dialog}
            toggleDialog={toggleDialog}
            customer_data={customer_data}
            ledgers_data={ledgers_data}
            setter={setter}
            t={t}
          />
        </Suspense>
        {!token && (
          <>
            <p className={styles['menu__text']}>{t('HEADERDROPDOWN.already')}</p>
            <span className={styles['menu__button']}> {t('HEADERDROPDOWN.login')}</span>
          </>
        )}
        {token && (
          <>
            {/* <p className={styles['menu__text']}> {t('HEADERDROPDOWN.got')}</p> */}
            <span onClick={() => logout()} className={styles['menu__button']}>
              {t('HEADERDROPDOWN.logout')}
              <LogoutIcon />
            </span>
          </>
        )}
      </div>
    </>
  );
};

export default PersonalDropdown;
