import React, {useState, useRef, useEffect} from 'react';
import styles from './DatePickerElement.module.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendar from '../../../src/assets/icons/datepicker.svg';
import {useTranslation} from 'react-i18next';
import {useOnClickOutside, useWindowWidth} from '../../helpers/hooks';

const DatePickerElement = ({label, date, setDate, isEnd = false}) => {
  const {t} = useTranslation();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const datePickerRef = useRef(null);
  const containerRef = useRef(null);
  const startYear = 2010;
  const years = Array.from({length: currentYear - startYear + 1}, (_, index) => startYear + index).reverse();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  useOnClickOutside(containerRef, () => setIsDatePickerOpen(false));
  const width = useWindowWidth();

  const months = [
    t('TRANSACTIONSELECTOR.january'),
    t('TRANSACTIONSELECTOR.february'),
    t('TRANSACTIONSELECTOR.march'),
    t('TRANSACTIONSELECTOR.april'),
    t('TRANSACTIONSELECTOR.may'),
    t('TRANSACTIONSELECTOR.june'),
    t('TRANSACTIONSELECTOR.july'),
    t('TRANSACTIONSELECTOR.august'),
    t('TRANSACTIONSELECTOR.september'),
    t('TRANSACTIONSELECTOR.october'),
    t('TRANSACTIONSELECTOR.november'),
    t('TRANSACTIONSELECTOR.december')
  ];

  const autoScrollDown = () => {
    if (width < 768 && window.scrollY < 300) {
      window.scrollTo({
        top: 300,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    if (datePickerRef.current !== null) {
      datePickerRef.current.input.readOnly = true;
    }
  }, [datePickerRef]);

  return (
    <div className={`${styles.root} ${isEnd ? styles.root__end : ''}`}>
      {label && <label className={styles.label}>{label}</label>}
      <div ref={containerRef} className={styles.input}>
        <DatePicker
          ref={datePickerRef}
          disabledKeyboardNavigation
          dateFormat={'dd/MM/yyyy'}
          renderCustomHeader={({date, changeYear, changeMonth}) => (
            <div className={styles.header}>
              <select
                value={months[date.getMonth()]}
                onChange={({target: {value}}) => changeMonth(months.indexOf(value))}
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <select value={date.getFullYear()} onChange={({target: {value}}) => changeYear(value)}>
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}
          selected={date}
          customInput={<input readOnly={true} />}
          onChange={(newDate) => {
            setDate(newDate);
            datePickerRef.current.setOpen(false);
            setIsDatePickerOpen(false);
          }}
          onKeyDown={(e) => {
            e.preventDefault();
            e.target.readOnly = true;
          }}
          onFocus={(e) => {
            autoScrollDown();
            setIsDatePickerOpen(true);
            e.preventDefault();
            e.target.blur();
            e.target.readOnly = true;
          }}
          onBlur={() => setIsDatePickerOpen(false)}
        />
        {/* THIS BUTTONS NEED TO COVER INPUT To PREVENT VIRTUAL KEYBOARD OPENING  */}
        <button
          className={styles.phantom__btn}
          type='button'
          aria-label='pahntom'
          onClick={() => {
            if (datePickerRef.current) {
              if (isDatePickerOpen) {
                // Close the date picker
                datePickerRef.current.setOpen(false);
                setIsDatePickerOpen(false);
              } else {
                // Open the date picker
                autoScrollDown();
                datePickerRef.current.setOpen(true);
                setIsDatePickerOpen(true);
              }
            }
          }}
        ></button>

        <button
          type='button'
          aria-label='calendar'
          className={`${styles.button} ${isDatePickerOpen && styles.button__open}`}
          onClick={() => {
            if (datePickerRef.current) {
              if (isDatePickerOpen) {
                // Close the date picker
                datePickerRef.current.setOpen(false);
                setIsDatePickerOpen(false);
              } else {
                // Open the date picker
                autoScrollDown();
                datePickerRef.current.setOpen(true);
                setIsDatePickerOpen(true);
              }
            }
          }}
        >
          <img src={calendar} width={24} height={26} alt='calendar' />
        </button>
      </div>
    </div>
  );
};

export default DatePickerElement;
