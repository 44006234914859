import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useHistory, useLocation} from 'react-router-dom';
import {ProgressBar, ToastComponent} from '../../shared';
import CreditRoutes from '../../routes/CreditRoutes';
import {mainPath} from '../../routes/paths';
import {useDispatch, useSelector} from 'react-redux';
import {GetCustomerFlags} from '../../features/Settings/settingsActions';
import {SubscriptionExpirationCheck} from '../../helpers/functions';

const CreditContainer = () => {
  const history = useHistory();
  const {pathname} = useLocation();
  const [supervisorPopup, setSupervisorPopup] = useState(false);
  const {customer_flags} = useSelector(({settings}) => settings);
  const dispatch = useDispatch();
  const expiredTime = sessionStorage.getItem('expired_date');

  useEffect(() => {
    //SUPERVISOR LOGIC -- ALLOWS JUST HOME PAGE
    const isHome = pathname === mainPath.overview.path;

    const isSupervisor =
      customer_flags?.flags.includes('SupervisionOfInterest') || customer_flags?.flags.includes('Banned');

    if (isSupervisor) {
      setSupervisorPopup(true);
      if (!isHome) {
        history.push(mainPath.overview.path);
      }
    }
  }, [pathname, customer_flags]);

  useEffect(() => {
    dispatch(GetCustomerFlags());
  }, []);

  return (
    <>
      <Helmet>
        <meta name='description' content='Here can be description for every page' />
        <meta name='keywords' content='Flex limit SEO keywords' />
        <meta property='og:title' content='Your Page Title' />
        <meta property='og:description' content='This is the description of the page.' />
      </Helmet>
      <ProgressBar />
      <ToastComponent
        isFixed
        isSuccess={false}
        visible={supervisorPopup}
        setter={setSupervisorPopup}
        title={'Sorry! You have access just to look around the home page'}
      />
      <SubscriptionExpirationCheck
        history={history}
        subscriptionEndDate={expiredTime}
        currentPage={pathname.split('/')[2]}
      />
      <CreditRoutes />
    </>
  );
};

export default CreditContainer;
