import React from 'react';
import styles from './Settings.module.scss';
import SideBar from '../SideBar';
import SettingsForm from './SettingsForm';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {CreditAccountCard, CustomerServiceCard} from '../../shared';
import {useWindowWidth} from '../../helpers/hooks';
import TransPageToast from '../../shared/TransPageToast/TransPageToast';

const Settings = () => {
  const {t} = useTranslation();
  const {is_menu_shown, guide_step} = useSelector((state) => state.settings);

  const need_tour = JSON.parse(localStorage.getItem('react_tour_data'))?.need_tour || false;
  const width = useWindowWidth();

  const showBlur = need_tour && guide_step === 6;

  return (
    <>
      <div className={'main__headers'}>
        <div className='container'>
          <h1 className={`${styles['overview__title']} main__title `}>{t('SETTINGS.title')}</h1>
          <div className={`${styles['overview__description']} main__description`}>
            <p> {t('SETTINGS.description')}</p>
            <p> {t('SETTINGS.description2')}</p>
          </div>
        </div>
      </div>
      <main className={`${styles.root} ${is_menu_shown && 'main__blured'} main__container container`}>
        <div className={`${styles['overview__main']} main__grid`}>
          <div className={`${styles['overview__column']} main__column ${showBlur && 'main__tourblur'}`}>
            <TransPageToast />
            <SettingsForm />

            {width <= 1000 && (
              <>
                {/* <UrgentCard size={'large'} /> */}

                <CreditAccountCard />
                <CustomerServiceCard type='sidebar' />
              </>
            )}
          </div>
          <SideBar />
        </div>
      </main>{' '}
    </>
  );
};

export default Settings;
