import React from 'react';
import styles from './NotificationPopup.module.scss';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {axiosInstance} from '../../app/store';
import triange from '../../assets/icons/triangle_icon.svg';
import NotificationsBlock from '../NotificationsBlock';
import {useSelector} from 'react-redux';

const NotificationPopup = ({isPersonalModal}) => {
  const {t} = useTranslation();
  const {notification_quantity} = useSelector((state) => state.settings);
  const {data: customer_data, isLoading} = useQuery('customer', async () => {
    const response = await axiosInstance.get('customers/GetCustomer');
    return response.data;
  });

  const max_to_show = 1;

  return (
    <>
      <div
        className={`${styles['triangle']} ${
          isPersonalModal && !isLoading ? styles.triangle__open : styles.triangle__closed
        }`}
      >
        <img src={triange} alt='triange' />
      </div>
      <div
        className={`${styles.root} ${isPersonalModal === null && styles.root__hide} ${
          isPersonalModal === false && styles.root__closed
        } ${isPersonalModal === true && !isLoading && styles.root__open} ${
          notification_quantity.length === max_to_show && styles['root__open--single']
        }`}
      >
        {/* <PulsationDot type='blue' pulse={false} className='mr7' /> */}
        {notification_quantity?.length !== max_to_show && (
          <p className={styles.title}>
            {t('NOTIFICATION_POPUP.you')} {notification_quantity.length || 0} {t('NOTIFICATION_POPUP.messages')}
          </p>
        )}

        {notification_quantity?.length === max_to_show && <NotificationsBlock isNotification />}
      </div>
    </>
  );
};

export default NotificationPopup;
