import {WITHDRAWAL} from './withdrawalActionTypes';

export function sendNewWithdrawal(data) {
  return {
    type: WITHDRAWAL.NEW_WITHDRAWAL,
    payload: {
      client: 'second',
      request: {
        url: `/withdrawals/NewWithdrawal`,
        method: 'post',
        data
      }
    }
  };
}

export function getConfirmationDetails(withdrawal_id) {
  return {
    type: WITHDRAWAL.GET_CONFIRMATION_DETAILS,
    payload: {
      client: 'second',
      request: {
        url: `/withdrawals/GetWithdrawalConfirmationDetails?withdrawal_id=${withdrawal_id}`,
        method: 'get'
      }
    }
  };
}

export function confirmWithdrawal(data) {
  return {
    type: WITHDRAWAL.CONFIRM_WITHDRAWAL,
    payload: {
      client: 'second',
      request: {
        url: `/withdrawals/ConfirmWithdrawal`,
        method: 'post',
        data
      }
    }
  };
}

export function cancelWithdrawal(data) {
  return {
    type: WITHDRAWAL.CANCEL_WITHDRAWAL,
    payload: {
      client: 'second',
      request: {
        url: `/withdrawals/CancelWithdrawal`,
        method: 'post',
        data
      }
    }
  };
}

export function markInfoShowRequest(data) {
  return {
    type: WITHDRAWAL.MARK_INFO_SHOW,
    payload: {
      client: 'second',
      request: {
        url: `/customers/MarkInfoShow`,
        method: 'post',
        data
      }
    }
  };
}
