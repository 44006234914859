import {CREDIT_FORM} from './creditFormActionTypes';

const INITIAL_STATE = {
  loading: false,
  update_loading: false,
  psd2_loading: false,
  error: null,
  first_circle: '',
  withdrawal_details: null,
  second_circle: '',
  third_circle: '',
  fourth_circle: '',
  seventh_circle: false,
  eights_circle: false,
  withdrawal_circles: true,
  costumer_details: null,
  application_data: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREDIT_FORM.GET_INCOMPLETE_DATA: {
      return {...state, update_loading: true};
    }
    case CREDIT_FORM.GET_INCOMPLETE_DATA_SUCCESS: {
      return {...state, update_loading: false, costumer_details: action.payload.data};
    }
    case CREDIT_FORM.GET_INCOMPLETE_DATA_FAIL: {
      return {...state, update_loading: false};
    }
    case CREDIT_FORM.GET_WITHDRAWAL_CONFIRMATION:
    case CREDIT_FORM.SAVE_FORM: {
      return {...state, loading: true};
    }
    case CREDIT_FORM.SAVE_FORM_FAIL:
    case CREDIT_FORM.GET_WITHDRAWAL_CONFIRMATION_FAIL:
    case CREDIT_FORM.SAVE_FORM_SUCCESS: {
      return {...state, loading: false};
    }

    case CREDIT_FORM.SET_PSD2_FOR_APP: {
      return {...state, psd2_loading: true};
    }
    case CREDIT_FORM.SET_PSD2_FOR_APP_FAIL:
    case CREDIT_FORM.SET_PSD2_FOR_APP_SUCCESS: {
      return {...state, psd2_loading: false};
    }

    case CREDIT_FORM.GET_WITHDRAWAL_CONFIRMATION_SUCCESS: {
      return {...state, loading: false, withdrawal_details: action.payload.data};
    }

    case CREDIT_FORM.GET_APPLICATION_CONFIRMATION: {
      return {...state, loading: true};
    }
    case CREDIT_FORM.GET_APPLICATION_CONFIRMATION_FAIL: {
      return {...state, loading: false};
    }
    case CREDIT_FORM.GET_APP_STATUS:
    case CREDIT_FORM.CANCEL_APP: {
      return {...state, loading: true};
    }
    case CREDIT_FORM.GET_APP_STATUS_SUCCESS:
    case CREDIT_FORM.GET_APP_STATUS_FAIL:
    case CREDIT_FORM.CANCEL_APP_SUCCESS:
    case CREDIT_FORM.CANCEL_APP_FAIL: {
      return {...state, loading: false};
    }

    case CREDIT_FORM.GET_APPLICATION_CONFIRMATION_SUCCESS: {
      return {...state, loading: false, application_data: action.payload.data};
    }

    case CREDIT_FORM.SET_APP_FIRST_CIRCLE:
      return {...state, first_circle: action.data};

    case CREDIT_FORM.SET_APP_SECOND_CIRCLE:
      return {...state, second_circle: action.data};
    case CREDIT_FORM.SET_APP_THIRD_CIRCLE:
      return {...state, third_circle: action.data};
    case CREDIT_FORM.SET_APP_FOURTH_CIRCLE:
      return {...state, fourth_circle: action.data};

    case CREDIT_FORM.SET_APP_SEVENTH_CIRCLE:
      return {...state, seventh_circle: action.data};
    case CREDIT_FORM.SET_APP_EIGHTS_CIRCLE:
      return {...state, eights_circle: action.data};
    case CREDIT_FORM.SHOW_WITHDRAWAL_CIRCLES:
      return {...state, withdrawal_circles: action.data};

    case CREDIT_FORM.SUBMIT_APP:
      return {...state, update_loading: true};
    case CREDIT_FORM.ABANDON_APP:
      return {...state, loading: true};

    case CREDIT_FORM.SUBMIT_APP_FAIL:
      return {...state, update_loading: true};
    case CREDIT_FORM.ABANDON_APP_FAIL:
    case CREDIT_FORM.ABANDON_APP_SUCCESS:
      return {...state, loading: false};

    case CREDIT_FORM.UPDATE_COSTUMER_DETAIL_FAIL:
      return {...state, update_loading: false};

    case CREDIT_FORM.UPDATE_COSTUMER_DETAIL:
    case CREDIT_FORM.SET_TIN_LOADER:
      return {...state, update_loading: true};

    case CREDIT_FORM.UPDATE_COSTUMER_DETAIL_SUCCESS:
      return {...state, update_loading: false, costumer_details: action.payload.data};

    case CREDIT_FORM.SUBMIT_APP_SUCCESS:
      return {...state, update_loading: false, costumer_details: action.payload.data};

    default:
      return state;
  }
}
