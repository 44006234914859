import React, {useEffect, useRef, useState} from 'react';
import styles from './ToastComponent.module.scss';
import {Warning, Success} from '../../assets/icons';

const ToastComponent = ({visible = false, setter, isSuccess = true, isFixed = false, title = null, description}) => {
  const toastRef = useRef(null);

  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isScrollToTop, setScrollToTop] = useState(true);

  const scrollTimer = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.pageYOffset;
      if (prevScrollPosition > currentPosition) {
        setScrollToTop(true);
      } else {
        setScrollToTop(false);
      }
      setPrevScrollPosition(currentPosition);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPosition]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollTimer.current) {
        clearTimeout(scrollTimer.current);
      }
      setIsScrolling(true);
      scrollTimer.current = setTimeout(() => {
        setIsScrolling(false);
        scrollTimer.current = null;
      }, 250);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (visible) {
      const timeout = setTimeout(() => {
        hideToast();
      }, 5000);
      if (toastRef.current && !isFixed) {
        const topOffset = toastRef.current.getBoundingClientRect().top + window.scrollY - 80;
        window.scroll({top: topOffset, behavior: 'smooth'});
      }

      return () => clearTimeout(timeout);
    }
  }, [visible]);

  const hideToast = () => {
    setter();
  };

  return (
    <>
      {
        <div
          ref={toastRef}
          id='toast__container'
          className={`${styles.root} ${isFixed && 'fixed'} ${isScrollToTop && 'fixed__upper'} ${
            isSuccess ? 'green' : 'orange'
          } ${visible ? 'show' : 'hide'}`}
        >
          {isSuccess ? <Success /> : <Warning />}
          <div className={styles['text__block']}>
            {title && <p className={styles['text__title']}>{title}</p>}
            {description && <p className={styles['text__description']}>{description}</p>}
          </div>
        </div>
      }
    </>
  );
};

export default ToastComponent;
