import React from 'react';
import styles from '../RecentTransactions/RecentTransactions.module.scss';
import {DatePickerElement, CheckboxMUI} from '../../../shared';
import {useTranslation} from 'react-i18next';

const Selectors = ({
  showFilter,
  setTransactionTypes,
  startDate,
  endDate,
  handleStartDate,
  handleEndDate,
  settedTransactionTypes
}) => {
  const {t} = useTranslation();
  return (
    <div className={`${styles.selectors} ${styles[`selectors--${showFilter ? 'show' : 'hide'}`]}`}>
      <DatePickerElement label={t('RECENTTRANSACTIONS.start')} date={startDate} setDate={handleStartDate} />
      <DatePickerElement isEnd label={t('RECENTTRANSACTIONS.end')} date={endDate} setDate={handleEndDate} />
      <div className={styles.checkboxes}>
        <div className={styles.checkbox}>
          <CheckboxMUI
            id='checkbox-1'
            onChange={(e) => {
              setTransactionTypes({
                ...settedTransactionTypes,
                isWithdrawal: !settedTransactionTypes.isWithdrawal
              });
            }}
            checked={settedTransactionTypes.isWithdrawal}
            className='mr6'
          />

          <label htmlFor={'checkbox-1'} className={styles.checkbox__text}>
            {t('RECENTTRANSACTIONS.withdrawal')}
          </label>
        </div>
        <div className={styles.checkbox}>
          <CheckboxMUI
            id='checkbox-2'
            onChange={(e) => {
              setTransactionTypes({
                ...settedTransactionTypes,
                isInterest: !settedTransactionTypes.isInterest
              });
            }}
            checked={settedTransactionTypes.isInterest}
            className='mr6'
          />

          <label htmlFor={'checkbox-2'} className={styles.checkbox__text}>
            {t('RECENTTRANSACTIONS.interest')}
          </label>
        </div>
        <div className={styles.checkbox}>
          <CheckboxMUI
            id='checkbox-3'
            onChange={(e) => {
              setTransactionTypes({
                ...settedTransactionTypes,
                isPayment: !settedTransactionTypes.isPayment
              });
            }}
            checked={settedTransactionTypes.isPayment}
            className='mr6'
          />

          <label htmlFor={'checkbox-3'} className={styles.checkbox__text}>
            {t('RECENTTRANSACTIONS.payment')}
          </label>
        </div>
        <div className={styles.checkbox}>
          <CheckboxMUI
            id='checkbox-4'
            onChange={(e) => {
              setTransactionTypes({
                ...settedTransactionTypes,
                isPaymentFree: !settedTransactionTypes.isPaymentFree
              });
            }}
            checked={settedTransactionTypes.isPaymentFree}
            className='mr6'
          />

          <label htmlFor={'checkbox-4'} className={styles.checkbox__text}>
            {t('RECENTTRANSACTIONS.free')}
          </label>
        </div>
      </div>
    </div>
  );
};

export default Selectors;
