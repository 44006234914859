import React, {useState, Fragment, useEffect} from 'react';
import styles from './AppTourDesktop.module.scss';
import {useTranslation} from 'react-i18next';
import {mainPath} from '../../routes/paths';
import {NextStep, CloseTour} from '../../assets/icons';
import {useHistory, useLocation} from 'react-router-dom';
import {useWindowWidth} from '../../helpers/hooks';
import {Dialog, Transition} from '@headlessui/react';
import {setGuideStep} from '../Settings/settingsActions';
import {useSelector, useDispatch} from 'react-redux';

const AppTourDesktop = ({setter, navbarSetter, isOpen}) => {
  // REQUIREMENTS
  // 1. FOR FIRST-TIME USERS
  // 2. IF USER DOESN'T SKIP TUTORIAL, SHOW AGAIN (IF HE REFRESHES THE PAGE)
  // 3. REMEMBER STEP WHERE THEY SKIPPED: If the user refreshes the page on the 2nd and subsequent pages without clicking skip, show the guide again when they return to this page.
  // 4. IF SOME STEP WAS SKIPPED BECAUSE THE USER REFRESHED THE PAGE ON THAT STEP, ALSO SHOW THIS STEP
  // 5. IF THE USER SKIPPED A STEP AND CHANGES THE ORDER OF PAGE VISITS, ALSO SHOW THE GUIDE AND THEN USE 4.
  const width = useWindowWidth();
  const dispatch = useDispatch();
  const {pathname, search} = useLocation();
  const react_tour_data = localStorage.getItem('react_tour_data');
  const [shownEntry, setShownEntry] = useState(false);
  let activeStep = JSON.parse(react_tour_data)?.active_step || 0;
  const [needToShow, setNeedToShow] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const {guide_step} = useSelector((state) => state.settings);
  const {t} = useTranslation();
  const history = useHistory();
  const handleSkipTheTour = () => {
    setter(false);
    localStorage.setItem(
      'react_tour_data',
      JSON.stringify({
        ...JSON.parse(react_tour_data),
        need_tour: false
      })
    );
  };

  useEffect(() => {
    const findTopRightCoordinates = () => {
      const mainColumnElement = document.querySelector('.main__column');

      if (mainColumnElement) {
        const rect = mainColumnElement.getBoundingClientRect();

        setCoordinates({
          top: rect.top,
          right: rect.right
        });
      }
    };

    findTopRightCoordinates();

    const scrollToTop = () => {
      window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const scrollPolling = setInterval(() => {
      const need_tour = (react_tour_data && JSON.parse(react_tour_data).need_tour) || false;
      if (window.scrollY !== 0 && width > 998 && need_tour) {
        scrollToTop();
      } else {
        clearInterval(scrollPolling);
      }
    }, 300);

    return () => {
      // You can add cleanup logic here if needed
    };
  }, [width]);

  const handleSetStep = (step) => {
    const possibleValues = [...Array(7)].map((_, index) => index + 1);
    const arrOfSteps = JSON.parse(react_tour_data).shownSteps || [];
    const unvisitedSteps = possibleValues.filter((value) => !arrOfSteps.includes(value));
    dispatch(setGuideStep(step));
    localStorage.setItem(
      'react_tour_data',
      JSON.stringify({
        ...JSON.parse(react_tour_data),
        active_step: unvisitedSteps[0],
        shownSteps: [...arrOfSteps, unvisitedSteps[0]]
      })
    );
    if (step === 1) {
      setShownEntry(true);
    }
  };

  const steps = [
    {
      order: 1,
      match: mainPath.overview.path,
      title: t('REACT__TOUR.step_1_title'),
      bold_1: t('REACT__TOUR.step_1_bold_1'),
      text_1: t('REACT__TOUR.step_1_text_1'),
      bold_2: t('REACT__TOUR.step_1_bold_2'),
      text_2: t('REACT__TOUR.step_1_text_2'),
      bold_3: null,
      text_3: null,
      next_text: t('REACT__TOUR.step_1_next_text'),
      next_action: () => {
        navbarSetter(true);
        handleSetStep(2);
      }
    },
    {
      order: 2,
      match: mainPath.overview.path,
      title: t('REACT__TOUR.step_2_title'),
      bold_1: t('REACT__TOUR.step_2_bold_1'),
      text_1: t('REACT__TOUR.step_2_text_1'),
      bold_2: t('REACT__TOUR.step_2_bold_2'),
      text_2: t('REACT__TOUR.step_2_text_2'),
      bold_3: null,
      text_3: null,
      next_text: t('REACT__TOUR.step_2_next_text'),
      next_action: () => {
        handleSetStep(3);
        history.push(mainPath.invoices.path);
      }
    },
    {
      order: 3,
      match: mainPath.invoices.path,
      title: t('REACT__TOUR.step_3_title'),
      bold_1: t('REACT__TOUR.step_3_bold_1'),
      text_1: t('REACT__TOUR.step_3_text_1'),
      bold_2: t('REACT__TOUR.step_3_bold_2'),
      text_2: t('REACT__TOUR.step_3_text_2'),
      bold_3: null,
      text_3: null,
      next_text: t('REACT__TOUR.step_3_next_text'),
      next_action: () => {
        handleSetStep(4);
        history.push(mainPath.transactions.path);
      }
    },
    {
      order: 4,
      match: mainPath.transactions.path,
      title: t('REACT__TOUR.step_4_title'),
      bold_1: t('REACT__TOUR.step_4_bold_1'),
      text_1: t('REACT__TOUR.step_4_text_1'),
      bold_2: t('REACT__TOUR.step_4_bold_2'),
      text_2: t('REACT__TOUR.step_4_text_2'),
      bold_3: null,
      text_3: null,
      next_text: t('REACT__TOUR.step_4_next_text'),
      next_action: () => {
        handleSetStep(5);
        history.push(mainPath.contracts.path);
      }
    },
    {
      order: 5,
      match: mainPath.contracts.path,
      title: t('REACT__TOUR.step_5_title'),
      bold_1: t('REACT__TOUR.step_5_bold_1'),
      text_1: t('REACT__TOUR.step_5_text_1'),
      bold_2: t('REACT__TOUR.step_5_bold_2'),
      text_2: t('REACT__TOUR.step_5_text_2'),
      bold_3: null,
      text_3: null,
      next_text: t('REACT__TOUR.step_5_next_text'),
      next_action: () => {
        handleSetStep(6);
        history.push(mainPath.settings.path);
      }
    },
    {
      order: 6,
      match: mainPath.settings.path,
      title: t('REACT__TOUR.step_6_title'),
      bold_1: t('REACT__TOUR.step_6_bold_1'),
      text_1: t('REACT__TOUR.step_6_text_1'),
      bold_2: t('REACT__TOUR.step_6_bold_2'),
      text_2: t('REACT__TOUR.step_6_text_2'),
      bold_3: null,
      text_3: null,
      next_text: t('REACT__TOUR.step_6_next_text'),
      next_action: () => {
        handleSetStep(7);
        history.push(`${mainPath.overview.path}`);
      }
    },
    {
      order: 7,
      match: `${mainPath.overview.path}`,
      title: t('REACT__TOUR.step_7_title'),
      bold_1: t('REACT__TOUR.step_7_bold_1'),
      text_1: t('REACT__TOUR.step_7_text_1'),
      bold_2: null,
      text_2: null,
      bold_3: null,
      text_3: null,
      next_text: t('REACT__TOUR.step_7_next_text'),
      next_action: () => {
        handleSkipTheTour();
      }
    }
  ];

  const TourContainer = () => {
    return (
      <div>
        {activeStep === 0 && !shownEntry && (
          <div className={styles.welcome}>
            <div className={styles.welcome__header}>
              <h2>{t('REACT__TOUR.welcome')}</h2>
            </div>
            <div className={styles.welcome__main}>
              <p>
                <b>{t('REACT__TOUR.text1')}</b>
                {t('REACT__TOUR.text2')}
              </p>
              <p>
                <b>{t('REACT__TOUR.text3')} </b>
                {t('REACT__TOUR.text4')}
              </p>

              <div className={styles.welcome__buttons}>
                <button
                  onClick={() => {
                    handleSetStep(1);
                  }}
                  type='button'
                  className={styles.welcome__button}
                >
                  {t('REACT__TOUR.yes')}
                </button>
                <button onClick={handleSkipTheTour} type='button' className={styles.welcome__button}>
                  {t('REACT__TOUR.skip')}
                </button>
              </div>
            </div>
          </div>
        )}

        {activeStep !== 0 && (
          <div
            style={
              width > 998
                ? {
                    transform: guide_step === 2 || guide_step === 7 ? 'translate(-90%, -10%)' : 'translate(-25%, -10%)',
                    top: `${coordinates?.top < 0 ? 228 : coordinates?.top || 228}px`,
                    left: `${coordinates?.right || 1480}px`
                  }
                : {}
            }
            className={styles.steps}
          >
            <div className={styles.steps__header}>
              <h2> {steps[activeStep - 1].title}</h2>
              <button tabIndex={0} aria-label='close tour' onClick={handleSkipTheTour}>
                <CloseTour />
              </button>
            </div>
            <div className={styles.steps__main}>
              <p>
                <b>{steps[activeStep - 1].bold_1}</b>
                {steps[activeStep - 1].text_1}
              </p>
              <p>
                <b>{steps[activeStep - 1].bold_2}</b>
                {steps[activeStep - 1].text_2}
              </p>
              {steps[activeStep - 1].bold_3 && (
                <p>
                  <b>{steps[activeStep - 1].bold_3}</b>
                  {steps[activeStep - 1].text_3}
                </p>
              )}
            </div>

            <div className={styles.steps__footer}>
              <span className={styles.footer__counter}>{steps[activeStep - 1].order}/7</span>
              <button type='button' onClick={steps[activeStep - 1].next_action} className={styles.footer__btn}>
                {steps[activeStep - 1].next_text}
                <span className={styles.footer__arrow}>
                  {' '}
                  <NextStep />
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (activeStep !== 0) {
      // Find the order of the current pathname in the list of steps
      const currentStepOrder = steps.findIndex((step) => pathname.includes(step.match)) + 1;

      // Check if the current step has been shown before
      const isStepAlreadyShown = JSON.parse(react_tour_data)?.shownSteps?.includes(currentStepOrder);

      // If the current step hasn't been shown, set activeStep to the currentStepOrder
      if (currentStepOrder && !isStepAlreadyShown && react_tour_data) {
        const arrOfSteps = JSON.parse(react_tour_data).shownSteps || [];

        // Set activeStep to the currentStepOrder only if it's within the valid range of steps
        const newActiveStep = currentStepOrder <= steps.length ? currentStepOrder : activeStep;
        setNeedToShow(true);
        localStorage.setItem(
          'react_tour_data',
          JSON.stringify({
            ...JSON.parse(react_tour_data),
            active_step: newActiveStep,
            shownSteps: [...arrOfSteps, currentStepOrder]
          })
        );
      }
    }
  }, [pathname, react_tour_data, steps, activeStep]);

  return activeStep === 7 ? (
    isOpen && `${pathname === steps[activeStep - 1].match}${search}` && (
      <div className={`${styles.root} ${activeStep !== 0 && styles.root__light}`}>
        <TourContainer />
      </div>
    )
  ) : (
    <Transition
      appear
      show={isOpen && (pathname === steps[activeStep - 1 < 0 ? 0 : activeStep - 1].match || needToShow)}
      as={Fragment}
    >
      <Dialog as='div' className={`${styles.root} ${activeStep !== 0 && styles.root__light}`} onClose={setter}>
        <TourContainer />
      </Dialog>
    </Transition>
  );
};

export default AppTourDesktop;
