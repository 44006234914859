import React, {useState, useRef, useEffect, useLayoutEffect} from 'react';
import styles from './PaidInvoice.module.scss';
import {ArrowRightBlack} from '../../../assets/icons';
import {File} from '../../../assets/icons';
import {useTranslation} from 'react-i18next';
import {SkeletonMUI, AnimationWrapper} from '../../../shared';
import dayjs from 'dayjs';
import {getInvoice, getPDF} from '../invoicesActions';
import {useDispatch, useSelector} from 'react-redux';
import {handleDownload} from '../../../helpers/functions';
import {useLocation} from 'react-router-dom';

const PaidInvoice = ({data, isLoading}) => {
  const dispatch = useDispatch();
  const [pdfDownloaded, setPdfDownloaded] = useState(false);
  const {t} = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const {loaded_invoices_ids, pdf, loading_invoices, loaded_invoices_info} = useSelector(({invoices}) => invoices);

  const {search} = useLocation();
  const scrolledID = (search && search.split('?invoice_id=').length > 0 && search.split('?invoice_id=')[1]) || null;

  const arrowHandler = () => {
    setShowMenu(!showMenu);
    //To avoid repeat request call when you just togle Invoice
    if (!(loaded_invoices_ids.includes(data?.invoice_id) || loading_invoices.includes(data?.invoice_id))) {
      dispatch(getInvoice(data.invoice_id));
    }
  };
  const currentInvoice =
    loaded_invoices_info &&
    loaded_invoices_info.length > 0 &&
    loaded_invoices_info.filter((obj) => +obj?.invoice_id === +data?.invoice_id)[0];

  const isCurrentInvoiceLoading = loading_invoices.includes(+data?.invoice_id) ? true : false;

  //DOWNLOAD PDF FUNCTION
  const handleDownloadPDF = async (invoice_id) => {
    await dispatch(getPDF(invoice_id, 'Invoice'));
    setPdfDownloaded(true);
  };

  useEffect(() => {
    pdfDownloaded && pdf && handleDownload(pdf);
    setPdfDownloaded(false);
  }, [pdfDownloaded]);

  const invoice_ref = useRef(null);

  useLayoutEffect(() => {
    if (invoice_ref.current && scrolledID && data && +scrolledID === +data.invoice_id) {
      invoice_ref.current.scrollIntoView({behavior: 'smooth'});

      const topOffset = invoice_ref.current.getBoundingClientRect().top + window.scrollY - 180;
      window.scroll({top: topOffset, behavior: 'smooth'});
    }
  }, [scrolledID, data]);

  //NEW DESIGN CHANGES FOR CLOSED BY PFM

  const isClosedByPFM = data?.invoice_status === 'PaymentFreeMonth';

  const handleSetTitle = (status, sold_to) => {
    if (status === 'Creditloss') {
      return t('PAIDINVOICE.credit_loss');
    } else if (status === 'Sold' && sold_to !== 'None') {
      return `${t('PAIDINVOICE.sold')} ${sold_to} ${t('PAIDINVOICE.on')}`;
    } else if (status === 'Collected' && sold_to === 'None') {
      return t('PAIDINVOICE.collected');
    } else if (status === 'Aborted') {
      return t('PAIDINVOICE.aborted');
    } else if (status === 'PaidToPartner') {
      return t('PAIDINVOICE.paid_to_parthner');
    } else if (status === 'PaymentFreeMonth') {
      return t('PAIDINVOICE.pfm_closed');
    } else if (status === 'Paid') {
      return t('PAIDINVOICE.paidon');
    }
  };
  const handleSetDate = (status, sold_to) => {
    if (status === 'Creditloss') {
      return dayjs(data?.invoice_due_date).format('DD.MM.YYYY');
    } else if (status === 'Sold' && sold_to !== 'None') {
      return dayjs(data?.invoice_sold_time).format('DD.MM.YYYY');
    } else if (status === 'Collected' && sold_to === 'None') {
      return dayjs(data?.invoice_sold_time).format('DD.MM.YYYY');
    } else if (status === 'Aborted') {
      return dayjs(data?.invoice_aborted_date).format('DD.MM.YYYY');
    } else if (status === 'PaidToPartner') {
      //Felix said it should work 21/03/2024
      return dayjs(data?.paid_time).format('DD.MM.YYYY');
    } else if (status === 'PaymentFreeMonth') {
      return dayjs(data?.invoice_aborted_date).format('DD.MM.YYYY');
    } else if (status === 'Paid') {
      return dayjs(data?.paid_time).format('DD.MM.YYYY');
    }
  };

  return isLoading ? (
    <SkeletonMUI className={styles.skeleton} variant='rectangular' height={160} width={'100%'} loading />
  ) : (
    <AnimationWrapper>
      <div id={`invoice_${data?.invoice_id}`} className={`${styles.root} ${isLoading && 'loading'}`}>
        <div ref={invoice_ref} className={styles.header}>
          <b>
            {t('PAIDINVOICE.invoice_s')}{' '}
            <span className={isClosedByPFM && styles.closed_by_pfm}>{data?.invoice_id}</span>.
          </b>
          {handleSetTitle(data.invoice_status, data.sold_to)} {handleSetDate(data.invoice_status, data.sold_to)}
        </div>

        <div onClick={arrowHandler} className={styles['paidinvoice__header']}>
          {
            <>
              <div className={styles['paidinvoice__char']}>
                <p className={styles['paidinvoice__char--title']}>{t('PAIDINVOICE.due')}</p>
                <span className={`${styles['paidinvoice__char--value']} ${isClosedByPFM && styles.closed_by_pfm}`}>
                  {dayjs(data?.invoice_due_date).format('DD.MM.YYYY ')}
                </span>
              </div>

              {showMenu ? (
                <div className={styles['paidinvoice__char']}>
                  <p className={styles['paidinvoice__char--title']}>{t('PAIDINVOICE.date')}</p>

                  <span className={styles['paidinvoice__char--value']}>
                    {isCurrentInvoiceLoading ? (
                      <SkeletonMUI variant='rectangular' height={20} width={'80%'} loading />
                    ) : (
                      dayjs(currentInvoice?.invoice_date).format('DD.MM.YYYY ')
                    )}
                  </span>
                </div>
              ) : (
                <div className={styles['paidinvoice__char']}>
                  <p className={styles['paidinvoice__char--title']}>{t('PAIDINVOICE.amount')}</p>
                  <span className={`${styles['paidinvoice__char--value']} `}>
                    {data?.invoice_total.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
                  </span>
                </div>
              )}
            </>
          }

          <button aria-label='show menu' onClick={arrowHandler} className={styles['paidinvoice__button']}>
            <ArrowRightBlack className={!showMenu ? styles.icon : styles.icon__rotate} />
          </button>
        </div>
        <div className={`${styles['paidinvoice__main']} ${showMenu ? styles.show : styles.hide}`}>
          <div className={styles['paidinvoice__column']}>
            <div className={styles['paidinvoice__row']}>
              {isCurrentInvoiceLoading ? (
                <SkeletonMUI className='ml30' variant='rectangular' height={70} width={'80%'} loading />
              ) : (
                <>
                  <div className={styles['paidinvoice__char']}>
                    <p className={styles['paidinvoice__char--title']}>{t('PAIDINVOICE.capital')}</p>
                    <span className={styles['paidinvoice__char--value']}>
                      {currentInvoice?.capital &&
                        currentInvoice.capital.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
                    </span>
                  </div>
                  <div className={styles['paidinvoice__char']}>
                    <p className={styles['paidinvoice__char--title']}>{t('PAIDINVOICE.interest')}</p>
                    <span className={styles['paidinvoice__char--value']}>
                      {currentInvoice?.interest &&
                        currentInvoice.interest.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className={styles['paidinvoice__row']}>
              {isCurrentInvoiceLoading ? (
                <SkeletonMUI className='ml30' variant='rectangular' height={70} width={'80%'} loading />
              ) : (
                <div className={styles['paidinvoice__char']}>
                  <p className={styles['paidinvoice__char--title']}>{t('PAIDINVOICE.fees')}</p>

                  <span className={`${styles['paidinvoice__char--value']} `}>
                    {currentInvoice?.fees &&
                      currentInvoice.fees.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className={styles['paidinvoice__column']}>
            {isCurrentInvoiceLoading ? (
              <SkeletonMUI className='mb16' variant='rectangular' height={80} width={288} loading />
            ) : (
              <div className={`${styles['paidinvoice__amount']} ${isClosedByPFM && styles.closed_by_pfm_b}`}>
                {data?.invoice_total.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
              </div>
            )}
            <button onClick={() => handleDownloadPDF(data?.invoice_id)} className={styles['paidinvoice__download']}>
              <File className='mr10' />
              {t('PAIDINVOICE.download')}
            </button>
          </div>
        </div>
      </div>
    </AnimationWrapper>
  );
};

export default PaidInvoice;
