import React, {useEffect, useMemo} from 'react';
import styles from './CreditForm.module.scss';
import {Controller} from 'react-hook-form';
import {InputFieldCredit, DropdownSelectCredit, CheckboxMUI, InputPhoneFieldCredit} from '../../shared';
import BlockSeparator from './BlockSeparator';
import {useTranslation} from 'react-i18next';
import {useWindowWidth} from '../../helpers/hooks';
import {useDispatch, useSelector} from 'react-redux';
import {setAppFisrtCircle, updateCustomerDetails} from './creditFormActions';
import {loan_usage, how_did_you_find_us_values} from '../../helpers/mockData';

const StepOne = ({
  control,
  errors,
  dirtyFields,
  resetField,
  setValue,
  email_send,
  trigger,
  sms_send,
  setSendEmail,
  setSendSms,
  getValues,
  touchedFields
}) => {
  const {t} = useTranslation();
  const width = useWindowWidth();
  const dispatch = useDispatch();
  const {costumer_details, loading} = useSelector(({credit_form}) => credit_form);

  const usedFields = ['street', 'post_code', 'city', 'phone', 'email', 'loan_usage', 'how_did_you_find_us'];

  function generateFieldArray(fields, object, negationOperator) {
    return fields.map((field) => {
      const fieldValue = object[field];
      const isError = negationOperator ? !fieldValue : fieldValue;
      return isError;
    });
  }

  const touched__state = generateFieldArray(usedFields, touchedFields, '!!');

  useEffect(() => {
    const {basic_info} = costumer_details || {};

    if (basic_info?.address) {
      const {street, post_code, city} = basic_info.address;

      setValue('city', city);
      setValue('street', street);
      setValue('post_code', post_code);
    }
    setValue('allow_email_marketing', basic_info?.allow_email_marketing);
    setValue('allow_sms_marketing', basic_info?.allow_sms_marketing);
    //PAY ATTENTION THAT WE CAN'T USE A FULL ARR
    usedFields.slice(3, 7).forEach((field) => {
      setValue(field, basic_info?.[field]);
    });
  }, [costumer_details]);

  const valid_arr = usedFields.map((field) => !!(getValues(field) === 0 ? true : getValues(field)) && !!!errors[field]);

  const isAnyTouched = touched__state.includes(true);
  const isAllBlockInValid = valid_arr.includes(false);
  const error_arr = useMemo(() => generateFieldArray(usedFields, errors, '!!!'), [errors, isAllBlockInValid]);
  const isError = error_arr.includes(false);

  let color;

  if (!isAnyTouched && isAllBlockInValid) {
    color = 'gray';
  } else if (!isAllBlockInValid) {
    color = 'green';
  } else if (isError) {
    color = 'red';
    // } else if (isAllBlockInValid && !isError) {
    //   color = 'red 2';
  } else {
    color = 'gray';
  }

  useEffect(() => {
    dispatch(setAppFisrtCircle(color));
  }, [isAllBlockInValid, color, isError]);

  const handleUpdateInfo = (body, name, isDropdown = false) => {
    !errors[name] &&
      costumer_details &&
      (isDropdown ? true : dirtyFields[name]) &&
      dispatch(
        updateCustomerDetails({
          id: costumer_details?.id,
          ...body
        })
      ).then(() => resetField(name));
  };

  return (
    <>
      <BlockSeparator anchor={'spep_1'} color={color} step={1} />
      <h3 className={styles['block__title']}>{t('CREDIT_FORM.personal')}</h3>
      <p className={styles['block__subtitle']}>{t('CREDIT_FORM.personal_description')}</p>

      <div className={styles.header}>
        <div className={styles.header__char}>
          <span>{t('SETTINGSFORM.first')}</span>
          <p>{costumer_details?.basic_info?.first_name}</p>
        </div>
        <div className={styles.header__char}>
          <span>{t('SETTINGSFORM.surname')}</span>
          <p>{costumer_details?.basic_info?.last_name}</p>
        </div>
        <div className={styles.header__char}>
          <span>{t('SETTINGSFORM.security')}</span>
          <p>{costumer_details?.basic_info?.ssn}</p>
        </div>
      </div>

      <div className={styles.row__simple}>
        <Controller
          name='street'
          control={control}
          render={({field}) => (
            <InputFieldCredit
              className={'credit'}
              onChange={(value) => field.onChange(value)}
              customBlur={() => {
                field.onBlur();
                handleUpdateInfo(
                  {
                    basic_info: {
                      address: {
                        street: getValues('street')
                      }
                    }
                  },
                  'street'
                );
              }}
              label={t('CREDIT_FORM.street')}
              placeholder={''}
              subtitle={width > 650 && t('CREDIT_FORM.street_subtitle')}
              error={errors.street?.message}
              isTouched={touchedFields?.street}
              inputProps={field}
            />
          )}
        />
        <Controller
          name='post_code'
          control={control}
          render={({field}) => (
            <InputFieldCredit
              type='number'
              className={'credit'}
              onChange={(value) => field.onChange(value)}
              customBlur={() => {
                field.onBlur();
                handleUpdateInfo(
                  {
                    basic_info: {
                      address: {
                        post_code: getValues('post_code')
                      }
                    }
                  },
                  'post_code'
                );
              }}
              label={t('CREDIT_FORM.postal')}
              placeholder={''}
              subtitle={width > 650 && t('CREDIT_FORM.postal_subtitle')}
              error={errors.post_code?.message}
              isTouched={touchedFields?.post_code}
              inputProps={field}
            />
          )}
        />
      </div>
      <div className={styles.row__simple}>
        <Controller
          name='city'
          control={control}
          render={({field}) => (
            <InputFieldCredit
              className={'credit'}
              onChange={(value) => field.onChange(value)}
              customBlur={() => {
                field.onBlur();
                handleUpdateInfo(
                  {
                    basic_info: {
                      address: {
                        city: getValues('city')
                      }
                    }
                  },
                  'city'
                );
              }}
              label={t('CREDIT_FORM.city')}
              placeholder={''}
              subtitle={width > 650 && t('CREDIT_FORM.city_subtitle')}
              error={errors.city?.message}
              isTouched={touchedFields?.city}
              inputProps={field}
            />
          )}
        />

        <Controller
          name='phone'
          control={control}
          render={({field}) => (
            <InputPhoneFieldCredit
              className={'credit'}
              onChange={(value) => field.onChange(value)}
              customBlur={() => {
                field.onBlur();
                handleUpdateInfo(
                  {
                    basic_info: {
                      phone: getValues('phone')
                    }
                  },
                  'phone'
                );
              }}
              label={t('CREDIT_FORM.phone')}
              placeholder={''}
              subtitle={width > 650 && t('CREDIT_FORM.phone_subtitle')}
              error={errors.phone?.message}
              isTouched={touchedFields?.phone}
              inputProps={field}
            />
          )}
        />
      </div>

      <div className={styles.row__simple}>
        <Controller
          name='email'
          control={control}
          render={({field}) => (
            <InputFieldCredit
              className={`${styles['credit__small']} credit`}
              onChange={(value) => field.onChange(value)}
              customBlur={() => {
                field.onBlur();
                handleUpdateInfo(
                  {
                    basic_info: {
                      email: getValues('email')
                    }
                  },
                  'email'
                );
              }}
              label={t('CREDIT_FORM.email')}
              placeholder={''}
              subtitle={width > 650 && t('CREDIT_FORM.email_subtitle')}
              error={errors.email?.message}
              isTouched={touchedFields?.email}
              inputProps={field}
            />
          )}
        />
        <div className={styles.credit__empty}></div>
      </div>

      <div className={styles.row__simple}>
        <div className={styles['column__checkbox']}>
          <h3 className={styles['checkbox__title']}> {t('CREDIT_FORM.would')}</h3>

          <div className={styles.checkbox}>
            <CheckboxMUI
              id='checkbox-email'
              onChange={(e) => {
                setValue('allow_email_marketing', !email_send);
                handleUpdateInfo(
                  {
                    basic_info: {
                      allow_email_marketing: !email_send
                    }
                  },
                  'phone'
                );
                setSendEmail(!email_send);
              }}
              checked={email_send}
              className='mr6'
            />

            <label htmlFor={'checkbox-email'} className={styles.checkbox__text}>
              {t('CREDIT_FORM.email_send')}
            </label>
          </div>
          <div className={styles.checkbox}>
            <CheckboxMUI
              id='checkbox-sms'
              onChange={(e) => {
                setValue('allow_sms_marketing', !sms_send);
                setSendSms(!sms_send);
                handleUpdateInfo(
                  {
                    basic_info: {
                      allow_sms_marketing: !sms_send
                    }
                  },
                  'phone'
                );
              }}
              checked={sms_send}
              className='mr6'
            />

            <label htmlFor={'checkbox-sms'} className={styles.checkbox__text}>
              {t('CREDIT_FORM.sms')}
            </label>
          </div>
        </div>
        <div className={styles.column}>
          <Controller
            name='loan_usage'
            control={control}
            render={({field}) => (
              <DropdownSelectCredit
                label={t('CREDIT_FORM.money_for')}
                className={'credit__select'}
                onChange={(value) => {
                  setValue('loan_usage', value);
                  trigger('loan_usage');
                  handleUpdateInfo(
                    {
                      basic_info: {
                        loan_usage: value
                      }
                    },
                    'loan_usage',
                    true
                  );
                }}
                placeholder={costumer_details && getValues('loan_usage')}
                values={loan_usage}
                subtitle={''}
                isTouched={touchedFields?.loan_usage}
                error={errors.loan_usage?.message}
                inputProps={field}
              />
            )}
          />

          <Controller
            name='how_did_you_find_us'
            control={control}
            render={({field}) => (
              <DropdownSelectCredit
                label={t('CREDIT_FORM.hear_about')}
                className={'credit__select'}
                onChange={(value) => {
                  setValue('how_did_you_find_us', value);
                  trigger('how_did_you_find_us');
                  handleUpdateInfo(
                    {
                      basic_info: {
                        how_did_you_find_us: value
                      }
                    },
                    'how_did_you_find_us',
                    true
                  );
                }}
                placeholder={costumer_details && getValues('how_did_you_find_us')}
                values={how_did_you_find_us_values}
                subtitle={''}
                isTouched={touchedFields?.how_did_you_find_us}
                error={errors.how_did_you_find_us?.message}
                inputProps={field}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default StepOne;
