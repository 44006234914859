import React, {useEffect} from 'react';
import styles from './WithdrawalPopup.module.scss';
import DialogMUI from '../DialogMUI';
import {useTranslation} from 'react-i18next';
import {ArrowRightBlackThin} from '../../assets/icons';
import {useDispatch, useSelector} from 'react-redux';
import {getConfirmationDetails, confirmWithdrawal, cancelWithdrawal} from '../../features/Overview/withdrawalActions';

const WithdrawalPopup = ({dialog, showToast, toggleDialog, setToastType}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {details, new_withdrawal} = useSelector(({withdrawal}) => withdrawal);

  const tableRows = [
    {
      title: 'WITHDRAWAL_POPUP.credit_used',
      from: details?.credit_used?.old_value,
      to: details?.credit_used?.new_value
    },
    {
      title: 'WITHDRAWAL_POPUP.credit_left',
      from: details?.credit_left?.old_value,
      to: details?.credit_left?.new_value
    },
    {
      title: 'WITHDRAWAL_POPUP.daily_interest',
      from: details?.daily_interest?.old_value,
      to: details?.daily_interest?.new_value
    },
    {
      title: 'WITHDRAWAL_POPUP.next_payment',
      from: details?.next_payment?.old_value,
      to: details?.next_payment?.new_value
    }
  ];

  useEffect(() => {
    new_withdrawal?.withdrawal_id && dispatch(getConfirmationDetails(new_withdrawal?.withdrawal_id));
  }, [new_withdrawal]);

  const handleShowAndScrollToToast = (type) => {
    type === 'confirm' &&
      setToastType({isSuccess: true, title: 'Confirmed', description: 'The request was successfully confirmed'});
    type === 'cancel' &&
      setToastType({isSuccess: true, title: 'Canceled', description: 'The request was successfully canceled'});
    showToast();
  };

  const handleConfirmation = () => {
    if (new_withdrawal?.withdrawal_id) {
      dispatch(confirmWithdrawal({withdrawal_id: new_withdrawal.withdrawal_id})).then((res) => {
        if (res?.payload?.status === 200) {
          toggleDialog();
          handleShowAndScrollToToast('confirm');
        }
      });
    }
  };

  const handleCancelation = () => {
    if (new_withdrawal?.withdrawal_id) {
      dispatch(cancelWithdrawal({withdrawal_id: new_withdrawal.withdrawal_id})).then((res) => {
        if (res?.payload?.status === 200) {
          toggleDialog();
          handleShowAndScrollToToast('cancel');
        }
      });
    }
  };

  return (
    <DialogMUI withdrawal withHeader={t('WITHDRAWAL_POPUP.confirm')} open={dialog} onClose={handleCancelation}>
      <div className={styles.root}>
        {/* {width > 768 && <h2 className={styles.title}>{t('WITHDRAWAL_POPUP.confirm')}</h2>} */}

        <div className={styles.scrolled__block}>
          <div className={styles['price__block']}>
            <p className={styles['price__value']}>
              {details?.amount.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}{' '}
            </p>
            <p className={styles['price__subtitle']}>{t('WITHDRAWAL_POPUP.money')}</p>
          </div>

          <div className={styles['personal__data']}>
            <p className={styles['personal__text']}>{details?.name}</p>
            <p className={styles['personal__text']}>{details?.account_number}</p>
          </div>

          <p className={styles['personal__subtitle']}>{t('WITHDRAWAL_POPUP.account')}</p>

          <div className={styles.table}>
            {tableRows.map((item, idx) => {
              return (
                <div key={idx} className={styles['table__row']}>
                  <p className={styles['table__row--title']}>{t(item?.title)}</p>
                  <p className={styles['table__row--from']}>
                    {item?.from?.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
                  </p>
                  <ArrowRightBlackThin />
                  <p className={styles['table__row--to']}>
                    {item?.to?.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.footer__sticky}>
          <button className={`${styles['button__confirm']} main__button`} onClick={() => handleConfirmation()}>
            {t('WITHDRAWAL_POPUP.confirm_button')}
          </button>

          <button type='button' className={styles['button__cancel']} onClick={() => handleCancelation()}>
            {t('WITHDRAWAL_POPUP.cancel')}
          </button>
        </div>
      </div>
    </DialogMUI>
  );
};

export default WithdrawalPopup;
