import React, {useEffect, useState} from 'react';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import NavBar from '../../layout/NavBar';
import MainRoutes from '../../routes/MainRoutes';
import {SubscriptionExpirationCheck} from '../../helpers/functions';
import {useHistory, useLocation} from 'react-router-dom';
import {ChatBubble, ToastComponent} from '../../shared';
import {useWindowWidth} from '../../helpers/hooks';
import AppTourMobile from '../../features/AppTourMobile/AppTourMobile';
import AppTourDesktop from '../../features/AppTourDesktop/AppTourDesktop';
import {GetCustomerFlags} from '../../features/Settings/settingsActions';
import {useDispatch, useSelector} from 'react-redux';
import {mainPath} from '../../routes/paths';

const MainContainer = () => {
  const history = useHistory();
  const {pathname, search} = useLocation();
  const [supervisorPopup, setSupervisorPopup] = useState(false);
  const pageTitle = pathname.split('/')[2];
  const capitalizedPageTitle = pageTitle?.charAt(0).toUpperCase() + pageTitle?.slice(1);
  const width = useWindowWidth();
  const [showAppTour, setShowAppTour] = useState(false);
  const dispatch = useDispatch();

  const expiredTime = sessionStorage.getItem('expired_date');
  const [isPersonalModal, setPersonalOpen] = useState(null);

  const {customer_flags} = useSelector(({settings}) => settings);

  useEffect(() => {
    document.title = `FlexLimit - ${capitalizedPageTitle}`;

    //SUPERVISOR LOGIC -- ALLOWS JUST HOME PAGE
    const isHome = pathname === mainPath.overview.path;
    const isSupervisor =
      (customer_flags &&
        Array.isArray(customer_flags?.flags) &&
        customer_flags?.flags.includes('SupervisionOfInterest')) ||
      false;

    if (isSupervisor) {
      //setSupervisorPopup(true);
      if (!isHome) {
        history.push(mainPath.overview.path);
      }
    }
  }, [pathname, customer_flags]);

  useEffect(() => {
    dispatch(GetCustomerFlags());

    //REACT TOUR LOGIC

    const reactTourData = JSON.parse(localStorage.getItem('react_tour_data')) || {
      need_tour: true,
      tour_type: 'mobile',
      active_step: 0,
      shownSteps: []
    };

    if (reactTourData.need_tour) {
      setShowAppTour(true);
      const updatedTourData = {
        ...reactTourData
        //need_tour: false
      };

      localStorage.setItem('react_tour_data', JSON.stringify(updatedTourData));
    }
  }, []);

  return (
    <>
      <Header isPersonalModal={isPersonalModal} setPersonalOpen={setPersonalOpen} />
      <MainRoutes />
      <Footer />
      <NavBar />
      {width > 768 ? <ChatBubble /> : !isPersonalModal && <ChatBubble />}
      <SubscriptionExpirationCheck
        history={history}
        subscriptionEndDate={expiredTime}
        currentPage={pathname.split('/')[2]}
      />
      <ToastComponent
        isFixed
        isSuccess={false}
        visible={supervisorPopup}
        setter={setSupervisorPopup}
        title={'Sorry! You have access just to look around the home page'}
      />
      {width <= 998 ? (
        <AppTourMobile isOpen={showAppTour} navbarSetter={setPersonalOpen} setter={setShowAppTour} />
      ) : (
        <AppTourDesktop isOpen={showAppTour} navbarSetter={setPersonalOpen} setter={setShowAppTour} />
      )}
    </>
  );
};

export default MainContainer;
