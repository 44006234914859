import React from 'react';
import ToastComponent from '../ToastComponent/ToastComponent';
import {useSelector, useDispatch} from 'react-redux';
import {setToastParams} from '../../features/Auth/authActions';

const TransPageToast = () => {
  const {toast_open, toast_type, toast_text, toast_description} = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setToastParams({toast_open: false, toast_type: false, toast_text: ''}));
  };

  return (
    <ToastComponent
      isFixed
      isSuccess={toast_type}
      description={toast_description || null}
      visible={toast_open}
      setter={handleClose}
      title={toast_text}
    />
  );
};

export default TransPageToast;
