import React from 'react';
import styles from './Page404.module.scss';
import {useTranslation} from 'react-i18next';
import {Link} from '@mui/material';
import {mainPath} from '../../routes/paths';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

const Page404 = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const {is_menu_shown} = useSelector((state) => state.settings);

  return (
    <main className={`${styles.root} ${is_menu_shown && 'main__blured'} main__container container`}>
      <div className={styles.container}>
        <h1 className={styles.title}>404</h1>
        <h2 className={styles.subtitle}>{t('PAGE404.subtitle')}</h2>
        <p className={styles.text}>
          {t('PAGE404.looks')}{' '}
          <span>
            {' '}
            <Link to={'/'}>{t('PAGE404.customer')}</Link>{' '}
          </span>
          {t('PAGE404.help')}
        </p>
        <button className={`${styles.link} main__button`} onClick={() => history.push(mainPath.overview.path)}>
          {t('PAGE404.back')}
        </button>
      </div>
    </main>
  );
};

export default Page404;
