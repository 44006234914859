import React, {useEffect, Suspense} from 'react';
import styles from './Contracts.module.scss';
import SideBar from '../SideBar';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {getContracts} from './contractsActions';
import {useWindowWidth} from '../../helpers/hooks';
import {SkeletonMUI, CustomerServiceCard, CreditAccountCard} from '../../shared';
import Contract from './Contract/Contract';
import ContractMobileCard from './ContractMobileCard';
import TransPageToast from '../../shared/TransPageToast/TransPageToast';

const Contracts = () => {
  const {t} = useTranslation();
  const width = useWindowWidth();

  const dispatch = useDispatch();
  const {loading, contracts} = useSelector(({contracts}) => contracts);
  const {is_menu_shown, guide_step} = useSelector((state) => state.settings);
  useEffect(() => {
    dispatch(getContracts());
  }, []);

  const need_tour = JSON.parse(localStorage.getItem('react_tour_data'))?.need_tour || false;

  const showBlur = need_tour && guide_step === 5;

  const renderContracts = () => {
    if (loading) {
      if (width >= 769) {
        return Array(2)
          .fill()
          .map((_, idx) => <Contract key={idx} isLoading />);
      } else {
        return Array(3)
          .fill()
          .map((_, idx) => (
            <Suspense key={idx} fallback={<SkeletonMUI className='mb16' variant='rectangular' height={70} loading />}>
              <ContractMobileCard isLoading />
            </Suspense>
          ));
      }
    } else {
      if (width >= 769 && contracts) {
        return contracts.credit_contracts
          .filter((item) => item.status !== 'None')
          .sort((a, b) => {
            if (a.status < b.status) return -1;
            if (a.status > b.status) return 1;
            return 0;
          })
          .map((item, idx) => <Contract data={item} key={idx} />);
      } else if (width < 769 && contracts) {
        return contracts.credit_contracts
          .filter((item) => item.status !== 'None')
          .sort((a, b) => {
            if (a.status < b.status) return -1;
            if (a.status > b.status) return 1;
            return 0;
          })
          .map((item, idx) => (
            <Suspense key={idx} fallback={<SkeletonMUI className='mb16' variant='rectangular' height={70} loading />}>
              <ContractMobileCard data={item} />
            </Suspense>
          ));
      }
    }

    return null;
  };

  return (
    <>
      <div className={'main__headers'}>
        <div className='container'>
          <h1 className={`${styles['contracts__title']} main__title `}>{t('CONTRACTS.contracts')}</h1>

          {width > 768 ? (
            <p className={`${styles['contracts__description']} main__description`}>
              {t('CONTRACTS.note')} <br />
              {t('CONTRACTS.send')}
            </p>
          ) : (
            <p className={`${styles['contracts__description']} main__description`}>{t('CONTRACTS.please')}</p>
          )}
          {contracts?.credit_contracts.filter((item) => item.status !== 'None').length === 0 && (
            <b className={styles.no_contracts}>{t('CONTRACTS.no_contracts')}</b>
          )}
        </div>
      </div>
      <main className={`${styles.root} ${is_menu_shown && 'main__blured'} main__container container`}>
        <TransPageToast />

        <div className={`${styles['contracts__main']} main__grid`}>
          <div className={`${styles['contracts__column']} main__column ${showBlur && 'main__tourblur'}`}>
            {renderContracts()}
          </div>
          <SideBar />
          {width <= 1000 && (
            <>
              <div>
                <CreditAccountCard />
              </div>
              <div>
                <CustomerServiceCard type='sidebar' />
              </div>
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default Contracts;
