import React, {useState} from 'react';
import styles from './PayInvoice.module.scss';
import {RangeInput} from '../../../shared';
import {InputMUI} from '../../../shared';
import {creditAppPath, mainPath} from '../../../routes/paths';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useWindowWidth} from '../../../helpers/hooks';
import {useQuery} from 'react-query';
import {axiosInstance} from '../../../app/store';
import {AnimationWrapper} from '../../../shared';
import {WarningIcon} from '../../../assets/icons';
import {useSelector} from 'react-redux';

const PayInvoice = ({isUnavailable = false, type = 'banned'}) => {
  const {data, isLoading} = useQuery('ledgers', async () => {
    const response = await axiosInstance.get('ledgers/GetLedger'); // Replace with your API endpoint
    return response.data;
  });
  const {notifications} = useSelector((state) => state.settings);

  const [sliderValue, setSliderValue] = useState(500);
  const {t} = useTranslation();

  const availableLimit = data?.available_balance || 18000;

  const percentAdapter = (value) => {
    return (value * 100) / availableLimit;
  };

  //setSliderValue((e.target.value * 100) / availableLimit)

  const width = useWindowWidth();
  const sliderWidth = width > 978 ? 700 : width * 0.78;

  const invoiceData = [
    {
      title: t('PAYINVOICE.available'),
      price: `${
        data?.available_balance && data?.available_balance.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})
      }`
    },
    {
      title: t('PAYINVOICE.credit'),
      price: `${data?.principal && data?.principal.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}`
    },
    {
      title: t('PAYINVOICE.total'),
      price: `${data?.credit_limit && data?.credit_limit.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}`
    }
  ];

  const invoiceDataMobile = [
    {
      title: t('WITHDRAWACCOUNT.available_s'),
      price: `${
        data?.available_balance && data?.available_balance.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})
      }`
    },
    {
      title: t('WITHDRAWACCOUNT.used_s'),
      price: `${data?.principal && data?.principal.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}`
    },
    {
      title: t('WITHDRAWACCOUNT.total_s'),
      price: `${
        data?.credit_limit && data?.credit_limit.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})
      } `
    }
  ];

  const withdrawal_amount =
    notifications && notifications.length > 0
      ? notifications.filter((notification) => notification.type === 'WithdrawalTransferring')[0]?.meta_data
          .withdrawal_amount
      : 0;

  return (
    <AnimationWrapper>
      <div className={styles.root}>
        <>
          <div className={styles.header}>
            {!isUnavailable && (
              <div className={styles.header__message}>
                <WarningIcon />
                <div className={styles.header__text}>
                  {type === 'past_due' ? (
                    <>
                      <p>{t('PAYINVOICE.unpaid')}</p>
                      <br />
                      <p>
                        <Link to={creditAppPath.creditPath.path}>{t('PAYINVOICE.resolve')}</Link>
                      </p>
                      <p>{t('PAYINVOICE.again')}</p>
                    </>
                  ) : (
                    <>
                      <p>{t('PROFILECARD.arreares')}</p>
                      <br />
                      <p>
                        <Link to={mainPath.invoices.path}>{t('PROFILECARD.arreares_pay')}</Link>
                      </p>
                      <p>{t('PROFILECARD.arreares_to')}</p>
                    </>
                  )}
                </div>
              </div>
            )}
            {isUnavailable && (
              <div className={styles.header__message}>
                <WarningIcon />
                <div className={styles.header__text}>
                  {type === 'banned' && (
                    <>
                      <p>{t('PAYINVOICE.banned')}</p>
                    </>
                  )}
                  {type === 'withdrawal_in_progress' && (
                    <>
                      <p>{t('PROFILECARD.your_withdrawal', {amount: withdrawal_amount})}</p>
                    </>
                  )}
                  {type !== 'banned' && type !== 'withdrawal_in_progress' && (
                    <>
                      {' '}
                      <p>{t('PAYINVOICE.under')}</p>
                      <br />
                      <p>{t('PAYINVOICE.please')}</p>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={styles.container}>
            <div className={styles['invoice__columns']}>
              <div className={styles['invoice__column']}>
                <div className={styles['invoice__input']}>
                  <InputMUI
                    className={styles['invoice__input--field']}
                    placeholder='500'
                    variant='standard'
                    value={sliderValue}
                    disabled
                    type='number'
                    label='Amount'
                    fullWidth
                  />

                  <span className={styles['invoice__input--currency']}>kr</span>
                </div>
                <p className={styles['invoice__text']}>{t('PAYINVOICE.enter')}</p>
              </div>
              <div className={styles['invoice__column']}>
                {invoiceData.map((data, index) => (
                  <div key={index} className={styles['invoice__column--container']}>
                    <p className={styles['invoice__column--title']}>{data.title}</p>
                    <span className={styles['invoice__column--price']}>{data.price}</span>
                  </div>
                ))}
              </div>
            </div>

            <RangeInput
              totalLimit={data?.credit_limit}
              availableLimit={data?.available_balance}
              usedLimit={data?.principal}
              sliderWidth={sliderWidth}
              disabled
              value={percentAdapter(sliderValue)}
            />

            <div className={styles['invoice__elems']}>
              {invoiceDataMobile.map((data, index) => (
                <div key={index} className={styles['invoice__elems--container']}>
                  <p className={styles['invoice__elems--title']}>{data.title}</p>
                  <span className={styles['invoice__elems--price']}>{data.price}</span>
                </div>
              ))}
            </div>
            <Link
              to={mainPath.invoices.path}
              disabled={isUnavailable}
              className={`${styles['invoice__button']} ${
                isUnavailable && styles['invoice__button--disabled']
              } main__button`}
            >
              {t(isUnavailable ? 'PAYINVOICE.unavailable' : 'PAYINVOICE.pay')}
            </Link>
          </div>
        </>
      </div>
    </AnimationWrapper>
  );
};

export default PayInvoice;
