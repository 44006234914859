import React from 'react';
import {useTranslation} from 'react-i18next';
import {Logotype} from '../../../../assets/icons';
import {mainPath} from '../../../../routes/paths';
import {Link} from 'react-router-dom';
import styles from '../ApplicationStatus.module.scss';

const DeniedBlock = () => {
  const {t} = useTranslation();
  return (
    <>
      <Logotype />
      <h2 className={styles.title}>{t('APPLICATION_STATUS.title1')}</h2>
      <p className={styles.subtitle}>{t('APPLICATION_STATUS.subtitle1')}</p>
      <p className={styles.subtitle2}>{t('APPLICATION_STATUS.subtitle2')}</p>
      <Link to={mainPath.overview.path} className={`${styles.button} main__button`}>
        {t('APPLICATION_STATUS.return2')}
      </Link>
    </>
  );
};

export default DeniedBlock;
