import React, {useEffect} from 'react';
import styles from './CreditForm.module.scss';
import {Controller} from 'react-hook-form';
import {InputFieldCredit} from '../../shared';
import BlockSeparator from './BlockSeparator';
import {useTranslation} from 'react-i18next';
import {useWindowWidth} from '../../helpers/hooks';
import {useDispatch, useSelector} from 'react-redux';
import {setAppThirdCircle} from './creditFormActions';
import {updateCustomerDetails} from './creditFormActions';

const StepThree = ({
  control,
  trigger,
  errors,
  setValue,
  touchedFields,
  getValues,
  setError,
  clearError,
  resetField
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {costumer_details} = useSelector(({credit_form}) => credit_form);

  useEffect(() => {
    setValue(
      'personal_income_monthly_gross_income',

      costumer_details?.income_and_expenses?.personal_income?.monthly_gross_income
    );
    setValue(
      'personal_income_monthly_net_income',
      costumer_details?.income_and_expenses?.personal_income?.monthly_net_income
    );
    setValue(
      'personal_expenses_monthly_expenses',
      costumer_details?.income_and_expenses?.personal_expenses?.monthly_expenses
    );
    //setValue('contracts_exist', costumer_details?.income_and_expenses?.contracts?.exists);
    setValue('contracts_amount', costumer_details?.income_and_expenses?.contracts?.amount);
    // setValue('co_borrower', costumer_details?.income_and_expenses?.co_borrower?.exists);
    setValue('total_income', costumer_details?.income_and_expenses?.household_income?.monthly_gross_income);

    // setValue('loans_exists', costumer_details?.income_and_expenses?.loans?.exists);
    setValue('loans_amount', Number(costumer_details?.income_and_expenses?.loans?.amount));

    setValue('household_debt', costumer_details?.income_and_expenses?.household_debt);

    //NEW FIELDS
    setValue(
      'household_income_monthly_net_income',
      costumer_details?.income_and_expenses?.household_income?.monthly_net_income
    );
    setValue(
      'household_expenses_monthly_expenses',
      costumer_details?.income_and_expenses?.household_expenses?.monthly_expenses
    );

    setValue('co_borrower_amount', costumer_details?.income_and_expenses?.co_borrower?.amount);
    setValue('assets', costumer_details?.income_and_expenses?.assets);
  }, [costumer_details]);

  const valid_arr = [
    typeof getValues('personal_income_monthly_gross_income') === 'number' &&
      !!!errors.personal_income_monthly_gross_income,
    typeof getValues('personal_income_monthly_net_income') === 'number' && !!!errors.personal_income_monthly_net_income,
    typeof getValues('personal_expenses_monthly_expenses') === 'number' && !!!errors.personal_expenses_monthly_expenses,
    //typeof getValues('contracts_exist') === 'boolean' && !!!errors.contracts_exist,
    (!!getValues('contracts_amount') || getValues('contracts_amount') === 0) && !!!errors.contracts_amount,
    //typeof getValues('co_borrower') === 'boolean' && !!!errors.co_borrower,

    costumer_details?.household?.number_of_adults > 1
      ? (!!getValues('total_income') || getValues('total_income') === 0) && !!!errors.total_income
      : getValues('total_income') >= 0 && !!!errors.total_income,

    // typeof getValues('loans_exists') === 'boolean' && !!!errors.loans_exists,
    typeof getValues('household_debt') === 'number' && !!!errors.household_debt,
    getValues('loans_amount') >= 0 && !!!errors.loans_amount,

    //NEW FIELDS

    costumer_details?.household?.number_of_adults > 1
      ? (!!getValues('household_income_monthly_net_income') ||
          getValues('household_income_monthly_net_income') === 0) &&
        !!!errors.household_income_monthly_net_income
      : getValues('household_income_monthly_net_income') >= 0 && !!!errors.household_income_monthly_net_income,

    getValues('household_expenses_monthly_expenses') >= 0 && !!!errors.household_expenses_monthly_expenses,

    (!!getValues('co_borrower_amount') || getValues('co_borrower_amount') >= 0) && !!!errors.co_borrower_amount,

    typeof getValues('assets') === 'number' && !!!errors.assets
  ];

  const error_arr = [
    !!!errors.personal_income_monthly_gross_income,
    !!!errors.personal_income_monthly_net_income,
    !!!errors.personal_expenses_monthly_expenses,
    //!!!errors.contracts_exist,
    !!!errors.guarantee,
    !!!errors.co_burrower,
    !!!errors.total_income,
    // !!!errors.loans_exists,
    !!!errors.household_debt,
    !!!errors.loans_amount,
    //NEW FIELDS
    !!!errors.household_income_monthly_net_income,
    !!!errors.household_expenses_monthly_expenses,
    !!!errors.co_borrower_amount,
    !!!errors.assets
  ];

  const touched__state = [
    !!touchedFields.personal_income_monthly_gross_income,
    !!touchedFields.personal_income_monthly_net_income,
    !!touchedFields.personal_expenses_monthly_expenses,
    //!!touchedFields.contracts_exist,
    !!touchedFields.contracts_amount,
    // !!touchedFields.co_borrower,
    !!touchedFields.total_income,
    // !!touchedFields.loans_exists,
    !!touchedFields.loans_amount,
    !!touchedFields.household_debt,
    //NEW FIELDS
    !!touchedFields.household_income_monthly_net_income,
    !!touchedFields.household_expenses_monthly_expenses,
    !!touchedFields.co_borrower_amount,
    !!touchedFields.assets
  ];

  const isAnyTouched = touched__state.includes(true);
  const isAllBlockInValid = valid_arr.includes(false);
  const isError = error_arr.includes(false);

  let color;
  if (!isAnyTouched && isAllBlockInValid && !isError) {
    color = 'gray 1';
  } else if (!isAllBlockInValid) {
    color = 'green';
  } else if (isError) {
    color = 'red';
  } else {
    color = 'gray 2';
  }

  useEffect(() => {
    dispatch(setAppThirdCircle(color));
  }, [color, isAllBlockInValid, isAnyTouched]);

  const handleUpdateInfo = (body, name, isDropdown = false) => {
    !errors[name] &&
      costumer_details &&
      //TO AVOID API CALL IF NOTHING CHANGED
      //(isDropdown ? true : dirtyFields[name]) &&
      //(isDropdown ? true : dirtyFields[name]) &&

      dispatch(
        updateCustomerDetails({
          id: costumer_details?.id,
          ...body
        })
      ).then(() => {
        resetField(name);
        trigger(Object.keys(touchedFields));
      });
  };

  //Personal monthly GROSS income:personal_income_monthly_gross_income
  const personal_income_monthly_gross_income_order = 1;

  //Personal monthly NET income:personal_income_monthly_net_income
  const personal_income_monthly_net_income_order = 2;

  //Assets
  const assets_order = 3;

  //Personal monthly expensenses: personal_expenses_monthly_expenses
  const personal_expenses_monthly_expenses_order = 4;

  //Do you have contract based expenses: contracts_amount
  const contracts_amount_order = 5;

  // Other consumer loans in the last 30 days?: loans_amount
  const loans_amount_order = 6;

  //Are you a co borrower? : co_borrower_amount
  const gar_com_am_order = 7;

  //Personal debt burden:personal_debt_burden

  const personal_debt_burden_order = 8;

  // The household’s total GROSS income : total_income

  const total_income_order = 9;

  //The household’s total NET income : household_income_monthly_net_income
  const household_income_monthly_net_income_order = 10;

  // The household’s total debt burden : household_debt*
  const household_debt_order = 11;

  //The household’s total monthly expenses*

  const household_expenses_monthly_expenses_order = 12;

  //ERRORS LISTENER

  // total_income > household_income_monthly_net_income

  useEffect(() => {
    const total_income = getValues('total_income');
    const household_income_monthly_net_income = getValues('household_income_monthly_net_income');
    const personal_income_monthly_gross_income = getValues('personal_income_monthly_gross_income');
    const personal_income_monthly_net_income = getValues('personal_income_monthly_net_income');
    const household_expenses_monthly_expenses = getValues('household_expenses_monthly_expenses');
    const personal_expenses_monthly_expenses = getValues('personal_expenses_monthly_expenses');

    //monthly_net_income < monthly_gross_income

    if (personal_income_monthly_net_income > personal_income_monthly_gross_income) {
      setError('personal_income_monthly_net_income', {
        message: 'Personal monthly NET income should be less than the personal monthly GROSS income'
      });
      setError('personal_income_monthly_gross_income', {
        message: 'Personal monthly NET income should be less than the personal monthly GROSS income'
      });
    }

    if (costumer_details?.household?.number_of_adults > 1 && household_income_monthly_net_income > total_income) {
      setError('total_income', {
        message: 'The household’s total NET income be greater than personal monthly NET income'
      });
      setError('household_income_monthly_net_income', {
        message: 'The household’s total NET income be greater than personal monthly NET income'
      });
    } else {
      clearError('total_income');
      clearError('household_income_monthly_net_income');
    }

    //household_income.monthly_net_income > personal_income.monthly_net_income

    if (
      costumer_details?.household?.number_of_adults > 1 &&
      household_income_monthly_net_income < personal_income_monthly_net_income
    ) {
      if (household_income_monthly_net_income !== null && personal_income_monthly_net_income !== null) {
        setError('household_income_monthly_net_income', {
          message: 'The household’s total NET income should be greater than personal monthly NET income'
        });
        setError('personal_income_monthly_net_income', {
          message: 'The household’s total NET income should be greater than personal monthly NET income'
        });
      }
    } else {
      clearError('household_income_monthly_net_income');
      clearError('personal_income_monthly_net_income');
    }

    //NEW: total_income > household_income_monthly_net_income

    if (costumer_details?.household?.number_of_adults > 1 && household_income_monthly_net_income > total_income) {
      if (household_income_monthly_net_income !== null && total_income !== null) {
        setError('household_income_monthly_net_income', {
          message: 'The household’s total GROSS income should be greater than the household’s total NET income'
        });
        setError('total_income', {
          message: 'The household’s total GROSS income should be greater than the household’s total NET income'
        });
      }
    } else {
      clearError('total_income');
      clearError('household_income_monthly_net_income');
    }

    //NEW: household_expenses_monthly_expenses >  personal_expenses_monthly_expenses

    if (
      costumer_details?.household?.number_of_adults > 1 &&
      household_expenses_monthly_expenses < personal_expenses_monthly_expenses
    ) {
      if (household_expenses_monthly_expenses !== null && personal_expenses_monthly_expenses !== null) {
        setError('household_expenses_monthly_expenses', {
          message: 'The  household’s total monthly expenses should be greater than personal monthly expenses'
        });
        setError('personal_expenses_monthly_expenses', {
          message: 'The  household’s total monthly expenses should be greater than personal monthly expenses'
        });
      }
    } else {
      clearError('household_expenses_monthly_expenses');
      clearError('personal_expenses_monthly_expenses');
    }

    //household_income.monthly_gross_income > personal_income.monthly_gross_income

    if (costumer_details?.household?.number_of_adults > 1 && total_income < personal_income_monthly_gross_income) {
      if (total_income !== null && personal_income_monthly_gross_income !== null) {
        setError('total_income', {
          message: 'The household’s total GROSS income should be greater than personal monthly GROSS income'
        });
        setError('personal_income_monthly_gross_income', {
          message: 'The household’s total GROSS income should be greater than personal monthly GROSS income'
        });
      }
    } else {
      clearError('total_income');
      clearError('personal_income_monthly_gross_income');
    }
  }, [errors, isAnyTouched, costumer_details]);

  return (
    <>
      <BlockSeparator anchor={'spep_3'} step={3} color={color} />
      <h3 className={styles['block__title']}>{t('CREDIT_FORM.personal')}</h3>
      <p className={styles['block__subtitle']}>{t('CREDIT_FORM.income_descr1')}</p>
      <p className={`${styles['block__subtitle']} mb20`}>{t('CREDIT_FORM.income_descr2')}</p>

      <div className={styles.grid}>
        <Controller
          name='personal_income_monthly_gross_income'
          control={control}
          render={({field}) => (
            <InputFieldCredit
              type='number'
              className={`${styles[`credit_order${personal_income_monthly_gross_income_order}`]}  credit`}
              onChange={(value) => {
                field.onChange(value);
              }}
              customBlur={() => {
                field.onBlur();
                handleUpdateInfo(
                  {
                    income_and_expenses: {
                      personal_income: {
                        monthly_gross_income:
                          getValues('personal_income_monthly_gross_income') === ''
                            ? 0
                            : +getValues('personal_income_monthly_gross_income')
                      },
                      household_income:
                        costumer_details.household.number_of_adults === 1
                          ? {
                              monthly_gross_income: getValues('personal_income_monthly_gross_income')
                            }
                          : null
                    }
                  },
                  'personal_income_monthly_gross_income'
                );
              }}
              label={t('CREDIT_FORM.gross')}
              subtitle={t('CREDIT_FORM.gross_subtitle')}
              error={errors.personal_income_monthly_gross_income?.message || null}
              inputProps={field}
              placeholder={''}
              isTouched={touchedFields?.personal_income_monthly_gross_income}
            />
          )}
        />
        <Controller
          name='personal_income_monthly_net_income'
          control={control}
          render={({field}) => (
            <InputFieldCredit
              type='number'
              className={`${styles[`credit_order${personal_income_monthly_net_income_order}`]}  credit`}
              onChange={(value) => field.onChange(value)}
              customBlur={() => {
                field.onBlur();

                handleUpdateInfo(
                  {
                    income_and_expenses: {
                      personal_income: {
                        monthly_net_income:
                          getValues('personal_income_monthly_net_income') === ''
                            ? 0
                            : +getValues('personal_income_monthly_net_income')
                      },
                      household_income:
                        costumer_details.household.number_of_adults === 1
                          ? {
                              monthly_net_income:
                                getValues('personal_income_monthly_net_income') === ''
                                  ? 0
                                  : +getValues('personal_income_monthly_net_income')
                            }
                          : null
                    }
                  },
                  'personal_income_monthly_net_income'
                );
              }}
              label={t('CREDIT_FORM.net')}
              subtitle={t('CREDIT_FORM.net_subtitle')}
              error={errors.personal_income_monthly_net_income?.message}
              inputProps={field}
              placeholder={''}
              isTouched={touchedFields?.personal_income_monthly_net_income}
            />
          )}
        />
        <Controller
          name='personal_expenses_monthly_expenses'
          control={control}
          render={({field}) => (
            <InputFieldCredit
              type={'number'}
              className={`${styles[`credit_order${personal_expenses_monthly_expenses_order}`]}  credit`}
              onChange={(value) => field.onChange(value)}
              customBlur={() => {
                field.onBlur();
                handleUpdateInfo(
                  {
                    income_and_expenses: {
                      personal_expenses: {
                        monthly_expenses:
                          getValues('personal_expenses_monthly_expenses') === ''
                            ? 0
                            : +getValues('personal_expenses_monthly_expenses')
                      },
                      household_expenses:
                        costumer_details.household.number_of_adults === 1
                          ? {
                              monthly_expenses:
                                getValues('personal_expenses_monthly_expenses') === ''
                                  ? 0
                                  : +getValues('personal_expenses_monthly_expenses')
                            }
                          : null
                    }
                  },
                  'personal_expenses_monthly_expenses'
                );
              }}
              label={t('CREDIT_FORM.expensenses')}
              placeholder={''}
              subtitle={t('CREDIT_FORM.expensenses_subtitle')}
              error={errors.personal_expenses_monthly_expenses?.message}
              inputProps={field}
              isTouched={touchedFields?.personal_expenses_monthly_expenses}
            />
          )}
        />

        {costumer_details?.household?.number_of_adults === 1 && (
          <Controller
            name='household_debt'
            control={control}
            render={({field}) => (
              <InputFieldCredit
                type={'number'}
                className={`${styles[`credit_order${personal_debt_burden_order}`]}  credit`}
                onChange={(value) => field.onChange(value)}
                customBlur={() => {
                  field.onBlur();
                  handleUpdateInfo(
                    {
                      income_and_expenses: {
                        household_debt: +getValues('household_debt') === '' ? 0 : +getValues('household_debt')
                      }
                    },
                    'household_debt'
                  );
                }}
                label={t('CREDIT_FORM.personal_debt_burden_label')}
                placeholder={''}
                subtitle={t('CREDIT_FORM.personal_debt_burden_subtitle')}
                error={errors.household_debt?.message}
                inputProps={field}
                isTouched={touchedFields?.household_debt}
              />
            )}
          />
        )}

        <Controller
          name='co_borrower_amount'
          control={control}
          render={({field}) => (
            <InputFieldCredit
              type='number'
              className={`${styles[`credit_order${gar_com_am_order}`]}  credit`}
              onChange={(value) => field.onChange(value)}
              customBlur={() => {
                field.onBlur();
                handleUpdateInfo(
                  {
                    income_and_expenses: {
                      co_borrower: {
                        amount: !getValues('co_borrower_amount') ? 0 : +getValues('co_borrower_amount'),
                        exists: getValues('co_borrower_amount') ? true : false
                      }
                    }
                  },
                  'co_borrower_amount'
                );
              }}
              label={t('CREDIT_FORM.co_borrower_amount')}
              subtitle={t('CREDIT_FORM.co_borrower_amount_subtitle')}
              error={errors.co_borrower_amount?.message}
              inputProps={field}
              isTouched={touchedFields?.co_borrower_amount}
            />
          )}
        />

        <Controller
          name='contracts_amount'
          control={control}
          render={({field}) => (
            <InputFieldCredit
              type={'number'}
              className={`${styles[`credit_order${contracts_amount_order}`]}  credit`}
              onChange={(value) => field.onChange(value)}
              customBlur={() => {
                field.onBlur();
                handleUpdateInfo(
                  {
                    income_and_expenses: {
                      contracts: {
                        amount: getValues('contracts_amount') === '' ? 0 : +getValues('contracts_amount'),
                        exists: !getValues('contracts_amount') ? false : true
                      }
                    }
                  },
                  'contracts_amount'
                );
              }}
              label={t('CREDIT_FORM.contract')}
              subtitle={t('CREDIT_FORM.contract_subtitle')}
              error={errors.contracts_amount?.message}
              inputProps={field}
              isTouched={touchedFields?.contracts_amount}
            />
          )}
        />

        <Controller
          name='loans_amount'
          control={control}
          render={({field}) => (
            <InputFieldCredit
              type='number'
              className={`${styles[`credit_order${loans_amount_order}`]}  credit`}
              onChange={(value) => field.onChange(value)}
              customBlur={() => {
                field.onBlur();
                handleUpdateInfo(
                  {
                    income_and_expenses: {
                      loans: {
                        amount: +getValues('loans_amount') === '' ? 0 : +getValues('loans_amount'),
                        exists: !getValues('loans_amount') ? false : true
                      }
                    }
                  },
                  'loans_amount'
                );
              }}
              label={t('CREDIT_FORM.other')}
              placeholder={''}
              subtitle={t('CREDIT_FORM.other_subtitle')}
              error={errors.loans_amount?.message}
              inputProps={field}
              isTouched={touchedFields?.loans_amount}
            />
          )}
        />

        <Controller
          name='assets'
          control={control}
          render={({field}) => (
            <InputFieldCredit
              type='number'
              className={`${styles[`credit_order${assets_order}`]}  credit`}
              onChange={(value) => field.onChange(value)}
              customBlur={() => {
                field.onBlur();
                handleUpdateInfo(
                  {
                    income_and_expenses: {
                      assets: getValues('assets') === '' ? 0 : +getValues('assets')
                    }
                  },
                  'assets'
                );
              }}
              label={t('CREDIT_FORM.assets')}
              subtitle={t('CREDIT_FORM.assets_subtitle')}
              error={errors.assets?.message}
              inputProps={field}
              isTouched={touchedFields?.assets}
            />
          )}
        />
      </div>

      {costumer_details?.household?.number_of_adults > 1 && (
        <>
          <h3 className={styles['block__title']}>{t('CREDIT_FORM.household_title')}</h3>
          <p className={styles['block__subtitle']}>{t('CREDIT_FORM.household_subtitle')}</p>
          <p className={`${styles['block__subtitle']} mb20`}>{t('CREDIT_FORM.household_subtitle2')}</p>
          <div className={styles.grid}>
            {costumer_details?.household?.number_of_adults > 1 && (
              <Controller
                name='total_income'
                control={control}
                render={({field}) => (
                  <InputFieldCredit
                    type='number'
                    className={`${styles[`credit_order${total_income_order}`]}  credit`}
                    onChange={(value) => field.onChange(value)}
                    customBlur={() => {
                      field.onBlur();
                      handleUpdateInfo(
                        {
                          income_and_expenses: {
                            household_income: {
                              monthly_gross_income: getValues('total_income') === '' ? 0 : +getValues('total_income')
                            }
                          }
                        },
                        'total_income'
                      );
                    }}
                    label={t('CREDIT_FORM.total_income')}
                    placeholder={''}
                    subtitle={t('CREDIT_FORM.total_income_subtitle')}
                    error={errors.total_income?.message}
                    inputProps={field}
                    isTouched={touchedFields?.total_income}
                  />
                )}
              />
            )}
            {costumer_details?.household?.number_of_adults > 1 && (
              <Controller
                name={'household_income_monthly_net_income'}
                control={control}
                render={({field}) => (
                  <InputFieldCredit
                    type='number'
                    className={`${styles[`credit_order${household_income_monthly_net_income_order}`]}  credit`}
                    onChange={(value) => field.onChange(value)}
                    customBlur={() => {
                      field.onBlur();
                      handleUpdateInfo(
                        {
                          income_and_expenses: {
                            household_income: {
                              monthly_net_income:
                                getValues('household_income_monthly_net_income') === ''
                                  ? 0
                                  : getValues('household_income_monthly_net_income')
                            }
                          }
                        },
                        'household_income_monthly_net_income'
                      );
                    }}
                    label={t('CREDIT_FORM.monthly_net_income')}
                    subtitle={t('CREDIT_FORM.monthly_net_income_subtitle')}
                    error={errors.household_income_monthly_net_income?.message}
                    inputProps={field}
                    isTouched={touchedFields?.household_income_monthly_net_income}
                  />
                )}
              />
            )}
            <Controller
              name='household_debt'
              control={control}
              render={({field}) => (
                <InputFieldCredit
                  type='number'
                  className={`${styles[`credit_order${household_debt_order}`]}  credit`}
                  onChange={(value) => field.onChange(value)}
                  customBlur={() => {
                    field.onBlur();
                    handleUpdateInfo(
                      {
                        income_and_expenses: {
                          household_debt: getValues('household_debt') === '' ? 0 : +getValues('household_debt')
                        }
                      },
                      'household_debt'
                    );
                  }}
                  label={t('CREDIT_FORM.total_debt')}
                  subtitle={t('CREDIT_FORM.total_debt_subtitle')}
                  error={errors.household_debt?.message}
                  inputProps={field}
                  isTouched={touchedFields?.household_debt}
                />
              )}
            />
            <Controller
              name='household_expenses_monthly_expenses'
              control={control}
              render={({field}) => (
                <InputFieldCredit
                  type='number'
                  className={`${styles[`credit_order${household_expenses_monthly_expenses_order}`]}  credit`}
                  onChange={(value) => field.onChange(value)}
                  customBlur={() => {
                    field.onBlur();
                    handleUpdateInfo(
                      {
                        income_and_expenses: {
                          household_expenses: {
                            monthly_expenses:
                              getValues('household_expenses_monthly_expenses') === ''
                                ? 0
                                : +getValues('household_expenses_monthly_expenses')
                          }
                        }
                      },
                      'household_expenses_monthly_expenses'
                    );
                  }}
                  label={t('CREDIT_FORM.household_expenses_monthly_expenses')}
                  subtitle={t('CREDIT_FORM.household_expenses_monthly_expenses_subtitle')}
                  error={errors.household_expenses_monthly_expenses?.message}
                  inputProps={field}
                  isTouched={touchedFields?.household_expenses_monthly_expenses}
                />
              )}
            />
          </div>
        </>
      )}
    </>
  );
};

export default StepThree;
