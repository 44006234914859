import React, {useEffect} from 'react';
import styles from './UrgentCard.module.scss';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {getNotifications} from '../../features/SideBar/sidebarActions';
import dayjs from 'dayjs';
import SkeletonMUI from '../SkeletonMUI';
import AnimationWrapper from '../AnimationWrapper';

const UrgentCard = ({size = 'small'}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {notifications, loading} = useSelector(({sidebar}) => sidebar);

  useEffect(() => {
    dispatch(getNotifications('flexlimit', true));
  }, []);

  //sizes "small" -- for SideBar | "large" -- for Overwiew main block
  return (
    <AnimationWrapper>
      <div className={`${styles.root} card__root`}>
        <div className={styles['urgent__header']}>
          <h2 className={styles['urgent__header--title']}>{t('URGENTCARD.urgent')}</h2>
        </div>
        <div className={`${styles['urgent__main']} ${size === 'large' && size}`}>
          {loading ? (
            <>
              <SkeletonMUI height={60} width='80%' loading />
              <SkeletonMUI height={60} width='80%' loading />
            </>
          ) : (
            notifications &&
            notifications.length > 0 &&
            notifications.map((message, idx) => {
              return (
                <div className={styles['urgent__message']} key={idx}>
                  <h3 className={styles['urgent__message--date']}>
                    {dayjs(message.PublishStart).format('DD.MM.YYYY hh:mm')}
                  </h3>
                  <p className={styles['urgent__message--content']}>{message.Content}</p>
                </div>
              );
            })
          )}
        </div>
      </div>
    </AnimationWrapper>
  );
};

export default UrgentCard;
