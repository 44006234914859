export const dashboardData = [
  {id: '2746234293', name: 'Calvin', email: 'calvin.reynolds@gmail.com', referrals: '12', profit: '1,425.50'},
  {id: '9283420941', name: 'Rosa', email: 'rosa_gilbert@gmail.com', referrals: '8', profit: '852.75'},
  {id: '0293389241', name: 'Bettie', email: 'bettie.higgins@gmail.com', referrals: '7', profit: '640.00'},
  {id: '4949234293', name: 'Calvin', email: 'calvin.reynolds@gmail.com', referrals: '12', profit: '1,425.50'},
  {id: '2334909023', name: 'Rosa', email: 'rosa_gilbert@gmail.com', referrals: '8', profit: '852.75'},
  {id: '2323389241', name: 'Bettie', email: 'bettie.higgins@gmail.com', referrals: '7', profit: '640.00'},
  {id: '3429327462', name: 'Calvin', email: 'calvin.reynolds@gmail.com', referrals: '12', profit: '1,425.50'},
  {id: '8342092941', name: 'Rosa', email: 'rosa_gilbert@gmail.com', referrals: '8', profit: '852.75'},
  {id: '0292413389', name: 'Bettie', email: 'bettie.higgins@gmail.com', referrals: '7', profit: '640.00'},
  {id: '6234227493', name: 'Calvin', email: 'calvin.reynolds@gmail.com', referrals: '12', profit: '1,425.50'}
];

export const accordionData = [
  {summary: 'AccordionMUI Summary', details: 'Nulla quis lorem ut libero malesuada feugiat.'},
  {summary: 'AccordionMUI Summary 2', details: 'Nulla quis lorem ut libero malesuada feugiat.'},
  {summary: 'AccordionMUI Summary 3', details: 'Nulla quis lorem ut libero malesuada feugiat.'}
];

export const multiSelectOptions = ['Chocolate', 'Strawberry', 'Vanilla', 'Pineapple', 'Apple'];

export const selectOptions = [
  {value: 'chocolate', label: 'Chocolate'},
  {value: 'strawberry', label: 'Strawberry'},
  {value: 'vanilla', label: 'Vanilla'}
];

export const reditFormData = {
  id: 'b49f7a51-b5d4-4fc0-9129-606ed9fe92d8',
  customer_id: 1234,
  loan_type: 1,
  ip_address: '127.0.0.1',
  affiliate_info: {
    affiliate_id: 1,
    name: 'Adtraction',
    campaign: '',
    params: {
      at_gd: 'A58C9C01873B659057EF8B5B8C449ABB60DF18C7',
      cma: '30',
      cv: 'A58C9C01873B659057EF8B5B8C449ABB60DF18C7',
      pubid: '1134829802',
      sn: 'at_gd'
    },
    raw_url: ''
  },
  product: {
    product_id: 3,
    limit: 9000,
    nominal_interest_rate: 39.9,
    origination_fee: 380,
    monthly_fees: 45,
    minimum_payment_percent: 10,
    minimum_payment_amount: 500,
    apr: 26.9
  },
  basic_info: {
    ssn: '010101-XXXX',
    first_name: 'Vlad',
    given_name: 'Testi',
    last_name: 'Kozlov',
    email: 'vlad@dev.com',
    phone: '+46408677414',
    allow_sms_marketing: true,
    allow_email_marketing: true,
    loan_usage: 6,
    how_did_you_find_us: 3,
    address: {
      street: 'Test street 15',
      city: 'TestCity',
      post_code: '666'
    }
  },
  income_and_expenses: {
    personal_income: {
      monthly_gross_income: 50000,
      monthly_net_income: 32000
    },
    household_income: {
      monthly_gross_income: 90000,
      monthly_net_income: 73000
    },
    personal_expenses: {
      monthly_expenses: 20000
    },
    household_expenses: {
      monthly_expenses: 46000
    },
    loans: {
      exists: true,
      amount: 50000
    },
    assets: 50000,
    household_debt: 5000,
    contracts: {
      exists: false,
      amount: 0
    },
    co_burrower: {
      exists: true,
      amount: 50000
    }
  },
  household: {
    number_of_adults: 2,
    number_of_minors: 1,
    people: [
      {
        identifier: 'person_1',
        age: {
          value: 34,
          unit: 0
        }
      },
      {
        identifier: 'person_2',
        age: {
          value: 32,
          unit: 0
        }
      },
      {
        identifier: 'person_3',
        age: {
          value: 8,
          unit: 1
        }
      }
    ]
  },
  created_time: '2023-06-19T15:05:45.8111535+00:00',
  updated_time: '0001-01-01T00:00:00+00:00',
  submitted: false,
  submitted_time: '0001-01-01T00:00:00+00:00'
};

export const loan_usage = [
  {id: 0, title: 'None'},
  {id: 'Expenses', title: 'Expenses'},
  {id: 'Traveling', title: 'Traveling'},
  {id: 'Hobbies', title: 'Hobbies'},
  {id: 'Vehicles', title: 'Vehicles'},
  {id: 'Renovating', title: 'Renovating'},
  {id: 'LoanPayments', title: 'LoanPayments'},
  {id: 'Bills', title: 'Bills'},
  {id: 'UnforeseenExpense', title: 'UnforeseenExpense'},
  {id: 'Other', title: 'Other'}
];

export const how_did_you_find_us_values = [
  {id: 0, title: 'None'},
  {id: 'OldCustomer', title: 'OldCustomer'},
  {id: 'Email', title: 'Email'},
  {id: 'Sms', title: 'Sms'},
  {id: 'FacebookAd', title: 'FacebookAd'},
  {id: 'ComparisonSite', title: 'ComparisonSite'},
  {id: 'Forum', title: 'Forum'},
  {id: 'Google', title: 'Google'},
  {id: 'Bing', title: 'Bing'},
  {id: 'Facebook', title: 'Facebook'},
  {id: 'Twitter', title: 'Twitter'},
  {id: 'Instagram', title: 'Instagram'},
  {id: 'Blog', title: 'Blog'},
  {id: 'Banner', title: 'Banner'},
  {id: 'RadioAd', title: 'RadioAd'},
  {id: 'RadioProgram', title: 'RadioProgram'},
  {id: 'OutdoorCampaign', title: 'OutdoorCampaign'},
  {id: 'Other', title: 'Other'}
];

// [EnumMember(Value = "none")] None,

// [EnumMember(Value = "sold")] Sold,
// [EnumMember(Value = "balance")] Balance,

// [EnumMember(Value = "sold_principal")] SoldPrincipal,

//total = 22
//used =18

export const withdrawal_arr = ['withdrawal'];
export const interest_arr = ['interest'];
export const payment_arr = ['payment', 'extra_payment', 'interest_payment', 'fee_payment', 'principal_payment'];
export const fees_arr = [
  'fee',
  'origination_fee',
  'invoice_fee',
  'late_payment_fee',
  'invoice_reminder_fee',
  'payment_free_month_fee',
  'fee_withdrawal'
];
