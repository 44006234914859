import React, {useEffect} from 'react';
import styles from './PaymentFreeMonth.module.scss';
import DialogMUI from '../DialogMUI';
import {useTranslation} from 'react-i18next';
import {postConfirmPFM} from '../../features/Invoices/invoicesActions';
import {useDispatch, useSelector} from 'react-redux';
import {getPaymentfreeMonthData} from '../../features/Invoices/invoicesActions';
import {format} from 'date-fns';
import {setToastParams} from '../../features/Auth/authActions';
import {getCustomerNotifications, GetCustomerFlags} from '../../features/Settings/settingsActions';

const PaymentFreeMonth = ({dialog, toggleDialog}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleClose = () => {
    toggleDialog();
  };

  const handleConfirmPFM = () => {
    dispatch(postConfirmPFM())
      .then((res) => {
        if (res?.payload?.status === 200) {
          dispatch(
            setToastParams({
              toast_open: true,
              toast_type: res.payload.data.result,
              toast_text: res.payload.data.result
                ? 'Payment free month accepted'
                : 'Payment free month was not accepted'
            })
          );
          handleClose(res.payload.data.message);
        } else {
          dispatch(
            setToastParams({
              toast_open: true,
              toast_type: false,
              toast_text: 'Opps somthing went wrong. Please try again later'
            })
          );
          handleClose(res.payload.data.message);
        }
      })
      .then(() => {
        dispatch(GetCustomerFlags());
        dispatch(getCustomerNotifications());
      });
  };

  const {pfm_data} = useSelector((state) => state.invoices);

  const tableRows = [
    {
      key: 'invoiceNumber',
      value: pfm_data?.invoice_number,
      className: `${styles['table__value']} line-through`
    },
    {
      key: 'sum',
      value: pfm_data?.old_invoice_total.toLocaleString('sv-SE', {
        style: 'currency',
        maximumFractionDigits: 0,
        currency: 'SEK'
      }),
      className: `${styles['table__value']} line-through`
    },
    {
      key: 'expirationDate',
      value: pfm_data?.old_invoice_due_date && format(new Date(pfm_data?.old_invoice_due_date), 'dd.MM.yyyy'),
      className: `${styles['table__value']} line-through`
    },
    {
      key: 'newExpirationDate',
      value: pfm_data?.new_invoice_due_date && format(new Date(pfm_data?.new_invoice_due_date), 'dd.MM.yyyy'),
      className: styles['table__value']
    },
    {
      key: 'paymentFreeMonthCost',
      value: pfm_data?.payment_free_month_cost.toLocaleString('sv-SE', {
        style: 'currency',
        maximumFractionDigits: 0,
        currency: 'SEK'
      }),
      className: styles['table__value']
    }
  ];

  useEffect(() => {
    dialog && dispatch(getPaymentfreeMonthData());
  }, [dialog]);

  return (
    <>
      <DialogMUI withHeader={t('PAYMENTFREEMONTH.title')} open={dialog} onClose={handleClose}>
        <div className={styles.root}>
          {/* {width > 768 && <h2 className={styles.title}>{t('PAYMENTFREEMONTH.title')}</h2>} */}

          <div className={styles.scrolled__block}>
            <p className={styles.description}>{t('PAYMENTFREEMONTH.description')}</p>
            <div className={styles.table}>
              {tableRows.map((row) => (
                <div className={styles['table__row']} key={row.key}>
                  <b className={styles['table__key']}>{t(`PAYMENTFREEMONTH.table.${row.key}`)}</b>
                  <p className={row.className}>{row.value}</p>
                </div>
              ))}
            </div>
            <div className={styles['next__block']}>
              <div className={styles['next__block--col']}>
                <h4 className={styles['next__block--title']}>{t('PAYMENTFREEMONTH.nextPayment')}</h4>
                <p className={styles['next__block--text']}>{t('PAYMENTFREEMONTH.sumMayChange')}</p>
              </div>
              <div className={styles['next__block--col']}>
                <p className={styles['next__block--value']}>
                  {pfm_data?.new_invoice_total.toLocaleString('sv-SE', {
                    style: 'currency',
                    maximumFractionDigits: 0,
                    currency: 'SEK'
                  })}
                </p>
              </div>
            </div>
          </div>

          <div className={styles.footer__sticky}>
            <button type='button' onClick={() => handleConfirmPFM()} className={`${styles['button']} main__button`}>
              {t('PAYMENTFREEMONTH.accept')}
            </button>
            <button type='button' onClick={() => handleClose()} className={styles.cancel}>
              {t('PAYMENTFREEMONTH.cancel')}
            </button>
          </div>
        </div>
      </DialogMUI>
    </>
  );
};

export default PaymentFreeMonth;
