import {INVOICES} from './invoicesActionTypes';

export function getInvoices() {
  return {
    type: INVOICES.GET_INVOICES,
    payload: {
      client: 'second',
      request: {
        url: `/invoices/GetInvoices`,
        method: 'get'
      }
    }
  };
}

export function getInvoice(invoice_id) {
  return {
    type: INVOICES.GET_INVOICE,
    payload: {
      client: 'second',
      request: {
        url: `/invoices/GetInvoiceDetails?invoice_id=${invoice_id}`,
        method: 'get'
      }
    }
  };
}

export function getProducts(brand) {
  return {
    type: INVOICES.GET_PRODUCTS,
    payload: {
      client: 'second',
      request: {
        url: `/products/GetActiveProducts?brand=${brand}`,
        method: 'get'
      }
    }
  };
}

export function getPDF(invoice_id, type = false) {
  let url = `/invoices/GetPDF?invoice_id=${invoice_id}`;
  if (type) {
    url += `&document_type=${type}`;
  }
  return {
    type: INVOICES.GET_PDF,
    payload: {
      client: 'second',
      request: {
        url: url,
        method: 'get'
      }
    }
  };
}

export function postConfirmPFM(data) {
  return {
    type: INVOICES.CONFIRM_PFM,
    payload: {
      client: 'second',
      request: {
        url: `/invoices/ConfirmPFM`,
        method: 'post',
        data
      }
    }
  };
}

export function getPaymentfreeMonthData() {
  return {
    type: INVOICES.GET_PFM_INFO,
    payload: {
      client: 'second',
      request: {
        url: `/invoices/GetPFMDetails`,
        method: 'get'
      }
    }
  };
}
