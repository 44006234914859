import React, {useState, useEffect} from 'react';
import styles from './InvoiceMobileCard.module.scss';
import {getInvoice, getPDF} from '../invoicesActions';
import {useDispatch, useSelector} from 'react-redux';
import {handleDownload} from '../../../helpers/functions';
import dayjs from 'dayjs';
import {SkeletonMUI} from '../../../shared';
import {useTranslation} from 'react-i18next';
import {ArrowRightBlack, File} from '../../../assets/icons';
import {AnimationWrapper} from '../../../shared';
import {useLocation} from 'react-router-dom';

const InvoiceMobileCard = ({data, isLoading = false}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {loaded_invoices_ids, pdf, loading_invoices, loaded_invoices_info} = useSelector(({invoices}) => invoices);

  const arrowHandler = () => {
    setShowMenu(!showMenu);

    if (!(loaded_invoices_ids.includes(data?.invoice_id) || loading_invoices.includes(data?.invoice_id))) {
      data.invoice_id && dispatch(getInvoice(data.invoice_id));
    } else {
      setShowMenu(!showMenu);
    }
  };
  const [pdfDownloaded, setPdfDownloaded] = useState(false);
  const currentInvoice =
    loaded_invoices_info &&
    loaded_invoices_info.length > 0 &&
    loaded_invoices_info.filter((obj) => +obj?.invoice_id === +data?.invoice_id)[0];

  const type = data?.invoice_status === 'Open' ? 'active' : data?.invoice_status === 'PastDue' ? 'late' : 'paid';
  const [showMenu, setShowMenu] = useState(type !== 'paid' ? true : false);

  //EXPIRE DATE
  const today = dayjs().startOf('day');
  const otherDate = dayjs(currentInvoice?.invoice_due_date).startOf('day');
  const differenceInDays = otherDate.diff(today, 'day');

  const [qrLoading, setQrLoading] = useState(true);
  const handleQrLoad = () => {
    setQrLoading(false);
  };

  //DOWNLOAD PDF FUNCTION
  const handleDownloadPDF = async (invoice_id) => {
    await dispatch(getPDF(invoice_id, 'Invoice'));
    setPdfDownloaded(true);
  };

  useEffect(() => {
    pdfDownloaded && pdf && handleDownload(pdf);
    setPdfDownloaded(false);
  }, [pdfDownloaded]);

  useEffect(() => {
    type !== 'paid' && data.invoice_id && arrowHandler();
  }, [data]);

  //SCROLL FUNC

  const {search} = useLocation();
  const scrolledID = (search && search.split('?invoice_id=').length > 0 && search.split('?invoice_id=')[1]) || null;

  useEffect(() => {
    if (+scrolledID === +data?.invoice_id) {
      // Find the element with the matching invoice_id
      const scrolledElement = document.getElementById(`invoice_${data?.invoice_id}`);
      const topOffset = scrolledElement.offsetTop - 80;
      if (scrolledElement) {
        window.scrollTo({top: topOffset, behavior: 'smooth'});
      }
    }
  }, [scrolledID, isLoading]);

  const charArr = [
    {
      title: 'PAIDINVOICE.capital',
      value: `${
        currentInvoice?.capital
          ? currentInvoice?.capital.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})
          : ''
      }`
    },
    {
      title: 'PAIDINVOICE.interest',
      value: `${
        currentInvoice?.interest
          ? currentInvoice?.interest.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})
          : ''
      }`
    },
    {
      title: 'PAIDINVOICE.fees',
      value: `${
        currentInvoice?.fees ? currentInvoice?.fees.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'}) : ''
      }`
    }
  ];

  const tableRows = [
    {
      label: 'MOBILEINVOICE.ocr',
      value: currentInvoice?.ocr
    },
    {
      label: 'MOBILEINVOICE.payee',
      value: currentInvoice?.payee
    },
    {
      label: 'MOBILEINVOICE.svea',
      value: currentInvoice?.plus_giro
    },
    {
      label: 'MOBILEINVOICE.bank',
      value: currentInvoice?.bank_giro
    }
  ];

  const isClosedByPFM = data?.invoice_status === 'PaymentFreeMonth';

  //DONT USE iT FOR DESKTOP THEY'RE DIFFRENT
  const handleSetTitleMob = (status, sold_to) => {
    if (status === 'Creditloss') {
      return t('PAIDINVOICE.credit_loss');
    } else if (status === 'Sold' && sold_to !== 'None') {
      return `${t('PAIDINVOICE.sold')} ${sold_to} ${t('PAIDINVOICE.on')}`;
    } else if (status === 'Collected' && sold_to === 'None') {
      return t('PAIDINVOICE.collected');
    } else if (status === 'Aborted') {
      return t('PAIDINVOICE.aborted');
    } else if (status === 'PaidToPartner') {
      return t('PAIDINVOICE.paid_to_parthner');
    } else if (status === 'PaymentFreeMonth') {
      return t('PAIDINVOICE.paidon');
    } else if (status === 'Paid') {
      return t('PAIDINVOICE.paidon');
    } else if (status === 'PastDue') {
      return t('MOBILEINVOICE.due');
    }
  };

  const handleSetDateMob = (status, sold_to) => {
    if (status === 'Creditloss') {
      return dayjs(data?.invoice_due_date).format('DD.MM.YYYY');
    } else if (status === 'Sold' && sold_to !== 'None') {
      return dayjs(data?.invoice_sold_time).format('DD.MM.YYYY');
    } else if (status === 'Collected' && sold_to === 'None') {
      return dayjs(data?.invoice_sold_time).format('DD.MM.YYYY');
    } else if (status === 'Aborted') {
      return dayjs(data?.invoice_aborted_date).format('DD.MM.YYYY');
    } else if (status === 'PaidToPartner') {
      return dayjs(data?.paid_time).format('DD.MM.YYYY');
    } else if (status === 'PaymentFreeMonth') {
      return dayjs(data?.invoice_aborted_date).format('DD.MM.YYYY');
    } else if (status === 'Paid') {
      return dayjs(data?.paid_time).format('DD.MM.YYYY');
    } else if (status === 'PastDue') {
      return dayjs(data?.paid_time).format('DD.MM.YYYY');
    }
  };

  return isLoading ? (
    <SkeletonMUI className='mb16' variant='rectangular' height={70} loading />
  ) : (
    <AnimationWrapper>
      <div id={`invoice_${data?.invoice_id}`} className={styles.root}>
        <div className={styles.header}>
          <div className={`${styles['header__status']} ${type}`}>
            <div>
              <b>
                {t('MOBILEINVOICE.invoice')}{' '}
                <span className={isClosedByPFM && styles.closed_by_pfm}>{data?.invoice_id} </span>.
              </b>

              {type === 'late' && <p>{t('MOBILEINVOICE.late')}</p>}
              {type === 'late' && <p>{t('MOBILEINVOICE.pay')}</p>}

              {type === 'active' && (
                <p>
                  {handleSetTitleMob(data.invoice_status, data.sold_to)} {differenceInDays} {t('MOBILEINVOICE.days')}
                </p>
              )}

              {type === 'paid' && (
                <p>
                  {handleSetTitleMob(data.invoice_status, data.sold_to)}{' '}
                  {handleSetDateMob(data.invoice_status, data.sold_to)}
                </p>
              )}
            </div>
          </div>
          <div className={styles['header__main']}>
            <div className={styles['header__char']}>
              <p className={styles['header__char--title']}>{t('INVOICE.due')}</p>
              <span className={`${styles['header__char--text']} ${isClosedByPFM && styles.closed_by_pfm}`}>
                {dayjs(data?.invoice_due_date).format('DD.MM.YYYY')}
              </span>
            </div>
            {!showMenu && (
              <div className={styles['header__char']}>
                <p className={styles['header__char--title']}>{t('PAIDINVOICE.amount')}</p>
                <span className={`${styles['header__char--text']} ${isClosedByPFM && styles.closed_by_pfm}`}>
                  {data?.invoice_total.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
                </span>
              </div>
            )}

            {showMenu && (
              <div className={styles['header__char']}>
                <p className={styles['header__char--title']}>{t('MOBILEINVOICE.invoice_date')}</p>
                <span className={styles['header__char--text']}>
                  {dayjs(currentInvoice?.invoice_date).format('DD.MM.YYYY')}
                </span>
              </div>
            )}

            <button aria-label='show menu' onClick={arrowHandler} className={styles['header__button']}>
              <ArrowRightBlack className={!showMenu ? styles.icon : styles.icon__rotate} />
            </button>
          </div>
        </div>

        <div className={`${styles.main} ${showMenu ? (type !== 'paid' ? styles.show : styles.show_s) : styles.hide}`}>
          <div className={styles['main__sum']}>
            <p className={`${styles['main__sum--value']} ${isClosedByPFM && styles.closed_by_pfm_b}`}>
              {data?.invoice_total.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
              {/* <span>kr</span> */}
            </p>
            <div className={styles['main__chars']}>
              {charArr.map((char, index) => (
                <div className={styles['main__char']} key={index}>
                  <p className={styles['main__char--title']}>{t(char?.title)}</p>
                  <span className={styles['main__char--value']}>{char?.value}</span>
                </div>
              ))}
            </div>
          </div>

          <div className={styles['main__table']}>
            {type !== 'paid' && (
              <>
                {tableRows.map((row, index) => (
                  <div className={styles['main__table--row']} key={index}>
                    <div className={styles['main__table--char']}>{t(row.label)}</div>
                    <div className={styles['main__table--char']}>{row.value}</div>
                  </div>
                ))}
                {/* <div className={styles['invoice__qr']}>
                  <p className={styles['invoice__qr--txt']}>{t('INVOICE.pay')}</p>
                  {qrLoading && <SkeletonMUI variant='rectangular' height={80} width={80} loading />}
                  {currentInvoice?.qr && (
                    <img
                      style={{display: qrLoading ? 'none' : 'flex'}}
                      className={styles['invoice__qr--elem']}
                      src={`data:image/png;base64,${currentInvoice?.qr}`}
                      onLoad={() => {
                        handleQrLoad(false);
                      }}
                      alt='qr code'
                    />
                  )}
                </div> */}
              </>
            )}

            <button onClick={() => handleDownloadPDF(data?.invoice_id)} className={styles['main__download']}>
              <File className='mr10' />
              {t('PAIDINVOICE.download')}
            </button>
          </div>
        </div>
      </div>
    </AnimationWrapper>
  );
};

export default InvoiceMobileCard;
