import React, {useEffect, useRef, useState} from 'react';

import styles from './NavBar.module.scss';
import {useTranslation} from 'react-i18next';
import {Overview, Invoices, Transactions, Contracts, Settings} from '../../assets/icons';
import {NavLink} from 'react-router-dom';
import {mainPath} from '../../routes/paths';
import {useSelector} from 'react-redux';

const navElems = [
  {title: 'NAVBAR.overview', icon: <Overview />, path: mainPath.overview.path},
  {title: 'NAVBAR.invoices', icon: <Invoices />, path: mainPath.invoices.path},
  {title: 'NAVBAR.transactions', icon: <Transactions />, path: mainPath.transactions.path},
  {title: 'NAVBAR.contracts', icon: <Contracts />, path: mainPath.contracts.path},
  {title: 'NAVBAR.settings', icon: <Settings />, path: mainPath.settings.path}
];

const NavBar = () => {
  const {t} = useTranslation();

  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isScrollToTop, setScrollToTop] = useState(true);
  const scrollTimer = useRef(null);
  const {is_menu_shown} = useSelector((state) => state.settings);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.pageYOffset;
      if (prevScrollPosition > currentPosition) {
        setScrollToTop(true);
      } else {
        setScrollToTop(false);
      }
      setPrevScrollPosition(currentPosition);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPosition]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollTimer.current) {
        clearTimeout(scrollTimer.current);
      }
      setIsScrolling(true);
      scrollTimer.current = setTimeout(() => {
        setIsScrolling(false);
        scrollTimer.current = null;
      }, 250);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav
      id='navbar'
      className={`${styles.root} ${
        is_menu_shown ? 'hide' : prevScrollPosition < 20 ? 'show' : isScrollToTop ? 'show' : 'hide'
      }`}
    >
      {navElems.map((elem, idx) => (
        <NavLink className={styles.link} to={elem.path} key={idx}>
          {elem.icon}
          <p>{t(elem.title)}</p>
        </NavLink>
      ))}
    </nav>
  );
};

export default NavBar;
