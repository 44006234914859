import React, {useEffect, useRef, useState, useLayoutEffect} from 'react';
import styles from './Invoice.module.scss';
import {File} from '../../../assets/icons';
import {useTranslation} from 'react-i18next';
import {SkeletonMUI, AnimationWrapper} from '../../../shared';
import dayjs from 'dayjs';
import {useDispatch, useSelector} from 'react-redux';
import {getInvoice, getPDF} from '../invoicesActions';
import {handleDownload} from '../../../helpers/functions';

import {useLocation} from 'react-router-dom';

const Invoice = ({type = 'active', data, isLoading = false}) => {
  //INVOICE STATUSES: 0 - None | 1 - Open | 2 - PastDue | 3 - Paid
  //types "active" || "late"
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [pdfDownloaded, setPdfDownloaded] = useState(false);
  const {loaded_invoices_ids, loaded_invoices_info, pdf} = useSelector(({invoices}) => invoices);
  const {search} = useLocation();
  const scrolledID = (search && search.split('?invoice_id=').length > 0 && search.split('?invoice_id=')[1]) || null;

  useEffect(() => {
    data && !loaded_invoices_ids.includes(data?.invoice_id) && dispatch(getInvoice(data?.invoice_id));
  }, [data?.invoice_id]);

  const currentInvoice =
    loaded_invoices_info &&
    loaded_invoices_info.length > 0 &&
    loaded_invoices_info.filter((obj) => +obj?.invoice_id === +data?.invoice_id)[0];

  //Expire day calculations
  const today = dayjs().startOf('day');
  const otherDate = dayjs(currentInvoice?.invoice_due_date).startOf('day');
  const differenceInDays = otherDate.diff(today, 'day');

  //QR image loader
  const [qrLoading, setQrLoading] = useState(true);
  const handleQrLoad = () => {
    setQrLoading(false);
  };

  //DOWNLOAD PDF FUNCTION
  const handleDownloadPDF = async (invoice_id) => {
    await dispatch(getPDF(invoice_id, 'Invoice'));
    setPdfDownloaded(true);
  };

  useEffect(() => {
    pdfDownloaded && pdf && handleDownload(pdf);
    setPdfDownloaded(false);
  }, [pdfDownloaded]);

  const invoiceData = [
    {label: t('INVOICE.ocr'), value: currentInvoice?.ocr},
    {label: t('INVOICE.payee'), value: currentInvoice?.payee},
    {label: t('INVOICE.svea'), value: currentInvoice?.plus_giro},
    {label: t('INVOICE.bank'), value: currentInvoice?.bank_giro}
  ];

  const invoice_ref = useRef(null);

  useLayoutEffect(() => {
    if (invoice_ref.current && scrolledID && data && +scrolledID === +data.invoice_id) {
      invoice_ref.current.scrollIntoView({behavior: 'smooth'});

      const topOffset = invoice_ref.current.getBoundingClientRect().top + window.scrollY - 180;
      window.scroll({top: topOffset, behavior: 'smooth'});
    }
  }, [scrolledID, data]);

  return (
    <AnimationWrapper>
      <div id={`invoice_${data?.invoice_id}`} className={styles.root}>
        <div ref={invoice_ref} className={`${styles['invoice__header']} ${type}`}>
          {type === 'active' ? (
            <p>
              <b>
                {t('INVOICE.invoice_1')} {data?.invoice_id}
              </b>
              {t('INVOICE.due_in')}
              {differenceInDays}
              {t('INVOICE.days')}
            </p>
          ) : (
            <p>
              <b>
                {t('INVOICE.late')}
                {data.invoice_id}
              </b>
              {t('INVOICE.credit')}
            </p>
          )}
        </div>
        <div className={`${styles['invoice__main']} `}>
          <div className={styles['invoice__column']}>
            <div className={styles['invoice__row']}>
              {isLoading ? (
                <SkeletonMUI className='ml30' variant='rectangular' height={70} width={'80%'} loading />
              ) : (
                <>
                  <div className={styles['invoice__char']}>
                    <div className={styles['invoice__char--title']}>{t('INVOICE.due')}</div>
                    <div className={styles['invoice__char--text']}>
                      {dayjs(data.invoice_due_date).format('DD.MM.YYYY ')}
                    </div>
                  </div>
                  <div className={styles['invoice__char']}>
                    <div className={styles['invoice__char--title']}>{t('INVOICE.invoice')}</div>
                    <div className={styles['invoice__char--text']}>
                      {dayjs(currentInvoice.invoice_date).format('DD.MM.YYYY ')}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className={styles['invoice__row']}>
              {isLoading ? (
                <SkeletonMUI className='ml30' variant='rectangular' height={70} width={'80%'} loading />
              ) : (
                <>
                  <div className={styles['invoice__char']}>
                    <div className={styles['invoice__char--title']}>{t('INVOICE.capital')}</div>
                    <div className={styles['invoice__char--text']}>
                      {currentInvoice?.capital &&
                        currentInvoice.capital.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}{' '}
                    </div>
                  </div>
                  <div className={styles['invoice__char']}>
                    <div className={styles['invoice__char--title']}>{t('INVOICE.interest')}</div>
                    <div className={styles['invoice__char--text']}>
                      {currentInvoice?.interest &&
                        currentInvoice?.interest.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}{' '}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className={styles['invoice__row']}>
              {isLoading ? (
                <SkeletonMUI className='ml30' variant='rectangular' height={70} width={'80%'} loading />
              ) : (
                <div className={styles['invoice__char']}>
                  <div className={styles['invoice__char--title']}>{t('INVOICE.fees')}</div>
                  <div className={styles['invoice__char--text']}>
                    {currentInvoice?.fees &&
                      currentInvoice.fees.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}{' '}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles['invoice__column']}>
            {isLoading ? (
              <SkeletonMUI className='mb16' variant='rectangular' height={80} width={288} loading />
            ) : (
              <h4 className={styles['invoice__value']}>
                {data?.invoice_total &&
                  data?.invoice_total.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}{' '}
              </h4>
            )}

            {isLoading ? (
              <SkeletonMUI className='mb16' variant='rectangular' height={156} width={288} loading />
            ) : (
              <div className={styles['invoice__table']}>
                {invoiceData.map((data, index) => (
                  <div key={index} className={styles['invoice__table--row']}>
                    <p>{data.label}</p>
                    <span>{data.value}</span>
                  </div>
                ))}
              </div>
            )}

            <hr className={styles['invoice__hr']} />
            {/* <div className={styles['invoice__qr']}>
              <p className={styles['invoice__qr--txt']}>{t('INVOICE.pay')}</p>
              {qrLoading && <SkeletonMUI variant='rectangular' height={158} width={158} loading />}
              {currentInvoice?.qr && (
                <img
                  style={{display: qrLoading ? 'none' : 'flex'}}
                  className={styles['invoice__qr--elem']}
                  src={`data:image/png;base64,${currentInvoice?.qr}`}
                  onLoad={() => {
                    handleQrLoad(false);
                  }}
                  alt='qr code'
                />
              )}
            </div> */}
            <button onClick={() => handleDownloadPDF(data?.invoice_id)} className={styles['invoice__download']}>
              <File className='mr10' />
              {t('INVOICE.download')}
            </button>
          </div>
        </div>
      </div>
    </AnimationWrapper>
  );
};

export default Invoice;
