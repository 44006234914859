import React, {useState, useEffect} from 'react';
import styles from './ApplicationStatus.module.scss';
import {useTranslation} from 'react-i18next';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {mainPath} from '../../../routes/paths';
import {useDispatch, useSelector} from 'react-redux';

import {
  setPSD2ForApplicationRequest,
  getCustomer,
  GetCustomerFlags,
  getCustomerNotifications,
  handleTinkReturn
} from '../../Settings/settingsActions';

import {
  getApplicationConfirmationDetails,
  GetWithdrawalConfirmationDetails,
  setWithdrawalCirclesQuantity,
  confirmApplication,
  setAppSeventhCircle,
  setAppEightsCircle
} from '../creditFormActions';

import BlockSeparator from '../BlockSeparator';

import {CheckboxMUI} from '../../../shared';
import {MakeFirstWithdrawal, TableBlock, DownloadFiles, PrimaryBank, DeniedBlock} from './blocks';
import Warning from '../Warning';
import BadLoan from './blocks/BadLoan';

const ApplicationStatus = () => {
  const {t} = useTranslation();
  const [withdrawalAmount, setWithdrawalAmount] = useState(null);
  const [account, setAccount] = useState(null);
  const [bill_date, setBillDate] = useState(21);
  const history = useHistory();
  const dispatch = useDispatch();
  const {search} = useLocation();
  const {handle_tink, notifications} = useSelector(({settings}) => settings);
  const {seventh_circle, application_data} = useSelector(({credit_form}) => credit_form);

  function checkParam(array, param) {
    for (let i = 0; i < array?.length; i++) {
      // Check if the 'type' property matches the given 'param'
      if (array[i].type === param) {
        return true;
      }
    }
    return false;
  }

  const [check, setCheck] = useState(false);

  const pageType = search.split('=')[1]?.split('&')[0] || 'default';

  const applicationId =
    notifications && notifications.length > 0
      ? notifications.filter((notification) => notification.type === 'ApplicationWaitingForConfirmation')[0]?.identifier
      : null;
  const isUnconfirmedEmail = checkParam(notifications, 'UnconfirmedEmail');
  const isApplicationWaitingForConfirmation = checkParam(notifications, 'ApplicationWaitingForConfirmation');
  const isApplicationUnfinished = checkParam(notifications, 'ApplicationUnfinished');

  const handleSetAcoount = (e) => {
    setAccount(e);
    !isUnconfirmedEmail && dispatch(setAppEightsCircle(true));
  };

  // let pageType;

  // switch (true) {
  //   case isUnconfirmedEmail && !isApplicationWaitingForConfirmation:
  //     pageType = 'first_withdrawal';
  //     break;
  //   case application_data?.old_product && isApplicationWaitingForConfirmation:
  //     //For existing user
  //     pageType = 'upselling_page';
  //     break;

  //   case isApplicationUnfinished:
  //     pageType = 'denied_page';
  //     break;
  //   default:
  //     pageType = 'None';
  //     break;
  // }

  const isUpselling = pageType === 'upselling_page' ? true : false;

  const psd2Id = handle_tink?.id || null;
  const tink_url = localStorage.getItem('tink_url') || null;

  useEffect(() => {
    dispatch(getCustomer());
    dispatch(GetCustomerFlags());
    dispatch(getCustomerNotifications());
  }, []);

  useEffect(() => {
    dispatch(setWithdrawalCirclesQuantity(!isUpselling));
  }, [isUpselling]);

  useEffect(() => {
    applicationId &&
      (psd2Id && dispatch(GetWithdrawalConfirmationDetails(applicationId)),
      dispatch(getApplicationConfirmationDetails(applicationId)).then((res) => {
        if (res?.payload?.status === 200) {
          const data = res.payload.data;
          setWithdrawalAmount(data.new_product.limit);
        }
      }),
      psd2Id &&
        dispatch(
          setPSD2ForApplicationRequest({
            application_id: applicationId,
            psd2_id: !isUpselling ? psd2Id : account
          })
        ));
  }, [applicationId, psd2Id]);

  const handleAccept = () => {
    dispatch(
      confirmApplication({
        application_id: applicationId,
        ip_address: sessionStorage.getItem('IP'),
        withdrawal_amount: withdrawalAmount,
        account: isUpselling ? psd2Id : account,
        invoice_expiry_day: bill_date
      })
    ).then(
      (res) =>
        res?.payload?.status === 200 && res.payload.data?.status === 'Accepted' && history.push(mainPath.overview.path)
    );
  };

  return (
    <div className={`${styles.root} container`}>
      {pageType === 'first_withdrawal' && !isUnconfirmedEmail && (
        <MakeFirstWithdrawal unconfirmed={isUnconfirmedEmail} />
      )}
      {pageType === 'default' && (
        <div className={styles.container}>
          {!isUpselling && <BlockSeparator anchor={'spep_1'} color={seventh_circle ? 'green' : 'grey'} step={1} />}
          <h2 className={styles.title}>{t('APPLICATION_STATUS.title')}</h2>
          <p className={styles.subtitle}>{t('APPLICATION_STATUS.subtitle')}</p>
          <p className={styles.amount}>
            {application_data?.new_product?.limit.toLocaleString('sv-SE', {
              style: 'currency',
              currency: 'SEK',
              maximumFractionDigits: 0
            })}
          </p>
          <span className={styles.amount__txt}>{t('APPLICATION_STATUS.approved')}</span>
          <TableBlock isUpselling={isUpselling} />
          <DownloadFiles />
          <div className={styles.checkbox}>
            <CheckboxMUI
              id='checkbox-allow'
              onChange={(e) => {
                dispatch(setAppSeventhCircle(!check));
                setCheck(!check);
              }}
              checked={check}
              className={`mr6 ${check ? styles.checkbox__check : styles.checkbox__uncheck}`}
            />

            <label htmlFor={'checkbox-allow'} className={styles.checkbox__text}>
              {t('APPLICATION_STATUS.have')}
            </label>
          </div>
          <PrimaryBank
            bill_date={bill_date}
            setBillDate={setBillDate}
            unconfirmed={isUnconfirmedEmail}
            sliderSetter={setWithdrawalAmount}
            setter={handleSetAcoount}
            isUpselling={!isUpselling}
          />
          <Warning className='mt32' />

          <button
            //NEED TO ADD CHECKING  isUnconfirmedEmail
            disabled={(!isUpselling ? !check || !account || !withdrawalAmount : !check) || isUnconfirmedEmail}
            onClick={handleAccept}
            type='button'
            className={`${styles.button} main__button `}
          >
            {t('APPLICATION_STATUS.accept')}
          </button>
          <Link className={styles.link} to={mainPath.overview.path}>
            {t('APPLICATION_STATUS.return')}
          </Link>
        </div>
      )}

      {pageType === 'rejected' && (
        <div className={styles.container}>
          <DeniedBlock />
        </div>
      )}

      {pageType === 'badloan' && (
        <div className={styles.container}>
          <BadLoan />
        </div>
      )}
    </div>
  );
};

export default ApplicationStatus;
