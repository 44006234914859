import {SIDEBAR} from './sidebarActionTypes';

const INITIAL_STATE = {
  loading: false,
  notifications: null,
  ledgers: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIDEBAR.GET_NOTIFICATIONS:
    case SIDEBAR.GET_LEDGERS:
      return {...state, loading: true};

    case SIDEBAR.GET_NOTIFICATIONS_SUCCESS:
      return {...state, loading: false, notifications: action?.payload?.data};

    case SIDEBAR.GET_LEDGERS_SUCCESS:
      return {...state, loading: false, ledgers: action?.payload?.data};
    default:
      return state;
  }
}
