import React, {useRef, useState} from 'react';
import styles from './DropdownSelect.module.scss';
import {useOnClickOutside} from '../../helpers/hooks';
import {Triangle} from '../../assets/icons';

const mockArr = [
  {id: 1, title: 'Poltava'},
  {id: 2, title: 'Kharkiv'},
  {id: 3, title: 'Lviv'},
  {id: 4, title: 'Oslo'},
  {id: 5, title: 'Sumy'}
];

const DropdownSelect = ({
  label = 'Number',
  values,
  onChange,
  value = null,
  subtitle = 'Select number or number range',
  className = '',
  placeholder = 'Choose some data'
}) => {
  const ref2 = useRef();
  const [openSelect, setOpenSelect] = useState(false);
  const [choose, setChoosen] = useState(null);
  useOnClickOutside(ref2, () => setOpenSelect(false));

  const options = !values ? mockArr : values;

  let settedValue;
  if (value !== null) {
    settedValue = values[value - 1]?.title;
  } else if (choose) {
    settedValue = choose?.title;
  } else {
    settedValue = placeholder;
  }

  return (
    <div className={`${styles.root} ${className}`}>
      <p className={styles['select__label']}>{label}</p>
      <div ref={ref2} className={styles['select__dropdown']}>
        <button
          type={'button'}
          onClick={() => setOpenSelect(!openSelect)}
          className={styles['select__dropdown--select']}
        >
          <p className={styles['select__dropdown--placeholder']}> {settedValue}</p>
          <Triangle className={`${styles['select__dropdown--arrow']} ${openSelect && 'down'}`} />
        </button>
        {openSelect && (
          <div className={styles['select__items']}>
            {options &&
              options.length > 0 &&
              options.map((item, idx) => {
                return (
                  <button
                    type={'button'}
                    key={idx}
                    onClick={() => {
                      onChange(item.id);
                      setChoosen(item);
                      setOpenSelect(false);
                    }}
                    className={styles['select__item']}
                  >
                    {item.title}
                  </button>
                );
              })}
          </div>
        )}
      </div>
      <span className={styles['select__subtitle']}>{subtitle}</span>
    </div>
  );
};

export default DropdownSelect;
