import React, {useState} from 'react';
import {Logotype} from '../../../../assets/icons';
import {InputMUI, RangeInputPure} from '../../../../shared';
import {useTranslation} from 'react-i18next';
import styles from '../ApplicationStatus.module.scss';
import {useWindowWidth} from '../../../../helpers/hooks';

const MakeFirstWithdrawal = ({unconfirmed}) => {
  const {t} = useTranslation();
  const [sliderValue, setSliderValue] = useState(10000);

  let sliderWidth;
  const totalLimit = 20000;
  const availableLimit = 20000;
  const usedLimit = 3000;
  const step = 500;

  const width = useWindowWidth();

  switch (true) {
    case width > 978:
      sliderWidth = 350;
      break;
    case width < 410:
      sliderWidth = 280;
      break;
    default:
      sliderWidth = 350 * 0.78;
      break;
  }

  const percentAdapter = (value) => {
    const lowerBound = 5000;
    const upperBound = 20000;
    if (+value === +lowerBound) {
      return 0;
    } else if (+value === +upperBound) {
      return 100;
    } else if (value > lowerBound && value < upperBound) {
      const ratio = (value - lowerBound) / (upperBound - lowerBound);
      return ratio * 100;
    } else {
    }
  };

  const valueSetter = (value) => {
    setSliderValue(+value);
  };

  return (
    <div className={styles['withdrawal__container']}>
      <Logotype />
      <h2 className={styles.title}>{t('APPLICATION_STATUS.title1')}</h2>
      <p className={styles.subtitle}>{t('FIRST_WITHDRAWAL.text')}</p>
      <b className={styles.block__bold}>{t('APPLICATION_STATUS.please')}</b>

      {unconfirmed && (
        <button
          onClick={() => dispatch(postEmailConfirmation({email_address: 'test@gmail.com'}))}
          className={styles.resend}
        >
          {t('APPLICATION_STATUS.resend')}
        </button>
      )}
      <div className={styles['withdraw__column']}>
        <div className={`${styles['withdraw__input']} ${unconfirmed && 'disabled'}`}>
          <InputMUI
            disabled={unconfirmed}
            value={sliderValue}
            className={styles['withdraw__input--field']}
            placeholder={'5000'}
            variant='standard'
            error={sliderValue > 20000 || sliderValue < 5000 ? 'The amount is out of limits' : null}
            type='number'
            label={t('WITHDRAWACCOUNT.amount')}
            fullWidth
          />

          <span className={styles['withdraw__input--currency--disabled']}>kr</span>
        </div>
        <p className={styles['withdraw__text--disabled']}>{t('FIRST_WITHDRAWAL.minimum')}</p>
      </div>
      <RangeInputPure
        disabled={unconfirmed}
        sliderWidth={sliderWidth}
        totalLimit={totalLimit}
        availableLimit={availableLimit}
        usedLimit={usedLimit}
        step={percentAdapter(step)}
        value={percentAdapter(sliderValue)}
        setter={valueSetter}
      />
      <button disabled={true} className={`mt22 main__button`}>
        {t('WITHDRAWACCOUNT.make')}
      </button>
    </div>
  );
};

export default MakeFirstWithdrawal;
