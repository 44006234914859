import React, {useState, useEffect} from 'react';
import styles from './TinkProcessing.module.scss';
import {useTranslation} from 'react-i18next';
import {Link} from '@mui/material';
import {creditAppPath, mainPath} from '../../../routes/paths';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {getApplicationStatus} from '../creditFormActions';
import testAnimatedLogo from '../../../assets/icons/test_logo.gif';
import {setToastParams} from '../../Auth/authActions';
import {getCustomerNotifications, getCustomer, handleTinkReturn} from '../../Settings/settingsActions';
import {setPSD2ForApplication} from '../creditFormActions';

const TinkProcessing = () => {
  const {t} = useTranslation();
  const [emailVerified, setEmailVerified] = useState(false);
  const {customer_data, notifications, loading} = useSelector(({settings}) => settings);
  const dispatch = useDispatch();
  const history = useHistory();
  const application_id = localStorage.getItem('application_id');
  const {pathname, search, ...rest} = useLocation();

  const handleError = (withRedirect = true) => {
    dispatch(
      setToastParams({
        toast_open: true,
        toast_type: false,
        toast_text: t('APPLICATION_STATUS.went_wrong'),
        toast_description: t('APPLICATION_STATUS.went_wrong_try')
      })
    );
    history.push(mainPath.overview.path);
  };

  useEffect(() => {
    dispatch(getCustomer());
    dispatch(getCustomerNotifications());

    dispatch(
      handleTinkReturn({
        return_url: 'https://flexlimit.4-com.pro' + creditAppPath.tinkProcessingPath.path + search
      })
    )
      .then((res) => {
        if (res?.payload?.status === 200) {
          if (res.payload.data.status === 'Complete') {
            localStorage.setItem('psd2_id', res.payload.data.id);

            dispatch(
              setPSD2ForApplication({
                application_id: localStorage.getItem('application_id'),
                psd2_id: localStorage.getItem('psd2_id')
              })
            ).then((response) => {
              switch (response?.payload?.status) {
                case 200:
                  const {status} = response.payload.data;
                  switch (status) {
                    case 'Rejected':
                    case 'WaitingForManual':
                      // Do nothing
                      break;
                    case 'WaitingForConfirmationRisk':
                      //Return to the Risk Page
                      history.push(
                        creditAppPath.applicationStatusPath.path +
                          `?type=badloan&application_id=${localStorage.getItem('application_id')}`
                      );
                      break;
                    case 'WaitingForConfirmation':
                      //Return to the Finalize Page
                      history.push(creditAppPath.applicationStatusPath.path);
                      break;
                    default:
                      break;
                  }
                  break;
                default:
                  handleError();
                  break;
              }
            });
          } else {
            handleError();
          }
        } else {
          handleError();
        }
      })
      .catch((error) => {
        handleError();
      });
  }, []);

  useEffect(() => {
    let intervalId;
    let pollCount = 0;

    const poll = () => {
      application_id && pollCount <= 25
        ? dispatch(getApplicationStatus(application_id)).then((res) => {
            if (!res.payload.error) {
              const {status} = res.payload.data;
              switch (status) {
                case 'WaitingForPSD2Report':
                  console.log('Application status is WaitingForPSD2Report');
                  break;
                case 'WaitingForConfitmation':
                  //WaitingForConfitmation => finalize page
                  history.push(creditAppPath.applicationStatusPath.path + `?type=default`);
                  break;
                case 'WaitingForConfirmationAtRisk':
                  //WaitingForConfirmationAtRisk => warning page
                  history.push(
                    creditAppPath.applicationStatusPath.path +
                      `?type=badloan&application_id=${res.payload.data.application_id}`
                  );
                  break;
                case 'Rejected':
                  //to overview with rejected toast
                  history.push(mainPath.overview.path);
                  dispatch(
                    setToastParams({
                      toast_open: true,
                      toast_type: false,
                      toast_text: '',
                      toast_description: t('CONTRACTMOBILE.rejected')
                    })
                  );

                  break;
                case 'WaitingForManual':
                  //so that it goes to overview and updateNotifications
                  history.push(mainPath.overview.path);
                  dispatch(getCustomerNotifications());

                  break;
                default:
                  //so that it goes to overview and updateNotifications
                  history.push(mainPath.overview.path);
                  dispatch(getCustomerNotifications());
              }
            } else {
              history.push(mainPath.overview.path);
              dispatch(
                setToastParams({
                  toast_open: true,
                  toast_type: false,
                  toast_text: t('APPLICATION_STATUS.went_wrong'),
                  toast_description: t('APPLICATION_STATUS.went_wrong_try')
                })
              );
            }
            pollCount++;
          })
        : history.push(mainPath.overview.path);
    };

    intervalId = setInterval(poll, 10000);

    return () => clearInterval(intervalId);
  }, [application_id]);

  function checkParam(array, param) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].type === param) {
        return true;
      }
    }
    return false;
  }

  //Based on the param we make redirect to the next step or keep the user here
  const isUnconfirmedContract = customer_data?.notifications
    ? checkParam(customer_data?.notifications, 'UnconfirmedContract')
    : false;

  // useEffect(() => {
  //   !isUnconfirmedContract && history.push(creditAppPath.applicationStatusPath.path);
  // }, [isUnconfirmedContract]);

  //Based on the param we set type of loading page

  const isUnconfirmedEmail = notifications ? checkParam(notifications, 'UnconfirmedEmail') : false;

  useEffect(() => {
    isUnconfirmedEmail && setEmailVerified(!isUnconfirmedEmail);
  }, [isUnconfirmedEmail]);

  return (
    <div className={`${styles.root} `}>
      {emailVerified ? (
        <h1 className={styles.title}>{t('TINK_PROCESS.title_1')}</h1>
      ) : (
        <h1 className={styles.title}>{t('TINK_PROCESS.title')}</h1>
      )}

      <p className={`${styles.text__main} mt30`}>
        {t('TINK_PROCESS.subtitle')} <b>{t('TINK_PROCESS.minutes')}</b>
      </p>
      <b className={`${styles.text__bold} mt30`}>{t('TINK_PROCESS.let')}</b>
      <img className={styles['logo']} src={testAnimatedLogo} alt='testAnimatedLogo' />
      <h3 className={styles.text__subtitle}>{emailVerified ? t('TINK_PROCESS.get') : t('TINK_PROCESS.verify')}</h3>

      {!emailVerified ? (
        <>
          <b className={`${styles.text__bold} mt30`}>{t('TINK_PROCESS.this')}</b>
          <p className={`${styles.text__main}`}>{t('TINK_PROCESS.and')}</p>

          <h3 className={`${styles.text__subtitle} mt30`}>{t('TINK_PROCESS.or')}</h3>
          <p className={`${styles.text__main} ${styles['text__main--xl']}`}>{t('TINK_PROCESS.get_to')}</p>
        </>
      ) : (
        <>
          <p className={`${styles.text__main} mt30`}>{t('TINK_PROCESS.good')}</p>
        </>
      )}
      <Link className={`${styles['link']} main__button mt30`} href={mainPath.overview.path}>
        {t('TINK_PROCESS.my')}
      </Link>
    </div>
  );
};

export default TinkProcessing;
