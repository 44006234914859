import React from 'react';
import {useTranslation} from 'react-i18next';
import styles from '../ApplicationStatus.module.scss';
import {useSelector} from 'react-redux';
import {ArrowRightBlackThin} from '../../../../assets/icons';
import {SkeletonMUI} from '../../../../shared';

const TableBlock = ({isUpselling}) => {
  const {t} = useTranslation();
  const {application_data, loading} = useSelector(({credit_form}) => credit_form);
  const rowsArr1 = [
    {
      label: 'APPLICATION_STATUS.monthy',
      from: `${application_data?.new_product?.nominal_interest_rate}% + ${t('APPLICATION_STATUS.styranta')}`
    },
    {
      label: 'APPLICATION_STATUS.fee',
      from: `${application_data?.new_product?.origination_fee.toLocaleString('sv-SE', {
        style: 'currency',
        currency: 'SEK'
      })} `
    },
    {
      label: 'APPLICATION_STATUS.charge',
      from: `${application_data?.new_product?.monthly_fees.toLocaleString('sv-SE', {
        style: 'currency',
        currency: 'SEK'
      })} `
    },
    {
      label: 'APPLICATION_STATUS.minimum',
      from: `${application_data?.new_product?.minimum_payment_percent}%`
    }
  ];

  const rowsArr = [
    {
      label: 'APPLICATION_STATUS.credit',
      from: `${application_data?.old_product?.limit.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}`,
      to: `${application_data?.new_product?.limit.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}`
    },
    {
      label: 'APPLICATION_STATUS.credit_left',
      from: `${application_data?.old_product?.minimum_payment_amount.toLocaleString('sv-SE', {
        style: 'currency',
        currency: 'SEK'
      })} `,
      to: `${application_data?.new_product?.minimum_payment_amount.toLocaleString('sv-SE', {
        style: 'currency',
        currency: 'SEK'
      })} `
    },
    {
      label: 'APPLICATION_STATUS.daily',
      from: `${application_data?.old_product?.origination_fee.toLocaleString('sv-SE', {
        style: 'currency',
        currency: 'SEK'
      })} `,
      to: `${application_data?.new_product?.origination_fee.toLocaleString('sv-SE', {
        style: 'currency',
        currency: 'SEK'
      })} `
    },
    {
      label: 'APPLICATION_STATUS.next',
      from: `${application_data?.old_product?.minimum_payment_percent}%`,
      to: `${application_data?.new_product?.minimum_payment_percent}%`
    },
    {
      label: 'APPLICATION_STATUS.original',
      from: `${application_data?.old_product?.origination_fee.toLocaleString('sv-SE', {
        style: 'currency',
        currency: 'SEK'
      })} `,
      to: `${application_data?.new_product?.origination_fee.toLocaleString('sv-SE', {
        style: 'currency',
        currency: 'SEK'
      })} `
    }
  ];

  const isLoading = loading || !application_data;
  return (
    <>
      {!isUpselling && !isLoading && (
        <div className={styles['table1']}>
          {rowsArr1.map((row, index) => (
            <div className={styles['table1--row']} key={index}>
              <p className={styles['table1--char']}>{t(row.label)}</p>
              <span className={styles['table1--char']}>{row.from}</span>
            </div>
          ))}
        </div>
      )}
      {isUpselling && !isLoading && (
        <div className={styles.table}>
          {rowsArr.map((item, idx) => {
            return (
              <div key={idx} className={styles['table__row']}>
                <p className={styles['table__row--title']}>{t(item?.label)}</p>
                <p className={styles['table__row--from']}>
                  {item?.from?.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
                </p>
                <ArrowRightBlackThin />
                <p className={styles['table__row--to']}>
                  {item?.to?.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
                </p>
              </div>
            );
          })}
        </div>
      )}
      {isLoading && (
        <div className={styles.table}>
          {[...Array(4)].map((elem, idx) => (
            <SkeletonMUI key={idx} width={'100%'} height={40} className={styles.skeleton} loading />
          ))}
        </div>
      )}
    </>
  );
};

export default TableBlock;
