import React, {useState, useEffect} from 'react';
import styles from '../ApplicationStatus.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useToggle, useWindowWidth, useDebounce} from '../../../../helpers/hooks';
import {DropdownSelect, RangeInputPure, InputMUI, BillingCalendar} from '../../../../shared';
import BlockSeparator from '../../BlockSeparator';
import {SkeletonMUI} from '../../../../shared';
import ConfirmEmailPopup from '../../ConfirmEmailPopup/ConfirmEmailPopup';
import {getCustomerNotifications, isEmailConfirmed, postEmailConfirmation} from '../../../Settings/settingsActions';

const PrimaryBank = ({isUpselling, setter, sliderSetter, setBillDate, bill_date}) => {
  const {t} = useTranslation();
  const [sliderValue, setSliderValue] = useState(500);
  const [email, setEmail] = useState('');
  const {application_data, eights_circle} = useSelector(({credit_form}) => credit_form);
  const {customer, email_confirmed} = useSelector(({settings}) => settings);

  function convertAccountsToObjects(accounts) {
    return accounts.map((account) => {
      return {id: account, title: account};
    });
  }

  const dispatch = useDispatch();

  const acoountsArr = application_data?.accounts ? convertAccountsToObjects(application_data?.accounts) : [];

  const totalLimit = application_data?.new_product?.limit;
  const availableLimit = application_data?.new_product?.limit;
  const usedLimit = 0;
  const step = 500;
  const width = useWindowWidth();

  let sliderWidth;

  switch (true) {
    case width > 978:
      sliderWidth = 350;
      break;
    case width < 410:
      sliderWidth = 280;
      break;
    default:
      sliderWidth = 350;
      break;
  }

  const list = [
    {value: 0, label: '0'},
    {value: 50, label: application_data?.new_product?.limit / 2},
    {value: 100, label: application_data?.new_product?.limit}
  ];

  const percentAdapter = (value) => {
    const lowerBound = 0;
    const upperBound = application_data?.new_product?.limit;
    if (+value === +lowerBound) {
      return 0;
    } else if (+value >= +upperBound) {
      return 100;
    } else if (value > lowerBound && value < upperBound) {
      const ratio = (value - lowerBound) / (upperBound - lowerBound);
      return ratio * 100;
    } else {
    }
  };

  const [dialog, setDialog] = useToggle(false);

  const valueSetter = (value) => {
    sliderSetter(value);
    setSliderValue(+Math.ceil(value));
  };

  let typingTimer;
  const doneTypingInterval = 2000;

  const debouncedTyping = useDebounce(sliderValue, doneTypingInterval);

  const handleEnterValue = (event) => {
    clearTimeout(typingTimer);

    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/[^0-9.,]/g, '');
    const numericValue = +sanitizedValue;

    if (!isNaN(numericValue)) {
      setSliderValue(numericValue);
    }
  };

  useEffect(() => {
    if (!isNaN(sliderValue) && sliderValue < 500) {
      setSliderValue(500);
    }
    if (sliderValue > availableLimit) {
      setSliderValue(availableLimit);
    }
  }, [debouncedTyping]);

  useEffect(() => {
    let intervalId;
    let pollCount = 0;

    const poll = () => {
      email &&
        pollCount <= 25 &&
        dispatch(isEmailConfirmed(email)).then((res) => {
          if (res.payload.data.confirmed) {
            dispatch(getCustomerNotifications());
            clearInterval(intervalId);
          } else {
          }
          pollCount++;
        });
    };

    intervalId = setInterval(poll, 10000);

    return () => clearInterval(intervalId);
  }, [email]);

  useEffect(() => {
    customer?.email && setEmail(customer?.email);
  }, [customer]);

  //const debouncedSliderAdapter = useDebounce(email, 700);
  //const [sended_count, setSendedCount] = useState(0);

  // useEffect(() => {
  //   if (email && !email_confirmed && sended_count === 0) {
  //     dispatch(postEmailConfirmation({email_address: email}));
  //     setSendedCount(1);
  //   }
  // }, [email, email_confirmed, sended_count]);

  return (
    <>
      {isUpselling && (
        <>
          <BlockSeparator anchor={'spep_2'} color={eights_circle ? 'green' : 'grey'} step={2} />
          <h3 className={styles.block__title}>{t('APPLICATION_STATUS.primary')}</h3>
          <p className={styles.block__subtitle}>{t('APPLICATION_STATUS.select')}</p>
          <DropdownSelect
            onChange={(e) => setter(e)}
            className={`${styles.select} mt20`}
            subtitle={t('APPLICATION_STATUS.desired')}
            placeholder={''}
            label={null}
            values={acoountsArr}
          />
          <div className={styles.calendar}>
            <BillingCalendar
              withHover
              active={bill_date}
              setter={setBillDate}
              title={t('APPLICATION_STATUS.calendar')}
            />
          </div>
          <h3 className={`${styles.block__title} `}>
            {t('APPLICATION_STATUS.make')} <br />
            {t('APPLICATION_STATUS.withdrawal')}
          </h3>
          {!email_confirmed ? (
            <p className={styles.block__subtitle}>{t('APPLICATION_STATUS.now')}</p>
          ) : (
            <p className={styles.block__subtitle}>{t('APPLICATION_STATUS.your')}</p>
          )}

          {email_confirmed === false && (
            <div className={styles.unconfirmed__block}>
              <b className={styles.block__bold}>{t('APPLICATION_STATUS.please')}</b>
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className={styles.unconfirmed__input}
                placeholder={'email-mc-email@face.com'}
              />
              <p className={styles.unconfirmed__text}>{t('APPLICATION_STATUS.this_email')}</p>
              <button
                type='button'
                onClick={() => dispatch(postEmailConfirmation({email_address: email}))}
                className={styles.resend}
              >
                {t('APPLICATION_STATUS.resend')}
              </button>
              <button onClick={() => setDialog(true)} type='button' className={styles.enter}>
                {t('SETTINGSFORM.enter')}
              </button>
            </div>
          )}
          <ConfirmEmailPopup email={email} dialog={dialog} handleCancelation={setDialog} />
          {email_confirmed && customer?.email && (
            <div className={styles.confirmed__block}>
              <b className={styles.block__bold}>{t('APPLICATION_STATUS.confirmed')}</b>
              <input
                disabled
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className={styles.confirmed__input}
                placeholder={'email-mc-email@face.com'}
              />
            </div>
          )}
          {application_data ? (
            email_confirmed ? (
              <>
                <div className={styles['withdraw__column']}>
                  <div className={styles['withdraw__input']}>
                    <InputMUI
                      onChange={(e) => handleEnterValue(e)}
                      value={sliderValue}
                      className={styles['withdraw__input--field']}
                      placeholder={'5000'}
                      variant='standard'
                      error={sliderValue > totalLimit || sliderValue < 500 ? 'The amount is out of limits' : null}
                      type='text'
                      label={t('WITHDRAWACCOUNT.amount')}
                      fullWidth
                    />

                    <span className={styles['withdraw__input--currency']}>kr</span>
                  </div>
                  <p className={`${styles['withdraw__text']} `}>{t('FIRST_WITHDRAWAL.minimum')}</p>
                </div>
                <RangeInputPure
                  marksArray={list}
                  sliderWidth={sliderWidth}
                  totalLimit={totalLimit}
                  availableLimit={availableLimit}
                  usedLimit={usedLimit}
                  step={10}
                  lowestValue={500}
                  value={percentAdapter(sliderValue)}
                  setter={valueSetter}
                />
              </>
            ) : (
              <>
                <div className={styles['withdraw__column']}>
                  <div className={`${styles['withdraw__input']} ${!email_confirmed && 'disabled'}`}>
                    <InputMUI
                      onChange={(e) => setSliderValue(e.target.value)}
                      disabled={!email_confirmed}
                      value={sliderValue}
                      className={styles['withdraw__input--field']}
                      placeholder={'5000'}
                      variant='standard'
                      error={sliderValue > 20000 || sliderValue < 5000 ? 'The amount is out of limits' : null}
                      type='number'
                      label={t('WITHDRAWACCOUNT.amount')}
                      fullWidth
                    />

                    <span className={styles['withdraw__input--currency--disabled']}>kr</span>
                  </div>
                  <p className={styles['withdraw__text--disabled']}>{t('FIRST_WITHDRAWAL.minimum')}</p>
                </div>

                <RangeInputPure
                  marksArray={list}
                  disabled={!email_confirmed}
                  sliderWidth={sliderWidth}
                  totalLimit={totalLimit}
                  availableLimit={availableLimit}
                  usedLimit={usedLimit}
                  step={percentAdapter(step)}
                  value={percentAdapter(sliderValue)}
                  setter={valueSetter}
                />
              </>
            )
          ) : (
            [...Array(1)].map((elem, idx) => {
              return <SkeletonMUI width={'90%'} key={idx} height={350} className={styles.skeleton} loading />;
            })
          )}
        </>
      )}
    </>
  );
};

export default PrimaryBank;
