import React from 'react';
import styles from './HeaderDropdown.module.scss';
import {mainPath} from '../../routes/paths';
import {NavLink, useHistory} from 'react-router-dom';
import arrows from './images/arrows.png';
import CustomerServiceCard from '../CustomerServiceCard';
import {authPath} from '../../routes/paths';
import {useTranslation} from 'react-i18next';

const HeaderDropdown = ({windowRef, setter}) => {
  const {t} = useTranslation();
  const pagesLinks = [
    {
      title: t('HEADERDROPDOWN.overview'),
      link: mainPath.overview.path
    },
    {
      title: t('HEADERDROPDOWN.invoices'),
      link: mainPath.invoices.path
    },
    {
      title: t('HEADERDROPDOWN.transactions'),
      link: mainPath.transactions.path
    },
    {
      title: t('HEADERDROPDOWN.contracts'),
      link: mainPath.contracts.path
    },
    {
      title: t('HEADERDROPDOWN.settings'),
      link: mainPath.settings.path
    }
  ];
  const subLinks = [
    // {
    //   title: mainPath.customerReviews.name,
    //   link: mainPath.customerReviews.path
    // },
    // {
    //   title: mainPath.responsibleLending.name,
    //   link: mainPath.responsibleLending.path
    // }
  ];
  const history = useHistory();
  const token = sessionStorage.getItem('session_id');
  const logout = () => {
    sessionStorage.clear();
    history.push(authPath.signIn.path);
  };

  return (
    <div ref={windowRef} className={`${styles.root} container`}>
      <div className={styles['triangle']}></div>
      <div className={styles['dropdown__column']}>
        <div className={'menu__links'}>
          {pagesLinks.map((elem, idx) => {
            return (
              <div key={idx} className={styles['menu__link']}>
                <NavLink className={styles['menu__link--href']} onClick={() => setter(false)} to={elem.link}>
                  {elem.title}
                </NavLink>
                <span className={styles['menu__link--shadow']}></span>
              </div>
            );
          })}
        </div>
        {subLinks.length > 0 && (
          <div className={styles['menu__sublinks']}>
            <img className={styles['menu__arrows']} src={arrows} alt='arrows' />
            <div className={styles['menu__sublink--column']}>
              {subLinks.map((elem, idx) => {
                return (
                  <div key={idx} className={styles['menu__sublink']}>
                    <NavLink className={styles['menu__sublink--href']} to={elem.link}>
                      {elem.title}
                    </NavLink>
                    <span className={styles['menu__sublink--shadow']}></span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {!token && (
          <>
            <p className={styles['menu__text']}>{t('HEADERDROPDOWN.already')}</p>
            <button className={styles['menu__button']}> {t('HEADERDROPDOWN.login')}</button>
          </>
        )}
        {token && (
          <>
            {/* <p className={styles['menu__text']}> {t('HEADERDROPDOWN.got')}</p> */}
            <button onClick={() => logout()} className={styles['menu__button']}>
              {t('HEADERDROPDOWN.logout')}
            </button>
          </>
        )}
      </div>

      <div className={`${styles['dropdown__column']} ${styles['service__column']} `}>
        <CustomerServiceCard />
      </div>
    </div>
  );
};

export default HeaderDropdown;
