import React from 'react';
import styles from './BlockSeparator.module.scss';

const BlockSeparator = ({step = 1, color = '', anchor, className}) => {
  return (
    <div id={anchor} className={`${styles.root} ${color}  ${className}`}>
      <div className={styles.circle}>
        <span className={styles.number}>{step}</span>
      </div>
    </div>
  );
};

export default BlockSeparator;
