import React, {useEffect, useState} from 'react';
import styles from './RecentTransactions.module.scss';
import {Link} from 'react-router-dom';
import {mainPath} from '../../../routes/paths';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import dayjs from 'dayjs';
import {useDispatch} from 'react-redux';
import {getListOfTransactions, getRecentTransactions} from '../transactionsActions';
import Selectors from '../Selectors';

import TransactionPopup from '../TransactioPopup';
import {useWindowWidth} from '../../../helpers/hooks';
import {AnimationWrapper, SkeletonMUI, MonthSelector} from '../../../shared';
import {File, MenuIcon} from '../../../assets/icons';
import {CSVLink} from 'react-csv';
import {withdrawal_arr, interest_arr, payment_arr, fees_arr} from '../../../helpers/mockData';

const RecentTransactions = ({type = 'full'}) => {
  //types "full" -- with title & show more btn
  //types "optimised" -- just table
  const {t} = useTranslation();
  const {transactions, recentTransactions, loading, recent_loading} = useSelector(({transaction}) => transaction);
  const [settedTransactionTypes, setTransactionTypes] = useState({
    isWithdrawal: true,
    isPayment: true,
    isInterest: true,
    isPaymentFree: true
  });

  const mapedTransactions =
    type === 'full'
      ? recentTransactions
      : //CHECK BOX FILTER LOGIC
        transactions?.ledger_transactions.filter((item) => {
          if (
            withdrawal_arr.includes(item.transaction_type) ||
            interest_arr.includes(item.transaction_type) ||
            payment_arr.includes(item.transaction_type) ||
            fees_arr.includes(item.transaction_type)
          ) {
            if (settedTransactionTypes.isWithdrawal && withdrawal_arr.includes(item.transaction_type)) {
              return item;
            }
            if (settedTransactionTypes.isInterest && interest_arr.includes(item.transaction_type)) {
              return item;
            }
            if (settedTransactionTypes.isPayment && payment_arr.includes(item.transaction_type)) {
              return item;
            }
            if (settedTransactionTypes.isPaymentFree && fees_arr.includes(item.transaction_type)) {
              return item;
            }
          } else {
            return item;
          }
        });

  const [showFilter, setShowFilter] = useState(false);
  const isLoading = recent_loading || loading;

  function sumTransactionAmounts(transactions) {
    if (!Array.isArray(transactions)) {
      return 0;
    }

    let totalAmount = 0;

    for (const transaction of transactions) {
      totalAmount += transaction.amount;
    }

    return totalAmount;
  }

  let totalAmount = sumTransactionAmounts(mapedTransactions);
  const firstDayOfMonth = new Date();
  firstDayOfMonth.setDate(1);

  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(new Date());
  const [touched, setTouched] = useState(false);
  const utcStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
  const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));

  const queryParams = [`start=${utcStartDate.toISOString()}`, `end=${endDate.toISOString()}`];

  if (touched === 'datepicker' || touched === 'month') {
    if (startDate !== null) {
      queryParams[0] = `start=${utcStartDate.toISOString()}`;
    }

    if (endDate !== null) {
      queryParams[1] = `end=${utcEndDate.toISOString()}`;
    }
  }

  const dateQuery = queryParams.length > 0 ? queryParams.join('&') : null;

  const handleStartDate = (e, selector = 'datepicker') => {
    setStartDate(e);
    setTouched(selector);
  };
  const handleEndDate = (e, selector = 'datepicker') => {
    setEndDate(e);
    setTouched(selector);
  };
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const dispatch = useDispatch();

  useEffect(() => {
    //HERE WE IMPLEMENTED LOGIC : Transaction page default value should be the customers latest ledgeroperations date. Example if customers latest operation was in January it should display that month rather than current month if current month does not have operations yet.

    dispatch(getRecentTransactions('?transaction_count=10')).then((res) => {
      if (res?.payload?.status === 200) {
        let firstDayOfMonth;

        if (res && res?.payload && res?.payload?.data && res?.payload?.data[0]) {
          firstDayOfMonth = new Date(res.payload.data[0].date);
          setCurrentMonth(firstDayOfMonth);
        } else {
          const currentDate = new Date();
          firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        }

        firstDayOfMonth.setDate(1);
        setStartDate(firstDayOfMonth);
      }
    });
  }, []);

  useEffect(() => {
    type !== 'full' && dispatch(getListOfTransactions(dateQuery, ''));
  }, [dateQuery]);

  const width = useWindowWidth();

  function transformToCSV(ledger_transactions) {
    const csvData = [['№', 'Date', 'Transaction type', 'Amount, kr']];
    let rowNum = 1;
    let totalAmount = 0;

    for (const transaction of ledger_transactions) {
      csvData.push([
        rowNum.toString(),
        dayjs(transaction.date).format('DD.MM.YYYY HH:mm'),
        transaction.transaction_type.replace(/_/g, ' '),
        transaction.amount.toFixed(2)
      ]);
      totalAmount += transaction.amount;
      rowNum++;
    }

    // Add a row for the total amount
    csvData.push(['', 'Total', '', totalAmount.toFixed(2)]);

    return csvData;
  }

  const ledger_transactions = (transactions?.ledger_transactions && mapedTransactions) || [];
  const csvData = transformToCSV(ledger_transactions);

  return (
    <AnimationWrapper>
      <div className={`${styles.root} ${type !== 'full' && styles.root__full}`}>
        {
          <h2 className={`${styles['transaction__title']} block__title`}>
            {t(type === 'full' ? 'RECENTTRANSACTIONS.title' : 'RECENTTRANSACTIONS.finder')}
          </h2>
        }

        <div className={styles.container}>
          {type !== 'full' && (
            <div className={styles.filterbar}>
              <MonthSelector
                setEndDate={handleEndDate}
                setStartDate={handleStartDate}
                queryParams={queryParams}
                setTouched={setTouched}
                touched={touched}
                currentMonth={currentMonth}
                setCurrentMonth={setCurrentMonth}
              />

              <button className={styles['filterbar__button']} onClick={() => setShowFilter(!showFilter)}>
                <p
                  style={{
                    width: !showFilter
                      ? width > 768
                        ? '157px'
                        : width > 390
                        ? '130px'
                        : '60px'
                      : width < 390
                      ? '60px'
                      : '100px'
                  }}
                >
                  {t(
                    !showFilter
                      ? width > 390
                        ? 'RECENTTRANSACTIONS.filters'
                        : 'RECENTTRANSACTIONS.filters_s'
                      : width > 390
                      ? 'RECENTTRANSACTIONS.hide'
                      : 'RECENTTRANSACTIONS.hide_s'
                  )}
                </p>
                <MenuIcon />
              </button>
            </div>
          )}
          {type !== 'full' && (
            <Selectors
              endDate={endDate}
              startDate={startDate}
              showFilter={showFilter}
              handleEndDate={handleEndDate}
              handleStartDate={handleStartDate}
              settedTransactionTypes={settedTransactionTypes}
              setTransactionTypes={setTransactionTypes}
            />
          )}

          {!isLoading && mapedTransactions?.length > 0 && (
            <table className={`${styles['table']} ${type === 'full' && styles['table__overview']}`}>
              <thead className={styles['table__head']}>
                <tr className={styles['table__head--row']}>
                  <th className={styles['table__head--char']}>{t('RECENTTRANSACTIONS.date')}</th>
                  <th className={styles['table__head--char']}>{t('RECENTTRANSACTIONS.transaction')}</th>
                  <th className={styles['table__head--char']}>{t('RECENTTRANSACTIONS.amount')}</th>
                </tr>
              </thead>

              <tbody className={styles['table__body']}>
                {mapedTransactions.map(({date, amount, details, transaction_type}, idx) => {
                  return (
                    <tr id={`row__${idx}`} key={idx} className={styles['table__body--row']}>
                      <td
                        className={`${styles['table__body--char']} ${
                          transaction_type.replace(/_/g, ' ') === 'payment' ? styles.comes : styles.ledger
                        }`}
                      >
                        {dayjs(date).format('DD.MM.YYYY ')} <br />{' '}
                        <span>
                          {/* {returnTransactionType(transaction_type, t)} */}
                          {transaction_type.replace(/_/g, ' ')}
                        </span>
                      </td>

                      <td className={styles['table__body--char']}>
                        {transaction_type.replace(/_/g, ' ')}
                        {/* {returnTransactionType(transaction_type, t)} */}
                      </td>

                      <td className={`${styles['table__body--char']} ${transaction_type === 'payment' && 'green'}`}>
                        {amount.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
                        {/* Withdrawal and payment fee month are just single lines :JOONAS */}
                        {type !== 'full' && (width < 576 ? transaction_type !== 1 && transaction_type !== 6 : true) && (
                          <TransactionPopup
                            idx={idx}
                            transaction_type={transaction_type}
                            date={date}
                            details={details}
                            amount={amount}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          {type !== 'full' && mapedTransactions?.length === 0 && !isLoading && (
            <p className={styles.empty2}>{t('RECENTTRANSACTIONS.empty')}</p>
          )}

          {!isLoading && type !== 'full' && (
            <div className={styles.table__total}>
              <p>{t('RECENTTRANSACTIONS.total')}</p>
              <p> {totalAmount.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}</p>
            </div>
          )}
          {!isLoading && type !== 'full' && (
            <CSVLink
              filename={`Transaction report ${dayjs(startDate).format('DD.MM.YYYY ')}-${dayjs(endDate).format(
                'DD.MM.YYYY'
              )}.csv`}
              data={csvData}
              className={`${styles.table__export} ${
                type !== 'full' && mapedTransactions?.length === 0 && styles.export__disabled
              }`}
            >
              <File />
              <p>{t('RECENTTRANSACTIONS.export')}</p>
            </CSVLink>
          )}

          {type === 'full' && mapedTransactions?.length === undefined && !isLoading && (
            <p className={styles.empty_2}>{t('RECENTTRANSACTIONS.empty')}</p>
          )}

          {isLoading &&
            [...Array(4)].map((elem, idx) => {
              return <SkeletonMUI key={idx} height={44} className={styles.skeleton} loading />;
            })}

          {type === 'full' && mapedTransactions?.length > 0 && (
            <Link className={styles.transactions} to={mainPath.transactions.path}>
              {t('RECENTTRANSACTIONS.all')}
            </Link>
          )}
        </div>
      </div>
    </AnimationWrapper>
  );
};

export default RecentTransactions;
