import * as yup from 'yup';

export const formSchema = yup.object().shape({
  allow_email_marketing: yup.boolean().required(),
  allow_sms_marketing: yup.boolean().required(),
  amount: yup.number().min(5000).required(),

  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required')
    .min(5, 'Email must be at least 5 characters')
    .max(255, 'Email must be at most 255 characters')
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email domain'),
  // household_debt: yup.number().typeError('Current field is required').required(),
  //total_income: yup.number().typeError('Current field is required').required(),
  how_did_you_find_us: yup.string().required(),
  loan_usage: yup.string().required(),

  // loans_amount: yup.number().when('loans_exists', (loans_exists, schema) => {
  //   return loans_exists ? schema.required() : schema;
  // }),

  //loans_exists: yup.boolean().required(),
  number_of_adults: yup.number().required(),
  number_of_minors: yup.string().required(),

  people: yup.array().of(
    yup.object().shape({
      // Define the schema for the 'people' array elements if needed.
    })
  ),

  household_debt: yup
    .number()
    .integer('No decimals allowed, please round to the closest full kr')
    .min(0, 'Negative values are not allowed.')
    .test('is-integer', 'No decimals allowed, please round to the closest full kr', function (value) {
      if (value !== undefined && value !== null) {
        return Number.isInteger(value);
      }
      return true;
    })
    .typeError('Current field is required'),

  household_expenses_monthly_expenses: yup
    .number()
    .integer('No decimals allowed, please round to the closest full kr')
    .min(0, 'Negative values are not allowed.')
    .test('is-integer', 'No decimals allowed, please round to the closest full kr', function (value) {
      if (value !== undefined && value !== null) {
        return Number.isInteger(value);
      }
      return true;
    })
    .typeError('Current field is required'),

  contracts_amount: yup
    .number()
    .integer('No decimals allowed, please round to the closest full kr')
    .min(0, 'Negative values are not allowed.')
    .test('is-integer', 'No decimals allowed, please round to the closest full kr', function (value) {
      if (value !== undefined && value !== null) {
        return Number.isInteger(value);
      }
      return true;
    })
    .typeError('Current field is required'),

  loans_amount: yup
    .number()
    .integer('No decimals allowed, please round to the closest full kr')
    .min(0, 'Negative values are not allowed.')
    .test('is-integer', 'No decimals allowed, please round to the closest full kr', function (value) {
      if (value !== undefined && value !== null) {
        return Number.isInteger(value);
      }
      return true;
    })
    .required()
    .typeError('Current field is required'),

  assets: yup
    .number()
    .integer('No decimals allowed, please round to the closest full kr')
    .min(0, 'Negative values are not allowed.')
    .test('is-integer', 'No decimals allowed, please round to the closest full kr', function (value) {
      if (value !== undefined && value !== null) {
        return Number.isInteger(value);
      }
      return true;
    })
    .typeError('Current field is required')
    .required(),

  personal_expenses_monthly_expenses: yup
    .number()
    .integer('No decimals allowed, please round to the closest full kr')
    .min(0, 'Negative values are not allowed.')
    .test('is-integer', 'No decimals allowed, please round to the closest full kr', function (value) {
      if (value !== undefined && value !== null) {
        return Number.isInteger(value);
      }
      return true;
    })
    .typeError('Current field is required')
    .required(),

  personal_income_monthly_gross_income: yup
    .number()
    .integer('No decimals allowed, please round to the closest full kr')
    .min(0, 'Negative values are not allowed.')
    .typeError('Current field is required')
    .moreThan(
      yup.ref('personal_income_monthly_net_income'),
      'Personal monthly NET income should be less than the personal monthly GROSS income'
    ),

  co_borrower_amount: yup
    .number()
    .integer('No decimals allowed, please round to the closest full kr')
    .min(0, 'Negative values are not allowed.')
    .typeError('Current field is required'),

  household_income_monthly_net_income: yup
    .number()
    .integer('No decimals allowed, please round to the closest full kr')
    .min(0, 'Negative values are not allowed.')
    .typeError('Current field is required'),

  personal_income_monthly_net_income: yup
    .number()
    .typeError('Current field is required')
    .integer('No decimals allowed, please round to the closest full kr')
    .min(0, 'Negative values are not allowed.')

    .lessThan(
      yup.ref('household_income_monthly_net_income'),
      'Personal monthly NET income should be less than the household’s total NET income'
    )
    .lessThan(
      yup.ref('personal_income_monthly_gross_income'),
      'Personal monthly NET income should be less than the personal monthly GROSS income'
    ),

  phone: yup
    .string()
    .matches(/^(0?70\d{7})$/, 'Enter a valid phone number')
    .required('Field is required'),

  post_code: yup.string().required(),

  sms_marketing: yup.string().required(),
  street: yup.string().required(),
  city: yup.string().required()
});
