import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {StyledEngineProvider} from '@mui/material/styles';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import {history, setupStore} from './app/store';
import App from './app/App';
import './i18n';
import {QueryClient, QueryClientProvider} from 'react-query';
import {axiosInstance} from './app/store';

export const store = setupStore();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      axios: axiosInstance,
      staleTime: 1 * (60 * 1000), // 1 mins
      cacheTime: 5 * (60 * 1000) // 5 mins
    },
    mutations: {
      axios: axiosInstance
    }
  }
});

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
