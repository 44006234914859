import React from 'react';
import styles from './DataCollection.module.scss';
import {User, Lock} from '../../assets/icons';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {creditAppPath} from '../../routes/paths';
import {useDispatch} from 'react-redux';
import {setLoader} from '../CreditForm/creditFormActions';

const DataCollection = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className={`${styles.root} container`}>
      <div className={styles.container}>
        <h2 className={styles.title}>{t('DATA_COLLECTION.title')}</h2>

        <div className={styles['textblock']}>
          <div className={styles['textblock__texts']}>
            <p className={styles['textblock__text']}>{t('DATA_COLLECTION.text_1')} </p>
            <h3 className={`${styles['textblock__title']} mt12`}>{t('DATA_COLLECTION.title_1')}</h3>
            <p className={styles['textblock__text']}>{t('DATA_COLLECTION.text_2')} </p>
            <h3 className={`${styles['textblock__title']} mt12`}>{t('DATA_COLLECTION.title_2')}</h3>
            <p className={styles['textblock__text']}>{t('DATA_COLLECTION.text_3')} </p>
            <h3 className={`${styles['textblock__title']} mt12`}>{t('DATA_COLLECTION.title_3')}</h3>
            <p className={styles['textblock__text']}>{t('DATA_COLLECTION.text_4')} </p>
          </div>
        </div>

        <Link className={`${styles.continue} main__button`} to={creditAppPath.tinkPath.path}>
          {t('DATA_COLLECTION.back')}
        </Link>
      </div>
    </div>
  );
};

export default DataCollection;
