export const AUTH = {
  SIGN_IN: 'SIGN_IN',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAIL: 'SIGN_IN_FAIL',

  POST_AUTH_AUTHOSTART: 'POST_AUTH_AUTHOSTART',
  POST_AUTH_AUTHOSTART_SUCCESS: 'POST_AUTH_AUTHOSTART_SUCCESS',
  POST_AUTH_AUTHOSTART_FAIL: 'POST_AUTH_AUTHOSTART_FAIL',

  GET_ANIMATED_QR: 'GET_ANIMATED_QR',
  GET_ANIMATED_QR_SUCCESS: 'GET_ANIMATED_QR_SUCCESS',
  GET_ANIMATED_QR_FAIL: 'GET_ANIMATED_QR_FAIL',

  GET_COLLECT: 'GET_COLLECT',
  GET_COLLECT_SUCCESS: 'GET_COLLECT_SUCCESS',
  GET_COLLECT_FAIL: 'GET_COLLECT_FAIL',

  CREATE_SESSION_WITH_BANK_ID: 'CREATE_SESSION_WITH_BANK_ID',
  CREATE_SESSION_WITH_BANK_ID_SUCCESS: 'CREATE_SESSION_WITH_BANK_ID_SUCCESS',
  CREATE_SESSION_WITH_BANK_ID_FAIL: 'CREATE_SESSION_WITH_BANK_ID_FAIL',

  REFRESH_SESSION: 'REFRESH_SESSION',
  REFRESH_SESSION_SUCCESS: 'REFRESH_SESSION_SUCCESS',
  REFRESH_SESSION_FAIL: 'REFRESH_SESSION_FAIL',

  GET_CANCEL: 'GET_CANCEL',
  GET_CANCEL_SUCCESS: 'GET_CANCEL_SUCCESS',
  GET_CANCEL_FAIL: 'GET_CANCEL_FAIL',

  SET_IP_ADDRESS: 'SET_IP_ADDRESS',

  SET_TOAST_PARAMS: 'SET_TOAST_PARAMS',

  RESET_STORE: 'RESET_STORE',

  EMAIL_CONFIRMATION: 'EMAIL_CONFIRMATION',
  EMAIL_CONFIRMATION_SUCCESS: 'EMAIL_CONFIRMATION_SUCCESS',
  EMAIL_CONFIRMATION_FAIL: 'EMAIL_CONFIRMATION_FAIL'
};
