import React from 'react';
import styles from './InputField.module.scss';

const InputField = ({label, type = 'text', inputProps, className, disabled, placeholder, onChange, subtitle}) => {
  return (
    <div className={`${styles.root} ${className}`}>
      {label && (
        <label className={styles.label} htmlFor={label}>
          {label}
        </label>
      )}
      <input
        type={type}
        className={styles.input}
        placeholder={placeholder}
        disabled={disabled}
        id={label}
        onChange={onChange}
        {...inputProps}
      ></input>
      {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
    </div>
  );
};

export default InputField;
