import React, {useState} from 'react';
import styles from './WithdrawAccount.module.scss';
import {RangeInput} from '../../../shared';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {InputMUI, ToastComponent, SkeletonMUI, AnimationWrapper} from '../../../shared';
import {useTranslation} from 'react-i18next';
import {useWindowWidth, useToggle} from '../../../helpers/hooks';
import WithdrawalPopup from '../../../shared/WithdrawalPopup';
import {useDispatch} from 'react-redux';
import {sendNewWithdrawal} from '../withdrawalActions';
import {useQuery} from 'react-query';
import {axiosInstance} from '../../../app/store';

const WithdrawAccount = ({disabled}) => {
  const [sliderValue, setSliderValue] = useState(500);
  const [showToast, setShowToast] = useToggle(false);
  const [toastType, setToastType] = useState({isSuccess: null, title: null, description: null});
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {data, isLoading} = useQuery('ledgers', async () => {
    const response = await axiosInstance.get('ledgers/GetLedger');
    return response.data;
  });

  const availableLimit = data?.available_balance || 18000;

  const step = 500;

  const percentAdapter = (value) => {
    return (value * 100) / availableLimit;
  };

  const WithdrawAccountSchema = yup.object({
    amount: yup.number().min(500, 'Minimun value 500').max(availableLimit, `Maximum ${availableLimit}`)
  });

  const {
    control,
    setValue,
    trigger,
    getValues,
    formState: {errors, isValid}
  } = useForm({
    mode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(WithdrawAccountSchema),
    defaultValues: {
      amount: 500
    }
  });

  const valueSetter = (value) => {
    setSliderValue(+value);
    setValue('amount', +value);
    trigger('amount');
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value > data?.available_balance) {
      setSliderValue(data?.available_balance);
      setValue('amount', +data?.available_balance);
      trigger('amount');
    } else {
      setSliderValue(+value);
    }
  };

  const width = useWindowWidth();
  const sliderWidth = width > 978 ? 700 : width * 0.78;
  const [dialog, toggleDialog] = useToggle(false);

  const handleNewWithdrawal = () => {
    dispatch(sendNewWithdrawal({amount: getValues('amount')})).then((res) => {
      if (res?.payload?.status === 200) {
        const {status} = res.payload.data;
        //SHOW ERROR TOAST
        if (status === 'Rejected') {
          setToastType({
            isSuccess: false,
            title: 'The transaction was rejected',
            description: 'Please try again later or contact customer service.'
          });
          setShowToast();
        }
        //SHOW POPUP
        if (status === 'WaitingForTransfer') {
          toggleDialog();
        }
        //SHOW POPUP
        if (status === 'WaitingForManual') {
          //[12:44] Felix Wahlsberg so customer needs to come back to front page and get a notification that "hey you have a withdrawal waiting for confirmation"
          // () => toggleDialog();
          setToastType({
            isSuccess: true,
            title: 'Attention',
            description: 'Your withdrawal is waiting for confirmation from Flexlimit'
          });
        }
      }
    });
  };

  return (
    <>
      <ToastComponent
        isFixed
        isSuccess={toastType.isSuccess}
        visible={showToast}
        setter={setShowToast}
        title={toastType.title}
        description={toastType.description}
      />

      <AnimationWrapper>
        <div className={styles.root}>
          <h2 className={`${styles['withdraw__title']} block__title`}>
            {t('WITHDRAWACCOUNT.withdraw')} <br /> {t('WITHDRAWACCOUNT.account')}
          </h2>

          <div className={styles.container}>
            {' '}
            <div className={styles['withdraw__columns']}>
              <div className={styles['withdraw__column']}>
                <div className={styles['withdraw__input']}>
                  <Controller
                    name='amount'
                    control={control}
                    render={({field}) => (
                      <InputMUI
                        disabled={disabled}
                        onChange={(e) => handleInputChange(e)}
                        className={styles['withdraw__input--field']}
                        placeholder='500'
                        variant='standard'
                        type='number'
                        label={t('WITHDRAWACCOUNT.amount')}
                        fullWidth
                        error={errors.amount?.message}
                        inputProps={field}
                      />
                    )}
                  />
                  <span className={styles['withdraw__input--currency']}>kr</span>
                </div>
                <p className={styles['withdraw__text']}>{t('WITHDRAWACCOUNT.enter')}</p>
              </div>
              <div className={styles['withdraw__column']}>
                {!isLoading ? (
                  <>
                    <div className={styles['withdraw__column--container']}>
                      <p className={styles['withdraw__column--title']}>{t('WITHDRAWACCOUNT.available')}</p>
                      <span className={styles['withdraw__column--price']}>
                        {data?.available_balance &&
                          data?.available_balance.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}{' '}
                      </span>
                    </div>
                    <div className={styles['withdraw__column--container']}>
                      <p className={styles['withdraw__column--title']}>{t('WITHDRAWACCOUNT.used')}</p>
                      <span className={styles['withdraw__column--price']}>
                        {data?.principal_used &&
                          data?.principal_used.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}{' '}
                      </span>
                    </div>
                    <div className={styles['withdraw__column--container']}>
                      <p className={styles['withdraw__column--title']}>{t('WITHDRAWACCOUNT.total')}</p>
                      <span className={styles['withdraw__column--price']}>
                        {data?.credit_limit &&
                          data?.credit_limit.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}{' '}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    {[...Array(3)].map((elem, idx) => {
                      return <SkeletonMUI key={idx} height={60} className={styles.skeleton} loading />;
                    })}
                  </>
                )}
              </div>
            </div>
            <RangeInput
              disabled={disabled}
              sliderWidth={sliderWidth}
              totalLimit={data?.credit_limit}
              availableLimit={data?.available_balance}
              usedLimit={data?.principal_used}
              step={percentAdapter(step)}
              value={percentAdapter(sliderValue)}
              setter={valueSetter}
            />
            <div className={styles['withdraw__elems']}>
              {!isLoading ? (
                <>
                  <div className={styles['withdraw__elems--container']}>
                    <p className={styles['withdraw__elems--title']}>{t('WITHDRAWACCOUNT.available_s')}</p>
                    <span className={styles['withdraw__elems--price']}>
                      {data?.available_balance &&
                        data?.available_balance.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
                    </span>
                  </div>
                  <div className={styles['withdraw__elems--container']}>
                    <p className={styles['withdraw__elems--title']}>{t('WITHDRAWACCOUNT.used_s')}</p>
                    <span className={styles['withdraw__elems--price']}>
                      {data?.principal_used &&
                        data?.principal_used.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
                    </span>
                  </div>
                  <div className={styles['withdraw__elems--container']}>
                    <p className={styles['withdraw__elems--title']}>{t('WITHDRAWACCOUNT.total_s')}</p>
                    <span className={styles['withdraw__elems--price']}>
                      {data?.credit_limit &&
                        data?.credit_limit.toLocaleString('sv-SE', {
                          style: 'currency',
                          currency: 'SEK',
                          maximumFractionDigits: 0
                        })}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  {[...Array(3)].map((elem, idx) => {
                    return <SkeletonMUI key={idx} height={60} className={styles.skeleton} loading />;
                  })}
                </>
              )}
            </div>
            <button
              disabled={disabled}
              onClick={handleNewWithdrawal}
              className={`${styles['withdraw__button']} main__button`}
            >
              {t('WITHDRAWACCOUNT.make')}
            </button>
          </div>

          <WithdrawalPopup
            setToastType={setToastType}
            showToast={setShowToast}
            dialog={dialog}
            toggleDialog={toggleDialog}
          />
        </div>
      </AnimationWrapper>
    </>
  );
};

export default WithdrawAccount;
