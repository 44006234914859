import * as yup from 'yup';
const CountryRegex = /^07\d{8}$|^70\d{7}|^56\d{9}$/;
//const CountryRegex = /^\d{9}$/;

export const settingsSchema = yup.object({
  street: yup.string().required('Field is required'),
  city: yup.string().required('Field is required'),
  email_address: yup.string().email('Enter a valid email').required('Field is required'),
  phone: yup.string().matches(CountryRegex, 'Enter a valid phone number').required('Field is required'),
  //account: yup.string().required('Field is required'),
  postal_code: yup.string().required('Field is required'),
  email_marketing: yup.boolean().required('Field is required'),
  sms_marketing: yup.boolean().required('Field is required'),
  invoice_expiry_day: yup.number()
});
