export const CREDIT_FORM = {
  SET_APP_FIRST_CIRCLE: 'SET_APP_FIRST_CIRCLE',
  SET_APP_SECOND_CIRCLE: 'SET_APP_SECOND_CIRCLE',
  SET_APP_THIRD_CIRCLE: 'SET_APP_THIRD_CIRCLE',
  SET_APP_FOURTH_CIRCLE: 'SET_APP_FOURTH_CIRCLE',
  SET_APP_SEVENTH_CIRCLE: 'SET_APP_SEVENTH_CIRCLE',
  SET_APP_EIGHTS_CIRCLE: 'SET_APP_EIGHTS_CIRCLE',
  SHOW_WITHDRAWAL_CIRCLES: 'SHOW_WITHDRAWAL_CIRCLES',

  SET_TIN_LOADER: 'SET_TIN_LOADER',

  UPDATE_COSTUMER_DETAIL: 'UPDATE_COSTUMER_DETAIL',
  UPDATE_COSTUMER_DETAIL_FAIL: 'UPDATE_COSTUMER_DETAIL_FAIL',
  UPDATE_COSTUMER_DETAIL_SUCCESS: 'UPDATE_COSTUMER_DETAIL_SUCCESS',

  SUBMIT_APP: 'SUBMIT_APP',
  SUBMIT_APP_FAIL: 'SUBMIT_APP_FAIL',
  SUBMIT_APP_SUCCESS: 'SUBMIT_APP_SUCCESS',

  SET_PSD2_FOR_APP: 'SET_PSD2_FOR_APP',
  SET_PSD2_FOR_APP_FAIL: 'SET_PSD2_FOR_APP_FAIL',
  SET_PSD2_FOR_APP_SUCCESS: 'SET_PSD2_FOR_APP_SUCCESS',

  GET_APP_STATUS: 'GET_APP_STATUS',
  GET_APP_STATUS_FAIL: 'GET_APP_STATUS_FAIL',
  GET_APP_STATUS_SUCCESS: 'GET_APP_STATUS_SUCCESS',

  CANCEL_APP: 'CANCEL_APP',
  CANCEL_APP_FAIL: 'CANCEL_APP_FAIL',
  CANCEL_APP_SUCCESS: 'CANCEL_APP_SUCCESS',

  ABANDON_APP: 'ABANDON_APP',
  ABANDON_APP_FAIL: 'ABANDON_APP_FAIL',
  ABANDON_APP_SUCCESS: 'ABANDON_APP_SUCCESS',

  GET_INCOMPLETE_DATA: 'GET_INCOMPLETE_DATA',
  GET_INCOMPLETE_DATA_FAIL: 'GET_INCOMPLETE_DATA_FAIL',
  GET_INCOMPLETE_DATA_SUCCESS: 'GET_INCOMPLETE_DATA_SUCCESS',

  SAVE_FORM: 'SAVE_FORM',
  SAVE_FORM_FAIL: 'SAVE_FORM_FAIL',
  SAVE_FORM_SUCCESS: 'SAVE_FORM_SUCCESS',

  SUBMIT_APP: 'SUBMIT_APP',
  SUBMIT_APP_FAIL: 'SUBMIT_APP_FAIL',
  SUBMIT_APP_SUCCESS: 'SUBMIT_APP_SUCCESS',

  CONFIRM_APPLICATION: 'CONFIRM_APPLICATION',
  CONFIRM_APPLICATION_FAIL: 'CONFIRM_APPLICATION_FAIL',
  CONFIRM_APPLICATION_SUCCESS: 'CONFIRM_APPLICATION_SUCCESS',

  GET_APPLICATION_CONFIRMATION: 'GET_APPLICATION_CONFIRMATION',
  GET_APPLICATION_CONFIRMATION_FAIL: 'GET_APPLICATION_CONFIRMATION_FAIL',
  GET_APPLICATION_CONFIRMATION_SUCCESS: 'GET_APPLICATION_CONFIRMATION_SUCCESS',

  GET_WITHDRAWAL_CONFIRMATION: 'GET_WITHDRAWAL_CONFIRMATION',
  GET_WITHDRAWAL_CONFIRMATION_FAIL: 'GET_WITHDRAWAL_CONFIRMATION_FAIL',
  GET_WITHDRAWAL_CONFIRMATION_SUCCESS: 'GET_WITHDRAWAL_CONFIRMATION_SUCCESS'
};
