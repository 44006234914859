import React from 'react';
import styles from './Transactions.module.scss';
import RecentTransactions from './RecentTransactions';
import SideBar from '../SideBar';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {CreditAccountCard, CustomerServiceCard} from '../../shared';
import {useWindowWidth} from '../../helpers/hooks';
import TransPageToast from '../../shared/TransPageToast/TransPageToast';

const Transactions = () => {
  const {t} = useTranslation();
  const {is_menu_shown, guide_step} = useSelector((state) => state.settings);
  const width = useWindowWidth();
  const need_tour = JSON.parse(localStorage.getItem('react_tour_data'))?.need_tour || false;

  const showBlur = need_tour && guide_step === 4;

  return (
    <>
      <div className={'main__headers'}>
        <div className='container'>
          <h1 className={`${styles['transactions__title']} main__title `}>{t('TRANSACTIONS.title')}</h1>
          <p className={`${styles['transactions__description']} main__description`}>
            {t('TRANSACTIONS.description1')}
            <br />
            {t('TRANSACTIONS.description2')}
          </p>
        </div>
      </div>
      <main className={`${styles.root} ${is_menu_shown && 'main__blured'} main__container container`}>
        <TransPageToast />

        <div className={`${styles['transactions__main']} main__grid`}>
          <div className={`${styles['transactions__column']} main__column ${showBlur && 'main__tourblur'}`}>
            <RecentTransactions type={'optimised'} />
          </div>
          <SideBar />
          {width <= 1000 && (
            <>
              <div className={styles.under}>
                <CreditAccountCard />
              </div>
              <div>
                <CustomerServiceCard type='sidebar' />
              </div>
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default Transactions;
