import React, {useState, useEffect} from 'react';
import styles from './CreditForm.module.scss';
import {CheckboxMUI} from '../../shared';
import BlockSeparator from './BlockSeparator';
import {useTranslation} from 'react-i18next';
import {setAppFourthCircle} from './creditFormActions';
import {useDispatch, useSelector} from 'react-redux';
import {RangeInputPure, InputMUI} from '../../shared';
import {useWindowWidth} from '../../helpers/hooks';
import {Controller} from 'react-hook-form';
import {updateCustomerDetails} from './creditFormActions';
import {useDebounce} from '../../helpers/hooks';
import {useLocation, useHistory} from 'react-router-dom';

const StepFour = ({accept, setAccept, trigger, setValue, errors, control}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {fourth_circle, costumer_details} = useSelector(({credit_form}) => credit_form);
  const {credits_list} = useSelector(({invoices}) => invoices);
  const {search} = useLocation();
  const color = !accept ? 'green' : 'gray';
  //FROM APPLY FORM REDIRECT WITH QUERY SLIDER VALUE
  const querySliderValue = search.split('=')[1] || 5000;
  const history = useHistory();
  const usedLimit = 0;
  const width = useWindowWidth();

  let sliderWidth;

  switch (true) {
    case width > 978:
      sliderWidth = 350;
      break;
    case width < 410:
      sliderWidth = 280;
      break;
    default:
      sliderWidth = 350 * 0.78;
      break;
  }

  const valueSetter = (value) => {
    setSliderValue(+value.toFixed());
    setValue('amount', +value.toFixed());
    trigger('amount');
  };

  const handleUpdateInfo = (body, name) => {
    !errors[name] &&
      costumer_details &&
      dispatch(
        updateCustomerDetails({
          id: costumer_details?.id,
          ...body
        })
      ).then((res) => {
        const updatedUrl = new URL(window.location.href);

        updatedUrl.searchParams.set('amount', body.product.limit);
        // Update the URL without reloading the page
        history.push({
          pathname: updatedUrl.pathname,
          search: updatedUrl.search
        });
      });
  };

  const findMaxMinAmount = (arr) => {
    let minLowAmount = Infinity;
    let maxHighAmount = -Infinity;

    for (const obj of arr) {
      if (obj['low_amount'] < minLowAmount) {
        minLowAmount = obj['low_amount'];
      }
      if (obj['high_amount'] > maxHighAmount) {
        maxHighAmount = obj['high_amount'];
      }
    }

    return {minLowAmount, maxHighAmount};
  };

  const sliderValues = credits_list?.length > 0 && findMaxMinAmount(credits_list);

  const totalLimit = sliderValues?.maxHighAmount || 10000;
  const availableLimit = sliderValues?.maxHighAmount || 10000;
  const minValues = sliderValues?.minLowAmount || 500;

  const [sliderValue, setSliderValue] = useState(
    // costumer_details?.product?.limit ||
    querySliderValue
  );

  const debouncedSliderAdapter = useDebounce(sliderValue, 300);

  useEffect(() => {
    sliderValue !== totalLimit &&
      sliderValue > totalLimit &&
      handleUpdateInfo(
        {
          product: {
            limit: totalLimit
          }
        },
        'personal_income_monthly_gross_income'
      );
    sliderValue !== minValues &&
      sliderValue < minValues &&
      handleUpdateInfo(
        {
          product: {
            limit: minValues
          }
        },
        'personal_income_monthly_gross_income'
      );

    if (sliderValue >= 500) {
      let remainder = sliderValue % 500;
      let roundedValue;
      if (remainder >= 250) {
        roundedValue = sliderValue - remainder + 500;
      } else {
        roundedValue = sliderValue - remainder;
      }

      if (sliderValue >= minValues && sliderValue <= totalLimit) {
        handleUpdateInfo(
          {
            product: {
              limit: roundedValue
            }
          },
          'personal_income_monthly_gross_income'
        );
      }
    }
  }, [debouncedSliderAdapter]);

  useEffect(() => {
    if (costumer_details?.product?.limit) {
      setSliderValue(costumer_details?.product?.limit);
      setValue('amount', +costumer_details?.product?.limit);
      trigger('amount');
    } else {
      setValue('amount', +querySliderValue);
    }
  }, [costumer_details, setValue, trigger]);

  useEffect(() => {
    if (querySliderValue) {
      setSliderValue(querySliderValue);
      setValue('amount', +querySliderValue);
      trigger('amount');

      handleUpdateInfo(
        {
          product: {
            limit: querySliderValue
          }
        },
        'personal_income_monthly_gross_income'
      );
    }
  }, [credits_list]);

  const percentAdapter = (value) => {
    const lowerBound = 500;
    const upperBound = totalLimit;

    if (+value === +lowerBound) {
      return 0;
    } else if (+value >= +upperBound) {
      return 100;
    } else if (value > lowerBound && value < upperBound) {
      return (value * 100) / availableLimit;
    }
  };

  return (
    <>
      <BlockSeparator className={'mt40'} anchor={'spep_4'} color={fourth_circle} step={4} />
      <h3 className={styles.accept}>{t('CREDIT_FORM.confirm')}</h3>
      <p className={`${styles['block__subtitle']} small`}>{t('CREDIT_FORM.are_you_happy')}</p>
      <h3 className={styles.accept}>{t('CREDIT_FORM.accept')}</h3>
      <div className={styles['withdraw__column']}>
        <div className={styles['withdraw__input']}>
          <Controller
            name='amount'
            control={control}
            render={({field}) => (
              <InputMUI
                onChange={(e) => setSliderValue(e.target.value)}
                className={styles['withdraw__input--field']}
                placeholder={'500'}
                variant='standard'
                type='number'
                label={t('WITHDRAWACCOUNT.amount')}
                fullWidth
                error={errors.amount?.message}
                inputProps={field}
              />
            )}
          />

          <span className={styles['withdraw__input--currency']}>kr</span>
        </div>
        <p className={styles['withdraw__text']}>{t('CREDIT_FORM.enter')}</p>
      </div>
      {sliderValue && (
        <RangeInputPure
          sliderWidth={sliderWidth}
          totalLimit={totalLimit}
          availableLimit={availableLimit}
          usedLimit={usedLimit}
          step={(500 * 100) / totalLimit}
          value={percentAdapter(sliderValue)}
          setter={valueSetter}
        />
      )}
      <div className={`${styles.accept__terms}`}>
        <CheckboxMUI
          id='checkbox-alarm'
          onChange={() => {
            dispatch(setAppFourthCircle(color));
            setAccept(!accept);
          }}
          checked={accept}
          className={`mr6 ${!accept && 'mt5'}`}
        />

        <label htmlFor={'checkbox-alarm'} className={styles.accept__label}>
          {t('CREDIT_FORM.alarm')}
        </label>
      </div>
    </>
  );
};

export default StepFour;
