import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import dayjs from 'dayjs';
import {authPath} from '../routes/paths';

export const joinQueries = (arr) => `${arr.length && arr.length !== 0 ? '?' : ''}${arr.join('&')}`;

export function getOption(label) {
  return (
    <div className={`status ${label}`}>
      <div>
        {label !== 'All networks' && <span />}
        {label}
      </div>
    </div>
  );
}

export function splitByCommas(data) {
  return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const scrollToSmoothly = (options) => {
  const startY = 0;
  const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

  const linearEase = (t) => t;

  const checkWindowWidthAndScroll = () => {
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (windowWidth > 998) {
      // Stop scrolling if window width is more than 998px
      clearInterval(scrollInterval);
      options?.setter && options.setter();
    }
  };

  const animateScroll = () => {
    const currentTime = 'now' in window.performance ? performance.now() : new Date().getTime();
    const timeElapsed = currentTime - startTime;

    const progress = Math.min(1, timeElapsed / options.duration);
    window.scrollTo(0, interpolate(startY, options.top - 400, linearEase(progress)));

    // Check window width every 1000 ms
    checkWindowWidthAndScroll();

    if (progress >= 1) {
      clearInterval(scrollInterval);
      options?.setter && options.setter();
    }
  };

  const interpolate = (start, end, step) => start + step * (end - start);

  const scrollInterval = setInterval(animateScroll, 16); // 60 frames per second

  // Check window width every 1000 ms
  setInterval(checkWindowWidthAndScroll, 1000);
};

export const scrollToTopSmoothly = (options) => {
  const startY = window.scrollY;
  const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

  const easeInOutCubic = (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1);

  const animateScroll = () => {
    const currentTime = 'now' in window.performance ? performance.now() : new Date().getTime();
    const timeElapsed = currentTime - startTime;

    window.scrollTo(0, interpolate(startY, options.top, easeInOutCubic(timeElapsed / options.duration)));

    if (timeElapsed < options.duration) {
      requestAnimationFrame(animateScroll);
    }
  };

  const interpolate = (start, end, step) => start + step * (end - start);

  animateScroll();
};

// Scroll Restoration
export function ScrollToTop() {
  const {pathname} = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

// Convert string to slug
export function stringToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  let from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  let to = 'aaaaaaeeeeiiiioooouuuunc------';

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, ''); // trim - from end of text

  return str;
}

export const generateId = () => Math.floor(Math.random() * Date.now());

export const randomInteger = (max) => Math.floor(Math.random() * (max + 1));

export const randomRgbColor = () => {
  let r = randomInteger(255);
  let g = randomInteger(255);
  let b = randomInteger(255);
  return `rgb(${r}, ${g}, ${b})`;
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

export const dateForQuery = (date) => encodeURIComponent(date?.toISOString().replace('.000Z', ''));

export const formatNumber = (value) =>
  Number(value)
    .toLocaleString('fr', {
      maximumFractionDigits: 2
    })
    .replace(',', '.');

export const isNegative = (num) => Math.sign(num) === -1;

export const capitalize = (string) => string && string.charAt(0).toUpperCase() + string.slice(1);

export const objectToQueryString = (queryParams) => {
  return queryParams
    ? Object.entries(queryParams).reduce((queryString, [key, value]) => {
        const symbol = queryString.length === 0 ? '?' : '&';
        queryString +=
          (value || value === 0) && (typeof value === 'string' || typeof value === 'number')
            ? `${symbol}${key}=${encodeURIComponent(value)}`
            : '';
        return queryString;
      }, '')
    : '';
};

export const scrollToElement = (ref) => ref && ref.current.scrollIntoView({behavior: 'smooth'});

export const classList = (...classes) => classes.filter((item) => !!item).join(' ');

export const formatDate = (date, template = 'DD.MM.YYYY') => (date ? dayjs(date).format(template) : '-');

export const iOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document) ||
    navigator.userAgent.includes('Macintosh')
  );
};

export const onSuccessRedirect = (token, type) => {
  const successPathname = `/bankidcomplete?autostarttoken=${token}&type=${type}`;
  parent.postMessage({successPathname}, '*');
  // window.location.replace(successLink);
};

export const errorOccurred = (message) => {
  return !(
    !message ||
    message === 'Ok' ||
    message === 'outstandingTransaction' ||
    message === 'noClient' ||
    message === 'userSign' ||
    message === 'started'
  );
};

export const handleDownload = (pdf) => {
  // Extract base64-encoded PDF data

  const base64Pdf = pdf?.pdf_data.split(',')[1];

  // Decode base64 data
  const binaryPdf = atob(base64Pdf);

  // Convert binary string to Uint8Array
  const bytes = new Uint8Array(binaryPdf.length);
  for (let i = 0; i < binaryPdf.length; i++) {
    bytes[i] = binaryPdf.charCodeAt(i);
  }

  // Create a Blob from the Uint8Array
  const blob = new Blob([bytes], {type: 'application/pdf'});

  // Create a download link
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = pdf?.file_name;

  // Trigger the download
  link.click();

  // Clean up the URL.createObjectURL
  URL.revokeObjectURL(link.href);
};

export const returnTransactionType = (type, t) => {
  switch (type) {
    case 1:
      return t('TRANSIACTIONPOPUP.transaction_type_1');
    case 2:
      return t('TRANSIACTIONPOPUP.transaction_type_2');
    case 3:
      return t('TRANSIACTIONPOPUP.transaction_type_3');
    case 4:
      return t('TRANSIACTIONPOPUP.transaction_type_4');
    case 5:
      return t('TRANSIACTIONPOPUP.transaction_type_5');
    case 6:
      return t('TRANSIACTIONPOPUP.transaction_type_6');
    case 7:
      return t('TRANSIACTIONPOPUP.transaction_type_7');
    case 8:
      return t('TRANSIACTIONPOPUP.transaction_type_8');
    case 9:
      return t('TRANSIACTIONPOPUP.transaction_type_9');
    case 10:
      return t('TRANSIACTIONPOPUP.transaction_type_10');
    default:
      return t('RECENTTRANSACTIONS.transaction_type_1');
  }
};

export function SubscriptionExpirationCheck({history, currentPage}) {
  const subscriptionEndDate = sessionStorage.getItem('expired_date');
  useEffect(() => {
    const checkSubscriptionExpiration = () => {
      const session_id = sessionStorage.getItem('session_id');
      const currentTime = new Date();
      const expirationTime = new Date(subscriptionEndDate);

      const twoMinutesBeforeExpiration = new Date(expirationTime.getTime() - 2 * 60 * 1000);

      //  if (currentTime >= twoMinutesBeforeExpiration && currentTime < expirationTime)
      if (currentTime >= twoMinutesBeforeExpiration) {
        subscriptionEndDate && history.push(`${authPath.expiredSession.path}?comeFrom=${currentPage}`);
      }
    };

    checkSubscriptionExpiration();
    const interval = setInterval(checkSubscriptionExpiration, 5 * 1000);

    return () => clearInterval(interval);
  }, [subscriptionEndDate]);

  return null;
}

export function setExpiredDateInSessionStorage() {
  const currentTime = new Date();
  const expirationTime = new Date(currentTime.getTime() + 10 * 60 * 1000);
  const expiredDate = expirationTime.toISOString();

  sessionStorage.setItem('expired_date', expiredDate);
}

export const checkIsApp = () => {
  const {pathname} = useLocation();
  return pathname.includes('app');
};

export const arraysOfObjectsAreEqual = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (!objectsAreSame(array1[i], array2[i])) {
      return false;
    }
  }

  return true;
};

const objectsAreSame = (obj1, obj2) => {
  for (let propertyName in obj1) {
    if (obj1[propertyName] !== obj2[propertyName]) {
      return false;
    }
  }
  return true;
};

const isObject = (obj) => {
  return obj !== null && typeof obj === 'object';
};

export const generateMarks = (arr, setter, params, width) => {
  const mimAmount = Math.min(...arr.map((item) => item.low_amount));
  const maxHighAmount = Math.max(...arr.map((item) => item.high_amount));

  let marks;

  if (width < 768) {
    marks = [
      {value: 0, label: `${0}`},
      {value: 25, label: `${Math.round(maxHighAmount / 4)}`},
      {value: 50, label: `${Math.round(maxHighAmount / 2)}`},
      {value: 75, label: `${Math.round(maxHighAmount * 0.75)}`},
      {value: 100, label: `${maxHighAmount}`}
    ];
  } else {
    marks = Array.from({length: 11}, (_, index) => ({
      value: index * 10,
      label: index === 10 ? `${maxHighAmount}` : `${index * 5000}`
    }));
  }

  setter({
    ...params,
    availableLimit: maxHighAmount,
    lowestValue: mimAmount,
    totalLimit: maxHighAmount,
    marksList: marks
  });

  return marks;
};

export const findLoanProduct = (arr, val) => {
  const matchingProduct = arr.find((product, index) => {
    return val >= product.low_amount && val <= product.high_amount;
  });

  if (matchingProduct) {
    // If a matching product is found, return its order number (index + 1)
    return arr.indexOf(matchingProduct);
  } else {
    // Return -1 or any other value to indicate that no matching product was found
    return -1;
  }
};

export const findClosestRange = (value, arr) => {
  let closestRange = null;

  for (let i = 0; i < arr.length; i++) {
    const currentRange = arr[i];
    const nextRange = arr[i + 1];

    if (value >= currentRange.low_amount && value <= currentRange.high_amount) {
      // Value is within the range, return this range
      closestRange = currentRange;
      break;
    }

    if (nextRange && value > currentRange.high_amount && value < nextRange.low_amount) {
      // Value is within the gap, compare the difference between next minimum and current maximum
      const differenceToNextLow = Math.abs(nextRange.low_amount - value);
      const differenceToCurrentHigh = Math.abs(currentRange.high_amount - value);

      if (!closestRange || differenceToNextLow < differenceToCurrentHigh) {
        closestRange = {...nextRange, amount: differenceToNextLow};
      } else {
        closestRange = {...currentRange, amount: differenceToCurrentHigh};
      }

      break;
    }
  }

  return closestRange;
};
