import React from 'react';
import styles from './BillingCalendar.module.scss';

const BillingCalendar = ({title = 'Preferred billing date', setter, active = 14, subtitle, withHover = false}) => {
  const handleSetData = (value) => {
    setter && setter(value);
  };

  return (
    <div className={styles.root}>
      <span className={styles.title}>{title}</span>
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      <div className={`${styles.grid} ${withHover && styles.hovered}`}>
        {[...Array(31)].map((el, idx) => {
          const day = idx + 1;
          return (
            <button
              type='button'
              className={active === day ? styles.active : ''}
              onClick={() => handleSetData(day)}
              key={idx}
            >
              {day}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default BillingCalendar;
