import React from 'react';
import styles from './ProgressBar.module.scss';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

const ProgressBar = () => {
  const {t} = useTranslation();
  const {pathname} = useLocation();
  const {first_circle, second_circle, third_circle, fourth_circle, seventh_circle, eights_circle, withdrawal_circles} =
    useSelector(({credit_form}) => credit_form);

  function getCircleClassName(circle, step) {
    if (circle === 'red') {
      return 'red';
    } else if (circle === 'green' || step > 2) {
      return 'green';
    } else {
      return '';
    }
  }

  let step;

  switch (pathname) {
    case '/credit/make-withdrawal':
      step = 5;
      break;
    case '/credit/application-status':
      step = 4;
      break;
    case '/credit/tink':
      step = 3;
      break;
    case '/credit/tink-processing':
      step = 3;
      break;
    case '/credit/credit-form':
      step = 2;
      break;
    default:
      step = 1;
      break;
  }

  const scrollToSection = (targetId) => {
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({behavior: 'smooth'});
    }
  };

  //Your application has been denied - НЕ ЗАБУДЬ ДОДАТИ ЧЕРВОНИЙ МАРКЕР ДЛЯ СТАТУСА В ПРОГРЕС БАРІ КОЛИ ЗРОБЛЯТЬ НА БЕКЕ РЕДІРЕКТ
  return (
    <div className={styles.container}>
      <div className={styles.root}>
        <div className={styles.progress}>
          <div className={styles.block}>
            <p className={styles.title}>{t('PROGRESSBAR.identification')}</p>
            <div className={styles.circles}>
              <div className={`${styles.circle} ${step > 1 && 'green'}`}></div>
            </div>
          </div>

          <div className={styles.block}>
            <p className={styles.title}>{t('PROGRESSBAR.application')}</p>
            {[first_circle, second_circle, third_circle, fourth_circle].map((circle, index) => (
              <div className={styles.circles} key={index}>
                {!pathname.includes('form') ? (
                  <div className={`${styles.circle} ${getCircleClassName(circle, step)}`}></div>
                ) : (
                  <button
                    onClick={() => scrollToSection(`spep_${index + 1}`)}
                    className={`${styles.circle} ${getCircleClassName(circle, step)}`}
                  ></button>
                )}
              </div>
            ))}
          </div>

          <div className={styles.block}>
            <p className={styles.title}>{t('PROGRESSBAR.tink')}</p>
            <div className={`${styles.circles} `}>
              <div className={`${styles.circle} ${step >= 4 && 'green'}`}></div>
            </div>
          </div>
          <div className={styles.block}>
            <p className={styles.title}>{t('PROGRESSBAR.withdrawal')}</p>
            <div className={styles.circles}>
              <div className={`${styles.circle} ${seventh_circle && 'green'}`}></div>
            </div>
            {withdrawal_circles && (
              <div className={styles.circles}>
                <div className={`${styles.circle} ${eights_circle && 'green'} `}></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
