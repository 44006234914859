import {SETTINGS} from './settingsActionTypes';

export function getSettings() {
  return {
    type: SETTINGS.GET_SETTINGS,
    payload: {
      client: 'second',
      request: {
        url: `customers/GetCustomerDetails`,
        method: 'get'
      }
    }
  };
}

export function getCustomer() {
  return {
    type: SETTINGS.GET_CUSTOMER,
    payload: {
      client: 'second',
      request: {
        url: `customers/GetCustomer`,
        method: 'get'
      }
    }
  };
}

export function isEmailConfirmed(email) {
  return {
    type: SETTINGS.EMAIL_CONFIRMED,
    payload: {
      client: 'second',
      request: {
        url: `customers/IsEmailConfirmed?email=${email}`,
        method: 'get'
      }
    }
  };
}

export function GetCustomerFlags() {
  const flags = localStorage.getItem('flags');
  return {
    type: SETTINGS.GET_CUSTOMER_FLAGS,
    payload: {
      client: 'second',
      request: {
        url: `customers/GetCustomerFlags${flags ? `?flags=${flags}` : ''}`,
        method: 'get'
      }
    }
  };
}

export function getCustomerNotifications() {
  return {
    type: SETTINGS.GET_CUSTOMER_NOTIFICATIONS,
    payload: {
      client: 'second',
      request: {
        url: `customers/GetCustomerNotifications`,
        method: 'get'
      }
    }
  };
}

export function updateSettings(data) {
  return {
    type: SETTINGS.UPDATE_SETTINGS,
    payload: {
      client: 'second',
      request: {
        url: `/customers/UpdateCustomerDetails`,
        method: 'patch',
        data
      }
    }
  };
}

export function postEmailConfirmation(data) {
  return {
    type: SETTINGS.POST_EMAIL_CONFIRMATION,
    payload: {
      client: 'second',
      request: {
        url: `customers/SendConfirmationEmail`,
        method: 'post',
        data
      }
    }
  };
}

export function confirmEmailCode(data) {
  return {
    type: SETTINGS.CONFIRM_EMAIL_CODE,
    payload: {
      client: 'second',
      request: {
        url: `customers/ConfirmEmailCode?email=${data.email}&code=${data.code}`,
        method: 'post'
      }
    }
  };
}

export function getTinkUrl(page) {
  return {
    type: SETTINGS.GET_TINK,
    payload: {
      client: 'second',
      request: {
        url: `tink/GetTinkLinkUrl?page=${page}`,
        method: 'get'
      }
    }
  };
}

export function handleTinkReturn(data) {
  return {
    type: SETTINGS.GET_HANDLE_TINK,
    payload: {
      client: 'second',
      request: {
        url: `/tink/HandleTinkReturn`,
        method: 'post',
        data
      }
    }
  };
}

export function setPSD2ForApplicationRequest(body) {
  return {
    type: SETTINGS.PSD2_FOR_APP,
    payload: {
      client: 'second',
      request: {
        url: `applications/SetPSD2ForApplication`,
        method: 'post',
        body
      }
    }
  };
}

export function showMenu(value) {
  return {
    type: SETTINGS.SET_SHOW_MENU,
    value
  };
}

export function setNotificationFilteredQuantity(value) {
  return {
    type: SETTINGS.SET_NOTIFICATION_QUANTITY,
    value
  };
}

export function setGuideStep(value) {
  return {
    type: SETTINGS.SET_GUIDE_STEP,
    value
  };
}
