import React, {useState, useEffect} from 'react';
import styles from './ApplyAccount.module.scss';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {InputMUI, SkeletonMUI, AnimationWrapper, RangeInputPure} from '../../../shared';
import {useTranslation} from 'react-i18next';
import {useWindowWidth} from '../../../helpers/hooks';
import {useDispatch, useSelector} from 'react-redux';
import {getProducts} from '../../Invoices/invoicesActions';
import {generateMarks, findLoanProduct, findClosestRange} from '../../../helpers/functions';
import {Alarm} from '../../../assets/icons';
import {useHistory} from 'react-router-dom';
import {creditAppPath} from '../../../routes/paths';

const ApplyAccount = ({disabled = false}) => {
  const [sliderValue, setSliderValue] = useState(5000);
  const [error, setError] = useState(false);
  const history = useHistory();

  const [sliderParams, setSliderParams] = useState({
    marksList: [],
    activeProduct: 0,
    availableLimit: 20000,
    lowestValue: 5000,
    totalLimit: 20000,
    usedLimit: 0,
    step: 500
  });

  const {t} = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProducts('flexlimit'));
  }, []);

  const percentAdapter = (value) => {
    return (value * 100) / sliderParams.availableLimit;
  };

  const WithdrawAccountSchema = yup.object({
    amount: yup
      .number()
      .min(sliderParams.lowestValue, `Minimun value ${sliderParams.lowestValue}`)
      .max(sliderParams.availableLimit, `Maximum ${sliderParams.availableLimit}`)
  });

  const {
    control,
    setValue,
    trigger,
    formState: {errors, isValid}
  } = useForm({
    mode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(WithdrawAccountSchema),
    defaultValues: {
      amount: sliderParams.lowestValue || 500
    }
  });

  const valueSetter = (value) => {
    setSliderValue(+value);
    setValue('amount', +value);
    trigger('amount');
  };

  const width = useWindowWidth();
  const sliderWidth = width > 1200 ? 700 : width * 0.78;

  const {credit_loading, credits_list} = useSelector((state) => state.invoices);

  useEffect(() => {
    if (credits_list && credits_list.length > 0) {
      generateMarks(credits_list, setSliderParams, sliderParams, width);
    }
  }, [credits_list]);

  const setTemporaryError = (value) => {
    setError(value);
    setTimeout(() => {
      setError(false);
    }, 2000);
  };

  useEffect(() => {
    if (credits_list && credits_list.length > 0) {
      const activeProd = findLoanProduct(credits_list, sliderValue);
      if (activeProd === -1) {
        const closestValue = findClosestRange(sliderValue, credits_list)?.low_amount || sliderParams.lowestValue;
        closestValue > sliderParams.lowestValue && valueSetter(closestValue);
        !error && setTemporaryError(sliderValue);
      } else {
        setSliderParams({
          ...sliderParams,
          activeProduct: activeProd
        });
      }
    }
  }, [sliderValue]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value > sliderParams.totalLimit) {
      setSliderValue(sliderParams.totalLimit);
      setValue('amount', +sliderParams.totalLimit);
      trigger('amount');
    } else {
      setSliderValue(+value);
    }
  };

  const handleNewWithdrawal = () => {
    history.push(creditAppPath.creditPath.path + `?amount=${sliderValue}`);
  };

  return (
    <>
      <AnimationWrapper>
        <div className={styles.root}>
          <h2 className={`${styles['withdraw__title']} block__title`}>
            {t('APPLY_ACCOUNT.apply')} <br /> {t('APPLY_ACCOUNT.account')}
          </h2>
          <div className={styles.container}>
            <div className={styles['withdraw__columns']}>
              <div className={styles['withdraw__column']}>
                <div className={styles['withdraw__input']}>
                  <Controller
                    name='amount'
                    control={control}
                    render={({field}) => (
                      <InputMUI
                        disabled={disabled}
                        onChange={(e) => handleInputChange(e)}
                        className={styles['withdraw__input--field']}
                        placeholder={`${sliderParams.lowestValue}`}
                        variant='standard'
                        type='number'
                        label={t('WITHDRAWACCOUNT.amount')}
                        fullWidth
                        error={errors.amount?.message}
                        inputProps={field}
                      />
                    )}
                  />

                  <span
                    className={`${styles['withdraw__input--currency']} ${
                      disabled && styles['withdraw__input--currency--disabled']
                    }`}
                  >
                    kr
                  </span>
                </div>
                <p className={styles['withdraw__text']}>{t('APPLY_ACCOUNT.select')}</p>
              </div>
              <div className={styles['withdraw__column']}>
                {!credit_loading && credits_list ? (
                  <>
                    <div className={styles['withdraw__column--container']}>
                      <p className={styles['withdraw__column--title']}>{t('APPLY_ACCOUNT.monthly')}</p>
                      <span className={styles['withdraw__column--price']}>
                        {credits_list[sliderParams.activeProduct]?.monthly_interest} %
                      </span>
                    </div>
                    <div className={styles['withdraw__column--container']}>
                      <p className={styles['withdraw__column--title']}>{t('APPLY_ACCOUNT.setup')}</p>
                      <span className={styles['withdraw__column--price']}>
                        {credits_list[sliderParams.activeProduct]?.origination_fee &&
                          credits_list[sliderParams.activeProduct]?.origination_fee.toLocaleString('sv-SE', {
                            style: 'currency',
                            currency: 'SEK'
                          })}
                      </span>
                    </div>
                    <div className={styles['withdraw__column--container']}>
                      <p className={styles['withdraw__column--title']}>{t('APPLY_ACCOUNT.charge')}</p>
                      <span className={styles['withdraw__column--price']}>
                        {credits_list[sliderParams.activeProduct]?.monthly_fees &&
                          credits_list[sliderParams.activeProduct]?.monthly_fees.toLocaleString('sv-SE', {
                            style: 'currency',
                            currency: 'SEK'
                          })}
                      </span>
                    </div>
                    <div className={styles['withdraw__column--container']}>
                      <p className={styles['withdraw__column--title']}>{t('APPLY_ACCOUNT.minimum')}</p>
                      <span className={styles['withdraw__column--price']}>
                        {credits_list[sliderParams.activeProduct]?.apr} %
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    {[...Array(4)].map((elem, idx) => {
                      return <SkeletonMUI key={idx} height={40} className={styles.skeleton} loading />;
                    })}
                  </>
                )}
              </div>
            </div>
            {
              <div className={`${styles.error} ${error ? styles.error__open : styles.error__closed}`}>
                <Alarm />
                <p>
                  The{' '}
                  {error.toLocaleString('sv-SE', {
                    style: 'currency',
                    currency: 'SEK'
                  })}{' '}
                  is not available for choosing
                </p>
              </div>
            }
            {sliderValue && (
              <RangeInputPure
                disabled={disabled}
                lowestValue={sliderParams.lowestValue}
                marksArray={sliderParams.marksList}
                sliderWidth={sliderWidth}
                totalLimit={sliderParams.totalLimit}
                availableLimit={sliderParams.availableLimit}
                usedLimit={sliderParams.usedLimit}
                step={percentAdapter(sliderParams.step)}
                value={percentAdapter(sliderValue)}
                setter={valueSetter}
              />
            )}
            <div className={styles['withdraw__elems']}>
              {!credit_loading && credits_list ? (
                <>
                  <div className={styles['withdraw__column--container']}>
                    <p className={styles['withdraw__column--title']}>{t('APPLY_ACCOUNT.monthly')}</p>
                    <span className={styles['withdraw__column--price']}>
                      {credits_list[sliderParams.activeProduct]?.monthly_interest} %
                    </span>
                  </div>
                  <div className={styles['withdraw__column--container']}>
                    <p className={styles['withdraw__column--title']}>{t('APPLY_ACCOUNT.setup')}</p>
                    <span className={styles['withdraw__column--price']}>
                      {credits_list[sliderParams.activeProduct]?.origination_fee &&
                        credits_list[sliderParams.activeProduct]?.origination_fee.toLocaleString('sv-SE', {
                          style: 'currency',
                          currency: 'SEK'
                        })}
                    </span>
                  </div>
                  <div className={styles['withdraw__column--container']}>
                    <p className={styles['withdraw__column--title']}>{t('APPLY_ACCOUNT.charge')}</p>
                    <span className={styles['withdraw__column--price']}>
                      {credits_list[sliderParams.activeProduct]?.monthly_fees &&
                        credits_list[sliderParams.activeProduct]?.monthly_fees.toLocaleString('sv-SE', {
                          style: 'currency',
                          currency: 'SEK'
                        })}
                    </span>
                  </div>
                  <div className={styles['withdraw__column--container']}>
                    <p className={styles['withdraw__column--title']}>{t('APPLY_ACCOUNT.minimum')}</p>
                    <span className={styles['withdraw__column--price']}>
                      {credits_list[sliderParams.activeProduct]?.apr} %
                    </span>
                  </div>
                </>
              ) : (
                <>
                  {[...Array(4)].map((elem, idx) => {
                    return <SkeletonMUI key={idx} height={40} className={styles.skeleton} loading />;
                  })}
                </>
              )}
            </div>
            <button onClick={handleNewWithdrawal} className={`${styles['withdraw__button']} main__button`}>
              {t('APPLY_ACCOUNT.apply_btn')}
            </button>
          </div>
        </div>
      </AnimationWrapper>
    </>
  );
};

export default ApplyAccount;
