import React from 'react';
import styles from './Warning.module.scss';
import Icon from '../../../assets/images/warning.png';

const Warning = ({param = 'fit', className = ''}) => {
  //params: fit | full
  return (
    <div className={`${styles.root} ${styles[param]} ${className}`}>
      <img className={styles.root__image} src={Icon} alt='warning' />
      <div className={styles.root__texts}>
        <b>Det här är en högkostnadskredit</b>
        <p>
          Om du inte kan betala tillbaka hela skulden riskerar du en betalningsanmärkning. För stöd, vänd dig till
          budget- och skuldrådgivningen i din kommun. Kontaktuppgifter finns på{' '}
          <a target='blank' href='https://www.hallakonsument.se/'>
            hallåkonsument.se
          </a>
           
        </p>
      </div>
    </div>
  );
};

export default Warning;
