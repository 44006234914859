import React, {Children, cloneElement, useEffect} from 'react';
import PropTypes from 'prop-types';
import {capitalize} from '../../helpers/functions';
import {ReactComponent as NotFoundIcon} from './images/404-error.svg';
import {ReactComponent as BoxIcon} from './images/box.svg';
import styles from './NotFound.module.scss';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = ({
  children,
  className = '',
  color = '',
  icon,
  noData = false,
  small = false,
  title = 'Sorry, page not found'
}) => {
  const history = useHistory();

  useEffect(() => {
    history.push('/404');
  }, []);

  return <div className={`${styles.root}${small ? ` ${styles.small}` : ''}${className && ` ${className}`}`}>404</div>;
};

NotFound.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']),
  icon: PropTypes.element,
  noData: PropTypes.bool,
  small: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string
};

export default NotFound;
