import React from 'react';
import styles from './SideBar.module.scss';
import {ProfileCard, CreditAccountCard, CustomerServiceCard, UrgentCard} from '../../shared';
import {mainPath} from '../../routes/paths';
import {useLocation} from 'react-router-dom';

const getBlocksByPath = (path) => {
  switch (path) {
    case mainPath.contracts.path:
    case mainPath.transactions.path:
      return [
        <CreditAccountCard />,
        // <UrgentCard />
        // <ProfileCard />
        <CustomerServiceCard type='sidebar' />
      ];
    case mainPath.overview.path:
      return [
        <CreditAccountCard />,
        //  <UrgentCard />,
        <CustomerServiceCard type='sidebar' />
      ];
    case mainPath.settings.path:
    case mainPath.invoices.path:
      return [
        <CreditAccountCard />,
        // <UrgentCard />,
        <CustomerServiceCard type='sidebar' />
        // <ProfileCard />
      ];

    default:
      return [];
  }
};

const SideBar = ({className}) => {
  const {pathname} = useLocation();
  const blocks = getBlocksByPath(pathname);

  return (
    <div className={`${styles.root} ${className}`}>
      {blocks.map((item, index) => (
        <React.Fragment key={index}>{item}</React.Fragment>
      ))}
    </div>
  );
};

export default SideBar;
