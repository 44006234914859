import {AUTH} from './authActionTypes';

const INITIAL_STATE = {
  loading: false,
  email_confirmation_loading: false,
  email_confirmation_error: false,
  emailVerError: '',
  autoStartToken: '',
  collectErrorCode: '',
  collectStatus: '',

  type: '',
  qrCode: '',
  IP: '',
  userInfo: {},

  toast_open: false,
  toast_type: false,
  toast_text: ''
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH.EMAIL_VER_FAIL:
      return {...state, emailVerError: action?.error?.response?.data?.security_token};

    case AUTH.CREATE_SESSION_WITH_BANK_ID:
      return {...state, loading: true};

    case AUTH.CREATE_SESSION_WITH_BANK_ID_SUCCESS:
    case AUTH.CREATE_SESSION_WITH_BANK_ID_FAIL:
      return {...state, loading: false};

    case AUTH.RESET_STORE:
      return {
        ...INITIAL_STATE,
        IP: state.IP || sessionStorage.getItem('IP'),
        type: state.type || sessionStorage.getItem('type')
      };

    case AUTH.POST_AUTH_AUTHOSTART_SUCCESS:
      return {...state, autoStartToken: action?.payload?.data?.AutoStartToken};

    case AUTH.SET_IP_ADDRESS:
      sessionStorage.setItem('IP', action.IP);
      return {...state, IP: action.IP};

    case AUTH.SET_TOAST_PARAMS:
      return {
        ...state,
        toast_open: action.data.toast_open,
        toast_type: action.data.toast_type,
        toast_text: action.data.toast_text,
        toast_description: action.data?.toast_description || ''
      };

    case AUTH.GET_ANIMATED_QR_SUCCESS:
      return {...state, qrCode: action?.payload?.data?.QR};

    case AUTH.GET_COLLECT_SUCCESS:
      return {
        ...state,
        collectErrorCode: action?.payload.data.message || 'unknownError',
        collectStatus: action?.payload.data.status || action?.payload.data.Status,
        autoStartToken:
          action?.payload.data.AutoStartToken || action?.payload.data.autoStartToken || state.autoStartToken
      };

    case AUTH.USER_INFO_SUCCESS:
      return {...state, userInfo: action?.payload?.data};

    case AUTH.EMAIL_CONFIRMATION:
      return {...state, email_confirmation_loading: true, email_confirmation_error: false};
    case AUTH.EMAIL_CONFIRMATION_SUCCESS:
      return {...state, email_confirmation_loading: false, email_confirmation_error: false};
    case AUTH.EMAIL_CONFIRMATION_FAIL:
      return {...state, email_confirmation_loading: false, email_confirmation_error: true};
    default:
      return state;
  }
}
