import {AUTH} from './authActionTypes';

export function postSignIn(data) {
  return {
    type: AUTH.SIGN_IN,
    payload: {
      client: 'default',
      request: {
        url: `signin/`,
        method: 'post',
        data
      }
    }
  };
}

export function getCancelRequest(autoStartToken) {
  return {
    type: AUTH.GET_CANCEL,
    payload: {
      client: 'default',
      request: {
        url: `/Cancel?autoStartToken=${autoStartToken}`,
        method: 'get'
      }
    }
  };
}

export function postAuthAuthostart(data) {
  return {
    type: AUTH.POST_AUTH_AUTHOSTART,
    payload: {
      client: 'default',
      request: {
        url: '/AuthAutostart',
        method: 'post',
        data
        // {
        //   "IP": "192.168.0.1"
        // }
      }
    }
  };
}

export function getCollectRequest(autoStartToken) {
  return {
    type: AUTH.GET_COLLECT,
    payload: {
      client: 'default',
      request: {
        url: `/Collect?autoStartToken=${autoStartToken}`,
        method: 'get'
      }
    }
  };
}

export function resetStore() {
  return {
    type: AUTH.RESET_STORE
  };
}

export function getAnimatedQRRequest(autoStartToken) {
  return {
    type: AUTH.GET_ANIMATED_QR,
    payload: {
      client: 'default',
      request: {
        url: `/QR?autoStartToken=${autoStartToken}`,
        method: 'get'
      }
    }
  };
}

export function CreateSessionWithBankID(data) {
  return {
    type: AUTH.CREATE_SESSION_WITH_BANK_ID,
    payload: {
      client: 'second',
      request: {
        url: '/sessionhandler/BankIDLogin',
        method: 'post',
        data
      }
    }
  };
}

export function refreshSession(data) {
  return {
    type: AUTH.REFRESH_SESSION,
    payload: {
      client: 'second',
      request: {
        url: '/sessionhandler/RefreshSession',
        method: 'post',
        data
      }
    }
  };
}

export function setUserIP(IP) {
  return {
    type: AUTH.SET_IP_ADDRESS,
    IP
  };
}

export function setToastParams(data) {
  return {
    type: AUTH.SET_TOAST_PARAMS,
    data
  };
}

export function getConfirmEmailLink(data) {
  return {
    type: AUTH.EMAIL_CONFIRMATION,
    payload: {
      client: 'second',
      request: {
        url: `/customers/ConfirmEmailLink`,
        method: 'post',
        data
      }
    }
  };
}
