import React, {useState, useEffect} from 'react';
import styles from './ExpiredSession.module.scss';
import {Alarm} from '../../../assets/icons';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {authPath} from '../../../routes/paths';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {refreshSession} from '../authActions';

const ExpiredSession = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {search} = useLocation();

  const logOut = () => {
    sessionStorage.clear();
  };
  const logOutAndMoveToAuth = () => {
    document.title = `FlexLimit`;
    history.push(authPath.choogeAuth.path);
    sessionStorage.clear();
  };

  const [remainingTime, setRemainingTime] = useState(120);

  const handleRefreshSession = () => {
    dispatch(
      refreshSession({
        session_id: sessionStorage.getItem('session_id'),
        ip_address: sessionStorage.getItem('IP')
      })
    ).then((res) => {
      document.title = `FlexLimit`;
      sessionStorage.setItem('expired_date', res?.payload?.data?.expires);
      history.push(`/main/${search.split('=')[1]}`);
    });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime === 0) {
          logOut();
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const timeCounter = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  useEffect(() => {
    const timerValue = timeCounter(remainingTime);
    document.title = `FlexLimit ${timerValue}`;
  }, [remainingTime]);

  return (
    <div className={styles.root}>
      <Alarm />

      {remainingTime > 0 ? (
        <>
          <b className={styles.title}>{t('EXPIREDSESSION.expire')}</b>
          <p className={styles.paragraph}>{t('EXPIREDSESSION.will_be')}</p>
        </>
      ) : (
        <>
          <b className={styles.title}>{t('EXPIREDSESSION.expired')}</b>
          <p className={styles.paragraph}>{t('EXPIREDSESSION.please')}</p>
        </>
      )}

      {remainingTime > 0 && <p className={styles.counter}>{timeCounter(remainingTime)}</p>}

      {remainingTime > 0 ? (
        <>
          <button onClick={handleRefreshSession} className={`${styles['session__button--filled']} main__button`}>
            {t('EXPIREDSESSION.keep')}
          </button>
          <button onClick={logOutAndMoveToAuth} className={`${styles['session__button']} main__button`}>
            {t('EXPIREDSESSION.log_out')}
          </button>
        </>
      ) : (
        <>
          <Link to={authPath.choogeAuth.path} className={`${styles['session__button--filled']} main__button`}>
            {t('EXPIREDSESSION.loginBack')}
          </Link>

          <a className={styles['session__link']} href={new URL('https://www.flexlimit.se/')}>
            {t('EXPIREDSESSION.back')}
          </a>
        </>
      )}
    </div>
  );
};

export default ExpiredSession;
