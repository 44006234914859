import {TRANSACTION} from './transactionsActionTypes';

export function getListOfTransactions(query, query_trans = false) {
  const queryParams = {
    isWithdrawal: false,
    isPayment: false,
    isInterest: false,
    isPaymentFree: false
  };

  const mergedQuery = query_trans ? {...queryParams, ...query_trans} : queryParams;

  const queryString = Object.entries(mergedQuery)
    .filter(([key, value]) => value !== false)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const url = `ledgers/GetTransactions${query ? `?${query}` : ''}${queryString ? `&${queryString}` : ''}`;

  return {
    type: TRANSACTION.GET_LIST_OF_TRANSACTIONS,
    payload: {
      client: 'second',
      request: {
        url: url,
        method: 'get'
      }
    }
  };
}

export function getRecentTransactions(query) {
  return {
    type: TRANSACTION.GET_RECENT_TRANSACTIONS,
    payload: {
      client: 'second',
      request: {
        url: `ledgers/GetLatestTransactions${query || ''}`,
        method: 'get'
      }
    }
  };
}
