import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {rootMainPath, rootAuthPath, creditAppPath} from './paths';
import CreditForm from '../features/CreditForm';
import Tink from '../features/Tink';
import DataCollection from '../features/DataCollection';
import ApplicationStatus from '../features/CreditForm/ApplicationStatus';
import TinkProcessing from '../features/CreditForm/TinkProcessing'

const CreditRoutes = () => {
  if (!sessionStorage.session_id) return <Redirect to={rootAuthPath} />;

  return (
    <Switch>
      <Redirect from={rootMainPath} exact to={creditAppPath.creditPath.path} />
      <Route path={creditAppPath.creditPath.path} component={CreditForm} />
      <Route path={creditAppPath.tinkPath.path} component={Tink} />
      <Route path={creditAppPath.dataCollectionPath.path} component={DataCollection} />
      <Route path={creditAppPath.applicationStatusPath.path} component={ApplicationStatus} />
      <Route path={creditAppPath.tinkProcessingPath.path} component={TinkProcessing} />
    </Switch>
  );
};

export default CreditRoutes;
