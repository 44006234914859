import {TRANSACTION} from './transactionsActionTypes';

const INITIAL_STATE = {
  recent_loading: false,
  loading: false,
  transactions: null,
  recentTransactions: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case TRANSACTION.GET_LIST_OF_TRANSACTIONS:
      return {...state, loading: true};

    case TRANSACTION.GET_LIST_OF_TRANSACTIONS_SUCCESS:
      return {...state, loading: false, transactions: action?.payload?.data};

    case TRANSACTION.GET_RECENT_TRANSACTIONS:
      return {...state, recent_loading: true};
    case TRANSACTION.GET_RECENT_TRANSACTIONS_FAIL:
      return {...state, recent_loading: false};

    case TRANSACTION.GET_RECENT_TRANSACTIONS_SUCCESS:
      return {...state, recent_loading: false, recentTransactions: action?.payload?.data};

    default:
      return state;
  }
}
