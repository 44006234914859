import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getPDF} from '../../../Invoices/invoicesActions';
import styles from '../ApplicationStatus.module.scss';
import {File} from '../../../../assets/icons';
import {useDispatch, useSelector} from 'react-redux';
import {handleDownload} from '../../../../helpers/functions';

const DownloadFiles = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const data = null;
  const {pdf} = useSelector(({invoices}) => invoices);

  const [pdfDownloaded, setPdfDownloaded] = useState(false);
  const {application_data} = useSelector(({credit_form}) => credit_form);
  //DOWNLOAD PDF FUNCTION

  const handleDownloadPDF = (pdf_data, name) => {
    handleDownload({pdf_data: pdf_data, file_name: name});
  };

  // useEffect(() => {
  //   pdfDownloaded && pdf && handleDownload(pdf);
  //   setPdfDownloaded(false);
  // }, [pdfDownloaded]);

  return (
    <>
      <hr className={styles.hr} />
      <h3 className={styles.block__title}>{t('APPLICATION_STATUS.loan')}</h3>
      <p className={styles.block__subtitle}>{t('APPLICATION_STATUS.here')}</p>
      {application_data &&
        application_data?.documents &&
        application_data?.documents.length > 0 &&
        application_data?.documents.map((item, idx) => {
          return (
            <button
              key={idx}
              onClick={() => handleDownloadPDF(item.pdf_data, item?.file_name)}
              className={styles['main__download']}
            >
              <File className='mr10' />
              <p>
                {t('CONTRACT.download')} {item?.file_name} PDF
              </p>
            </button>
          );
        })}
    </>
  );
};

export default DownloadFiles;
