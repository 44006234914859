import React, {useEffect, useState} from 'react';
import styles from './Invoices.module.scss';
import SideBar from '../SideBar';
import Invoice from './Invoice';
import PaidInvoice from './PaidInvoice';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {getInvoices} from './invoicesActions';
import InvoiceMobileCard from './InvoiceMobileCard';
import {useWindowWidth} from '../../helpers/hooks';
import {CreditAccountCard, CustomerServiceCard} from '../../shared';
import {ArrowL, ArrowR} from '../../assets/icons';
import TransPageToast from '../../shared/TransPageToast/TransPageToast';
const Invoices = () => {
  //INVOICE STATUSES: 0 - None | 1 - Open | 2 - PastDue | 3 - Paid

  const {t} = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => dispatch(getInvoices()), []);
  const {is_menu_shown, guide_step} = useSelector((state) => state.settings);
  const {loading, closed_invoices, open_invoices} = useSelector(({invoices}) => invoices);
  const width = useWindowWidth();
  const current_date = new Date();
  const current_year = current_date.getFullYear();
  const [year, setYear] = useState(current_year);

  const changeYear = (type = 'decr') => {
    if (type === 'incr') {
      setYear((prev) => (prev + 1 > current_year ? prev : prev + 1));
    } else {
      setYear((prev) => prev - 1);
    }
  };
  const [filtered_by_year_closed_invoices, setFilteredByYearClosedInvoices] = useState([]);

  useEffect(() => {
    const filterInvoicesByYear = (invoices, year) => {
      if (invoices && year) {
        const filtered = invoices.filter((invoice) => {
          const paidYear = new Date(invoice.invoice_due_date).getFullYear();
          return paidYear === year;
        });
        setFilteredByYearClosedInvoices(filtered);
      }
    };

    filterInvoicesByYear(closed_invoices, year);
  }, [closed_invoices, year]);

  const need_tour = JSON.parse(localStorage.getItem('react_tour_data'))?.need_tour || false;

  const showBlur = need_tour && guide_step === 3;

  return (
    <>
      <div className={'main__headers main__headers--xl'}>
        <div className='container'>
          <h1 className={`${styles['invoices__title']} main__title `}>{t('INVOICES.invoices')}</h1>
          <p className={`${styles['invoices__description']} main__description`}>
            {t('INVOICES.days')}
            <br />
            {t('INVOICES.send')}
          </p>
        </div>
      </div>

      <main className={`${styles.root} ${is_menu_shown && 'main__blured'} main__container container`}>
        <TransPageToast />

        <div className={`${styles.open__block} ${open_invoices?.length === 0 && styles.open__block__gap} container`}>
          <h3 className={`${styles['invoices__subtitle']} ${styles['invoices__subtitle--w']} main__subtitle `}>
            {t('INVOICES.open')}
          </h3>
          {open_invoices?.length === 0 && (
            <p className={`${styles['invoices__empty']} main__empty`}>{t('INVOICES.empty')}</p>
          )}
        </div>

        <div className={`${styles['invoices__main']} main__grid`}>
          <div className={`${styles['invoices__column']} main__column ${showBlur && 'main__tourblur'}`}>
            {!loading
              ? //IF LOADING SHOW SKELETON
                open_invoices &&
                open_invoices.length > 0 &&
                //IF WINDOW WIDTH <=768px show MOBILE CARD
                (width < 769
                  ? open_invoices.map((item, idx) => {
                      return <InvoiceMobileCard key={idx} data={item} />;
                    })
                  : open_invoices.map((item, idx) => {
                      return (
                        <Invoice key={idx} data={item} type={item.invoice_status === 'PastDue' ? 'late' : 'active'} />
                      );
                    }))
              : width >= 769
              ? [...Array(2)].map((item, idx) => {
                  return <Invoice key={idx} isLoading />;
                })
              : [...Array(2)].map((item, idx) => {
                  return <InvoiceMobileCard key={idx} isLoading />;
                })}

            <h3 className={`${styles['invoices__subtitle']} main__subtitle`}>
              {t('INVOICES.paid')}{' '}
              <div className={styles.year__selector}>
                <button onClick={() => changeYear('decr')} aria-label='counter' className={styles.year__button}>
                  <ArrowL />
                </button>
                <span className={styles.year__value}>{year}</span>
                <button
                  disabled={year === current_year}
                  onClick={() => changeYear('incr')}
                  aria-label='counter'
                  className={styles.year__button}
                >
                  <ArrowR />
                </button>
              </div>
            </h3>

            {filtered_by_year_closed_invoices?.length === 0 && (
              <p className={`${styles['invoices__empty']} main__empty`}>
                {t('INVOICES.empty2')}
                {year}
                {t('INVOICES.yet')}
              </p>
            )}

            {!loading
              ? //IF LOADING SHOW SKELETON
                filtered_by_year_closed_invoices &&
                filtered_by_year_closed_invoices.length > 0 &&
                //IF WINDOW WIDTH <=768px show MOBILE CARD
                (width < 769
                  ? filtered_by_year_closed_invoices.map((item, idx) => {
                      return <InvoiceMobileCard key={idx} data={item} />;
                    })
                  : filtered_by_year_closed_invoices.map((item, idx) => {
                      return (
                        <PaidInvoice
                          key={idx}
                          data={item}
                          type={item.invoice_status === 'PastDue' ? 'late' : 'active'}
                        />
                      );
                    }))
              : width >= 769
              ? [...Array(2)].map((item, idx) => {
                  return <PaidInvoice key={idx} isLoading />;
                })
              : [...Array(2)].map((item, idx) => {
                  return <InvoiceMobileCard key={idx} isLoading />;
                })}
          </div>
          <SideBar />
          {width <= 1000 && (
            <>
              <div>
                <CreditAccountCard />
              </div>
              <div>
                <CustomerServiceCard type='sidebar' />
              </div>
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default Invoices;
