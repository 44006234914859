import React from 'react';
import styles from './TransactioPopup.module.scss';
import {Plus} from '../../../assets/icons';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import {useToggle} from '../../../helpers/hooks';
import {DialogMUI} from '../../../shared';

const TransactioPopup = ({details, transaction_type, amount, date, idx}) => {
  const {t} = useTranslation();
  const [open, setOpen] = useToggle(false);
  //const [dialog, toggleDialog] = useToggle(false);

  const buttonClick = (isAbleToRewrite = false) => {
    const element = document.getElementById(`row__${idx}`);
    const scrollPosition = window.scrollY || window.pageYOffset;

    if (!open) {
      setOpen(!open);
      element.classList.remove('fade__border');
    } else {
      setOpen(!open);
      if (element) {
        element.classList.add('fade__border');
      }
    }

    if (isAbleToRewrite) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set('scroll', scrollPosition);
      window.history.replaceState({}, '', `${window.location.pathname}?${queryParams}`);
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      const scrollToPosition = queryParams.get('scroll');

      if (scrollToPosition !== null) {
        const parsedScrollPosition = parseInt(scrollToPosition, 10);

        if (!isNaN(parsedScrollPosition)) {
          // Introduce a delay of 300ms before scrolling
          setTimeout(() => {
            window.scrollTo({
              top: parsedScrollPosition,
              behavior: 'smooth'
            });
          }, 300);
        } else {
          console.error('Invalid scroll position:', scrollToPosition);
        }
      }
    }
  };

  if (open) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }

  return (
    <div>
      <button
        aria-label='expand'
        className={`${styles['button']} ${open && styles['button__open']}`}
        onClick={() => buttonClick(true)}
        open={open}
      >
        <Plus />
      </button>

      <DialogMUI showCloseButton open={open} onClose={() => buttonClick(false)}>
        <div className={`${styles['inner']} `}>
          <div className={`${styles['content-wrapper']}`}>
            <div className={styles['interest__block']}>
              <p className={styles['interest__payment']}>
                {transaction_type.replace(/_/g, ' ')} {dayjs(date).format('DD.MM.YYYY ')}
              </p>

              <div className={styles['interest__value']}>
                <p> {amount.toLocaleString('sv-SE')}</p>

                <span>kr</span>
              </div>

              <p className={styles['interest__ledger']}>
                {transaction_type === 'interest' ? t('TRANSIACTIONPOPUP.this') : t('TRANSIACTIONPOPUP.here')}
              </p>
            </div>

            <div className={styles['transaction__block']}>
              <div className={styles['transaction__header']}>
                {transaction_type === 'interest' ? (
                  <>
                    <p>{t('TRANSIACTIONPOPUP.date')}</p>
                    <p>{t('TRANSIACTIONPOPUP.ledger')}</p>
                    <p>{t('TRANSIACTIONPOPUP.sum')}</p>
                  </>
                ) : (
                  <>
                    <p>{t('TRANSIACTIONPOPUP.transaction')}</p>
                    <p>{t('TRANSIACTIONPOPUP.ledger')}</p>
                    <p>{t('TRANSIACTIONPOPUP.sum')}</p>
                  </>
                )}
              </div>
              <div className={styles['transaction__list']}>
                {details &&
                  details.length > 0 &&
                  details.map((item, idx) => {
                    return (
                      <div key={idx} className={styles['transaction__list--item']}>
                        {transaction_type !== 'interest' ? (
                          <>
                            <p className={styles['transaction__list--date']}>
                              {item?.transaction_type.replace(/_/g, ' ')}
                            </p>
                            <p className={styles['transaction__list--ledger']}>{item?.ledger_id}</p>
                            <p className={styles['transaction__list--sum']}>
                              {item?.amount.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
                            </p>
                          </>
                        ) : (
                          <>
                            <p className={styles['transaction__list--date']}>
                              {dayjs(item?.date).format('DD.MM.YYYY')}
                            </p>
                            <p className={styles['transaction__list--ledger']}>{item?.ledger_id}</p>
                            <p className={styles['transaction__list--sum']}>
                              {item?.amount.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
                            </p>
                          </>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>

            <button className={styles['interest__cancel']} onClick={buttonClick}>
              {t('SETTINGSFORM.cancel')}
            </button>
          </div>
        </div>
      </DialogMUI>
    </div>
  );
};

export default TransactioPopup;
