import React from 'react';
import Reveal from 'react-awesome-reveal';
import {keyframes} from '@emotion/react';

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0px, +100px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const AnimationWrapper = ({children}) => {
  return (
    <Reveal keyframes={customAnimation} triggerOnce={true}>
      {children}
    </Reveal>
  );
};

export default AnimationWrapper;
