import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import {applyMiddleware, createStore} from 'redux';
import {multiClientMiddleware} from 'redux-axios-middleware';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import axios from 'axios';
import {API_BASE_URL} from '../config';
import {setToastParams} from '../features/Auth/authActions';
import {store} from '../../src/index';

const api = {
  default: {
    client: axios.create({
      baseURL: API_BASE_URL,
      responseType: 'json'
    })
  },

  second: {
    client: axios.create({
      baseURL: 'https://dev-mobileapp-apm.azure-api.net/',
      responseType: 'json'
    })
  }
};

const setTestType = (url) => {
  const urlWithoutQuerry = url.split('?')[0];
  switch (urlWithoutQuerry) {
    case 'ledgers/GetLatestTransactions':
      return 'latesttransactions1';

    case 'invoices/GetInvoices':
      return 'invoices1';

    case 'ledgers/GetTransactions':
      return 'transactions1';

    case '/invoices/GetInvoiceDetails':
      return 'invoicedetail1';

    case 'customers/PostEmailConfirmation':
      return 'confirmationemail1';

    // case 'customers/GetCustomer':
    //   return 'customer1';

    case '/sessionhandler/RefreshSession':
      return 'refreshsession';

    // case 'customers/GetCustomerDetails':
    //   return 'customerdetails1';

    case '/applications/GetApplicationConfirmationDetails':
      return 'new-customer';
    // new-customer  | upselling

    case 'applications/GetCreditContracts':
      return 'creditcontracts1';

    case '/applications/GetIncompleteApplication':
      return 'full-data';

    case '/products/GetActiveProducts':
      //{single | multi-joined | multi-gap | 3products}
      //3products 5000-15000/15001-25000/25001-50000
      //multi-gap 5000-15000/25001-50000
      //multi-joined 5000-15000/15001-20000/
      //single 5000-20000
      return '3products';

    case '/cms/GetNotifications':
      //single | multiple
      return 'multiple';

    // case 'ledgers/GetLedger':
    //   //{ledger1 | ledger2}
    //   return 'ledger2';

    case '/applications/ConfirmApplication':
      return 'application-accepted';

    //{application-accepte | application-error}

    case '/invoices/GetPDF':
      return 'invoicepdf1';

    case '/invoices/ConfirmPFM':
      return 'confirmpfm2';

    case '/applications/AbandonApplication':
      return 'success';

    case '/applications/SaveApplication':
      return 'test-session-application';

    case '/withdrawals/NewWithdrawal':
      return 'waiting_for_confirmation';

    case 'tink/GetTinkLinkUrl':
      return 'tinklinkurl1';

    case 'tink/HandleTinkReturn':
      return 'handletinkreturn1';

    case 'applications/GetPDF':
      return 'applicationpdf1';

    default:
      return 'invoices1';
  }
};

//THE LIST OF ENDPOINTS THAT DON'T USE THE TEST TYPE
const liveModeArr = [
  'customers/GetCustomerDetails',
  'customers/GetCustomer',
  'customers/GetCustomerFlags',
  '/customers/UpdateCustomerDetails',
  '/withdrawals/NewWithdrawal',
  '/withdrawals/CancelWithdrawal',
  '/withdrawals/ConfirmWithdrawal',
  '/withdrawals/GetWithdrawalConfirmationDetails',
  '/customers/ConfirmEmailLink',
  '/invoices/GetInvoices',
  //'ledgers/GetLedger',
  'customers/SendConfirmationEmail'
];
const axiosMiddlewareOptions = {
  interceptors: {
    request: [
      (action, config) => {
        const withoutHeaders = config.url === '/customers/ConfirmEmailLink';

        config.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_SUBSCRIPTION_KEY;

        // if (
        //   config.url === '/applications/GetIncompleteApplication' ||
        //   config.url === '/applications/SaveApplication' ||
        //   config.url === '/applications/UpdateApplication' ||
        //   config.url === '/applications/SubmitApplication'
        // ) {
        //   config.headers['session_id'] = 'test-session-application';
        // } else {
        //   if (
        //     !liveModeArr.includes(config.url) &&
        //     !config.url.includes('SendConfirmationEmail') &&
        //     !config.url.includes('ConfirmEmailCode') &&
        //     !config.url.includes('GetWithdrawalConfirmationDetails')
        //     // &&!config.url.includes('GetActiveProducts')
        //   ) {
        //     config.headers['test-mode'] = 'true';
        //     config.headers['test-type '] = setTestType(config.url);
        //   } else {
        //     if (!withoutHeaders) {
        //       config.headers['session_id'] = localStorage.getItem('session_id') || '12345752';
        //     }
        //   }
        // }

        if (config.url.includes('ledgers/GetLatestTransactions') || config.url.includes('ledgers/GetTransactions')) {
          config.headers['brand_id'] = '22';
        }

        if (config.url.includes('tink/HandleTinkReturn')) {
          config.headers['X-Forwarded-For'] = sessionStorage.getItem('IP');
        }

        if (config.url.includes('AuthAutostart') || config.url.includes('BankIDLogin')) {
          config.headers['test-mode'] = 'true';
          config.headers['test-type '] = setTestType(config.url);
        }

        config.headers['session_id'] = localStorage.getItem('session_id') || '12345752';

        return config;
      }
    ],

    response: [
      {
        success: ({dispatch}, response) => {
          if (response.headers.expires) {
            sessionStorage.setItem('expired_date', new Date(response.headers.expires).toISOString());
          }

          return response;
        },
        error: ({dispatch}, error) => {
          if (error.response.status === 401) {
            localStorage.clear();
          }
          if (error.response.status === 502 || error.response.status === 500) {
            store.dispatch(
              setToastParams({
                toast_open: true,
                toast_type: false,
                toast_text: 'Something went wrong',
                toast_description: 'If the problem persist, please contact the customer service'
              })
            );
          }

          return Promise.reject(error);
        }
      }
    ]
  }
};

export const axiosInstance = axios.create({
  baseURL: 'https://dev-mobileapp-apm.azure-api.net/',
  responseType: 'json'
});

axiosInstance.interceptors.request.use((config) => {
  config.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_SUBSCRIPTION_KEY;
  config.headers['session_id'] = localStorage.getItem('session_id') || '12345752';

  // if (!liveModeArr.includes(config.url)) {
  //   config.headers['test-mode'] = 'true';
  //   config.headers['test-type '] = setTestType(config.url);
  // }
  return config;
});

export const history = createBrowserHistory();

export const setupStore = () => {
  const appRouterMiddleware = routerMiddleware(history);
  const sagaMiddleware = createSagaMiddleware();

  const createStoreWithMiddleware = applyMiddleware(
    multiClientMiddleware(api, axiosMiddlewareOptions),
    appRouterMiddleware,
    sagaMiddleware
  )(createStore);

  const store = createStoreWithMiddleware(
    rootReducer(history),
    {},
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
  );

  sagaMiddleware.run(rootSaga);

  return store;
};
