import React, {useEffect, useState} from 'react';
import styles from './ConfirmationEmailStatus.module.scss';
import {useTranslation} from 'react-i18next';
import {useLocation, Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {getConfirmEmailLink} from '../authActions';
import confirmed from '../../../assets/icons/confirmed.svg';
import {mainPath} from '../../../routes/paths';
import testAnimatedLogo from '../../../assets/icons/test_logo.gif';
import {useSelector} from 'react-redux';
import {Logotype, Alarm} from '../../../assets/icons';

const ConfirmationEmailStatus = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {search} = useLocation();

  const {email_confirmation_loading, email_confirmation_error} = useSelector((state) => state.auth);

  const [email, setEmail] = useState('');
  const [encoded_text, setEncodedText] = useState('');

  useEffect(() => {
    encoded_text && dispatch(getConfirmEmailLink({encoded_text: `${encoded_text}`}));
  }, [encoded_text]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailParam = params.get('email');
    const encodedTextParam = params.get('encoded_text');

    if (emailParam) setEmail(emailParam);
    if (encodedTextParam) {
      setEncodedText(search.split('encoded_text=')[1]);
    }
  }, []);

  return (
    <div className={styles.root}>
      {email_confirmation_loading ? (
        <>
          <div style={{display: email_confirmation_loading ? 'flex' : ' none'}}>
            <img className={styles['logo']} src={testAnimatedLogo} alt='testAnimatedLogo' />
          </div>

          <h1 className={styles.root__title}>{t('CONFIRM_EMAIL_SUCCESS.wait')}</h1>
        </>
      ) : email_confirmation_error ? (
        <>
          <Alarm />
          <h1 className={styles.root__title}>{t('CONFIRM_EMAIL_SUCCESS.oops')}</h1>
          <p className={styles.root__subtitle}>{t('CONFIRM_EMAIL_SUCCESS.warning')}</p>
          <Link className={styles.root__link} to={mainPath.settings.path}>
            {t('CONFIRM_EMAIL_SUCCESS.return')}
          </Link>
        </>
      ) : (
        <>
          <img className={styles.root__confirmed} src={confirmed} alt='confirmed' />
          <h1 className={styles.root__title}>{t('CONFIRM_EMAIL_SUCCESS.title')}</h1>
          <p className={styles.root__subtitle}>{t('CONFIRM_EMAIL_SUCCESS.subtitle')}</p>
          {email && <b className={styles.root__email}>{email}</b>}
          <Link className={styles.root__link} to={mainPath.settings.path}>
            {t('CONFIRM_EMAIL_SUCCESS.return')}
          </Link>
        </>
      )}
    </div>
  );
};

export default ConfirmationEmailStatus;
