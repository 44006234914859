import React, {useState, useEffect} from 'react';
import styles from './CreditForm.module.scss';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {formSchema} from './formSchema';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import {submitApp} from './creditFormActions';
import {getProducts} from '../Invoices/invoicesActions';
import {getUserData} from './creditFormActions';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {creditAppPath, mainPath} from '../../routes/paths';
import {useToggle} from '../../helpers/hooks';
import AvbrytPopup from './AvbrytPopup/AvbrytPopup';
import {setToastParams} from '../Auth/authActions';
import Warning from './Warning';

const CreditForm = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {costumer_details} = useSelector(({credit_form}) => credit_form);
  const ip = sessionStorage.getItem('IP');

  const onSubmit = async () => {
    if (!ip) {
      history.push(mainPath.overview.path);
      return;
    }

    const res = await dispatch(submitApp({id: costumer_details?.id, ip_address: ip}));

    if (res?.payload?.status === 200) {
      const status = res.payload.data.status;
      localStorage.setItem('application_id', res.payload.data.application_id);

      switch (status) {
        case 'WaitingForConfirmation':
          history.push(creditAppPath.applicationStatusPath.path + `?type=default`);
          break;
        case 'Rejected':
          history.push(creditAppPath.applicationStatusPath.path + `?type=rejected`);
          break;
        case 'WaitingForConfirmationAtRisk':
          history.push(
            creditAppPath.applicationStatusPath.path + `?type=badloan&application_id=${res.payload.data.application_id}`
          );
          break;
        case 'WaitingForCustomerToCompletePSD2':
          history.push(creditAppPath.tinkPath.path);
          break;
        case 'Error':
          history.push(mainPath.overview.path);
          dispatch(
            setToastParams({
              toast_open: true,
              toast_type: false,
              toast_text: t('APPLICATION_STATUS.went_wrong'),
              toast_description: t('APPLICATION_STATUS.went_wrong_try')
            })
          );
          break;
        case 'WaitingForManual':
          history.push(mainPath.overview.path);
          break;
        default:
          break;
      }
    } else {
      dispatch(
        setToastParams({
          toast_open: true,
          toast_type: false,
          toast_text: t('APPLICATION_STATUS.went_wrong'),
          toast_description: t('APPLICATION_STATUS.went_wrong_try')
        })
      );
      history.push(mainPath.overview.path);
    }
  };

  const [email_send, setSendEmail] = useState(true);
  const [sms_send, setSendSms] = useState(true);
  const [accept, setAccept] = useState(false);
  const [warning, showWarning] = useState(false);
  const [dialog, setDialog] = useToggle(false);

  const {
    handleSubmit,
    resetField,
    clearErrors,
    setError,
    trigger,
    formState: {errors, touchedFields, isValid, dirtyFields, ...rest},
    ...formProps
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'all',
    resolver: yupResolver(formSchema),
    defaultValues: {
      sms_marketing: true,
      allow_sms_marketing: '',
      amount: '',
      // co_borrower: false,
      contracts_amount: '',
      contracts_exist: '',
      email: '',
      household_debt: '',
      how_did_you_find_us: '',
      loan_usage: '',
      loans_amount: '',
      // loans_exists: '',
      number_of_adults: '',
      number_of_minors: '',
      people: '',
      person_1: '',
      personal_expenses_monthly_expenses: '',
      personal_income_monthly_gross_income: '',
      personal_income_monthly_net_income: '',
      phone: '',
      post_code: '',
      city: '',
      street: '',
      total_income: ''
    }
  });

  useEffect(() => {
    dispatch(getUserData())
      .then((userDataResponse) => {
        const userData = userDataResponse.payload?.data?.basic_info;
        if (userData) {
          setSendEmail(userData.allow_email_marketing);
          setSendSms(userData.allow_sms_marketing);
        }
      })
      .then(() => dispatch(getProducts('flexlimit')))

      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  // }, [costumer_details]);

  return (
    <div className={`${styles.root} container`}>
      <Warning param='full' />
      <div className={`${styles.container} `}>
        <h1 className={styles['credit__title']}>{t('CREDIT_FORM.title')}</h1>
        <p className={styles['credit__subtitle']}>{t('CREDIT_FORM.subtitle')}</p>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <StepOne
            resetField={resetField}
            dirtyFields={dirtyFields}
            errors={errors}
            touchedFields={touchedFields}
            email_send={email_send}
            setSendEmail={setSendEmail}
            sms_send={sms_send}
            setSendSms={setSendSms}
            {...formProps}
          />
          <StepTwo
            resetField={resetField}
            dirtyFields={dirtyFields}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            touchedFields={touchedFields}
            {...formProps}
          />
          <StepThree
            resetField={resetField}
            dirtyFields={dirtyFields}
            touchedFields={touchedFields}
            setError={setError}
            clearError={clearErrors}
            errors={errors}
            trigger={trigger}
            {...formProps}
          />
          <StepFour trigger={trigger} accept={accept} setAccept={setAccept} errors={errors} {...formProps} />

          <div
            onMouseEnter={() => !isValid && showWarning(true)}
            onMouseLeave={() => !isValid && showWarning(false)}
            className={styles.continue__container}
          >
            <button
              disabled={!accept || !isValid}
              className={`${styles.continue} ${accept && isValid && 'green'} main__button`}
              type='submit'
            >
              {t('CREDIT_FORM.continue')}
            </button>
            {warning && <p className={styles.please}>{t('CREDIT_FORM.warning')}</p>}
          </div>

          <button onClick={() => setDialog()} className={styles.avbryt} type='button'>
            {t('CREDIT_FORM.avbryt')}
          </button>
        </form>
      </div>
      <AvbrytPopup dialog={dialog} handleCancelation={setDialog} />
    </div>
  );
};

export default CreditForm;
