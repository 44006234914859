import {CONTRACTS} from './contractsActionTypes';

const INITIAL_STATE = {
  loading: false,
  pdf_loading: false,
  contracts: null,
  pdf: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONTRACTS.GET_CONTRACTS:
      return {...state, loading: true};
    case CONTRACTS.GET_PDF:
      return {...state, pdf_loading: true};
    case CONTRACTS.GET_CONTRACTS_SUCCESS:
      return {...state, loading: false, contracts: action.payload.data};

    case CONTRACTS.GET_PDF_SUCCESS:
      return {...state, pdf_loading: false, pdf: action.payload.data};

    default:
      return state;
  }
}
