import {INVOICES} from './invoicesActionTypes';

const INITIAL_STATE = {
  credit_loading: false,
  loading: false,
  pdf_loading: false,
  closed_invoices: null,
  open_invoices: null,
  current_invoice: null,
  loading_invoices: [],
  loaded_invoices_ids: [],
  loaded_invoices_info: [],
  products: null,
  confirm_pfm: null,
  credits_list: null,
  pfm_data: null,
  pdf: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case INVOICES.GET_INVOICES:
      return {...state, loading: true};
    case INVOICES.CONFIRM_PFM:
    case INVOICES.GET_PFM_INFO:
      return {...state, loading: true};

    case INVOICES.CONFIRM_PFM_FAIL:
      return {...state, loading: false};

    case INVOICES.GET_PFM_INFO_SUCCESS:
      return {...state, loading: false, pfm_data: action?.payload?.data};

    case INVOICES.GET_PFM_INFO_FAIL:
      return {...state, loading: false, pfm_data: null};

    case INVOICES.GET_PDF:
      return {...state, pdf: null, pdf_loading: true};

    case INVOICES.GET_INVOICE:
      return {...state, loading_invoices: [...state.loading_invoices, +action.payload.request.url.split('=')[1]]};

    case INVOICES.GET_INVOICE_FAIL:
      const invoice_id = +action.payload.request.url.split('=')[1];
      return {...state, loading_invoices: state.loading_invoices.filter((item) => item != invoice_id)};

    case INVOICES.GET_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        closed_invoices: action?.payload?.data?.closed_invoices,

        open_invoices: action?.payload?.data?.open_invoices
      };

    case INVOICES.GET_INVOICE_SUCCESS:
      const loaded_invoice_id = +action.payload.config.url.split('=')[1];

      return {
        ...state,
        loaded_invoices_ids: [...state.loaded_invoices_ids, loaded_invoice_id],
        loading_invoices: state.loading_invoices.filter((item) => item != loaded_invoice_id),
        loaded_invoices_info: [...state.loaded_invoices_info, {...action?.payload?.data}],
        current_invoice: action?.payload?.data
      };
    case INVOICES.GET_PRODUCTS:
      return {...state, credit_loading: true};
    case INVOICES.GET_PRODUCTS_FAIL:
      return {...state, credit_loading: false};
    case INVOICES.GET_PRODUCTS_SUCCESS:
      return {...state, credits_list: action?.payload?.data, credit_loading: false};

    case INVOICES.GET_INVOICES_SUCCESS:
      return {...state, products: action?.payload?.data};

    case INVOICES.GET_PDF_SUCCESS:
      return {...state, pdf_loading: false, pdf: action?.payload?.data};

    default:
      return state;
  }
}
