import React, {useMemo} from 'react';
import styles from './RangeInput.module.scss';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {iOS} from '../../helpers/functions';

const RangeInput = ({
  sliderWidth = 700,
  step = 5,
  setter,
  value,
  totalLimit = 20000,
  availableLimit = 18000,
  usedLimit = 3000,
  disabled = false,
  showMarks = true
}) => {
  const usedLimitWidth = usedLimit !== 0 && Math.ceil((sliderWidth * usedLimit) / totalLimit);
  const availableLimitWidth = disabled
    ? Math.ceil(sliderWidth - usedLimitWidth)
    : Math.ceil(sliderWidth - usedLimitWidth);

  const percentValueAdapter = (enteredValue) => {
    const percentValue = Math.round((enteredValue * availableLimit) / 100 / 10) * 10;

    if (percentValue >= 500) {
      setter(percentValue);
    } else {
      setter(500);
    }
  };

  const generateMarks = useMemo(() => {
    if (totalLimit / 10000 >= 1) {
      let generatedMarks = [];
      const stepSize = Math.floor(totalLimit / 10000);
      const stepValue = totalLimit / stepSize;

      for (let i = 0; i <= stepSize; i++) {
        const value = +(i * stepValue * 100) / totalLimit;
        const label = (i * 10000).toLocaleString('sv-SE');
        generatedMarks.push({value, label});
      }

      // if (availableLimit <= totalLimit) {
      //   const availableValue = +(availableLimit * 100) / totalLimit;
      //   generatedMarks.push({value: availableValue, label: `${availableLimit.toLocaleString('sv-SE')}`});
      // }

      generatedMarks.sort((a, b) => a.value - b.value);
      return (generatedMarks = [
        {value: 0, label: 0},
        {value: 25, label: Math.floor(totalLimit / 4)},
        {value: 50, label: Math.floor(totalLimit / 2)},
        {value: 75, label: Math.floor(totalLimit * 0.75)},
        {value: 100, label: totalLimit}
      ]);
    } else {
      let generatedMarks = [];
      return (generatedMarks = [
        {value: 0, label: 0},
        {value: 25, label: Math.floor(totalLimit / 4)},
        {value: 50, label: Math.floor(totalLimit / 2)},
        {value: 75, label: Math.floor(totalLimit * 0.75)},
        {value: 100, label: totalLimit}
      ]);
    }
  }, [totalLimit, availableLimit]);

  const isiOS = iOS();

  return (
    <div className={`${styles.root} ${disabled && 'disabled'}`}>
      <Box sx={{width: availableLimitWidth}}>
        <Slider
          aria-label='Custom marks'
          step={step}
          value={value}
          onChange={(e) => {
            !isiOS && navigator.vibrate([15, 30, 15, 30]);
            !disabled && percentValueAdapter(e.target.value);
          }}
          valueLabelDisplay='off'
          color='secondary'
          sx={{
            width: availableLimitWidth,
            color: `${disabled ? '#D9D9D9' : '#235971'}`,
            '& .MuiSlider-mark': {
              background: 'transparent'
            },
            '& .MuiSlider-markLabel': {
              top: '120px'
            },
            '&.MuiSlider-root': {
              height: '70px',
              padding: '0'
            },
            '& .MuiSlider-track': {
              borderRadius:
                usedLimitWidth > 0 ? '20px 0 0 20px' : value === 100 ? '20px 20px 20px 20px' : '20px 0 0 20px',
              borderRight: disabled ? '1px solid #CBCBCB' : '1px solid black'
            },
            '& .MuiSlider-thumb': {
              borderRadius: '50%',
              width: '38px',
              height: '38px',
              background: '#22232A',
              zIndex: 100
            },
            '& .MuiSlider-rail': {
              backgroundColor: '#D9D9D9',
              opacity: 1,
              borderRadius: showMarks && usedLimitWidth > 0 ? '20px 0px 0px 20px' : '20px 20px 20px 20px',
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)'
            }
          }}
        />
        {showMarks && usedLimitWidth > 0 && <hr className={disabled ? styles.hr__disabled : styles.hr} />}
      </Box>

      {showMarks && usedLimitWidth > 0 && (
        <div className={styles.loading} style={{width: usedLimitWidth}}>
          <div className={disabled ? styles.bg__disabled : styles.bg}></div>
        </div>
      )}

      {showMarks && (
        <div className={styles.marks}>
          {generateMarks.length > 0 &&
            generateMarks.map((item, idx) => {
              const isLastChild = idx === generateMarks.length - 1;
              const leftPosition = isLastChild
                ? `calc(${item.value}% - 40px)`
                : `calc(${item.value}% - ${totalLimit / 10000 >= 1 ? 22 : 22}px)`;
              return (
                <span className={styles['marks__elem']} key={idx} style={{left: idx === 0 ? 0 : leftPosition}}>
                  {item.label}
                </span>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default RangeInput;
