import React, {useState, useEffect} from 'react';
import styles from './ChatBubble.module.scss';
import {Chat, Hide, FileIcon} from '../../assets/icons';
import {useTranslation} from 'react-i18next';
import {FileUploader} from 'react-drag-drop-files';
import {useDispatch, useSelector} from 'react-redux';
import {showMenu} from '../../features/Settings/settingsActions';
import {useWindowWidth} from '../../helpers/hooks';

const ChatBubble = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const width = useWindowWidth();
  const [isOpen, setIsOpen] = useState(false);
  const [isScrollToTop, setScrollToTop] = useState(true);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const {is_menu_shown} = useSelector((state) => state.settings);
  const [hasNew, setHasNew] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.pageYOffset;
      if (prevScrollPosition > currentPosition) {
        setScrollToTop(true);
      } else {
        setScrollToTop(false);
      }
      setPrevScrollPosition(currentPosition);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPosition]);

  const handleOpenChat = () => {
    setHasNew(false);
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    dispatch(showMenu(isOpen));
    const rootElement = document.getElementById('root');
    if (rootElement) {
      if (isOpen) {
        rootElement.classList.add('root__blur');
      } else {
        rootElement.classList.remove('root__blur');
      }
    }
    return () => {
      if (rootElement) {
        rootElement.classList.remove('root__blur');
      }
    };
  }, [dispatch, isOpen]);

  //SET SCROLL DISABLE

  useEffect(() => {
    if (width < 768) {
      if (isOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'initial';
      }
    }
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, [isOpen]);

  return (
    <div
      className={`${styles.root} ${
        is_menu_shown
          ? styles.bottom
          : prevScrollPosition < 20
          ? styles.top
          : isScrollToTop
          ? styles.top
          : styles.bottom
      }`}
    >
      <div className={styles.chat__container}>
        <div className={`${styles.chat__block} ${isOpen ? styles.chat__open : styles.chat__closed}`}>
          <div className={styles.chat__sticky}>
            <h3 className={styles.chat__title}>{t('CHAT_BUBLE.leave')}</h3>
            <button onClick={handleOpenChat} className={styles.chat__hide} type='button' aria-label='hide chat'>
              <Hide />
            </button>
          </div>

          <div className={styles.chat__column}>
            <div className={styles.chat__input}>
              <label htmlFor='chat__name'> {t('CHAT_BUBLE.name')}</label>
              <input id='chat__name' type='text' />
            </div>
            <div className={styles.chat__input}>
              <label htmlFor='chat__email'> {t('CHAT_BUBLE.email')}</label>
              <input id='chat__email' type='text' />
            </div>
            <div className={styles.chat__input}>
              <label htmlFor='chat__message'> {t('CHAT_BUBLE.message')}</label>
              <textarea id='chat__message' type='text' />
            </div>
            <div className={styles.chat__files}>
              <label htmlFor='chat__attachments'> {t('CHAT_BUBLE.attachments')}</label>
              <FileUploader
                handleChange={(e) => console.log(e)}
                multiple={true}
                maxSize={5}
                style={{border: '1px dashed var(--border-element, #B0B0B0)'}}
                children={
                  <div className={styles.dragAndDropContainer}>
                    <span className={styles.icon}>
                      <FileIcon />
                    </span>
                    <p className={styles.text}>{t('CHAT_BUBLE.add')}</p>
                  </div>
                }
              />
            </div>
            <button className={styles.chat__send} type='button'>
              {t('CHAT_BUBLE.send')}
            </button>
          </div>
        </div>

        <button
          onClick={handleOpenChat}
          className={`${styles.chat__button} ${hasNew && styles['chat__button--active']}`}
          aria-label='open chat'
        >
          <Chat />
        </button>
        {isOpen && <div className={styles['triangle']}></div>}
      </div>
    </div>
  );
};

export default ChatBubble;
