import React, {useState, useEffect} from 'react';
import styles from './Contract.module.scss';
import {ArrowRightBlack} from '../../../assets/icons';
import {File} from '../../../assets/icons';
import {SkeletonMUI, AnimationWrapper} from '../../../shared';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import {getPDF} from '../contractsActions';
import {useDispatch, useSelector} from 'react-redux';
import {handleDownload} from '../../../helpers/functions';

const Contract = ({data, isLoading}) => {
  const dispatch = useDispatch();
  const [pdfDownloaded, setPdfDownloaded] = useState(false);
  const {t} = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const {pdf, loading} = useSelector(({contracts}) => contracts);

  const arrowHandler = () => {
    setShowMenu(!showMenu);
  };

  //DOWNLOAD PDF FUNCTION
  const handleDownloadPDF = async (id, type, name) => {
    await dispatch(getPDF(id, type, name));
    setPdfDownloaded(true);
  };

  useEffect(() => {
    pdfDownloaded && handleDownload(pdf);
    setPdfDownloaded(false);
  }, [pdfDownloaded]);

  const rowsArr = [
    {id: 2, title: 'CONTRACT.rate', value: data?.product_details?.nominal_interest_rate, sufix: ' %'},
    {id: 3, title: 'CONTRACT.payment', value: data?.product_details?.minimum_payment_percent, sufix: ' %'},
    {
      id: 4,
      title: 'CONTRACT.minimum_payment',
      value: data?.product_details?.minimum_payment_amount.toLocaleString('sv-SE', {
        style: 'currency',
        currency: 'SEK'
      }),
      sufix: ''
    },
    {
      id: 5,
      title: 'CONTRACT.fee',
      value: data?.product_details?.origination_fee.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'}),
      sufix: ''
    },
    {
      id: 6,
      title: 'CONTRACT.fees',
      value: data?.product_details?.monthly_fees.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'}),
      sufix: ' '
    },
    {
      id: 7,
      title: 'CONTRACT.apr',
      value: data?.product_details?.apr.toFixed(2),
      sufix: '%'
    }
  ];

  return isLoading ? (
    <SkeletonMUI className={styles.skeleton} height={200} loading />
  ) : (
    <AnimationWrapper>
      <div className={styles.root}>
        {
          <div
            className={`${styles.header} ${
              data?.status === 'Active' ? 'active' : data?.status === 'Rejected' ? 'rejected' : 'terminated'
            }`}
          >
            <b>
              {data?.status === 'Rejected' ? t('CONTRACT.rejected') : `${t('CONTRACT.loan')}`}
              {data?.status !== 'Rejected' && `${data?.contract_id}.`}
            </b>
            {data?.status === 'Active'
              ? t('CONTRACT.status_a')
              : data?.status === 'Rejected'
              ? `${t('CONTRACT.status_rejected_on')} ${dayjs(data?.applied_date).format('DD.MM.YYYY')}`
              : `${t('CONTRACT.status_closed')} ${dayjs(data?.terminated_date).format('DD.MM.YYYY')}`}
          </div>
        }
        <div onClick={arrowHandler} className={styles['paidinvoice__header']}>
          <>
            <div className={styles['paidinvoice__char']}>
              <p className={styles['paidinvoice__char--title']}>{t('CONTRACT.date')}</p>
              <span className={styles['paidinvoice__char--value']}>
                {dayjs(data?.applied_date).format('DD.MM.YYYY')}
              </span>
            </div>
            {
              <div className={styles['paidinvoice__char']}>
                <p className={styles['paidinvoice__char--title']}>{t('CONTRACT.amount')}</p>
                <span className={styles['paidinvoice__char--value']}>
                  {data?.product_details?.limit.toLocaleString('sv-SE', {style: 'currency', currency: 'SEK'})}
                </span>
              </div>
            }
          </>

          <button aria-label='show menu' onClick={arrowHandler} className={styles['paidinvoice__button']}>
            <ArrowRightBlack className={!showMenu ? styles.icon : styles.icon__rotate} />
          </button>
        </div>
        <div className={`${styles['paidinvoice__main']} ${showMenu ? styles.show : styles.hide}`}>
          <div className={styles['paidinvoice__column']}>
            <div className={styles['paidinvoice__table']}>
              {rowsArr.map((item, idx) => {
                return (
                  <div className={styles['paidinvoice__table--row']} key={idx}>
                    <p>{t(item.title)}</p>
                    <span>
                      {item.value}
                      {item?.sufix}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>

          <div className={styles['paidinvoice__column']}>
            <div className={styles['contract__subtitle']}>{t('CONTRACT.below')}</div>

            {data &&
              !loading &&
              data?.documents &&
              data?.documents.length > 0 &&
              data?.documents.map((item, idx) => {
                return (
                  <button
                    key={idx}
                    onClick={() => handleDownloadPDF(data?.contract_id, item.document_type, item.file_name)}
                    className={styles['paidinvoice__download']}
                  >
                    <File className='mr10' />
                    {t('CONTRACT.download')} {item?.file_name}
                  </button>
                );
              })}
          </div>
        </div>
      </div>
    </AnimationWrapper>
  );
};

export default Contract;
