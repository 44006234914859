import React from 'react';
import {DialogMUI} from '../../../shared';
import {useTranslation} from 'react-i18next';
import {Alarm} from '../../../assets/icons';
import styles from './AvbrytPopup.module.scss';
import {useHistory} from 'react-router-dom';
import {mainPath} from '../../../routes/paths';
import {useDispatch, useSelector} from 'react-redux';
import {abandonApplication} from '../creditFormActions';
import {setToastParams} from '../../Auth/authActions';

const AvbrytPopup = ({dialog, handleCancelation}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {costumer_details} = useSelector((state) => state.credit_form);

  const handleClosePopup = () => {
    handleCancelation();
  };

  const handleSave = () => {
    history.push(mainPath.overview.path);
  };

  const handleAbandon = () => {
    costumer_details.id &&
      dispatch(abandonApplication(costumer_details.id)).then((res) => {
        if (res?.payload?.status === 200) {
          handleSave();
        } else {
          handleSave();
          dispatch(
            setToastParams({
              toast_open: true,
              toast_type: false,
              toast_text: t('APPLICATION_STATUS.went_wrong'),
              toast_description: 'If the problem persist, please contact the customer service'
            })
          );
        }
      });
  };

  return (
    <DialogMUI open={dialog} showCloseButton={false} onClose={handleCancelation}>
      <div className={styles.root}>
        <Alarm className={styles.icon} />

        <h2 className={styles.main_title}>{t('AVBRYT_POPUP.title')}</h2>

        <b className={styles.title}>{t('AVBRYT_POPUP.description')}</b>
        <p className={styles.paragraph}>{t('AVBRYT_POPUP.description_2')}</p>

        <button onClick={handleSave} type='button' className={`${styles['session__button--filled']} main__button`}>
          {t('AVBRYT_POPUP.save')}
        </button>
        <button onClick={handleAbandon} type='button' className={`${styles['session__button']} main__button`}>
          {t('AVBRYT_POPUP.abandon')}
        </button>
        <footer className={styles.footer}>
          <button onClick={handleClosePopup} className={styles.back}>
            {t('AVBRYT_POPUP.back')}
          </button>
        </footer>
      </div>
    </DialogMUI>
  );
};

export default AvbrytPopup;
