import React, {useEffect} from 'react';
import styles from './NotificationsBlock.module.scss';
import {Calendar} from '../../assets/icons';
import {creditAppPath, mainPath} from '../../routes/paths';
import PulsationDot from '../PulsationDot';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {showMenu} from '../../features/Settings/settingsActions';
import {useSelector} from 'react-redux';
import {setNotificationFilteredQuantity} from '../../features/Settings/settingsActions';

const NotificationsBlock = ({customer_data, isPopup, toggleDialog, isNotification = false, closeDropdown = null}) => {
  const {t} = useTranslation();
  const {notifications, notification_quantity} = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleRedirect = (link) => {
    history.push(link);
    dispatch(showMenu(false));
    closeDropdown(false);
  };

  const notificationsToDisplay = isNotification
    ? [
        notification_quantity.lenght > 0 && notification_quantity[0]?.type === 'div'
          ? notifications[1]
          : notifications.lenght >= 1
          ? notifications[0]
          : notifications[1]
      ]
    : notifications;

  const notificationItems = notificationsToDisplay?.map((notification, index) => {
    const key = `notification_${index}`;

    switch (notification?.type) {
      case 'UnconfirmedEmail':
        return (
          <div
            key={key}
            className={`${styles['card__footer--elem']} ${
              isNotification && styles['card__footer--elem__notification']
            }`}
          >
            <PulsationDot className='mr17' />
            <p>
              {t('PROFILECARD.please')}
              <button onClick={() => handleRedirect(`${mainPath.settings.path}?anchor=resend`)}>
                {t('PROFILECARD.resend')}
              </button>
            </p>
          </div>
        );

      case 'ApplicationWaitingForCustomerPSD2':
        return (
          <div
            key={key}
            className={`${styles['card__footer--elem']} ${
              isNotification && styles['card__footer--elem__notification']
            }`}
          >
            <PulsationDot className='mr17' />
            <p>
              {t('PROFILECARD.waiting')}
              <button onClick={() => handleRedirect(`${creditAppPath.tinkPath.path}`)}>
                {t('PROFILECARD.continue_here')}
              </button>
            </p>
          </div>
        );

      case 'CanApply':
        return (
          <div
            key={key}
            className={`${styles['card__footer--elem']} ${
              isNotification && styles['card__footer--elem__notification']
            }`}
          >
            <PulsationDot className='mr17' />
            <p>
              {t('PROFILECARD.apply')}{' '}
              <button onClick={() => handleRedirect(creditAppPath.applicationStatusPath.path)}>
                {t('PROFILECARD.apply_link')}
              </button>
            </p>
          </div>
        );

      case 'EligibleForUpselling':
        return (
          <div
            key={key}
            className={`${styles['card__footer--elem']} ${
              isNotification && styles['card__footer--elem__notification']
            }`}
          >
            <PulsationDot type='blue' className='mr17' />
            <p>
              {t('PROFILECARD.eligable_upselling')}{' '}
              <button onClick={() => handleRedirect(creditAppPath.creditPath.path)}>
                {t('PROFILECARD.eligable_upselling_here')}
              </button>
            </p>
          </div>
        );

      case 'Banned':
        return (
          <div
            key={key}
            className={`${styles['card__footer--elem']} ${
              isNotification && styles['card__footer--elem__notification']
            }`}
          >
            <PulsationDot className='mr17' />
            <p>{t('PROFILECARD.banned')} </p>
          </div>
        );

      case 'SupervisionOfInterest':
        return (
          <div
            key={key}
            className={`${styles['card__footer--elem']} ${
              isNotification && styles['card__footer--elem__notification']
            }`}
          >
            <PulsationDot className='mr17' />
            <p>{t('PROFILECARD.supervision')} </p>
          </div>
        );

      case 'InvoiceOpen':
        return (
          <div
            key={key}
            className={`${styles['card__footer--elem']} ${
              isNotification && styles['card__footer--elem__notification']
            }`}
          >
            <PulsationDot type='blue' pulse={true} className='mr17' />
            <p>
              {' '}
              {t('PROFILECARD.open_invoice')} {notification?.meta_data?.days_due} {t('PROFILECARD.open_invoice_days')}
              <br />
              <button
                onClick={() => handleRedirect(`${mainPath.invoices.path}?invoice_id=${notification?.identifier}`)}
              >
                {t('PROFILECARD.see')}
              </button>
            </p>
          </div>
        );

      case 'InvoicePastDue':
        return (
          <div
            key={key}
            className={`${styles['card__footer--elem']} ${
              isNotification && styles['card__footer--elem__notification']
            }`}
          >
            <PulsationDot className='mr17' />
            <p>
              {t('PROFILECARD.past_due')}
              <button
                onClick={() => {
                  handleRedirect(`${mainPath.invoices.path}?invoice_id=${notification?.identifier}`);
                }}
              >
                {t('PROFILECARD.resolve')}
              </button>
            </p>
          </div>
        );

      case 'WithdrawalTransferring':
        return (
          <div
            key={key}
            className={`${styles['card__footer--elem']} ${
              isNotification && styles['card__footer--elem__notification']
            }`}
          >
            <PulsationDot className='mr17' type='blue' pulse={true} />

            <p>
              {t('PROFILECARD.pending')} {notification?.meta_data?.withdrawal_amount} kr {t('PROFILECARD.pending_2')}
            </p>
          </div>
        );

      case 'InvoiceInArrears':
        return (
          <div
            key={key}
            className={`${styles['card__footer--elem']} ${
              isNotification && styles['card__footer--elem__notification']
            }`}
          >
            <PulsationDot className='mr17' />

            <p>
              {t('PROFILECARD.arreares')}{' '}
              <button
                onClick={() => {
                  handleRedirect(`${mainPath.invoices.path}?invoice_id=${notification?.identifier}`);
                }}
              >
                {t('PROFILECARD.arreares_pay')}
              </button>{' '}
              {t('PROFILECARD.arreares_to')}
            </p>
          </div>
        );

      case 'ApplicationUnfinished':
        return (
          <div
            key={key}
            className={`${styles['card__footer--elem']} ${
              isNotification && styles['card__footer--elem__notification']
            }`}
          >
            <PulsationDot className='mr17' />
            <p>
              {t('PROFILECARD.you_have')}
              <button
                onClick={() => {
                  handleRedirect(creditAppPath.creditPath.path);
                }}
              >
                {t('PROFILECARD.unfinished')}
              </button>
            </p>
          </div>
        );

        {
          /* 
Your application is waiting for confirmation. 
Confirm here */
        }

        {
          /* {
        <div className={styles['card__footer--elem']}>
          <PulsationDot className='mr17' />
          <p>
            {t('PROFILECARD.confirm_your')}
            <Link to={mainPath.invoices.path}>{t('PROFILECARD.confirm')}</Link>
          </p>
        </div>
      } */
        }

        {
          /* Your application was denied */
        }

        {
          /* {
        <div className={styles['card__footer--elem']}>
          <PulsationDot className='mr17' />
          <p>{t('PROFILECARD.denied')} </p>
        </div>
      } */
        }

      // case 'ApplicationWaitingForCustomerPSD2':
      //   return (
      //     <div className={styles['card__footer--elem']}>
      //       <PulsationDot className='mr17' />
      //       <p>
      //         {' '}
      //         {t('PROFILECARD.waiting_psd')}{' '}
      //         <button onClick={() => handleRedirect(creditAppPath.creditPath.path)}>
      //           {t('PROFILECARD.waiting_psd_continue')}
      //         </button>
      //       </p>
      //     </div>
      //   );

      case 'ApplicationWaitingForPSD2Data':
        return (
          <div
            key={key}
            className={`${styles['card__footer--elem']} ${
              isNotification && styles['card__footer--elem__notification']
            }`}
          >
            <PulsationDot className='mr17' />
            <p> {t('PROFILECARD.waiting_psd_tink')}</p>
          </div>
        );

      case 'ApplicationAccepted':
        return null;

      case 'ApplicationWaitingForConfirmation':
        return (
          <div
            key={key}
            className={`${styles['card__footer--elem']} ${
              isNotification && styles['card__footer--elem__notification']
            }`}
          >
            <PulsationDot type='blue' pulse={true} className='mr17' />
            <p>
              {' '}
              {t('PROFILECARD.approved')}
              <br />
              <button onClick={() => handleRedirect(`${creditAppPath.applicationStatusPath.path}`)}>
                {t('PROFILECARD.finalize')}
              </button>
            </p>
          </div>
        );

      case 'EligibleForPaymentFreeMonth':
        return (
          <div
            key={key}
            className={`${styles['card__footer--elem']} ${
              isNotification && styles['card__footer--elem__notification']
            } ${styles['card__footer--calendar']}`}
          >
            <Calendar className='mr17 ml3' />
            <p>
              {t('PROFILECARD.have')} {t('PROFILECARD.payment')}
              <br />
              <button onClick={toggleDialog} type='button'>
                {t('PROFILECARD.apply_here')}{' '}
              </button>
            </p>
          </div>
        );

      case 'ApplicationDenied':
        return (
          <div
            key={key}
            className={`${styles['card__footer--elem']} ${
              isNotification && styles['card__footer--elem__notification']
            }`}
          >
            <PulsationDot className='mr17' />
            {t('PROFILECARD.aplication_denied')}
          </div>
        );

      // <div className={styles['card__footer--elem']}>
      //   <PulsationDot className='mr17' />
      //   <p>
      //     {t('PROFILECARD.unpaid')}
      //     <Link to={mainPath.invoices.path}>{t('PROFILECARD.resolve')}</Link>
      //   </p>
      // </div>s

      // {/* Your application has been approved! */}
      // <div className={styles['card__footer--elem']}>
      //   <PulsationDot type='blue' pulse={false} className='mr17' />
      //   <p>
      //     {t('PROFILECARD.approved')}{' '}
      //     <Link to={creditAppPath.applicationStatusPath.path}>{t('PROFILECARD.finalize')}</Link>
      //   </p>
      // </div>

      // <div className={`${styles['card__footer--elem']} ${styles['card__footer--calendar']}`}>
      //   <Calendar className='mr17 ml3' />
      //   <p>
      //     {t('PROFILECARD.have')}{' '}
      //     <button onClick={toggleDialog} type='button'>
      //       {t('PROFILECARD.payment')}{' '}
      //     </button>
      //     {t('PROFILECARD.available')}
      //   </p>
      // </div>

      default:
        return null;
    }
  });

  useEffect(() => {
    notificationItems.filter((item) => item).length > 0 &&
      dispatch(setNotificationFilteredQuantity(notificationItems.filter((item) => item)));
  }, [notifications]);

  return (
    <>
      <div className={`${styles.root} ${isPopup && styles.root__popup}`}>{notificationItems} </div>
    </>
  );
};

export default NotificationsBlock;
