export const rootAuthPath = '/auth';
export const rootMainPath = '/main';
export const rootCreditAppPath = '/credit';

export const authPath = {
  signIn: {path: `${rootAuthPath}/sign-in`, name: 'Sign In'},
  choogeAuth: {path: `${rootAuthPath}/choose-authorization`, name: 'BankID e-legitimation'},
  //FOR APP
  signInApp: {path: `${rootAuthPath}/sign-in-app`, name: 'Sign In'},
  choogeAuthApp: {path: `${rootAuthPath}/choose-authorization-app`, name: 'BankID e-legitimation'},
  expiredSession: {path: `${rootAuthPath}/expired-session`, name: 'Expired session'},
  confirmationEmailCode: {path: `${rootAuthPath}/confirmation-email-code`, name: 'Confirm email'},
  confirmationEmailStatus: {path: `${rootAuthPath}/confirmation-email-status`, name: 'Confirmation email status'}
};

export const mainPath = {
  overview: {path: `${rootMainPath}/overview`, name: 'Overview'},
  contracts: {path: `${rootMainPath}/contracts`, name: 'Contracts'},
  transactions: {path: `${rootMainPath}/transactions`, name: 'Transactions'},
  settings: {path: `${rootMainPath}/settings`, name: 'Settings'},
  invoices: {path: `${rootMainPath}/invoices`, name: 'Invoices'},
  customerReviews: {path: `${rootMainPath}/customer-reviews`, name: 'Customer reviews'},
  responsibleLending: {path: `${rootMainPath}/responsible-lending`, name: 'Responsible lending'},
  examples: {path: `${rootMainPath}/examples`, name: 'Examples'},
  navLink: {path: `${rootMainPath}/nav-link`, name: 'Nav link'}
};

export const creditAppPath = {
  creditPath: {path: `${rootCreditAppPath}/credit-form`, name: 'Credit App'},
  tinkPath: {path: `${rootCreditAppPath}/tink`, name: 'Tink'},
  dataCollectionPath: {path: `${rootCreditAppPath}/data-collection`, name: 'Data collection'},
  applicationStatusPath: {path: `${rootCreditAppPath}/application-status`, name: 'Application Status'},
  tinkProcessingPath: {path: `${rootCreditAppPath}/tink-processing`, name: 'Tink Processing'}
};
