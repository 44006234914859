export const SETTINGS = {
  GET_SETTINGS: 'GET_SETTINGS',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAIL: 'GET_SETTINGS_FAIL',

  GET_CUSTOMER: 'GET_CUSTOMER',
  GET_CUSTOMER_SUCCESS: 'GET_CUSTOMER_SUCCESS',
  GET_CUSTOMER_FAIL: 'GET_CUSTOMER_FAIL',

  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  UPDATE_SETTINGS_SUCCESS: 'UPDATE_SETTINGS_SUCCESS',
  UPDATE_SETTINGS_FAIL: 'UPDATE_SETTINGS_FAIL',

  CONFIRM_EMAIL_CODE: 'CONFIRM_EMAIL_CODE',
  CONFIRM_EMAIL_CODE_SUCCESS: 'CONFIRM_EMAIL_CODE_SUCCESS',
  CONFIRM_EMAIL_CODE_FAIL: 'CONFIRM_EMAIL_CODE_FAIL',

  POST_EMAIL_CONFIRMATION: 'POST_EMAIL_CONFIRMATION',
  POST_EMAIL_CONFIRMATION_SUCCESS: 'POST_EMAIL_CONFIRMATION_SUCCESS',
  POST_EMAIL_CONFIRMATION_FAIL: 'POST_EMAIL_CONFIRMATION_FAIL',

  SET_NOTIFICATION_QUANTITY: 'SET_NOTIFICATION_QUANTITY',

  GET_TINK: 'GET_TINK',
  GET_TINK_SUCCESS: 'GET_TINK_SUCCESS',
  GET_TINK_FAIL: 'GET_TINK_FAIL',

  EMAIL_CONFIRMED: 'EMAIL_CONFIRMED',
  EMAIL_CONFIRMED_SUCCESS: 'EMAIL_CONFIRMED_SUCCESS',
  EMAIL_CONFIRMED_FAIL: 'EMAIL_CONFIRMED_FAIL',

  GET_HANDLE_TINK: 'GET_HANDLE_TINK',
  GET_HANDLE_TINK_SUCCESS: 'GET_HANDLE_TINK_SUCCESS',
  GET_HANDLE_TINK_FAIL: 'GET_HANDLE_TINK_FAIL',

  PSD2_FOR_APP: 'PSD2_FOR_APP',
  PSD2_FOR_APP_SUCCESS: 'PSD2_FOR_APP_SUCCESS',
  PSD2_FOR_APP_FAIL: 'PSD2_FOR_APP_FAIL',

  SET_SHOW_MENU: 'SET_SHOW_MENU',

  SET_GUIDE_STEP: 'SET_GUIDE_STEP',

  GET_CUSTOMER_FLAGS: 'GET_CUSTOMER_FLAGS',
  GET_CUSTOMER_FLAGS_SUCCESS: 'GET_CUSTOMER_FLAGS_SUCCESS',
  GET_CUSTOMER_FLAGS_FAIL: 'GET_CUSTOMER_FLAGS_FAIL',

  GET_CUSTOMER_NOTIFICATIONS: 'GET_CUSTOMER_NOTIFICATIONS',
  GET_CUSTOMER_NOTIFICATIONS_SUCCESS: 'GET_CUSTOMER_NOTIFICATIONS_SUCCESS',
  GET_CUSTOMER_NOTIFICATIONS_FAIL: 'GET_CUSTOMER_NOTIFICATIONS_FAIL'
};
