import {CREDIT_FORM} from './creditFormActionTypes';

export function setAppFisrtCircle(data) {
  return {
    type: CREDIT_FORM.SET_APP_FIRST_CIRCLE,
    data
  };
}
export function setAppSecondCircle(data) {
  return {
    type: CREDIT_FORM.SET_APP_SECOND_CIRCLE,
    data
  };
}

export function setAppThirdCircle(data) {
  return {
    type: CREDIT_FORM.SET_APP_THIRD_CIRCLE,
    data
  };
}

export function setAppSeventhCircle(data) {
  return {
    type: CREDIT_FORM.SET_APP_SEVENTH_CIRCLE,
    data
  };
}

export function setAppEightsCircle(data) {
  return {
    type: CREDIT_FORM.SET_APP_EIGHTS_CIRCLE,
    data
  };
}

export function setWithdrawalCirclesQuantity(data) {
  return {
    type: CREDIT_FORM.SHOW_WITHDRAWAL_CIRCLES,
    data
  };
}

export function setLoader() {
  return {
    type: CREDIT_FORM.SET_TIN_LOADER
  };
}

export function setAppFourthCircle(data) {
  return {
    type: CREDIT_FORM.SET_APP_FOURTH_CIRCLE,
    data
  };
}

export function updateCustomerDetails(data) {
  return {
    type: CREDIT_FORM.UPDATE_COSTUMER_DETAIL,
    payload: {
      client: 'second',
      request: {
        url: `/applications/UpdateApplication`,
        method: 'patch',
        data
      }
    }
  };
}

export function cancelApplication(data) {
  return {
    type: CREDIT_FORM.CANCEL_APP,
    payload: {
      client: 'second',
      request: {
        url: `/applications/CancelApplication`,
        method: 'post',
        data
      }
    }
  };
}

export function setPSD2ForApplication(data) {
  return {
    type: CREDIT_FORM.SET_PSD2_FOR_APP,
    payload: {
      client: 'second',
      request: {
        url: `/applications/SetPSD2ForApplication`,
        method: 'post',
        data
      }
    }
  };
}

export function getApplicationStatus(app_id) {
  return {
    type: CREDIT_FORM.GET_APP_STATUS,
    payload: {
      client: 'second',
      request: {
        url: `/applications/GetApplicationStatus?application_id=${app_id}`,
        method: 'get'
      }
    }
  };
}

export function abandonApplication(applicationId) {
  return {
    type: CREDIT_FORM.ABANDON_APP,
    payload: {
      client: 'second',
      request: {
        url: `/applications/AbandonApplication?application_id=${applicationId}`,
        method: 'get'
      }
    }
  };
}

export function getUserData() {
  return {
    type: CREDIT_FORM.GET_INCOMPLETE_DATA,
    payload: {
      client: 'second',
      request: {
        url: `/applications/GetIncompleteApplication`,
        method: 'get'
      }
    }
  };
}

export function saveForm(data) {
  return {
    type: CREDIT_FORM.SAVE_FORM,
    payload: {
      client: 'second',
      request: {
        url: `/applications/SaveApplication`,
        method: 'post',
        data
      }
    }
  };
}

export function submitApp(data) {
  return {
    type: CREDIT_FORM.SUBMIT_APP,
    payload: {
      client: 'second',
      request: {
        url: `/applications/SubmitApplication`,
        method: 'post',
        data
      }
    }
  };
}

export function confirmApplication(data) {
  return {
    type: CREDIT_FORM.CONFIRM_APPLICATION,
    payload: {
      client: 'second',
      request: {
        url: `/applications/ConfirmApplication`,
        method: 'post',
        data
      }
    }
  };
}

export function getApplicationConfirmationDetails(application_id) {
  return {
    type: CREDIT_FORM.GET_APPLICATION_CONFIRMATION,
    payload: {
      client: 'second',
      request: {
        url: `/applications/GetApplicationConfirmationDetails?application_id=${application_id}`,
        method: 'get'
      }
    }
  };
}

export function GetWithdrawalConfirmationDetails(application_id) {
  return {
    type: CREDIT_FORM.GET_WITHDRAWAL_CONFIRMATION,
    payload: {
      client: 'second',
      request: {
        url: `/withdrawals/GetWithdrawalConfirmationDetails?application_id=${application_id}`,
        method: 'get'
      }
    }
  };
}
