import React, {useState} from 'react';
import styles from './Auth.module.scss';
import logo from './images/bank.png';
import {Qr, Devices, ArrowRightBlack, Error} from '../../assets/icons';
import {getCollectRequest, CreateSessionWithBankID} from './authActions';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {useTranslation} from 'react-i18next';

import {getCustomer} from '../Settings/settingsActions';

import {useHistory} from 'react-router-dom';
import {authPath, mainPath, creditAppPath} from '../../routes/paths';
import BankIdQrCode from './BankIdQrCode/BankIdQrCode';
import BankIdOnTheDevice from './BankIdOnTheDevice/';
import {checkIsApp} from '../../helpers/functions';

const ChooseAuth = () => {
  const {IP, autoStartToken} = useSelector(({auth}) => auth);
  const [pageData, setPageData] = useState({step: 1, type: null});
  const dispatch = useDispatch();
  const history = useHistory();
  const {t} = useTranslation();
  const isApp = checkIsApp();
  const {search} = useLocation();

  function checkForApplicationWaiting(array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].type === 'ApplicationWaitingForCustomerPSD2') {
        return true;
      }
    }
    return false;
  }

  const handleCollectInterval = () => {
    dispatch(getCollectRequest(autoStartToken)).then(
      (res) =>
        res?.payload?.status === 200 &&
        res.payload.data.Status === 'complete' &&
        pageData.type === 'device' &&
        dispatch(CreateSessionWithBankID({autostart_token: autoStartToken, ip_address: IP})).then(
          (res) =>
            res?.payload?.status === 200 &&
            (sessionStorage.setItem('expires', res.payload.data.expires),
            sessionStorage.setItem('session_id', res.payload.data.session_id),
            //HERE WE CHECK IF USER HAS ALREADY HAVE A ApplicationWaitingForCustomerPSD
            isApp
              ? dispatch(getCustomer()).then((res) =>
                  res?.payload?.status === 200 && checkForApplicationWaiting(res?.payload?.data?.notifications)
                    ? history.push(creditAppPath.tinkPath.path)
                    : history.push(`${creditAppPath.creditPath.path}${search}`)
                )
              : history.push(mainPath.overview.path))

          //history.push(isApp ? creditAppPath.creditPath.path : mainPath.overview.path))
        )
    );
  };

  return (
    <div className={styles.root}>
      <img className={styles.logo} src={logo} alt='bank id' />
      <h2 className={styles.title}>{t('CHOOSEAUTH.identification')}</h2>
      {pageData.step === 1 && (
        <>
          <button onClick={() => setPageData({step: 2, type: 'device'})} className={styles.auth__type}>
            <Devices /> <p>{t('CHOOSEAUTH.ondevice')}</p> <ArrowRightBlack />
          </button>

          <button onClick={() => setPageData({step: 2, type: 'qr'})} className={styles.auth__type}>
            <Qr /> <p>{t('CHOOSEAUTH.qr')}</p> <ArrowRightBlack />
          </button>
        </>
      )}

      {pageData.step === 2 && pageData.type === 'device' && <BankIdOnTheDevice actionFunc={handleCollectInterval} />}
      {pageData.step === 2 && pageData.type === 'qr' && <BankIdQrCode />}
      {pageData.step === 3 && (
        <>
          <Error />
          <p className={`${styles.text} mb16`}>{t('CHOOSEAUTH.withoutbank')}</p>
          <p className={`${styles.text} mb16`}>{t('CHOOSEAUTH.internet')} https://install.bankid.com.</p>
        </>
      )}
      <button
        onClick={() =>
          pageData.step === 1
            ? history.push(isApp ? authPath.signInApp.path : authPath.signIn.path)
            : setPageData({...pageData, step: pageData.step - 1 < 1 ? 1 : pageData.step - 1})
        }
        className={styles.link}
      >
        {t('CHOOSEAUTH.back')}
      </button>
    </div>
  );
};

export default ChooseAuth;
