import React, {useState} from 'react';
import styles from './ConfirmEmailPopup.module.scss';
import {DialogMUI} from '../../../shared';
import {useDispatch} from 'react-redux';
import {useQuery} from 'react-query';
import {useTranslation} from 'react-i18next';
import Letter from '../../Auth/images/letter.png';
import Success from './success.png';
import {MarkSuccess} from '../../../assets/icons';
import {confirmEmailCode, postEmailConfirmation} from '../../Settings/settingsActions';
import {axiosInstance} from '../../../app/store';
import {notifyError} from '../../../helpers/notifySnack';
import {isEmailConfirmed} from '../../Settings/settingsActions';

const ConfirmEmailPopup = ({dialog, handleCancelation, email}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [step, setStep] = useState(1);

  const {data: customer_data} = useQuery('customer', async () => {
    const response = await axiosInstance.get('customers/GetCustomer');
    return response.data;
  });

  const [code, setCode] = useState('');

  const handleSetCode = (e) => {
    if (e.target.value.length <= 5) {
      setCode(e.target.value);
    }
  };

  const handleSubmitEmailCode = (e) => {
    e.preventDefault();
    dispatch(confirmEmailCode({code, email: email})).then((res) => {
      if (res.payload.data.success) {
        setStep(2);
        dispatch(isEmailConfirmed(email));
      } else {
        if (res.payload.data.message === 'email_code_not_confirmed') {
          notifyError("An email code wasn't confirmed");
        }
      }
    });
  };

  const handleReturnWithoutConfirming = () => {
    handleCancelation();
    setStep(1);
  };

  const handleResendEmail = (email) => {
    dispatch(postEmailConfirmation(JSON.stringify({email_address: email}))).then((res) => {
      if (res.payload.data.success) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      } else {
        notifyError(res.payload.data.message);
      }
    });
  };
  return (
    <DialogMUI contentClassName={styles.container} open={dialog} showCloseButton={false} onClose={handleCancelation}>
      <form className={`${styles.root} ${success && styles.root__xl}`}>
        {step === 1 ? (
          <>
            <img className={styles.root__letter} src={Letter} alt='Letter' />
            <h2 className={styles.root__title}>{t('CONFIRM_EMAIL_POPUP.title')}</h2>
            <p className={styles.root__subtitle}>
              {t('CONFIRM_EMAIL_POPUP.subtitle')} <b>{email}</b>
            </p>

            <input
              value={code}
              onChange={(e) => handleSetCode(e)}
              placeholder={'-----'}
              className={styles.root__input}
              type='text'
            />
            <button
              type='button'
              disabled={code.length !== 5}
              onClick={(e) => handleSubmitEmailCode(e)}
              className={`${styles['root__button']}`}
            >
              {t('CONFIRM_EMAIL_POPUP.confirm')}
            </button>

            <button
              type='button'
              onClick={() => handleResendEmail(email)}
              className={`${styles['root__resend__button']}`}
            >
              {t('CONFIRM_EMAIL_POPUP.resend')}
            </button>

            <div
              className={`${styles['root__success']} ${
                success ? styles['root__success--open'] : styles['root__success--closed']
              }`}
            >
              <MarkSuccess />
              <p>
                Confirmation email sent to <b>{email}</b>
              </p>
            </div>

            <button
              type='button'
              onClick={() => handleReturnWithoutConfirming()}
              className={`${styles['root__return']}`}
            >
              {t('CONFIRM_EMAIL_POPUP.return')}
            </button>
          </>
        ) : (
          <>
            <img className={styles.root__mark} src={Success} alt='Letter' />
            <h2 className={styles.root__title}>{t('CONFIRM_EMAIL_POPUP.confirmed')}</h2>
            <p className={styles.root__subtitle}>
              {t('CONFIRM_EMAIL_POPUP.your_email')} <b>{email}</b>
            </p>
            <button
              type='button'
              onClick={() => handleReturnWithoutConfirming()}
              className={`${styles['root__return']}`}
            >
              {t('CONFIRM_EMAIL_POPUP.return2')}
            </button>
          </>
        )}
      </form>
    </DialogMUI>
  );
};

export default ConfirmEmailPopup;
