import React, {useState, useEffect, useRef} from 'react';
import styles from './SettingsForm.module.scss';
import {
  CheckboxMUI,
  InputField,
  PhoneField,
  BillingCalendar,
  AnimationWrapper,
  DropdownSelect,
  ToastComponent
} from '../../../shared';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {settingsSchema} from './settingsSchema';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {getSettings, postEmailConfirmation, getTinkUrl, confirmEmailCode} from '../settingsActions';
import {updateSettings} from '../settingsActions';
import {useWindowWidth} from '../../../helpers/hooks';
import {useHistory, useLocation} from 'react-router-dom';
import Loader from '../../../assets/images/loader.png';
import {authPath} from '../../../routes/paths';

const SettingsForm = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [sms, setSMS] = useState(true);
  const [email, setEmail] = useState(true);
  const [bill_date, setBillDate] = useState(1);
  const [clearingNumber, setClearingNumber] = useState('FI20 8934 4756 9345 878');
  const width = useWindowWidth();
  const params = useLocation();
  const anchor = params.search.split('=').length > 0 && params.search.split('=')[1];
  const {settings, loading, options, settings_loading, customer_loading} = useSelector(({settings}) => settings);
  const targetRef = useRef(null);

  useEffect(() => {
    if (anchor && targetRef.current) {
      targetRef.current.scrollIntoView({behavior: 'smooth'});

      const topOffset = targetRef.current.getBoundingClientRect().top + window.scrollY - 280;
      window.scroll({top: topOffset, behavior: 'smooth'});
    }
  }, [settings]);

  const history = useHistory();
  const processInputValue = (value) => {
    if (value.startsWith('+46')) {
      return value.substring(3);
    } else if (value.startsWith('46')) {
      return value.substring(2);
    }
    return value;
  };

  const [showToast, setShowToast] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    resetField,
    trigger,
    formState: {errors, isValid, touchedFields}
  } = useForm({
    mode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(settingsSchema),
    defaultValues: {
      street: '',
      postal_code: '',
      city: '',
      phone: '',
      email_address: '',
      account: '',
      email_marketing: '',
      sms_marketing: '',
      invoice_expiry_day: ''
    }
  });

  const onSubmit = async (data) => {
    const updateBody = {
      address: {
        street: data.street,
        city: data.city,
        postal_code: data.postal_code
      },
      invoice_expiry_day: data.invoice_expiry_day >= 1 && data.invoice_expiry_day <= 31 ? data.invoice_expiry_day : 1,
      email: {
        email_address: data.email_address
      },
      consents: [
        {consent: 1, consent_agreement: data.email_marketing},
        {consent: 2, consent_agreement: data.sms_marketing}
      ],
      phone: '+46' + processInputValue(data.phone)
    };

    dispatch(updateSettings(updateBody)).then((res) => {
      setShowToast(true), resetField('email_address', {keepTouched: false});
    });
  };

  useEffect(() => {
    dispatch(getSettings()).then((res) => {
      res?.payload?.status === 200 &&
        (setValue('sms_marketing', res.payload.data.consents[1].consent_agreement),
        setValue('email_marketing', res.payload.data.consents[0].consent_agreement),
        res?.payload?.data?.phone && setValue('phone', processInputValue(res?.payload?.data?.phone)),
        setValue('street', res.payload.data.address.street),
        setValue('postal_code', res.payload.data.address.postal_code),
        setValue('city', res.payload.data.address.city),
        setValue('email_address', res.payload.data.email.email_address),
        setValue('invoice_expiry_day', res.payload.data.invoice_expiry_day),
        setBillDate(res.payload.data.invoice_expiry_day),
        setSMS(res.payload.data.consents[1].consent_agreement),
        setEmail(res.payload.data.consents[0].consent_agreement),
        res.payload.data.accounts.map((elem, idx) => elem?.is_primary === true && setClearingNumber(elem?.iban)));
    });
  }, []);

  const handleClose = () => {
    history.push('/');
  };

  const confirmation__email = getValues('email_address');
  const handleResendEmail = (email) => {
    dispatch(postEmailConfirmation(JSON.stringify({email_address: email})));
  };

  const handleGoToConfirmPasswordPage = () => {
    history.push(`${authPath.confirmationEmailCode.path}?from=settings`);
  };

  const unconfirmedEmail = settings && settings.email && !settings.email.confirmed;

  const [session_id, setSessionId] = useState(localStorage.getItem('session_id'));

  const handleSetSessionId = (value) => {
    setSessionId(value);
    localStorage.setItem('session_id', value);
  };

  return (
    <>
      <ToastComponent
        isFixed
        isSuccess={true}
        visible={showToast}
        setter={setShowToast}
        title={'Settings were  succesfully updated'}
      />
      <AnimationWrapper>
        <div className={styles.root}>
          <div className={styles.form__header}>
            <h3 className={styles['form__header--title']}>{t('SETTINGSFORM.personal')}</h3>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.header}>
              <div className={styles.header__char}>
                <span>{t('SETTINGSFORM.first')}</span>
                <p>
                  {settings?.first_name} {settings?.given_name}
                </p>
              </div>
              <div className={styles.header__char}>
                <span>{t('SETTINGSFORM.surname')}</span>
                <p>{settings?.last_name}</p>
              </div>
              <div className={styles.header__char}>
                <span>{t('SETTINGSFORM.security')}</span>
                <p>{settings?.ssn}</p>
              </div>
            </div>

            <div className={styles.row}>
              <Controller
                name='street'
                control={control}
                render={({field}) => (
                  <InputField
                    onChange={(value) => field.onChange(value)}
                    label={t('SETTINGSFORM.street')}
                    placeholder={t('SETTINGSFORM.street_placeholder')}
                    subtitle={width > 650 && t('SETTINGSFORM.street_subtitle')}
                    error={errors.street?.message}
                    inputProps={field}
                  />
                )}
              />
              <Controller
                name='postal_code'
                control={control}
                render={({field}) => (
                  <InputField
                    onChange={(value) => field.onChange(value)}
                    label={t('SETTINGSFORM.postal')}
                    placeholder='00100'
                    subtitle={width > 650 && t('SETTINGSFORM.postal_subtitle')}
                    error={errors.postal_code?.message}
                    inputProps={field}
                  />
                )}
              />
            </div>
            <div className={styles.row}>
              <Controller
                name='city'
                control={control}
                render={({field}) => (
                  <InputField
                    label={t('SETTINGSFORM.city')}
                    onChange={(value) => field.onChange(value)}
                    placeholder={t('SETTINGSFORM.city_placeholder')}
                    subtitle={t('SETTINGSFORM.city_subtitle')}
                    error={errors.city?.message}
                    inputProps={field}
                  />
                )}
              />

              <Controller
                name='phone'
                control={control}
                render={({field}) => (
                  <PhoneField
                    label={t('SETTINGSFORM.phone')}
                    placeholder=' 70 123 45 67'
                    subtitle={t('SETTINGSFORM.phone_subtitle')}
                    error={errors.phone?.message}
                    inputProps={field}
                  />
                )}
              />
            </div>

            <div className={styles.row}>
              <div>
                <Controller
                  name='email_address'
                  control={control}
                  render={({field}) => (
                    <InputField
                      label={
                        customer_loading || touchedFields?.email_address
                          ? t('SETTINGSFORM.email')
                          : unconfirmedEmail
                          ? t('SETTINGSFORM.email_unconfirmed')
                          : t('SETTINGSFORM.email_confirmed')
                      }
                      placeholder='email-mc-email@face.com'
                      className={
                        unconfirmedEmail
                          ? styles.email__unconfirmed
                          : !customer_loading && !touchedFields?.email_address && styles.email__confirmed
                      }
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      subtitle={t('SETTINGSFORM.email_subtitle')}
                      error={errors.email_address?.message}
                      inputProps={field}
                    />
                  )}
                />

                {unconfirmedEmail && (
                  <>
                    <button
                      ref={targetRef}
                      id='resend'
                      onClick={() => handleResendEmail(confirmation__email)}
                      type='button'
                      className={`${styles['resend__btn']} main__button `}
                    >
                      {t('SETTINGSFORM.resend')}
                    </button>
                    <button
                      onClick={() => handleGoToConfirmPasswordPage()}
                      className={`${styles['enter__code']} `}
                      type='button'
                    >
                      {t('SETTINGSFORM.enter')}
                    </button>
                  </>
                )}
              </div>
            </div>
            <h3 className={styles.form__title}>{t('SETTINGSFORM.details')}</h3>
            <div className={`${styles.row} ${styles.row__calendar}`}>
              <div>
                <Controller
                  name='account'
                  control={control}
                  render={({field}) => (
                    <DropdownSelect
                      label={t('SETTINGSFORM.clearing')}
                      className={styles['reapply__select']}
                      placeholder={clearingNumber}
                      onChange={(value) => setValue('account', value)}
                      subtitle={null}
                      values={options}
                      error={errors.account?.message}
                      inputProps={field}
                    />
                  )}
                />
                <div className={styles['reapply__root']}>
                  <p className={styles['reapply__txt']}>{t('SETTINGSFORM.primary__description')}</p>
                  <button
                    type='button'
                    onClick={() => dispatch(getTinkUrl('settings'))}
                    className={`${styles['reapply__btn']} main__button `}
                  >
                    {t('SETTINGSFORM.reapply')}
                  </button>
                </div>
              </div>

              <div>
                <BillingCalendar
                  active={bill_date}
                  //IT SHOULD BE DISABLED
                  setter={null}
                  title={t('SETTINGSFORM.billing_date')}
                  subtitle={t('SETTINGSFORM.subtitle')}
                />
              </div>
            </div>

            <h3 className={styles.form__title}>Developer mode</h3>

            <InputField
              label={'Session id'}
              placeholder='123456789'
              onChange={(e) => handleSetSessionId(e.target.value)}
              inputProps={{value: session_id || ''}}
              error={false}
              subtitle={'Enter your session id here'}
            />

            <h3 className={styles.form__title}>{t('SETTINGSFORM.advertising')}</h3>
            <b className={styles.bold__text}>{t('SETTINGSFORM.would')}</b>
            {!loading && (
              <div className={styles.checkbox}>
                <CheckboxMUI
                  id='checkbox-1'
                  onChange={(e) => {
                    setValue('email_marketing', !email);
                    setEmail(!email);
                  }}
                  checked={email}
                  className='mr6'
                />

                <label htmlFor={'checkbox-1'} className={styles.checkbox__text}>
                  {t('SETTINGSFORM.email_send')}
                </label>
              </div>
            )}
            {!loading && (
              <div className={styles.checkbox}>
                <CheckboxMUI
                  id='checkbox-2'
                  onChange={(e) => {
                    setValue('sms_marketing', !sms);
                    setSMS(!sms);
                  }}
                  checked={sms}
                  className='mr6'
                />
                <label htmlFor={'checkbox-2'} className={styles.checkbox__text}>
                  {t('SETTINGSFORM.sms')}
                </label>
              </div>
            )}

            <button disabled={settings_loading} type='submit' className={`${styles['settings__save']} main__button `}>
              {settings_loading ? (
                <span className={styles.spin}>
                  <img src={Loader} alt='loading' />
                </span>
              ) : (
                t('SETTINGSFORM.save')
              )}
            </button>
            <button onClick={handleClose} type='button' className={`${styles['settings__cancel']}`}>
              {t('SETTINGSFORM.cancel')}
            </button>
          </form>
        </div>
      </AnimationWrapper>
    </>
  );
};

export default SettingsForm;
