import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {rootMainPath, rootAuthPath, mainPath} from './paths';
import Overview from '../features/Overview';
import Transactions from '../features/Transactions';
import Contracts from '../features/Contracts';
import NotFound from '../shared/NotFound';
import Invoices from '../features/Invoices';
import Settings from '../features/Settings';
import {checkIsApp} from '../helpers/functions';

const MainRoutes = () => {
  if (!sessionStorage.session_id) return <Redirect to={rootAuthPath} />;
  const isApp = checkIsApp();

  return (
    <Switch>
      <Redirect from={rootMainPath} exact to={mainPath.overview.path} />
      <Route path={mainPath.overview.path} component={Overview} />
      <Route path={mainPath.transactions.path} component={Transactions} />
      <Route path={mainPath.invoices.path} component={Invoices} />
      <Route path={mainPath.contracts.path} component={Contracts} />
      <Route path={mainPath.settings.path} component={Settings} />

      <Route path='*' component={NotFound} />
    </Switch>
  );
};

export default MainRoutes;
