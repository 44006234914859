import React, {useEffect, useState} from 'react';
import styles from './InputFieldCredit.module.scss';
const InputFieldCredit = ({
  label,
  type = 'text',
  isTouched = false,
  inputProps,
  customBlur,
  className,
  placeholder,
  onChange,
  error,
  subtitle
}) => {
  const {value} = inputProps;
  const colorClass = error
    ? 'red'
    : isTouched
    ? !error
      ? value && 'green 1'
      : 'red'
    : value || value === 0
    ? 'green 2'
    : '';

  const {onBlur, ...otherProps} = inputProps;

  return (
    <div className={`${styles.root} ${className} ${colorClass}`}>
      {label && (
        <label className={styles.label} htmlFor={label}>
          {label}
        </label>
      )}
      <input
        type={type}
        className={styles.input}
        placeholder={placeholder}
        id={label}
        onChange={onChange}
        onBlur={customBlur}
        {...otherProps}
      ></input>
      <span className={styles.circle}></span>
      {subtitle && !error && <span className={styles.subtitle}>{subtitle}</span>}
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default InputFieldCredit;
